import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../helpers/API";

export const loginUser = createAsyncThunk(
  "loginUser",
  async (logindetails, thunkAPI) => {
    try {
      const res = await API.post(`/user/login/`, logindetails);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const registerUser = createAsyncThunk(
  "registerUser",
  async (registerdetails, thunkAPI) => {
    try {
      const res = await API.post(`/user/userdetails/`, registerdetails);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
