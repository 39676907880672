import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  FormControl,
  TextField,
  MenuItem,
  Button,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Link, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setPassengers } from "../../redux/flight/flightslice";
import Loader from "../../layouts/Loaders/Loader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Footer from "../../views/footer/Footer";
import { farequotesTwo } from "../../redux/flight/flightapi";

const FareRulesModal = ({ open, handleClose, fareRules }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            overflowY: "auto", // This makes the content scrollable
          }}
        >
          <Typography variant="h6" component="h2">
            Fare Rules
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: fareRules }} />
        </Box>
      </Fade>
    </Modal>
  );
};

const FlightBooking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fareDetails = useSelector((state) => state.flightdata.fareDetails);
  // const Baggage = useSelector((state) => state.flightdata.ssrdetails.Baggage);
  // const MealDynamic = useSelector(
  //   (state) => state.flightdata.ssrdetails.MealDynamic
  // );
  // console.log(Baggage, MealDynamic);
  const fareQuotes = useSelector((state) => state.flightdata.farequotes);
  const loading = useSelector((state) => state.flightdata.loading);
  const searchData = useSelector((state) => state.flightdata.searchData);
  const [open, setOpen] = useState(false);
  const travelerInfo = useSelector(
    (state) => state?.flightdata?.searchData?.travelerInfo
  );

  const flightDetailsTwo = useSelector(
    (state) => state.flightdata.farequotesTwo?.Results?.Segments?.[0]?.[0]
  );

  console.log(flightDetailsTwo);

  const flightDetailsTomap = fareQuotes?.Results?.Segments;
  const flightDetailsTomapTwo = useSelector(
    (state) => state?.flightdata?.farequotesTwo?.Results?.Segments[0]
  );
  console.log(flightDetailsTomapTwo);
  const fareTomap = fareQuotes?.Results?.Fare;

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const passengerSchema = Yup.object().shape({
    passengers: Yup.array().of(
      Yup.object().shape({
        Title: Yup.string().required("Required"),
        FirstName: Yup.string().required("First name is required"),
        LastName: Yup.string().required("Last name is required"),
        Gender: Yup.string().required("Gender is required"),
      })
    ),
    Email: Yup.string().email("Invalid email").required("Email is required"),
    ContactNo: Yup.string().required("Contact number is required"),
    AddressLine1: Yup.string().required("Address is required"),
  });

  const initialPassengerData = Array(
    travelerInfo?.adults + travelerInfo?.children + travelerInfo?.infants
  ).fill({
    Title: "Mr",
    FirstName: "",
    LastName: "",
    PaxType: 1, // This should be 1 for adults, 2 for children, and 3 for infants
    DateOfBirth: "",
    Gender: "1",
    AddressLine1: "",
    CountryCode: "IN",
    CountryName: "INDIA",
    City: "",
    ContactNo: "",
    Email: "",
    PassportNo: "",
    PassportExpiry: "",
    Baggage: [],
    MealDynamic: [],
  });

  const CustomError = ({ fieldName, errors }) => {
    const errorMessage = errors[fieldName];
    return errorMessage ? (
      <div style={{ color: "red" }}>{errorMessage}</div>
    ) : null;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Formik
        initialValues={{
          passengers: initialPassengerData,
          Email: "",
          ContactNo: "",
          AddressLine1: "",
        }}
        validationSchema={passengerSchema}
        onSubmit={(values) => {
          dispatch(setPassengers(values.passengers));
          if (!flightDetailsTwo) {
            navigate("/flight-seat-booking");
          } else {
            navigate("/flight-seat-booking-roundtrip");
          }
          // navigate("/flight-seat-booking");
        }}
      >
        {({ values, handleChange, handleBlur, errors }) => (
          <Form>
            <Box>
              <Container maxWidth="xl">
                <Grid container spacing={2}>
                  <Grid item lg={3} md={3} sm={12} xs={12} my={2}>
                    <Box sx={{ backgroundColor: "#D5E9F4", padding: 2 }}>
                      <Typography sx={{ fontWeight: 700 }}>
                        Fare Summary{" "}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mb: 2,
                          mt: 1,
                        }}
                      >
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <AddIcon />
                          <Typography>Base Fare </Typography>
                        </Box>
                        <Typography>{fareTomap?.BaseFare}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mb: 2,
                        }}
                      >
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <AddIcon />
                          <Typography> Taxes and Surcharges </Typography>
                        </Box>
                        <Typography>{fareTomap?.Tax}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                          <RemoveIcon />
                          <Box sx={{ width: "100%" }}>
                            <Typography> Other Services</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Typography>
                                Charity{" "}
                                <span style={{ color: "#0A1893" }}>Remove</span>
                              </Typography>
                              <Typography>{fareTomap?.OtherCharges}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderTop: "1px solid #000",
                          mt: 1,
                          pt: 1,
                        }}
                      >
                        <Typography sx={{ fontWeight: "700" }}>
                          Total Amount{" "}
                        </Typography>
                        <Typography sx={{ fontWeight: "700" }}>
                          {fareTomap?.PublishedFare}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={9} md={9} sm={12} xs={12} my={2}>
                    <Box
                      sx={{
                        backgroundColor: "#D5E9F4",
                        padding: "7px 15px",
                        mb: 2,
                      }}
                    >
                      <Typography sx={{ fontWeight: "700" }}>
                        TICKET DETAILS
                      </Typography>
                    </Box>
                    {flightDetailsTomap.flat().map((item, index) => (
                      <Box
                        sx={{ backgroundColor: "#D5E9F4", padding: "7px 15px" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Box sx={{ my: 1 }}>
                            <Box sx={{ display: "flex", gap: 2 }}>
                              <Typography sx={{ fontWeight: "700" }}>
                                {item?.Origin?.CityName}
                              </Typography>
                              <Typography sx={{ fontWeight: "700" }}>
                                {item?.Destination?.CityName}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", gap: 2 }}>
                              <Typography
                                sx={{
                                  background: "#95BDFF",
                                  padding: "1px 7px",
                                }}
                              >
                                {moment(item?.DepTime).format("dddd, MMM Do") ??
                                  ""}
                              </Typography>
                              <Typography>{item?.Duration}m</Typography>
                            </Box>
                          </Box>

                          <Box sx={{ my: 1 }}>
                            <Typography>CANCELLATION FEES APPLY</Typography>
                            <Link onClick={handleOpen}>View Fare Rules</Link>
                          </Box>
                          <FareRulesModal
                            open={open}
                            handleClose={handleClose}
                            fareRules={fareDetails?.Results[0]?.FareRuleDetail}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                            mt: 2,
                          }}
                        >
                          <Box sx={{ my: 1 }}>
                            <Box sx={{ display: "flex", gap: 2 }}>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item?.Airline?.AirlineName}
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                {item?.Airline?.AirlineCode}
                                {item?.Airline?.FlightNumber}
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ my: 1 }}>
                            <Typography>
                              <span style={{ fontWeight: "700" }}>
                                {item?.CabinClassName} Class
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ mt: 1 }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: "700", fontSize: "12px" }}
                            >
                              {moment(item?.DepTime).format("h:mm A")}
                            </Typography>
                            <Box sx={{ display: "flex", gap: 2 }}>
                              <Typography
                                sx={{ fontWeight: "700", fontSize: "12px" }}
                              >
                                {item?.Origin?.CityName}
                              </Typography>
                              <Typography sx={{ fontSize: "12px" }}>
                                {item?.Origin?.AirportName}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                              mt: 2,
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: "700", fontSize: "12px" }}
                            >
                              {moment(item?.ArrTime).format("h:mm A")}
                            </Typography>
                            <Box sx={{ display: "flex", gap: 2 }}>
                              <Typography
                                sx={{ fontWeight: "700", fontSize: "12px" }}
                              >
                                {item?.Destination?.CityName}
                              </Typography>
                              <Typography sx={{ fontSize: "12px" }}>
                                {item?.Destination?.AirportName}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                              gap: 2,
                              mt: 1,
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{ fontWeight: "400", fontSize: "14px" }}
                              >
                                Baggage
                              </Typography>
                              <Typography
                                sx={{ fontWeight: "700", fontSize: "12px" }}
                              >
                                ADULT
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                sx={{ fontWeight: "400", fontSize: "14px" }}
                              >
                                Check-in
                              </Typography>
                              <Typography
                                sx={{ fontWeight: "700", fontSize: "12px" }}
                              >
                                {/* 25Kgs */}
                                {item?.Baggage}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                sx={{ fontWeight: "400", fontSize: "14px" }}
                              >
                                Cabin
                              </Typography>
                              <Typography
                                sx={{ fontWeight: "700", fontSize: "12px" }}
                              >
                                {item?.CabinBaggage}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                    {/* {flightDetailsTomapTwo} */}
                    {flightDetailsTomapTwo &&
                      flightDetailsTomapTwo.map((item, index) => (
                        <Box
                          sx={{
                            backgroundColor: "#D5E9F4",
                            padding: "7px 15px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <Box sx={{ my: 1 }}>
                              <Box sx={{ display: "flex", gap: 2 }}>
                                <Typography sx={{ fontWeight: "700" }}>
                                  {item?.Origin?.CityName}
                                </Typography>
                                <Typography sx={{ fontWeight: "700" }}>
                                  {item?.Destination?.CityName}
                                </Typography>
                              </Box>
                              <Box sx={{ display: "flex", gap: 2 }}>
                                <Typography
                                  sx={{
                                    background: "#95BDFF",
                                    padding: "1px 7px",
                                  }}
                                >
                                  {moment(item?.DepTime).format(
                                    "dddd, MMM Do"
                                  ) ?? ""}
                                </Typography>
                                <Typography>{item?.Duration}m</Typography>
                              </Box>
                            </Box>

                            <Box sx={{ my: 1 }}>
                              <Typography>CANCELLATION FEES APPLY</Typography>
                              <Link onClick={handleOpen}>View Fare Rules</Link>
                            </Box>
                            <FareRulesModal
                              open={open}
                              handleClose={handleClose}
                              fareRules={
                                fareDetails?.Results[0]?.FareRuleDetail
                              }
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexWrap: "wrap",
                              mt: 2,
                            }}
                          >
                            <Box sx={{ my: 1 }}>
                              <Box sx={{ display: "flex", gap: 2 }}>
                                <Typography sx={{ fontSize: "14px" }}>
                                  {item?.Airline?.AirlineName}
                                </Typography>
                                <Typography sx={{ fontSize: "14px" }}>
                                  {item?.Airline?.AirlineCode}
                                  {item?.Airline?.FlightNumber}
                                </Typography>
                              </Box>
                            </Box>
                            <Box sx={{ my: 1 }}>
                              <Typography>
                                <span style={{ fontWeight: "700" }}>
                                  {item?.CabinClassName} Class
                                </span>
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ mt: 1 }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "700", fontSize: "12px" }}
                              >
                                {moment(item?.DepTime).format("h:mm A")}
                              </Typography>
                              <Box sx={{ display: "flex", gap: 2 }}>
                                <Typography
                                  sx={{ fontWeight: "700", fontSize: "12px" }}
                                >
                                  {item?.Origin?.CityName}
                                </Typography>
                                <Typography sx={{ fontSize: "12px" }}>
                                  {item?.Origin?.AirportName}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                                mt: 2,
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "700", fontSize: "12px" }}
                              >
                                {moment(item?.ArrTime).format("h:mm A")}
                              </Typography>
                              <Box sx={{ display: "flex", gap: 2 }}>
                                <Typography
                                  sx={{ fontWeight: "700", fontSize: "12px" }}
                                >
                                  {item?.Destination?.CityName}
                                </Typography>
                                <Typography sx={{ fontSize: "12px" }}>
                                  {item?.Destination?.AirportName}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                                gap: 2,
                                mt: 1,
                              }}
                            >
                              <Box>
                                <Typography
                                  sx={{ fontWeight: "400", fontSize: "14px" }}
                                >
                                  Baggage
                                </Typography>
                                <Typography
                                  sx={{ fontWeight: "700", fontSize: "12px" }}
                                >
                                  ADULT
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  sx={{ fontWeight: "400", fontSize: "14px" }}
                                >
                                  Check-in
                                </Typography>
                                <Typography
                                  sx={{ fontWeight: "700", fontSize: "12px" }}
                                >
                                  {/* 25Kgs */}
                                  {item?.Baggage}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  sx={{ fontWeight: "400", fontSize: "14px" }}
                                >
                                  Cabin
                                </Typography>
                                <Typography
                                  sx={{ fontWeight: "700", fontSize: "12px" }}
                                >
                                  {item?.CabinBaggage}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    <Box
                      // key={index}
                      sx={{
                        mt: 2,
                        mb: 2,
                        px: 3,
                        py: 3,
                        backgroundColor: "#D5E9F4",
                      }}
                    >
                      {values.passengers.map((passenger, index) => (
                        <Box
                          key={index}
                          sx={{
                            mt: 2,
                            mb: 2,
                            // pt: 3,
                            // pb: 3,
                            // backgroundColor: "#D5E9F4",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1,
                            }}
                          >
                            <PersonIcon />
                            <Typography sx={{ ml: 1 }}>
                              Passenger {index + 1}
                            </Typography>
                          </Box>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                name={`passengers[${index}].Title`}
                                label="Title"
                                select
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.passengers[index].Title}
                              >
                                <MenuItem value="Mr">Mr</MenuItem>
                                <MenuItem value="Ms">Ms</MenuItem>
                                <MenuItem value="Mrs">Mrs</MenuItem>
                              </Field>
                              <ErrorMessage
                                name={`passengers[${index}].Title`}
                                component="div"
                                style={{ color: "red" }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                name={`passengers[${index}].FirstName`}
                                label="First Name"
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.passengers[index].FirstName}
                              />
                              {/* <ErrorMessage
                              name={`passengers[${index}].FirstName`}
                              component="div"
                              style={{ color: "red" }}
                            /> */}
                              {errors &&
                                errors.passengers?.[index]?.FirstName && (
                                  <div style={{ color: "red" }}>
                                    {errors.passengers[index].FirstName}
                                  </div>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                name={`passengers[${index}].LastName`}
                                label="Last Name"
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.passengers[index].LastName}
                              />
                              {/* <ErrorMessage
                              name={`passengers[${index}].LastName`}
                              component="div"
                              style={{ color: "red" }}
                            /> */}
                              {errors &&
                                errors.passengers?.[index]?.LastName && (
                                  <div style={{ color: "red" }}>
                                    {errors.passengers[index].LastName}
                                  </div>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                name={`passengers[${index}].Gender`}
                                label="Gender"
                                select
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.passengers[index].Gender}
                              >
                                <MenuItem value="1">Male</MenuItem>
                                <MenuItem value="2">Female</MenuItem>
                              </Field>
                              <ErrorMessage
                                name={`passengers[${index}].Gender`}
                                component="div"
                                style={{ color: "red" }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      ))}

                      <Box sx={{ mt: 2, backgroundColor: "#D5E9F4" }}>
                        <Typography sx={{ fontWeight: "700" }}>
                          Contact Details
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            {/* <Field
                            as={TextField}
                            name="Email"
                            label="Email"
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Email}
                          /> */}
                            <Field
                              as={TextField}
                              name={`Email`}
                              label="Email"
                              fullWidth
                              onChange={(e) => {
                                handleChange(e);
                                // Update email for each passenger
                                const updatedPassengerData =
                                  values.passengers.map((passenger) => ({
                                    ...passenger,
                                    Email: e.target.value,
                                  }));
                                handleChange({
                                  target: {
                                    name: "passengers",
                                    value: updatedPassengerData,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={values.passengers[0].Email}
                            />
                            <ErrorMessage
                              name="Email"
                              component="div"
                              style={{ color: "red" }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {/* <Field
                            as={TextField}
                            name="ContactNo"
                            label="Contact Number"
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ContactNo}
                          /> */}
                            <Field
                              as={TextField}
                              name="ContactNo"
                              label="Contact Number"
                              fullWidth
                              onChange={(e) => {
                                handleChange(e);
                                // Update contact number for each passenger
                                const updatedPassengerData =
                                  values.passengers.map((passenger) => ({
                                    ...passenger,
                                    ContactNo: e.target.value,
                                  }));
                                handleChange({
                                  target: {
                                    name: "passengers",
                                    value: updatedPassengerData,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={values.ContactNo}
                            />
                            <ErrorMessage
                              name="ContactNo"
                              component="div"
                              style={{ color: "red" }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              as={TextField}
                              name="AddressLine1"
                              label="Address"
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.AddressLine1}
                            />
                            <ErrorMessage
                              name="AddressLine1"
                              component="div"
                              style={{ color: "red" }}
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box sx={{ mt: 2 }}>
                        <Button
                          type="submit"
                          color="primary"
                          sx={{ border: "1px solid black" }}
                        >
                          Proceed
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Form>
        )}
      </Formik>
      <Footer />
    </>
  );
};

export default FlightBooking;
