// import React from "react";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   Typography,
//   Box,
// } from "@mui/material";
// import moment from "moment";

// const FlightDetailsModal = ({ open, onClose, flightData }) => {
//   if (!flightData || !flightData.Segments) return null;

//   const segments = flightData.Segments[0]; // Assuming we are only dealing with the first array of segments

//   const totalStops = segments.length - 1;
//   const totalDuration = segments.reduce(
//     (acc, segment) => acc + segment.Duration,
//     0
//   );

//   const formatDuration = (totalMinutes) => {
//     const hours = Math.floor(totalMinutes / 60);
//     const minutes = totalMinutes % 60;
//     return `${hours}h ${minutes}m`;
//   };

//   return (
//     <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
//       <DialogTitle>Flight Details</DialogTitle>
//       <DialogContent>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             mb: 2,
//           }}
//         >
//           <Typography variant="h6">Total Stops: {totalStops}</Typography>
//           <Typography variant="h6">
//             Total Duration: {formatDuration(totalDuration)}
//           </Typography>
//         </Box>
//         {segments.map((segment, index) => (
//           <Box
//             key={index}
//             sx={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//               mb: 2,
//               borderBottom: "1px solid #ccc",
//               pb: 2,
//             }}
//           >
//             <Box>
//               <Typography variant="h6">
//                 {segment.Airline.AirlineName}
//               </Typography>
//               <Typography>
//                 {segment.Airline.AirlineCode} {segment.Airline.FlightNumber}
//               </Typography>
//             </Box>
//             <Box>
//               <Typography variant="h6">
//                 {moment(segment.DepTime).format("hh:mm A")}
//               </Typography>
//               <Typography>
//                 {segment.Origin.CityName}, {segment.Origin.Terminal}
//               </Typography>
//             </Box>
//             <Box sx={{ textAlign: "center" }}>
//               <Typography>{segment.Duration} mins</Typography>
//             </Box>
//             <Box>
//               <Typography variant="h6">
//                 {moment(segment.ArrTime).format("hh:mm A")}
//               </Typography>
//               <Typography>
//                 {segment.Destination.CityName}, {segment.Destination.Terminal}
//               </Typography>
//             </Box>
//           </Box>
//         ))}
//       </DialogContent>
//     </Dialog>
//   );
// };

// export default FlightDetailsModal;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
} from "@mui/material";
import moment from "moment";

const FlightDetailsModal = ({ open, onClose, flightData, type, data }) => {
  if (!flightData || !flightData.Segments) return null;

  const segments =
    type === "roundTrip" ? flightData.Segments[1] : flightData.Segments[0]; // Assuming we are only dealing with the first array of segments

  const totalStops = segments.length - 1;
  const totalDuration = segments.reduce(
    (acc, segment) => acc + segment.Duration,
    0
  );

  const formatDuration = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const formatDateTime = (dateTime) => {
    return moment(dateTime).format("MMM DD, YYYY hh:mm A");
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Flight Details</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          {/* <Typography variant="h6">Total Stops: {totalStops}</Typography>
          <Typography variant="h6">
            Total Duration: {formatDuration(totalDuration)}
          </Typography> */}
        </Box>
        {/* {segments.map((segment, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              borderBottom: "1px solid #ccc",
              pb: 2,
            }}
          >
            <Box>
              <Typography variant="h6">
                {segment.Airline.AirlineName}
              </Typography>
              <Typography>
                {segment.Airline.AirlineCode} {segment.Airline.FlightNumber}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6">
                {formatDateTime(segment.DepTime)}
              </Typography>
              <Typography>
                {segment.Origin.CityName}, {segment.Origin.Terminal}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography>{formatDuration(segment.Duration)}</Typography>
            </Box>
            <Box>
              <Typography variant="h6">
                {formatDateTime(segment.ArrTime)}
              </Typography>
              <Typography>
                {segment.Destination.CityName}, {segment.Destination.Terminal}
              </Typography>
            </Box>
          </Box>
        ))} */}
        {(data === "multicity" ? flightData.Segments.flat() : segments).map(
          (segment, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
                borderBottom: "1px solid #ccc",
                pb: 2,
              }}
            >
              <Box>
                <Typography variant="h6">
                  {segment.Airline.AirlineName}
                </Typography>
                <Typography>
                  {segment.Airline.AirlineCode} {segment.Airline.FlightNumber}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6">
                  {formatDateTime(segment.DepTime)}
                </Typography>
                <Typography>
                  {segment.Origin.CityName}, {segment.Origin.Terminal}
                </Typography>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Typography>{formatDuration(segment.Duration)}</Typography>
              </Box>
              <Box>
                <Typography variant="h6">
                  {formatDateTime(segment.ArrTime)}
                </Typography>
                <Typography>
                  {segment.Destination.CityName}, {segment.Destination.Terminal}
                </Typography>
              </Box>
            </Box>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FlightDetailsModal;
