import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/img/logo/logo.svg";
// import { ReactComponent as Profile } from "../../../assets/img/Profile.svg";
import Profile from "../../../assets/img/icon/Profile.png";
import FlightICon from "../../../assets/img/icon/navflightIcon.png";
import HotelICon from "../../../assets/img/icon/navhotelicon.png";
import TrainICon from "../../../assets/img/icon/navtrainicon.png";
import BusICon from "../../../assets/img/icon/navbusicon.png";
import PackgesIcon from "../../../assets/img/icon/navpackgesicon.png";
import PersonaliPackgesIcon from "../../../assets/img/icon/navpersonali-packegicon.png";

import BusActiveIcon from "../../../assets/img/icon/bus-active.png";
import FlightActiveIcon from "../../../assets/img/icon/flightactiveicon.png";
import HotelActiveIcon from "../../../assets/img/icon/hotel-active.png";
import TrainActiveIcon from "../../../assets/img/icon/train-active.png";
import PackageActiveIcon from "../../../assets/img/icon/packages-active.png";
import PersonaliPackgesActiveIcon from "../../../assets/img/icon/personalised-active.png";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Navbar = (props) => {
  const [Fixnavbar, setFixnavbar] = useState(false);
  const [showNavbarDownList, setShowNavbarDownList] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false);
  const [isLocationPathname, setLocationPathname] = useState({
    isHotel: false,
    isFlight: false,
    isTrain: false,
    isBus: false,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const navigatLocation = useLocation();
  const splitedPathname = navigatLocation?.pathname?.split("-", 1);
  const handleActiveIcon = () => {
    if (splitedPathname !== "/" || "") {
      const locationMap = {
        "/hotel": {
          isHotel: true,
          isFlight: false,
          isBus: false,
          isPackage: false,
          isTrain: false,
          isPersonaliPackges: false,
        },
        "/bus": {
          isHotel: false,
          isFlight: false,
          isBus: true,
          isPackage: false,
          isTrain: false,
          isPersonaliPackges: false,
        },
        "/flight": {
          isHotel: false,
          isFlight: true,
          isBus: false,
          isPackage: false,
          isTrain: false,
          isPersonaliPackges: false,
        },
        "/train": {
          isHotel: false,
          isFlight: false,
          isBus: false,
          isPackage: false,
          isTrain: true,
          isPersonaliPackges: false,
        },
        "/package": {
          isHotel: false,
          isFlight: false,
          isBus: false,
          isPackage: true,
          isTrain: false,
          isPersonaliPackges: false,
        },
        "/Personalisedpackage": {
          isHotel: false,
          isFlight: false,
          isBus: false,
          isPackage: false,
          isTrain: false,
          isPersonaliPackges: true,
        },
      };

      setLocationPathname(locationMap[splitedPathname]);
    }
  };
  useEffect(() => {
    handleActiveIcon();

    const currentPathIsHomePage = [
      "/flight-home-page",
      "/hotel-home-page",
      "/train-home-page",
      "/bus-home-page",
    ].includes(location.pathname);
    setIsHomePage(currentPathIsHomePage);
    const hadelscroll = () => {
      if (window.scrollY > 0) {
        setFixnavbar(true);
        setShowNavbarDownList(true);
      } else {
        setFixnavbar(false);
        setShowNavbarDownList(false);
      }
    };
    window.addEventListener("scroll", hadelscroll);
    return () => {
      window.removeEventListener("scroll", hadelscroll);
    };
  }, [location.pathname]);

  const handleLinkClick = (link) => {
    setOpenMenu(false);
  };

  const user_data = JSON.parse(localStorage.getItem("userDetails"));

  const flightIcon = isLocationPathname?.isFlight
    ? FlightActiveIcon
    : FlightICon;
  const hotelIcon = isLocationPathname?.isHotel ? HotelActiveIcon : HotelICon;
  const busIcon = isLocationPathname?.isBus ? BusActiveIcon : BusICon;
  const trainIcon = isLocationPathname?.isTrain ? TrainActiveIcon : TrainICon;
  const packagesersonaliPackgesIcon = isLocationPathname?.isPersonaliPackges
    ? PersonaliPackgesActiveIcon
    : PersonaliPackgesIcon;
  var packageIcon = isLocationPathname?.isPackage
    ? PackageActiveIcon
    : PackgesIcon;

  return (
    <>
      {isHomePage ? (
        <Box className={`navbar-section ${Fixnavbar ? "fix-navbar" : ""} `}>
          {/* logo */}
          <Box>
            <Logo className="logo-icon" />
          </Box>
          {/* navbarlist */}
          <Box
            className={`navbar-list-group 
           ${!openMenu ? "navbar-list-group-show" : ""}
           ${setShowNavbarDownList ? "navbar-down-list-group" : ""} 
           `}
          >
            {/* <Box className="navbar-close-icon" onClick={() => setOpenMenu(!openMenu)}><CloseIcon /></Box> */}
            <Box>
              <Link
                className="nav-link"
                to="/flight-home-page"
                onClick={() => setOpenMenu(false)}
              >
                <Box className="nav-icon-box">
                  <img src={flightIcon} alt="" />
                </Box>
                <span>Flight</span>
              </Link>
            </Box>
            <Box>
              <Link
                className="nav-link"
                to="/hotel-home-page"
                onClick={() => handleLinkClick("hotel")}
              >
                <Box className="nav-icon-box">
                  <img src={hotelIcon} alt="" />
                  {/* <img src={activeLink === 'hotel' ? HotelActiveIcon : HotelICon} alt="" /> */}
                </Box>
                <span> Hotel</span>
              </Link>
            </Box>
            <Box>
              <Link className="nav-link" onClick={() => setOpenMenu(false)}>
                <Box className="nav-icon-box">
                  <img src={trainIcon} alt="" />
                </Box>
                <span> Train</span>
              </Link>
            </Box>
            <Box>
              <Link
                className="nav-link"
                to="/bus-home-page"
                onClick={() => setOpenMenu(false)}
              >
                <Box className="nav-icon-box">
                  <img src={busIcon} alt="" />
                </Box>
                <span> Buses</span>
              </Link>
            </Box>
            <Box>
              <Link className="nav-link" onClick={() => setOpenMenu(false)}>
                <Box className="nav-icon-box">
                  <img src={packageIcon} alt="" />
                </Box>
                <span> Our Packages</span>
              </Link>
            </Box>
            <Box>
              <Link className="nav-link" onClick={() => setOpenMenu(false)}>
                <Box className="nav-icon-box">
                  <img src={packagesersonaliPackgesIcon} alt="" />
                </Box>
                <span>Personalised Packages</span>
              </Link>
            </Box>
          </Box>
          {/* profile */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mr: 2,
              }}
            >
              {/* <Profile /> */}
              <img src={Profile} alt="" width={"40px"} />
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                {/* Raj Patel */}
                {user_data
                  ? user_data.first_name + " " + user_data.last_name
                  : "User"}
                <span>
                  <KeyboardArrowDownIcon />
                </span>
              </Typography>
              {localStorage.getItem("token") ? (
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    localStorage.clear();
                    navigate("/login");
                  }}
                >
                  Logout
                </Typography>
              ) : (
                ""
              )}
            </Box>

            <Box onClick={() => setOpenMenu(!openMenu)} className="menu-icon">
              {openMenu ? <CloseIcon /> : <MenuIcon />}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={`navbar-section ${Fixnavbar ? "fix-navbar" : ""} `}>
          {/* logo */}
          <Box>
            <Logo className="logo-icon" />
          </Box>
          {/* navbarlist */}
          <Box
            className={`navbar-list-group 
           ${!openMenu ? "navbar-list-group-show" : ""}
          
           `}
          >
            {/* <Box className="navbar-close-icon" onClick={() => setOpenMenu(!openMenu)}><CloseIcon /></Box> */}
            <Box>
              <Link
                className="nav-link"
                to="/flight-home-page"
                onClick={() => setOpenMenu(false)}
              >
                <Box className="nav-icon-box">
                  <img src={flightIcon} alt="" />
                </Box>
                <span> Flight</span>
              </Link>
            </Box>
            <Box>
              <Link
                className="nav-link"
                to="/hotel-home-page"
                onClick={() => setOpenMenu(false)}
              >
                <Box className="nav-icon-box">
                  <img src={hotelIcon} alt="" />
                </Box>
                <span> Hotel</span>
              </Link>
            </Box>
            <Box>
              <Link className="nav-link" onClick={() => setOpenMenu(false)}>
                <Box className="nav-icon-box">
                  <img src={trainIcon} alt="" />
                </Box>
                <span> Train</span>
              </Link>
            </Box>
            <Box>
              <Link
                className="nav-link"
                to="/bus-home-page"
                onClick={() => setOpenMenu(false)}
              >
                <Box className="nav-icon-box">
                  <img src={busIcon} alt="" />
                </Box>
                <span> Buses</span>
              </Link>
            </Box>
            <Box>
              <Link className="nav-link" onClick={() => setOpenMenu(false)}>
                <Box className="nav-icon-box">
                  <img src={packageIcon} alt="" />
                </Box>
                <span> Our Packages</span>
              </Link>
            </Box>
            <Box>
              <Link className="nav-link" onClick={() => setOpenMenu(false)}>
                <Box className="nav-icon-box">
                  <img src={packagesersonaliPackgesIcon} alt="" />
                </Box>
                <span>Personalised Packages</span>
              </Link>
            </Box>
          </Box>
          {/* profile */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mr: 2,
              }}
            >
              {/* <Profile /> */}
              <img src={Profile} alt="" width={"40px"} />
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                {" "}
                {/* Raj Patel */}
                {user_data
                  ? user_data.first_name + " " + user_data.last_name
                  : "User"}
                <span>
                  <KeyboardArrowDownIcon />
                </span>
              </Typography>
              {localStorage.getItem("token") ? (
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    localStorage.clear();
                    navigate("/login");
                  }}
                >
                  Logout
                </Typography>
              ) : (
                ""
              )}
            </Box>

            <Box onClick={() => setOpenMenu(!openMenu)} className="menu-icon">
              {openMenu ? <CloseIcon /> : <MenuIcon />}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Navbar;
