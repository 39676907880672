import "swiper/css/navigation";
import "swiper/css";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FlightDataselectBox from "../../layouts/Flight/FlightDataselectBox";
import image from "../../assets/img/flight/flight-company-logo.png";
import bannerImg from "../../assets/img/flight/bannerimg.png";
import AboutAppSection from "../../layouts/AboutAppSection";
import Footer from "../../views/footer/Footer";
import FlightAside from "../../layouts/Flight/FlightAside";
import FlightCard from "../../layouts/Flight/FlightCard";
import SliderFoteDateSelect from "../../layouts/Flight/SliderForDateSelect/SliderFoteDateSelect";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../layouts/Loaders/Loader";
import { OtherHouses } from "@mui/icons-material";
import {
  fareDetails,
  fareDetailsTwo,
  farequotes,
  farequotesTwo,
  seatview,
  seatviewTwo,
  ssrdetails,
  ssrdetailsTwo,
} from "../../redux/flight/flightapi";

const breadcrumbs = [
  <Link underline="hover" key="1" color="#1305B6" to="/">
    Home
  </Link>,
  <Link underline="hover" key="2" color="inherit" to="#">
    Hotel in
  </Link>,
];

const FlightRoundTrip = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedFlightLeft, setSelectedFlightLeft] = useState(null);
  const [selectedFlightRight, setSelectedFlightRight] = useState(null);
  const flightDetails = useSelector(
    (state) => state.flightdata.flightSearchData.Results
  );

  const searchData = useSelector((state) => state.flightdata.searchData);
  const loading = useSelector((state) => state.flightdata.loading);
  const flightDetails2 = useSelector(
    (state) => state.flightdata.flightSearchData
  );

  const { SrdvType, TraceId } = flightDetails2;
  const SrdvIndexLeft = selectedFlightLeft?.FareDataMultiple?.[0]?.SrdvIndex;
  const ResultIndexLeft =
    selectedFlightLeft?.FareDataMultiple?.[0]?.ResultIndex;

  const SrdvIndexRight = selectedFlightRight?.FareDataMultiple?.[0]?.SrdvIndex;
  const ResultIndexRight =
    selectedFlightRight?.FareDataMultiple?.[0]?.ResultIndex;

  const handleSelect = (flightData, side) => {
    if (side === "left") {
      setSelectedFlightLeft(flightData);
    } else if (side === "right") {
      setSelectedFlightRight(flightData);
    }
  };

  const handleProceed = () => {
    const dataLeft = {
      SrdvType: SrdvType,
      SrdvIndex: SrdvIndexLeft,
      TraceId: TraceId,
      ResultIndex: ResultIndexLeft,
    };
    const dataRight = {
      SrdvType: SrdvType,
      SrdvIndex: SrdvIndexRight,
      TraceId: TraceId,
      ResultIndex: ResultIndexRight,
    };
    // dispatch(fareDetails(data)).then((res) => navigate("/flight-bookig"));
    dispatch(fareDetails(dataLeft)).then((res) =>
      dispatch(farequotes(dataLeft)).then((res) =>
        dispatch(ssrdetails(dataLeft))
          .then((res) =>
            dispatch(seatview(dataLeft))
              .then((res) =>
                dispatch(fareDetailsTwo(dataRight)).then((res) =>
                  dispatch(farequotesTwo(dataRight)).then((res) =>
                    dispatch(ssrdetailsTwo(dataRight))
                      .then((res) =>
                        dispatch(seatviewTwo(dataRight))
                          .then((res) => navigate("/flight-bookig"))
                          .catch((err) => console.error(err))
                      )
                      .catch((err) => console.error(err))
                  )
                )
              )
              .catch((err) => console.error(err))
          )
          .catch((err) => console.error(err))
      )
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ backgroundColor: "#3c74dc" }}>
          <Stack spacing={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
          <FlightDataselectBox searchData={searchData} />
        </Box>
      </Container>

      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12} sx={{ my: 2 }}>
            {/* <FlightAside /> */}
          </Grid>
          {/* <Grid item lg={9} md={9} sm={12} xs={12} sx={{ my: 2 }}>
            <Box sx={{ my: 2 }}>
              {flightDetails[0]
                // .filter((item) => parseInt(item.id) < 3)
                .map((item, i) => {
                  return <FlightCard key={item.id} flightData={item} />;
                })}
            </Box>
            <Box>
              <img src={bannerImg} alt="" width={"100%"} />
            </Box>
          </Grid> */}
          <Grid item lg={9} md={9} sm={12} xs={12} sx={{ my: 2 }}>
            <Box sx={{ display: "flex", my: 2 }}>
              <Box sx={{ flex: 1, mx: 1 }}>
                {flightDetails[0].map((item, i) => {
                  return (
                    <FlightCard
                      key={item.id}
                      flightData={item}
                      type={"radio"}
                      selectedFlight={selectedFlightLeft}
                      handleSelect={(flightData) =>
                        handleSelect(flightData, "left")
                      }
                    />
                  );
                })}
              </Box>
              <Box sx={{ flex: 1, mx: 1 }}>
                {flightDetails[1]
                  .filter((item) => item.Segments[0][0])
                  .map((item, i) => {
                    return (
                      <FlightCard
                        key={item.id}
                        flightData={item}
                        type={"radio"}
                        selectedFlight={selectedFlightRight}
                        handleSelect={(flightData) =>
                          handleSelect(flightData, "right")
                        }
                      />
                    );
                  })}
              </Box>
            </Box>
            <Box>
              <img src={bannerImg} alt="" width={"100%"} />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <AboutAppSection />
      <Footer />
      {selectedFlightLeft && selectedFlightRight ? (
        <>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              backgroundColor: "#fff",
              boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
              padding: "10px 0",
              textAlign: "right",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleProceed}
              sx={{ mx: 2 }}
            >
              Proceed ahead
            </Button>
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default FlightRoundTrip;
