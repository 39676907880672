import './Loader.css';
import React from 'react'

const Loader = () => {
  return (
    <main>
      <span className="loader" />
    </main>
  )
}

export default Loader
