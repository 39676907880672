// import { Box, Typography } from "@mui/material";
// import React, { useState } from "react";

// const TravelerDetailsSugestBox = ({ setTravelerDetails }) => {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const handleNumberClick = (index) => {
//     setActiveIndex(index);
//   };
//   return (
//     <Box sx={{ position: "absolute" }} className="traveler-details-sugest-box">
//       <Box sx={{ mt: 2 }}>
//         <Typography>ADULTS (12y +)</Typography>
//         <Typography variant="body2" color={"#9b9b9b"}>
//           on the day of travel
//         </Typography>
//         <ul className="number-box-ul">
//           {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number, index) => (
//             <li
//               key={index}
//               className={`number-box ${index === activeIndex ? "active" : ""}`}
//               onClick={() => handleNumberClick(index)}
//             >
//               {number}
//             </li>
//           ))}
//         </ul>
//       </Box>
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "space-between",
//         }}
//       >
//         <Box sx={{ mt: 2 }}>
//           <Typography>CHILDREN (2y - 12y)</Typography>
//           <Typography variant="body2" color={"#9b9b9b"}>
//             on the day of travel
//           </Typography>
//           <ul className="number-box-ul">
//             {[1, 2, 3, 4, 5, 6].map((number, index) => (
//               <li
//                 key={index}
//                 className={`number-box ${
//                   index === activeIndex ? "active" : ""
//                 }`}
//                 onClick={() => handleNumberClick(index)}
//               >
//                 {number}
//               </li>
//             ))}
//           </ul>
//         </Box>
//         <Box sx={{ mt: 2 }}>
//           <Typography>INFANTS (below 2y)</Typography>
//           <Typography variant="body2" color={"#9b9b9b"}>
//             on the day of travel
//           </Typography>
//           <ul className="number-box-ul">
//             {[1, 2, 3, 4, 5, 6].map((number, index) => (
//               <li
//                 key={index}
//                 className={`number-box ${
//                   index === activeIndex ? "active" : ""
//                 }`}
//                 onClick={() => handleNumberClick(index)}
//               >
//                 {number}
//               </li>
//             ))}
//           </ul>
//         </Box>
//       </Box>
//       <Box sx={{ mt: 2 }}>
//         <Typography>CHOOSE TRAVEL CLASS</Typography>
//         <ul className="travel-class-box">
//           <li className="text-select active">Economy/Premium Economy</li>
//           <li className="text-select">Premium Economy</li>
//           <li className="text-select">Business</li>
//         </ul>
//       </Box>
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "end",
//           mt: 8,
//         }}
//       >
//         <button onClick={() => setTravelerDetails(false)} className="apply-btn">
//           APPLY
//         </button>
//       </Box>
//     </Box>
//   );
// };

// export default TravelerDetailsSugestBox;

import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

const TravelerDetailsSugestBox = ({
  setTravelerDetails,
  setTravelerInfo,
  travelerInfo,
}) => {
  const [adults, setAdults] = useState(travelerInfo?.adults || 1);
  const [children, setChildren] = useState(travelerInfo?.children || 0);
  const [infants, setInfants] = useState(travelerInfo?.infants || 0);
  const [travelClass, setTravelClass] = useState(
    travelerInfo?.travelClass || 1
  );

  const handleNumberClick = (type, number) => {
    if (type === "adults") setAdults(number);
    if (type === "children") setChildren(number);
    if (type === "infants") setInfants(number);
  };

  const handleClassClick = (selectedClass) => {
    setTravelClass(selectedClass);
  };

  const applySelection = () => {
    setTravelerInfo({ adults, children, infants, travelClass });
    setTravelerDetails(false);
  };

  return (
    <Box
      sx={{ position: "absolute", p: 2 }}
      className="traveler-details-sugest-box"
    >
      <Box sx={{ mt: 2 }}>
        <Typography>ADULTS (12y +)</Typography>
        <Typography variant="body2" color={"#9b9b9b"}>
          on the day of travel
        </Typography>
        <ul className="number-box-ul">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
            <li
              key={number}
              className={`number-box ${number === adults ? "active" : ""}`}
              onClick={() => handleNumberClick("adults", number)}
            >
              {number}
            </li>
          ))}
        </ul>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>CHILDREN (2y - 12y)</Typography>
        <Typography variant="body2" color={"#9b9b9b"}>
          on the day of travel
        </Typography>
        <ul className="number-box-ul">
          {[0, 1, 2, 3, 4, 5, 6].map((number) => (
            <li
              key={number}
              className={`number-box ${number === children ? "active" : ""}`}
              onClick={() => handleNumberClick("children", number)}
            >
              {number}
            </li>
          ))}
        </ul>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>INFANTS (below 2y)</Typography>
        <Typography variant="body2" color={"#9b9b9b"}>
          on the day of travel
        </Typography>
        <ul className="number-box-ul">
          {[0, 1, 2, 3, 4, 5, 6].map((number) => (
            <li
              key={number}
              className={`number-box ${number === infants ? "active" : ""}`}
              onClick={() => handleNumberClick("infants", number)}
            >
              {number}
            </li>
          ))}
        </ul>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>CHOOSE TRAVEL CLASS</Typography>
        <ul className="travel-class-box">
          {[
            { name: "Economy/Premium Economy", value: 1 },
            { name: "Premium Economy", value: 2 },
            { name: "Business", value: 3 },
          ].map((cls) => (
            <li
              key={cls.value}
              className={`text-select ${
                cls.value === travelClass ? "active" : ""
              }`}
              onClick={() => handleClassClick(cls.value)}
            >
              {cls.name}
            </li>
          ))}
        </ul>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          mt: 8,
        }}
      >
        <button onClick={applySelection} className="apply-btn">
          APPLY
        </button>
      </Box>
    </Box>
  );
};

export default TravelerDetailsSugestBox;
