import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LocationSuggestBox from "../../LocationSuggestBox/LocationSuggestBox";
import TravelerDetailsSugestBox from "../../TravelerDetailsSugestBox/TravelerDetailsSugestBox";

function CheckIn(props) {
  // const [isOpen, setIsOpen] = React.useState(false);

  const {
    SetOpenDatePicker,
    id,
    value,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
          padding: "8px",
        }}
        onClick={() => SetOpenDatePicker?.((prev) => !prev)}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontSize: "22px", fontWeight: "400" }}>
            Depature{props.title}
          </Typography>
          <KeyboardArrowDownIcon />
        </Box>
        <Box id={id} ref={ref} aria-label={ariaLabel}>
          <Typography sx={{ color: "#022474", fontSize: "17px" }}>
            {value.$d.toString().split(" ").slice(1, 4).join(" ")}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

function CheckInDatePicker(props) {
  const [openDatePicker, SetOpenDatePicker] = React.useState(false);
  const [value, setValue] = React.useState(dayjs());

  return (
    <DatePicker
      onChange={(newValue) => {
        setValue(newValue);
        // handleCheckInClick();
      }}
      disablePast
      slots={{ field: CheckIn, ...props.slots }}
      slotProps={{
        field: { SetOpenDatePicker },
        value: value,
      }}
      {...props}
      open={openDatePicker}
      onClose={() => SetOpenDatePicker(false)}
      onOpen={() => SetOpenDatePicker(true)}
      value={value} // Set the initial value here
    />
  );
}

const MultiCityTrip = ({ setLocationOne, handleSearch }) => {
  const [locationBox, setLocationBox] = useState({
    isFrom: false,
    isTo: false,
  });
  const [value, setValue] = useState(dayjs());

  const [from, setFrom] = useState("Mumbai");
  const [to, setTo] = useState("Pune");
  const [travelerDetails, setTravelerDetails] = useState("");
  const [travelerInfo, setTravelerInfo] = useState({
    adults: 1,
    children: 0,
    infants: 0,
    travelClass: 1,
  });

  const locationFromRef = useRef(null);
  const locationToRef = useRef(null);

  const [isLocationshowFrom, setLocationshowFrom] = useState(false);
  const [isLocationshowTo, setLocationshowTo] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        locationFromRef.current &&
        !locationFromRef.current.contains(event.target)
      ) {
        setLocationshowFrom(false);
      }
      if (
        locationToRef.current &&
        !locationToRef.current.contains(event.target)
      ) {
        setLocationshowTo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handeltravelerDetails = () => {
    setTravelerDetails(!travelerDetails);
  };
  const handleSyncClick = () => {
    const temp = from;
    setFrom(to);
    setTo(temp);
  };

  const handleMultiCityClick = (args) => {
    if (args === 0) {
      setLocationBox({
        isFrom: true,
        isTo: false,
      });
    } else if (args === 1) {
      setLocationBox({
        isFrom: false,
        isTo: true,
      });
    }
  };

  useEffect(() => {
    setLocationOne({
      to: to,
      from: from,
      travelerInfo: travelerInfo,
      value: value,
    });
  }, [to, from, travelerInfo, value]);

  return (
    <Grid container spacing={0} alignItems="stretch">
      <Grid
        item
        lg={4}
        md={4}
        sm={5}
        xs={12}
        sx={{ borderRight: "1px solid #000" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            alignItems: "center",
            gap: "20px",
            padding: "10px",
          }}
        >
          <Box
            ref={locationFromRef}
            onClick={() => setLocationshowFrom((oldVal) => !oldVal)}
            sx={{
              minWidth: "100px",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Typography className="from-to-text">From</Typography>
            <Typography className="location-text">
              {from.airport_city_name}
            </Typography>
            {isLocationshowFrom ? (
              <LocationSuggestBox
                type={"flightfrom"}
                setFrom={setFrom}
                locationBox={locationBox}
                setLocationBox={setLocationBox}
              />
            ) : null}
          </Box>
          <Box>
            <Box
              sx={{
                border: "1px solid #000",
                borderRadius: "50%",
                padding: "5px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={handleSyncClick}
            >
              <SyncAltIcon sx={{ fontSize: "17px" }} />
            </Box>
          </Box>
          <Box
            ref={locationToRef}
            onClick={() => setLocationshowTo((oldVal) => !oldVal)}
            sx={{
              minWidth: "100px",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Typography className="from-to-text">To</Typography>
            <Typography className="location-text">
              {to.airport_city_name}
            </Typography>
            {isLocationshowTo ? (
              <LocationSuggestBox
                type={"flightto"}
                setTo={setTo}
                to={to}
                locationBox={locationBox}
                setLocationBox={setLocationBox}
              />
            ) : null}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        lg={2}
        md={4}
        sm={3}
        xs={6}
        sx={{ borderRight: "1px solid #000" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CheckInDatePicker
            label={value == null ? null : value.format("MM/DD/YYYY")}
            value={value}
            onChange={(newValue) => setValue(newValue)}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item lg={4} md={4} sm={3} xs={6}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "10px 10px",
            position: "relative",
          }}
        >
          <Box sx={{ cursor: "pointer" }} onClick={handeltravelerDetails}>
            <Typography>Travellers & Class</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "22px", fontWeight: "400" }}>
                1 Traveller
              </Typography>
              <KeyboardArrowDownIcon />
            </Box>
            <Typography>Economy</Typography>
          </Box>
          <Box>
            <Box
              className="search-icon-box"
              // onClick={handleSearch}
              sx={{ cursor: "pointer" }}
            >
              <SearchIcon className="search-icon" onClick={handleSearch} />
            </Box>
          </Box>
          {travelerDetails ? (
            <TravelerDetailsSugestBox
              travelerDetails={travelerDetails}
              setTravelerDetails={setTravelerDetails}
              setTravelerInfo={setTravelerInfo}
              travelerInfo={travelerInfo}
            />
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
};

export default MultiCityTrip;
