import { createSlice } from "@reduxjs/toolkit";
import {
  blockSeats,
  boardingPoints,
  bookSeats,
  getBusCity,
  getBusDetails,
  seatDetails,
} from "./busapi";

export const busSlice = createSlice({
  name: "Bus",
  initialState: {
    loading: false,
    BusCityDetails: "",
    searchData: "",
    selectedBus: "",
    selectedSeat: "",
    AvailableBuses: "",
    SeatDetails: "",
    blockedSeats: "",
    bookSeats: "",
    boardingPoints: "",
    board: "",
    drop: "",
  },
  reducers: {
    // Add any reducers here if needed
    setSearchData: (state, action) => {
      state.searchData = action.payload;
    },
    setSelectedBus: (state, action) => {
      state.selectedBus = action.payload;
    },
    setSelectedSeat: (state, action) => {
      state.selectedSeat = action.payload;
    },
    PointoBoard: (state, action) => {
      state.board = action.payload;
    },
    PointtoDrop: (state, action) => {
      state.drop = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getBusCity.pending, (state) => {
        state.loading = false;
      })
      .addCase(getBusCity.fulfilled, (state, action) => {
        state.loading = false;
        state.BusCityDetails = action.payload;
      })
      .addCase(getBusCity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBusDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBusDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.AvailableBuses = action.payload;
      })
      .addCase(getBusDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(seatDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(seatDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.SeatDetails = action.payload;
      })
      .addCase(seatDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(boardingPoints.pending, (state) => {
        state.loading = true;
      })
      .addCase(boardingPoints.fulfilled, (state, action) => {
        state.loading = false;
        state.boardingPoints = action.payload;
      })
      .addCase(boardingPoints.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(blockSeats.pending, (state) => {
        state.loading = true;
      })
      .addCase(blockSeats.fulfilled, (state, action) => {
        state.loading = false;
        state.blockedSeats = action.payload;
      })
      .addCase(blockSeats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(bookSeats.pending, (state) => {
        state.loading = true;
      })
      .addCase(bookSeats.fulfilled, (state, action) => {
        state.loading = false;
        state.bookSeats = action.payload;
      })
      .addCase(bookSeats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setSearchData,
  setSelectedBus,
  setSelectedSeat,
  PointoBoard,
  PointtoDrop,
} = busSlice.actions;

export default busSlice.reducer;
