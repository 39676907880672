import {
  Box,
  Breadcrumbs,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBusDetails } from "../../../redux/bus/busapi";
import { setSearchData } from "../../../redux/bus/busslice";

const BusAsideSection = () => {
  const [acSelection, setAcSelection] = useState(null); // 'AC', 'Non AC', or null
  const [seatSelection, setSeatSelection] = useState(null); // 'Sleeper', 'Seater', or null
  const [BusCityDetails, setBusCityDetails] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchData = useSelector((state) => state?.busdata?.searchData);

  useEffect(() => {
    setBusCityDetails(searchData);
  }, []);

  const [lastFetched, setLastFetched] = useState({
    acSelection: null,
    seatSelection: null,
  });

  const fetchBusDetails = () => {
    // Check if selections have changed since the last fetch
    if (
      acSelection !== lastFetched.acSelection ||
      seatSelection !== lastFetched.seatSelection
    ) {
      const updatedBusCityDetails = {
        ...BusCityDetails,
        ac_type: acSelection, // 'AC' or 'Non AC'
        seat_type: seatSelection, // 'Sleeper' or 'Seater'
      };

      dispatch(getBusDetails(updatedBusCityDetails)).then(() => {
        dispatch(setSearchData(updatedBusCityDetails));

        // Update the last fetched state to prevent unnecessary fetches
        setLastFetched({ acSelection, seatSelection });
      });
    }
  };

  useEffect(() => {
    fetchBusDetails();
  }, [acSelection, seatSelection]); // Removed dispatch from the dependencies

  return (
    <Box className="bus-aside-filter-section">
      <Typography className="main-head-text">FILTER BY</Typography>
      <Box className="filter-box">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography className="filter-text">Quick Filters</Typography>
          {/* <Typography className="clear-text">Clear All</Typography> */}
          <Typography
            className="clear-text"
            onClick={() => {
              setAcSelection(null);
              setSeatSelection(null);
              // Optionally trigger a fetch here to refresh the data without these filters
            }}
          >
            Clear All
          </Typography>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className="filter-btn">AC</Typography>
          <Typography className="filter-btn">Non AC</Typography>
        </Box> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            className={`filter-btn ${acSelection === "ac" ? "selected" : ""}`}
            onClick={() => setAcSelection("ac")}
          >
            AC
          </Typography>
          <Typography
            className={`filter-btn ${
              acSelection === "non ac" ? "selected" : ""
            }`}
            onClick={() => setAcSelection("non ac")}
          >
            Non AC
          </Typography>
        </Box>
      </Box>
      <Box className="filter-box">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography className="filter-text">Seat Type</Typography>
          {/* <Typography className="clear-text">Clear All</Typography> */}
          <Typography
            className="clear-text"
            onClick={() => {
              setAcSelection(null);
              setSeatSelection(null);
              // Optionally trigger a fetch here to refresh the data without these filters
            }}
          >
            Clear All
          </Typography>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className="filter-btn">Sleeper</Typography>
          <Typography className="filter-btn">Seater</Typography>
        </Box> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            className={`filter-btn ${
              seatSelection === "sleeper" ? "selected" : ""
            }`}
            onClick={() => setSeatSelection("sleeper")}
          >
            Sleeper
          </Typography>
          <Typography
            className={`filter-btn ${
              seatSelection === "seater" ? "selected" : ""
            }`}
            onClick={() => setSeatSelection("seater")}
          >
            Seater
          </Typography>
        </Box>
      </Box>
      {/* <Box sx={{}}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
            padding: "10px 0px",
            borderTop: "1px solid #000",
            borderBottom: "1px solid #000",
          }}
        >
          <Typography className="filter-text">
            Picup Station ahmedabad
          </Typography>
          <Typography className="clear-text">Clear All</Typography>
        </Box>
        <Box>
          <FormGroup>
            <FormControlLabel control={<Checkbox />} label="Iscon" />
            <FormControlLabel control={<Checkbox />} label="Bopal" />
            <FormControlLabel control={<Checkbox />} label="Sola" />
            <FormControlLabel control={<Checkbox />} label="Kargil" />
          </FormGroup>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
            padding: "10px 0px",
            borderTop: "1px solid #000",
            borderBottom: "1px solid #000",
          }}
        >
          <Typography className="filter-text">Drop Station</Typography>
          <Typography className="clear-text">Clear All</Typography>
        </Box>
        <Box>
          <FormGroup>
            <FormControlLabel control={<Checkbox />} label="Iscon" />
            <FormControlLabel control={<Checkbox />} label="Bopal" />
            <FormControlLabel control={<Checkbox />} label="Sola" />
            <FormControlLabel control={<Checkbox />} label="Kargil" />
          </FormGroup>
        </Box>
      </Box> */}
      {/* <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
            padding: "10px 0px",
            borderTop: "1px solid #000",
            borderBottom: "1px solid #000",
          }}
        >
          <Typography className="filter-text">Arrival Time</Typography>
          <Typography className="clear-text">Clear All</Typography>
        </Box>
        <Box>
          <FormGroup>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FormControlLabel control={<Checkbox />} label="Morning" />
              <Typography sx={{ color: "#C4C4C4" }}>6 AM to noon</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FormControlLabel control={<Checkbox />} label="Afternoon" />
              <Typography sx={{ color: "#C4C4C4" }}>12 noon to 6 PM</Typography>
            </Box>
          </FormGroup>
        </Box>
      </Box> */}
    </Box>
  );
};

export default BusAsideSection;
