import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../helpers/API";

export const getBusCity = createAsyncThunk(
  "getBusCity",
  async (busstr, thunkAPI) => {
    try {
      const res = await API.get(`/bus/citylist/?city=${busstr}`);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getBusDetails = createAsyncThunk(
  "getBusDetails",
  async (busstr, thunkAPI) => {
    try {
      const res = await API.post(`/bus/searchbus/`, busstr);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const seatDetails = createAsyncThunk(
  "seatDetails",
  async (busstr, thunkAPI) => {
    try {
      const res = await API.post(`/bus/seatdetails/`, busstr);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const boardingPoints = createAsyncThunk(
  "boardingPoints",
  async (busstr, thunkAPI) => {
    try {
      const res = await API.post(`/bus/boardingdetails/`, busstr);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const blockSeats = createAsyncThunk(
  "blockSeats",
  async (busstr, thunkAPI) => {
    try {
      const res = await API.post(`/bus/blockseat/`, busstr);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const bookSeats = createAsyncThunk(
  "bookSeats",
  async (busstr, thunkAPI) => {
    try {
      const res = await API.post(`/bus/bookbus/`, busstr);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
