// import {
//   Box,
//   Checkbox,
//   FormControlLabel,
//   FormGroup,
//   Slider,
//   Typography,
// } from "@mui/material";
// import React, { useState } from "react";
// import { useSelector } from "react-redux";
// const airlineData = [
//   {
//     url: "http://0.0.0.0:8000/api/flight/airlinedetails/269/",
//     created_at: "2024-05-01T18:05:50.411026",
//     updated_at: "2024-05-01T18:05:50.411040",
//     airline_id: 269,
//     airline_code: "AI",
//     airline_name: "Air India",
//     airline_helpno: "",
//     airline_help_email: "",
//     airline_provider_type: "Amadeus Provider",
//     airline_markup: 10,
//     active: true,
//   },
//   {
//     url: "http://0.0.0.0:8000/api/flight/airlinedetails/880/",
//     created_at: "2024-05-01T18:05:52.197792",
//     updated_at: "2024-05-01T18:05:52.197807",
//     airline_id: 881,
//     airline_code: "SG",
//     airline_name: "Spicejet",
//     airline_helpno: "",
//     airline_help_email: "",
//     airline_provider_type:
//       "\nAmadeusProvider;ERetailWebFareProvider;TravelFusion2Provider\n",
//     airline_markup: 0,
//     active: true,
//   },
//   {
//     url: "http://0.0.0.0:8000/api/flight/airlinedetails/154/",
//     created_at: "2024-05-01T18:05:50.087808",
//     updated_at: "2024-05-01T18:05:50.087822",
//     airline_id: 154,
//     airline_code: "6E",
//     airline_name: "IndiGo",
//     airline_helpno: "",
//     airline_help_email: "",
//     airline_provider_type:
//       "\nAmadeusProvider;ERetailWebFareProvider;TravelFusion2Provider\n",
//     airline_markup: 0,
//     active: true,
//   },
//   {
//     url: "http://0.0.0.0:8000/api/flight/airlinedetails/336/",
//     created_at: "2024-05-01T18:05:50.604846",
//     updated_at: "2024-05-01T18:05:50.604864",
//     airline_id: 336,
//     airline_code: "CD",
//     airline_name: "Alliance Air",
//     airline_helpno: "",
//     airline_help_email: "",
//     airline_provider_type: "AmadeusProvider",
//     airline_markup: 0,
//     active: false,
//   },
// ];
// function valuetext(value) {
//   return `${value}°C`;
// }
// const FlightAside = ({ setFilterValue }) => {
//   const flightSearchObject = useSelector(
//     (state) => state.flightdata.flightSearchObject
//   );
//   console.log(flightSearchObject);
//   const [reqBody, setReqBody] = useState();
//   const [value, setValue] = useState([1000, 100000]);
//   const handlesliderChange = (event, newValue) => {
//     setValue(newValue);
//     setReqBody({
//       ...reqBody,
//       minprice: newValue[0],
//       maxprice: newValue[1],
//     });
//   };
//   return (
//     <Box className="bus-aside-filter-section">
//       <Typography className="main-head-text">FILTER BY</Typography>
//       <Box className="filter-box">
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             mb: 2,
//           }}
//         >
//           <Typography className="filter-text">Stops</Typography>
//           <Typography className="clear-text">Clear All</Typography>
//         </Box>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >
//           <Box>
//             <FormGroup>
//               <FormControlLabel control={<Checkbox />} label="Non-Stop" />
//               <FormControlLabel control={<Checkbox />} label="1 Stop" />
//               <FormControlLabel control={<Checkbox />} label="2 Stop" />
//             </FormGroup>
//           </Box>
//         </Box>
//       </Box>
//       <Box>
//         <Typography>One way Price</Typography>
//         <Slider
//           getAriaLabel={() => "Price range"}
//           value={value}
//           onChange={handlesliderChange}
//           valueLabelDisplay="auto"
//           getAriaValueText={valuetext}
//           min={1000} // Minimum value of the slider
//           max={100000} // Maximum value of the slider
//         />
//       </Box>
//       {/* <Box sx={{}}>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             mb: 2,
//             padding: "10px 0px",
//             borderTop: "1px solid #000",
//             borderBottom: "1px solid #000",
//           }}
//         >
//           <Typography className="filter-text">
//             Departure Time From BLR{" "}
//           </Typography>
//           <Typography className="clear-text">Clear All</Typography>
//         </Box>
//         <Box>
//           <FormGroup>
//             <FormControlLabel control={<Checkbox />} label="Early Morning" />
//             <FormControlLabel control={<Checkbox />} label=" Morning" />
//             <FormControlLabel control={<Checkbox />} label="Afternoon" />
//             <FormControlLabel control={<Checkbox />} label="Evening" />
//             <FormControlLabel control={<Checkbox />} label="Night" />
//           </FormGroup>
//         </Box>
//       </Box> */}

//       <Box>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             mb: 2,
//             padding: "10px 0px",
//             borderTop: "1px solid #000",
//             borderBottom: "1px solid #000",
//           }}
//         >
//           <Typography className="filter-text">Airlines</Typography>
//           <Typography className="clear-text">Clear All</Typography>
//         </Box>
//         <Box>
//           <FormGroup>
//             {airlineData.map((item, index) => (
//               <Box
//                 key={index}
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                 }}
//               >
//                 <FormControlLabel
//                   control={<Checkbox />}
//                   label={item.airline_name}
//                 />
//                 {/* <Typography>{item.price}</Typography> */}
//               </Box>
//             ))}
//           </FormGroup>
//         </Box>
//       </Box>
//       {/* <Box>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             mb: 2,
//             padding: "10px 0px",
//             borderTop: "1px solid #000",
//             borderBottom: "1px solid #000",
//           }}
//         >
//           <Typography className="filter-text">Class</Typography>
//           <Typography className="clear-text">Clear All</Typography>
//         </Box>
//         <Box>
//           <FormGroup>
//             <FormControlLabel control={<Checkbox />} label="First Class" />
//             <FormControlLabel control={<Checkbox />} label="Business Class" />
//             <FormControlLabel control={<Checkbox />} label="Economy Class" />
//           </FormGroup>
//         </Box>
//       </Box> */}
//     </Box>
//   );
// };

// export default FlightAside;

// import React, { useState } from "react";
// import { Box, Slider, Typography } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import { flightSearch } from "../../redux/flight/flightapi";

// const airlineData = [
//   {
//     url: "http://0.0.0.0:8000/api/flight/airlinedetails/269/",
//     created_at: "2024-05-01T18:05:50.411026",
//     updated_at: "2024-05-01T18:05:50.411040",
//     airline_id: 269,
//     airline_code: "AI",
//     airline_name: "Air India",
//     airline_helpno: "",
//     airline_help_email: "",
//     airline_provider_type: "Amadeus Provider",
//     airline_markup: 10,
//     active: true,
//   },
//   {
//     url: "http://0.0.0.0:8000/api/flight/airlinedetails/880/",
//     created_at: "2024-05-01T18:05:52.197792",
//     updated_at: "2024-05-01T18:05:52.197807",
//     airline_id: 881,
//     airline_code: "SG",
//     airline_name: "Spicejet",
//     airline_helpno: "",
//     airline_help_email: "",
//     airline_provider_type:
//       "\nAmadeusProvider;ERetailWebFareProvider;TravelFusion2Provider\n",
//     airline_markup: 0,
//     active: true,
//   },
//   {
//     url: "http://0.0.0.0:8000/api/flight/airlinedetails/154/",
//     created_at: "2024-05-01T18:05:50.087808",
//     updated_at: "2024-05-01T18:05:50.087822",
//     airline_id: 154,
//     airline_code: "6E",
//     airline_name: "IndiGo",
//     airline_helpno: "",
//     airline_help_email: "",
//     airline_provider_type:
//       "\nAmadeusProvider;ERetailWebFareProvider;TravelFusion2Provider\n",
//     airline_markup: 0,
//     active: true,
//   },
//   {
//     url: "http://0.0.0.0:8000/api/flight/airlinedetails/336/",
//     created_at: "2024-05-01T18:05:50.604846",
//     updated_at: "2024-05-01T18:05:50.604864",
//     airline_id: 336,
//     airline_code: "CD",
//     airline_name: "Alliance Air",
//     airline_helpno: "",
//     airline_help_email: "",
//     airline_provider_type: "AmadeusProvider",
//     airline_markup: 0,
//     active: false,
//   },
// ];

// const FlightAside = () => {
//   const dispatch = useDispatch();
//   const flightSearchObject = useSelector(
//     (state) => state.flightdata.flightSearchObject
//   );
//   const [value, setValue] = useState([1000, 100000]);
//   const [delayedValue, setDelayedValue] = useState([1000, 100000]);
//   const [sliderAdjusted, setSliderAdjusted] = useState(false); // State to track if slider has been adjusted

//   const handleSubmit = () => {
//     const updatedSearchObject = {
//       ...flightSearchObject,
//       minprice: delayedValue[0],
//       maxprice: delayedValue[1],
//     };
//     dispatch(flightSearch(updatedSearchObject));
//     setSliderAdjusted(false); // Reset sliderAdjusted state after API call
//   };

//   const handleSliderChange = (event, newValue) => {
//     setValue(newValue);
//     setDelayedValue(newValue);
//     setSliderAdjusted(true); // Set sliderAdjusted state to true when slider is adjusted
//   };

//   const handleSliderChangeCommitted = () => {
//     // Call handleSubmit only if slider has been adjusted
//     if (sliderAdjusted) {
//       handleSubmit();
//     }
//   };

//   return (
//     <Box className="bus-aside-filter-section">
//       <Typography className="main-head-text">FILTER BY</Typography>
//       <Box>
//         <Typography>One way Price</Typography>
//         <Slider
//           getAriaLabel={() => "Price range"}
//           value={value}
//           onChange={handleSliderChange}
//           onChangeCommitted={handleSliderChangeCommitted} // Call handleSliderChangeCommitted when slider adjustment is completed
//           valueLabelDisplay="auto"
//           min={1000}
//           max={100000}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default FlightAside;

import React, { useState, useEffect } from "react";
import {
  Box,
  Slider,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { flightSearch } from "../../redux/flight/flightapi";

const airlineData = [
  {
    url: "http://0.0.0.0:8000/api/flight/airlinedetails/269/",
    created_at: "2024-05-01T18:05:50.411026",
    updated_at: "2024-05-01T18:05:50.411040",
    airline_id: 269,
    airline_code: "AI",
    airline_name: "Air India",
    airline_helpno: "",
    airline_help_email: "",
    airline_provider_type: "Amadeus Provider",
    airline_markup: 10,
    active: true,
  },
  {
    url: "http://0.0.0.0:8000/api/flight/airlinedetails/880/",
    created_at: "2024-05-01T18:05:52.197792",
    updated_at: "2024-05-01T18:05:52.197807",
    airline_id: 881,
    airline_code: "SG",
    airline_name: "Spicejet",
    airline_helpno: "",
    airline_help_email: "",
    airline_provider_type:
      "\nAmadeusProvider;ERetailWebFareProvider;TravelFusion2Provider\n",
    airline_markup: 0,
    active: true,
  },
  {
    url: "http://0.0.0.0:8000/api/flight/airlinedetails/154/",
    created_at: "2024-05-01T18:05:50.087808",
    updated_at: "2024-05-01T18:05:50.087822",
    airline_id: 154,
    airline_code: "6E",
    airline_name: "IndiGo",
    airline_helpno: "",
    airline_help_email: "",
    airline_provider_type:
      "\nAmadeusProvider;ERetailWebFareProvider;TravelFusion2Provider\n",
    airline_markup: 0,
    active: true,
  },
  {
    url: "http://0.0.0.0:8000/api/flight/airlinedetails/336/",
    created_at: "2024-05-01T18:05:50.604846",
    updated_at: "2024-05-01T18:05:50.604864",
    airline_id: 336,
    airline_code: "CD",
    airline_name: "Alliance Air",
    airline_helpno: "",
    airline_help_email: "",
    airline_provider_type: "AmadeusProvider",
    airline_markup: 0,
    active: false,
  },
];

const FlightAside = () => {
  const dispatch = useDispatch();
  const flightSearchObject = useSelector(
    (state) => state.flightdata.flightSearchObject
  );
  const [value, setValue] = useState([1000, 100000]);
  const [delayedValue, setDelayedValue] = useState([1000, 100000]);
  const [sliderAdjusted, setSliderAdjusted] = useState(false); // State to track if slider has been adjusted
  const [selectedFlights, setSelectedFlights] = useState([]); // State to store selected flight codes

  const handleSubmit = () => {
    const updatedSearchObject = {
      ...flightSearchObject,
      minprice: delayedValue[0],
      maxprice: delayedValue[1],
      Sources: selectedFlights.join(",").toString(), // Concatenate selected flight codes into a string
    };
    dispatch(flightSearch(updatedSearchObject));
    setSliderAdjusted(false); // Reset sliderAdjusted state after API call
  };

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    setDelayedValue(newValue);
    setSliderAdjusted(true); // Set sliderAdjusted state to true when slider is adjusted
  };

  const handleSliderChangeCommitted = () => {
    // Call handleSubmit only if slider has been adjusted
    if (sliderAdjusted) {
      handleSubmit();
    }
  };

  const handleFlightCheckboxChange = (event) => {
    const { name, checked } = event.target;
    let updatedFlights = [...selectedFlights];
    if (checked) {
      updatedFlights.push(name);
    } else {
      updatedFlights = updatedFlights.filter((flight) => flight !== name);
    }
    setSelectedFlights(updatedFlights);
    // handleSubmit(); // Call handleSubmit whenever flight selection changes
  };

  useEffect(() => {
    handleSubmit();
  }, [selectedFlights]);

  return (
    <Box className="bus-aside-filter-section">
      <Typography className="main-head-text">FILTER BY</Typography>
      <Box>
        <Typography>One way Price</Typography>
        <Slider
          getAriaLabel={() => "Price range"}
          value={value}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderChangeCommitted} // Call handleSliderChangeCommitted when slider adjustment is completed
          valueLabelDisplay="auto"
          min={1000}
          max={100000}
        />
      </Box>
      <Box>
        <Typography>Airlines</Typography>
        <FormGroup>
          {airlineData.map((flight) => (
            <FormControlLabel
              key={flight.airline_id}
              control={
                <Checkbox
                  checked={selectedFlights.includes(flight.airline_code)}
                  onChange={handleFlightCheckboxChange}
                  name={flight.airline_code}
                />
              }
              label={flight.airline_name}
            />
          ))}
        </FormGroup>
      </Box>
    </Box>
  );
};

export default FlightAside;
