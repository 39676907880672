
import UserIcon from "../../assets/img/icon/user.png";
import EmailPhoneIcon from "../../assets/img/icon/email-or-phone.png";
import PasswordIcon from "../../assets/img/icon/password.png";
import GoogleIcon from "../../assets/img/icon/google-icon.png";
import FacebookIcon from "../../assets/img/icon/facebook-icon.png";

import {
  Box,
  Container,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const ResetPassword = () => {
    const navigate = useNavigate();
    const handlmoveotp = ()=>{
        navigate("/otp");
    }
  return (
    <Box className="auth-bg-section">
    <Container maxWidth="xl">
      <Box>

        <Box className="auth-card" sx={{pb:"50px",pt:"50px"}}>
          <Box className="form-section">
                <Box>

                <Typography className="reset-main-text">Reset Password</Typography>
                <Typography className="reset-sub-text">We will send you a reset OTP on your registered E-mail ID or Mobile Number</Typography>
                </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
            >
            
              <Box className=" from-box-reset" sx={{width:"100%"}}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="formcontroal-box"
                  
                >
                  <TextField
                    type="email"
                    placeholder="Registered E-mail ID Or Mobile Number"
                    sx={{ "& fieldset": { border: "none" } }}
                  />
                </FormControl>
              </Box>
            </Box>
                <Box className="reset-otp-box">
                    <button onClick={handlmoveotp } className="reset-otp-btn">Send OTP</button>
                </Box>

          </Box>
        </Box>

      </Box>
    </Container>
  </Box>
  )
}

export default ResetPassword
