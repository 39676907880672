import {
  Box,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import Footer from "../footer/Footer";
import SpecialOffersSection from "../../layouts/SpecialOffersSection";
import TopDestinationSection from "../../layouts/TopDestinationSection";
import AboutAppSection from "../../layouts/AboutAppSection";
import OneWay from "../../layouts/Flight/TimeDateSelectHomePage/OneWay";
import RoundTrip from "../../layouts/Flight/TimeDateSelectHomePage/RoundTrip";
import MultiCityTrip from "../../layouts/Flight/TimeDateSelectHomePage/Multi-city/MultiCityTrip";
import AddMoreTrip from "../../layouts/Flight/TimeDateSelectHomePage/Multi-city/AddMoreTrip";
import Loader from "../../layouts/Loaders/Loader";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { flightSearch } from "../../redux/flight/flightapi";
import { useNavigate } from "react-router-dom";
import { setHomePageSearchData } from "../../redux/flight/flightslice";

const FlightHomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tripValue, setTripValue] = useState("One-way");
  const [locationOne, setLocationOne] = useState("");
  const [locationTwo, setLocationTwo] = useState("");
  const loading = useSelector((state) => state.flightdata.loading);

  // const handleSearch = () => {
  //   const allowedTimes = {
  //     anyTime: "00:00:00",
  //     morning: "08:00:00",
  //     afternoon: "14:00:00",
  //     evening: "19:00:00",
  //     night: "01:00:00",
  //   };

  //   // Default to anyTime
  //   const chosenTime = allowedTimes.anyTime;

  //   const formattedDateOne = moment({
  //     year: locationOne.value.$y,
  //     month: locationOne.value.$M,
  //     day: locationOne.value.$D,
  //     hour: parseInt(chosenTime.split(":")[0], 10),
  //     minute: parseInt(chosenTime.split(":")[1], 10),
  //     second: parseInt(chosenTime.split(":")[2], 10),
  //   }).format("YYYY-MM-DDTHH:mm:ss");

  //   const formattedDateTwo = moment({
  //     year: locationTwo.value.$y,
  //     month: locationTwo.value.$M,
  //     day: locationTwo.value.$D,
  //     hour: parseInt(chosenTime.split(":")[0], 10),
  //     minute: parseInt(chosenTime.split(":")[1], 10),
  //     second: parseInt(chosenTime.split(":")[2], 10),
  //   }).format("YYYY-MM-DDTHH:mm:ss");

  //   const data = {
  //     EndUserIp: "1.1.1.1",
  //     AdultCount: locationOne.travelerInfo.adults,
  //     ChildCount: locationOne.travelerInfo.children,
  //     InfantCount: locationOne.travelerInfo.infants,
  //     JourneyType: "3",
  //     DirectFlight: 0,
  //     SoftwareType: "B2C",
  //     Sources: "",
  //     Segments: [
  //       {
  //         Origin: locationOne.from.airport_city_code,
  //         Destination: locationOne.to.airport_city_code,
  //         FlightCabinClass: locationOne.travelerInfo.travelClass,
  //         PreferredDepartureTime: formattedDateOne,
  //         PreferredArrivalTime: formattedDateOne,
  //       },
  //        {
  //          Origin: locationTwo.from.airport_city_code,
  //          Destination: locationTwo.to.airport_city_code,
  //          FlightCabinClass: locationOne.travelerInfo.travelClass,
  //          PreferredDepartureTime: formattedDateTwo,
  //          PreferredArrivalTime: formattedDateTwo,
  //        },
  //     ],
  //   };

  //   const searchData = {
  //     to: locationOne.to,
  //     from: locationOne.from,
  //     toTwo: locationTwo.to,
  //     fromTwo: locationTwo.from,
  //     date: formattedDateOne,
  //     dateTwo: formattedDateTwo,
  //     travelerInfo: locationOne.travelerInfo,
  //   };
  //   dispatch(setHomePageSearchData(searchData));
  //   dispatch(flightSearch(data))
  //     .then((res) => navigate("/flight-ticket-multi-city"))
  //     .catch((err) => console.error(err));
  // };
  const handleSearch = () => {
    const allowedTimes = {
      anyTime: "00:00:00",
      morning: "08:00:00",
      afternoon: "14:00:00",
      evening: "19:00:00",
      night: "01:00:00",
    };

    // Default to anyTime
    const chosenTime = allowedTimes.anyTime;

    const formattedDateOne = moment({
      year: locationOne.value.$y,
      month: locationOne.value.$M,
      day: locationOne.value.$D,
      hour: parseInt(chosenTime.split(":")[0], 10),
      minute: parseInt(chosenTime.split(":")[1], 10),
      second: parseInt(chosenTime.split(":")[2], 10),
    }).format("YYYY-MM-DDTHH:mm:ss");

    const segments = [
      {
        Origin: locationOne.from.airport_city_code,
        Destination: locationOne.to.airport_city_code,
        FlightCabinClass: locationOne.travelerInfo.travelClass,
        PreferredDepartureTime: formattedDateOne,
        PreferredArrivalTime: formattedDateOne,
      },
    ];

    locationTwo.forEach((row) => {
      const formattedDateTwo = moment({
        year: row.value.$y,
        month: row.value.$M,
        day: row.value.$D,
        hour: parseInt(chosenTime.split(":")[0], 10),
        minute: parseInt(chosenTime.split(":")[1], 10),
        second: parseInt(chosenTime.split(":")[2], 10),
      }).format("YYYY-MM-DDTHH:mm:ss");

      segments.push({
        Origin: row.from.airport_city_code,
        Destination: row.to.airport_city_code,
        FlightCabinClass: locationOne.travelerInfo.travelClass,
        PreferredDepartureTime: formattedDateTwo,
        PreferredArrivalTime: formattedDateTwo,
      });
    });

    const data = {
      EndUserIp: "1.1.1.1",
      AdultCount: locationOne.travelerInfo.adults,
      ChildCount: locationOne.travelerInfo.children,
      InfantCount: locationOne.travelerInfo.infants,
      JourneyType: "3",
      DirectFlight: 0,
      SoftwareType: "B2C",
      Sources: "",
      Segments: segments,
    };

    const searchData = {
      to: locationOne.to,
      from: locationOne.from,
      locationTwo: locationTwo.map((row) => ({
        to: row.to,
        from: row.from,
      })),
      date: formattedDateOne,
      dateTwo: locationTwo.map((row) =>
        row.value.format("YYYY-MM-DDTHH:mm:ss")
      ),
      travelerInfo: locationOne.travelerInfo,
    };

    // return true;

    dispatch(setHomePageSearchData(searchData));
    dispatch(flightSearch(data))
      .then((res) => navigate("/flight-ticket-multi-city"))
      .catch((err) => console.error(err));
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "inline-block",
            backgroundColor: "#D5E9F4",
            padding: "10px",
            borderRadius: "20px",
            marginRight: "10px",
          }}
        >
          <RadioGroup
            row
            name="row-radio-buttons-group"
            value={tripValue}
            onChange={(event) => setTripValue(event.target.value)}
          >
            {["One-way", "Round-Trip", "Multi-City"].map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
        </Box>
        <Box className="flight-booking-select-box">
          {tripValue === "One-way" && <OneWay setTripValue={setTripValue} />}

          {tripValue === "Round-Trip" && <RoundTrip />}
          {tripValue === "Multi-City" && (
            <>
              <MultiCityTrip
                setLocationOne={setLocationOne}
                handleSearch={handleSearch}
              />
              <AddMoreTrip setLocationTwo={setLocationTwo} />
            </>
          )}
        </Box>
      </Container>
      <SpecialOffersSection />
      <TopDestinationSection />
      <AboutAppSection />
      <Footer />
    </>
  );
};

export default FlightHomePage;
