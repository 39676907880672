import "swiper/css";
import "swiper/css/pagination";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import hotel1 from "../../assets/img/hotel/hotellook1.png";
import hotel2 from "../../assets/img/hotel/hotellook2.png";
import hotel3 from "../../assets/img/hotel/hotellook3.png";
import hotel4 from "../../assets/img/hotel/hotellook4.png";
import hotel5 from "../../assets/img/hotel/hotellook5.png";
import hotel6 from "../../assets/img/hotel/hotellook6.png";
import Banquet from "../../assets/img/icon/Banquet.png";
import Bathtub from "../../assets/img/icon/Bathtub.png";
import WIFI from "../../assets/img/icon/Wifi.png";
import Cheers from "../../assets/img/icon/Cheers.png";
import SmartTv from "../../assets/img/icon/Smart tv.png";
import Footer from "../footer/Footer";
import { ReactComponent as Location } from "../../assets/img/icon/Map_Pin.svg";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import HotelDateselectBox from "../../layouts/Hotel/HotelDateselectBox";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDispatch, useSelector } from "react-redux";
import { hotelRoomInfo } from "../../redux/hotel/hotelapi";
import Loader from "../../layouts/Loaders/Loader";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
const HotelDetailsPage = () => {
  const data = useSelector((state) => state?.hoteldata.hotelInfo);

  const homepagesearchdata = useSelector(
    (state) => state.hoteldata.searchData.dataToSendInSearch
  );
  const selectedHotel = useSelector((state) => state.hoteldata.selectedHotel);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.hoteldata.loading);

  const slideimg = [hotel1, hotel2, hotel3, hotel4, hotel5, hotel6, hotel6];
  const [activeimg, setActiveimg] = useState(
    data?.HotelInfoResult.HotelDetails.Images[0]
  );

  useEffect(() => {
    setActiveimg(data?.HotelInfoResult.HotelDetails.Images[0]);
  }, [data]);

  useEffect(() => {
    setActiveimg(data?.HotelInfoResult.HotelDetails.Images[0]);
  }, []);

  // if (loading) return <div>Loading...</div>;
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="#1305B6"
      href="/"
      onClick={handleClick}
    >
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      Hotel in {homepagesearchdata?.SearchCity.destination}
    </Link>,
  ];

  const handleBookHotel = () => {
    const BodyData = {
      ResultIndex: data.HotelInfoResult.ResultIndex,
      SrdvIndex: data.HotelInfoResult.SrdvIndex,
      SrdvType: data.HotelInfoResult.SrdvType,
      HotelCode: data.HotelInfoResult.HotelDetails.HotelCode,
      TraceId: data.HotelInfoResult.TraceId,
    };

    // return true;
    dispatch(hotelRoomInfo(BodyData));
  };

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );
  return (
    <>
      <section>
        <Container maxWidth="xl">
          <Box>
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="large" />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          </Box>
          <HotelDateselectBox />
        </Container>
      </section>
      <section>
        <Container maxWidth="xl">
          <Box>
            <Grid container spacing={2}>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <Box className="hotel-details-left-section">
                  <Box className="hotel-big-img-section">
                    <img src={activeimg} alt="" className="img-big-set" />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Swiper
                      slidesPerView={window.innerWidth <= 767 ? 4 : 6}
                      spaceBetween={10}
                      mousewheel={true}
                      // modules={[Navigation]}
                      className="hotel-slide-swiper"
                    >
                      {data?.HotelInfoResult.HotelDetails.Images.map(
                        (curEel, i) => (
                          <>
                            <SwiperSlide className="swiper-slide" key={i}>
                              <img
                                src={curEel}
                                onClick={() => setActiveimg(curEel)}
                                alt="img"
                                className="slider-small-img"
                              />
                            </SwiperSlide>
                          </>
                        )
                      )}
                    </Swiper>
                  </Box>
                  <Box className="our-facility-section">
                    <Typography className="our-facility-title">
                      Our Facility
                    </Typography>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <img src={WIFI} alt="wifi" width="40px" />
                      <img src={SmartTv} alt="smart-tv" width="40px" />
                      <img src={Banquet} alt="banquet" width="40px" />
                      <img src={Bathtub} alt="bathtub" width="40px" />
                      <img src={Cheers} alt="cheers" width="40px" />
                    </Box>
                  </Box>

                  {/* <Box>
                    <Typography>Cancellation Policy</Typography>
                  </Box> */}

                  <Box>
                    <Typography className="king-size-title">
                      King Size
                    </Typography>
                    {/* <Typography>
                      {data?.HotelInfoResult.HotelDetails.Description}
                    </Typography> */}
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: data?.HotelInfoResult.HotelDetails.Description,
                      }}
                    >
                      {/* Intentionally left blank */}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={4} md={5} sm={12} xs={12} ml="auto">
                <Box className="hotel-details-right-section">
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={6} xs={12}>
                      <Box className="your-stay-box-section">
                        <Typography className="main-head-text">
                          Your Stay
                        </Typography>
                        <Box className="date-info-box">
                          <Typography className="info-titile">Date:</Typography>
                          <Typography className="info-about">
                            {homepagesearchdata.CheckInDate} -{" "}
                            {homepagesearchdata.CheckOutDate}
                          </Typography>
                        </Box>
                        <Box className="date-info-box ">
                          <Typography className="info-titile">
                            Guests:
                          </Typography>
                          <Typography className="info-about">
                            {homepagesearchdata?.RoomGuests.reduce(
                              (accumulator, currentGroup) => {
                                const noOfAdults = parseInt(
                                  currentGroup.NoOfAdults,
                                  10
                                );

                                return accumulator + noOfAdults;
                              },
                              0
                            )}{" "}
                            Adults,
                            {homepagesearchdata?.RoomGuests.reduce(
                              (accumulator, currentGroup) => {
                                const noOfAdults = parseInt(
                                  currentGroup.NoOfChild,
                                  10
                                );
                                return accumulator + noOfAdults;
                              },
                              0
                            )}{" "}
                            Children
                          </Typography>
                        </Box>
                        <Box className="booking-box">
                          <Link to="/hotel-booking-pagetwo">
                            <button
                              className="book-now-btn"
                              // onClick={() => {
                              //   dispatch(hotelRoomInfo());
                              // }}
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleBookHotel();
                              }}
                            >
                              Book now
                            </button>
                          </Link>
                          <Box>
                            <Typography className="total-text">
                              {/* Total <span>4500 ₹</span> */}
                              Total{" "}
                              <span>
                                {selectedHotel.Price.PublishedPriceRoundedOff}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={6} xs={12}>
                      <Box
                        className="need-assistance-box-section"
                        sx={{ mt: { lg: 2, md: 2, sm: 0, xs: 2 } }}
                      >
                        <Typography className="main-head-text">
                          Need Assistance?
                        </Typography>
                        <Typography>
                          Our dedicated reservations team is ready to help you
                          around the clock.
                        </Typography>
                        <Box className="location-info-box">
                          <Typography className="info-titile">
                            <Location />
                          </Typography>
                          <Typography className="info-about">
                            {data?.HotelInfoResult.HotelDetails.Address}
                          </Typography>
                        </Box>
                        {/* <Box className="location-info-box">
                      <Typography className="info-titile">
                        <LocalPhoneIcon />
                      </Typography>
                      <Typography className="info-about">
                        <span
                          style={{
                            marginBottom: "20px",
                            display: "inline-block",
                          }}
                        >
                          {data.HotelInfoResult.HotelDetails.FaxNumber}
                        </span>
                        <br />{" "}
                        <span>{data.HotelInfoResult.HotelDetails.Email}</span>{" "}
                      </Typography>
                    </Box> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </section>
      <section style={{ marginTop: "50px" }}>
        <Footer />
      </section>
    </>
  );
};

export default HotelDetailsPage;
