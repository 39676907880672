import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import waterBottleIcon from "../../../assets/img/icon/water-bottle-icon.png";
import phoneChargerIcon from "../../../assets/img/icon/phone-charger.png";
import lapIcon from "../../../assets/img/icon/lamp-icon.png";
import ArrowRight from "../../../assets/img/icon/arrow-right-move.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";

import { setSearchData } from "../../../redux/bus/busslice";
import { getBusDetails } from "../../../redux/bus/busapi";
import { useNavigate } from "react-router-dom";
const StarRating = ({ rating }) => (
  <Box className="rating-box">
    {Array.from({ length: 5 }, (_, index) => (
      <StarIcon
        key={index}
        sx={{ fill: index < rating ? "#5A7FCB" : "#C4C4C4" }}
      />
    ))}
  </Box>
);
const busServiesProvied = [
  {
    id: "0",
    serviesName: "Amenities",
  },
  {
    id: "1",
    serviesName: "Bus Photos",
  },
  {
    id: "2",
    serviesName: "Boarding & Dropping Points",
  },
  {
    id: "3",
    serviesName: "Reviews",
  },
  {
    id: "4",
    serviesName: "Cancellation Policies",
  },
];

const BusCard = ({ handelClick, availablebus }) => {
  const [openBoardingPoints, setOpenBoardingPoints] = useState(false);
  const [openDroppingPoints, setOpenDroppingPoints] = useState(false);
  const [openCancellationPolicies, setOpenCancellationPolicies] =
    useState(false);

  const [BusCityDetails, setBusCityDetails] = useState({});
  const searchData = useSelector((state) => state?.busdata?.searchData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setBusCityDetails(searchData);
  }, [searchData]);

  const handleOpenBoardingPoints = () => setOpenBoardingPoints(true);
  const handleCloseBoardingPoints = () => setOpenBoardingPoints(false);

  const handleOpenDroppingPoints = () => setOpenDroppingPoints(true);
  const handleCloseDroppingPoints = () => setOpenDroppingPoints(false);

  const handleOpenCancellationPolicies = () =>
    setOpenCancellationPolicies(true);
  const handleCloseCancellationPolicies = () =>
    setOpenCancellationPolicies(false);

  const handleSearch = async () => {
    dispatch(getBusDetails(BusCityDetails)).then(() => {
      dispatch(setSearchData(BusCityDetails));
      navigate("/bus-booking-page");
    });
  };

  return (
    <>
      <Box>
        {availablebus?.BusResults.map((item, i) => {
          return (
            <>
              <Box
                sx={{
                  backgroundColor: "#D5E9F4",
                  padding: "10px 20px",
                  mb: 2,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={10} md={10} sm={9} xs={12}>
                    <Box
                      sx={{
                        mb: 1,
                        gap: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box className="time-location-section">
                        <Typography className="time-text">
                          {/* {moment(item.DepartureTime)} */}
                          {moment(item.DepartureTime).format("hh:mm A")}
                        </Typography>
                        <Typography className="arrival-text">
                          {item?.BoardingPoints[0]?.CityPointName}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ textAlign: "center", mb: 0 }}>
                          {/* {item.Hours}  */}
                          {/* {moment(item?.DroppingPoints[0]?.CityPointTime).diff(
                            moment(item?.BoardingPoints[0]?.CityPointTime),
                            "hours"
                          )} */}
                          {(() => {
                            // Convert the times to moment objects and calculate the difference
                            const difference = moment(item.ArrivalTime).diff(
                              moment(item.DepartureTime)
                            );

                            // Convert the difference into hours and minutes
                            const duration = moment.duration(difference);
                            const hours = duration.hours();
                            const minutes = duration.minutes();

                            return `${hours} hrs & ${minutes} mins`;
                          })()}
                        </Typography>
                        <img src={ArrowRight} alt="" />
                      </Box>
                      <Box className="time-location-section">
                        <Typography className="time-text">
                          {moment(item.ArrivalTime).format("hh:mm A")}
                        </Typography>
                        <Typography className="arrival-text">
                          {item?.DroppingPoints[0]?.CityPointName}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        mb: 2,
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Typography sx={{ color: "rgba(0, 0, 0, 0.25)" }}>
                        {item.TravelName}
                      </Typography>
                      <Typography>Ratings</Typography>
                      <StarRating rating={2} />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "7px",
                        }}
                      >
                        <img src={waterBottleIcon} alt="" width={25} />
                        <img src={phoneChargerIcon} alt="" width={25} />
                        <img src={lapIcon} alt="" width={25} />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          border: "1px solid #B9D7F166",
                          padding: "5px",
                          gap: "5px",
                          background: "#D5E9F4",
                          ml: 2,
                        }}
                      >
                        {busServiesProvied.map((item) => {
                          return (
                            // <Typography
                            //   sx={{
                            //     fontSize: "14px",
                            //     color: "#0063A7",
                            //     borderRight: "1px solid #000",
                            //     padding: "5px",
                            //     "&:last-child": { borderRight: "none" },
                            //   }}
                            // >
                            //   {item.serviesName}
                            // </Typography>
                            <Typography
                              onClick={() => {
                                if (
                                  item.serviesName ===
                                  "Boarding & Dropping Points"
                                ) {
                                  handleOpenBoardingPoints();
                                } else if (
                                  item.serviesName === "Cancellation Policies"
                                ) {
                                  handleOpenCancellationPolicies();
                                }
                                // Add more conditions if there are other buttons/services
                              }}
                              sx={{
                                fontSize: "14px",
                                color: "#0063A7",
                                borderRight: "1px solid #000",
                                padding: "5px",
                                "&:last-child": { borderRight: "none" },
                                cursor: "pointer", // Add cursor pointer for better UX
                              }}
                            >
                              {item.serviesName}
                            </Typography>
                          );
                        })}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={2} md={2} sm={3} xs={12}>
                    <Box className="price-section">
                      {/* <Typography className="coupun-text">
                        Code Applied
                      </Typography> */}
                      <Typography className="left-text">
                        {item.AvailableSeats
                          ? `Total ${item.AvailableSeats} seats left`
                          : ""}
                      </Typography>
                      <Typography className="price-text">
                        ₹ {item.Price.PublishedPrice}
                      </Typography>
                      <button
                        className="book-now-btn"
                        onClick={() => handelClick(item)}
                      >
                        Book Now
                      </button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          );
        })}
      </Box>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            alignItems: "center", // Ensures the modal is centered vertically
          },
          "& .MuiPaper-root": {
            width: "80%", // Adjusts the width of the modal. Change as needed.
            maxHeight: "90vh", // Maximum height before scrolling
          },
        }}
        open={openCancellationPolicies}
        onClose={handleCloseCancellationPolicies}
      >
        <DialogTitle>Cancellation Policies</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Policy Period</TableCell>
                  <TableCell align="right">Cancellation Charge</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {availablebus?.BusResults[0].CancellationPolicies.map(
                  (policy, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {policy.PolicyString}
                      </TableCell>
                      <TableCell align="right">
                        ₹{policy.CancellationCharge}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      {/* boarding and dropping points  */}
      <Dialog
        open={openBoardingPoints}
        onClose={handleCloseBoardingPoints}
        sx={{
          "& .MuiDialog-container": {
            alignItems: "center", // Ensures the modal is centered vertically
          },
          "& .MuiPaper-root": {
            width: "80%", // Adjusts the width of the modal. Change as needed.
            maxHeight: "60vh", // Maximum height before scrolling
          },
        }}
      >
        <DialogTitle>Boarding & Dropping Points</DialogTitle>
        <DialogContent>
          <Typography variant="h6" component="div" sx={{ mt: 2, mb: 1 }}>
            Boarding Points
          </Typography>
          <List>
            {availablebus?.BusResults[0].BoardingPoints.map((point, index) => (
              <ListItem key={`boarding-${index}`}>
                <ListItemText
                  primary={`${point.CityPointName} - ${point.CityPointLocation}`}
                  secondary={moment(point.CityPointTime).format("hh:mm A")}
                />
              </ListItem>
            ))}
          </List>
          <Typography variant="h6" component="div" sx={{ mt: 2, mb: 1 }}>
            Dropping Points
          </Typography>
          <List>
            {availablebus?.BusResults[0].DroppingPoints.map((point, index) => (
              <ListItem key={`dropping-${index}`}>
                <ListItemText
                  primary={`${point.CityPointName} - ${point.CityPointLocation}`}
                  secondary={moment(point.CityPointTime).format("hh:mm A")}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BusCard;
