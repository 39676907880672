import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../helpers/API";
import Notification from "../../layouts/Notification/Notification";

export const getFlightCity = createAsyncThunk(
  "getFlightCity",
  async (flightstr, thunkAPI) => {
    try {
      const res = await API.get(
        `/flight/airportdetails/?city=${flightstr}&country`
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const flightSearch = createAsyncThunk(
  "flightSearch",
  async (data, thunkAPI) => {
    try {
      const res = await API.post(`/flight/searchflights/`, data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fareDetails = createAsyncThunk(
  "fareDetails",
  async (argdata, thunkAPI) => {
    try {
      const res = await API.post("/flight/faredetails/", argdata);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fareDetailsTwo = createAsyncThunk(
  "fareDetailsTwo",
  async (argdata, thunkAPI) => {
    try {
      const res = await API.post("/flight/faredetails/", argdata);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const farequotes = createAsyncThunk(
  "farequotes",
  async (BodyData, thunkAPI) => {
    try {
      const res = await API.post("/flight/farequotes/", BodyData);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const farequotesTwo = createAsyncThunk(
  "farequotesTwo",
  async (BodyData, thunkAPI) => {
    try {
      const res = await API.post("/flight/farequotes/", BodyData);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const ssrdetails = createAsyncThunk(
  "ssrdetails",
  async (bodyData, thunkAPI) => {
    try {
      const { data, status } = await API.post("flight/ssrdetails/", bodyData);
      if (status === 200) {
        return data;
      } else {
        Notification("error", data.detail);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const ssrdetailsTwo = createAsyncThunk(
  "ssrdetailsTwo",
  async (bodyData, thunkAPI) => {
    try {
      const { data, status } = await API.post("flight/ssrdetails/", bodyData);
      if (status === 200) {
        return data;
      } else {
        Notification("error", data.detail);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const seatview = createAsyncThunk(
  "seatview",
  async (bodyData, thunkAPI) => {
    try {
      const res = await API.post("/flight/seatview/", bodyData);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const seatviewTwo = createAsyncThunk(
  "seatviewTwo",
  async (bodyData, thunkAPI) => {
    try {
      const res = await API.post("/flight/seatview/", bodyData);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const flightbookllc = createAsyncThunk(
  "flightbookllc",
  async (argdata, thunkAPI) => {
    try {
      const res = await API.post("/flight/bookllc/", argdata);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const flightbookgds = createAsyncThunk(
  "flightbookgds",
  async (argdata, thunkAPI) => {
    try {
      const res = await API.post("/flight/bookgds/", argdata);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const flightbookgdsTwo = createAsyncThunk(
  "flightbookgdsTwo",
  async (argdata, thunkAPI) => {
    try {
      const res = await API.post("/flight/bookgds/", argdata);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const flightholdgds = createAsyncThunk(
  "flightholdgds",
  async (argdata, thunkAPI) => {
    try {
      const res = await API.post("/flight/holdgds/", argdata);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const flightholdgdsTwo = createAsyncThunk(
  "flightholdgdsTwo",
  async (argdata, thunkAPI) => {
    try {
      const res = await API.post("/flight/holdgds/", argdata);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
