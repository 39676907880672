import { Box, Container, Grid, InputLabel, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import GooglePlayStoreIcon from "../assets/img/google-playstore.png";
import AppstoreIcon from "../assets/img/app-store-icon.png";
import ScreenerCode from "../assets/img/screener-code.png";
import FlagIcon from "../assets/img/flag.png";
import { FormControl, MenuItem, Select } from "@mui/base";
const AboutAppSection = () => {

  const [age, setAge] = useState('10');

  const handleChange = (event) => {
    setAge(event?.target?.value);
  };

  return (
    <section>
      <Container maxWidth="xl">
        <Box className="play-store-section">
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box>{/* <MobiledowlodIcon /> */}</Box>
                <Box sx={{ ml: 2 }}>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    Download APP now !{" "}
                  </Typography>
                  <Typography variant="body2">
                    Use Code WELCOMMT and get Flat 12 OFF* on your first
                    Domestic Flight .
                  </Typography>
                </Box>
              </Box>
              <Box className="downlod-section">
                <Box className="flag-section">
                  {/* <FlagIcon /> */}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box>
                      <img src={FlagIcon} alt="" width={50} />
                    </Box>
                    <Box sx={{ml:1}}>
                      <Box>
                        <Typography>91+</Typography>
                      </Box>
                    </Box>
                    <Box>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className="formcontroal-box"
                      >
                        <TextField
                          type="email"
                          // placeholder="number"
                          sx={{ "& fieldset": { border: "none" } }}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
                <Box className="app-link-section">
                  <Typography>
                    <Link className="link-text">GET APP LINK!</Link>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={5} md={6} sm={6} xs={12} sx={{ ml: "auto" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <Box>
                  <Box>
                    <img src={GooglePlayStoreIcon} alt="" style={{ width: { md: "100%", xs: "70px" } }} />
                  </Box>
                  <Box>
                    {/* <AppstoreIcon /> */}
                    <img src={AppstoreIcon} alt="" />
                  </Box>
                </Box>
                <Box>
                  {/* <ScnerIcon /> */}
                  <img src={ScreenerCode} alt="" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
};

export default AboutAppSection;
