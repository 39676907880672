import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import bag1 from "../../assets/img/icon/bag1.png";
import bag2 from "../../assets/img/icon/bag2.png";
import bag3 from "../../assets/img/icon/bag3.png";
import puhaFood from "../../assets/img/icon/puhafood.png";
import MusliFood from "../../assets/img/icon/muslifood.png";
import BeryaniFood from "../../assets/img/icon/beryani.png";
import FoodIcon from "../../assets/img/icon/foodIcon.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import arioPlan from "../../assets/img/icon/airoplan-bg-blue.png";
import seatIcon from "../../assets/img/icon/seatsicon.png";
import aeroplanHead from "../../assets/img/icon/airoplan-head.png";
import ManWomanIcon from "../../assets/img/icon/man-woman-icon.png";
import teaIcon from "../../assets/img/icon/tea-icon.png";
import arioPlanBottom from "../../assets/img/icon/airoplan-bottom.png";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Footer from "../footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from "../../helpers/API";
import axios from "axios";
import { bookSeats } from "../../redux/bus/busapi";
import { flightbookgds, flightbookllc } from "../../redux/flight/flightapi";
import Notification from "../../layouts/Notification/Notification";
import moment from "moment";

const foodData = [
  {
    id: "0",
    foodImg: puhaFood,
    foodName: "Puha Cambo",
    bagAmount: "350",
  },
  {
    id: "1",
    foodImg: MusliFood,
    foodName: "Musli",
    bagAmount: "450",
  },
  {
    id: "2",
    foodImg: BeryaniFood,
    foodName: "Beryani",
    bagAmount: "500",
  },
  {
    id: "3",
    foodImg: MusliFood,
    foodName: "Musli",
    bagAmount: "600",
  },
];

const FlightSeatBooking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fareQuotes = useSelector(
    (state) => state.flightdata.farequotes.Results.Fare.PublishedFare
  );
  const holdseatgds = useSelector((state) => state?.flightdata?.holdseatgds);

  const [farePrice, setFarePrice] = useState(fareQuotes ? fareQuotes : 0);

  const seatviewData = useSelector(
    (state) => state.flightdata.seatviewdata.Results
  );

  console.log(seatviewData, "seatviewData");

  const Baggage = useSelector(
    (state) => state.flightdata.ssrdetails.Baggage[0]
  );
  const MealDynamic = useSelector(
    (state) => state.flightdata.ssrdetails.MealDynamic[0]
  );

  const flightDetails = useSelector(
    (state) => state.flightdata.farequotes.Results.Segments[0][0]
  );

  const passengers = useSelector((state) => state?.flightdata?.passengers);
  const flightData = useSelector(
    (state) => state?.flightdata.farequotes.Results.Segments[0][0]
  );
  const flightDetailsData = useSelector(
    (state) => state?.flightdata.farequotes.Results.Segments
  );
  console.log(flightDetailsData);

  const Fare = useSelector((state) => state.flightdata.farequotes.Results.Fare);
  const datatosend = useSelector((state) => state.flightdata.farequotes);
  const fareData = useSelector((state) => state.flightdata.farequotes);

  const [passengerData, setPassengerData] = useState(
    passengers ? passengers : []
  );

  const [selectedExtras, setSelectedExtras] = useState({
    baggage: [],
    meals: [],
  });

  // Toggle selection for baggage
  // const handleToggleBaggage = (baggageOption) => {
  //   const isAlreadySelected = selectedExtras.baggage.some(
  //     (item) => item.Code === baggageOption.Code
  //   );
  //   if (isAlreadySelected) {
  //     setSelectedExtras((prevState) => ({
  //       ...prevState,
  //       baggage: prevState.baggage.filter(
  //         (item) => item.Code !== baggageOption.Code
  //       ),
  //     }));
  //   } else {
  //     setSelectedExtras((prevState) => ({
  //       ...prevState,
  //       baggage: [...prevState.baggage, baggageOption],
  //     }));
  //   }
  // };
  const handleToggleBaggage = (baggageOption, action) => {
    if (action === "+") {
      setSelectedExtras((prevState) => ({
        ...prevState,
        baggage: [...prevState.baggage, baggageOption],
      }));
    } else if (action === "-") {
      const indexToRemove = selectedExtras.baggage.findIndex(
        (item) => item.Code === baggageOption.Code
      );
      if (indexToRemove !== -1) {
        setSelectedExtras((prevState) => ({
          ...prevState,
          baggage: [
            ...prevState.baggage.slice(0, indexToRemove),
            ...prevState.baggage.slice(indexToRemove + 1),
          ],
        }));
      }
    }
  };

  // Toggle selection for meals
  const handleToggleMeal = (mealOption, action) => {
    if (action === "+") {
      setSelectedExtras((prevState) => ({
        ...prevState,
        meals: [...prevState.meals, mealOption],
      }));
    } else if (action === "-") {
      const indexToRemove = selectedExtras.meals.findIndex(
        (item) => item.Code === mealOption.Code
      );
      if (indexToRemove !== -1) {
        setSelectedExtras((prevState) => ({
          ...prevState,
          meals: [
            ...prevState.meals.slice(0, indexToRemove),
            ...prevState.meals.slice(indexToRemove + 1),
          ],
        }));
      }
    }
  };

  const updatePassengerExtras = () => {
    const updatedPassengers = passengerData.map((passenger) => ({
      ...passenger,
      Baggage: selectedExtras.baggage,
      MealDynamic: selectedExtras.meals,
    }));
    setPassengerData(updatedPassengers);
  };

  useEffect(() => {
    updatePassengerExtras();
  }, [selectedExtras]);

  const CustomSeat = ({
    seatId,
    onSelectSeat,
    rowIndex,
    passenger,
    planeIndex,
  }) => {
    const isBooked = seatId.IsBooked;

    // Determine if this seat is selected for the current planeIndex
    const isSelected = passenger.some((p) =>
      p.Seat?.some(
        (seat, index) =>
          seat.planeIndex === planeIndex &&
          seat.SeatNumber === seatId.SeatNumber
      )
    );

    const handleSeatClick = () => {
      if (isBooked) return;

      onSelectSeat(seatId, rowIndex, planeIndex);
    };

    return (
      <Box
        sx={{
          cursor: isBooked ? "not-allowed" : "pointer",
          width: "20px",
          height: "20px",
          background: isBooked
            ? "#FF0000" // Red color for booked seats
            : isSelected
            ? "#0063A7" // Blue color for selected seats
            : "#D9D9D9", // Default color for available seats
          "&:hover": {
            background: isBooked ? "#FF0000" : "#0063A7", // Keep or change color on hover based on availability
          },
        }}
        onClick={!isBooked ? handleSeatClick : undefined} // Disable click if booked
      ></Box>
    );
  };

  const numRows = 32;

  // Create an array with numbers 1 to numRows
  const rowNumbers = Array.from({ length: numRows }, (_, index) => index + 1);

  const handleSelectSeat = (seatId, rowIndex, planeIndex) => {
    const updatedPassengers = passengerData.map((passenger) => ({
      ...passenger,
      Seat: passenger.Seat ? [...passenger.Seat] : [],
    }));

    let newFareQuotes = farePrice;

    // Check if the seat is already assigned to a passenger for the current plane
    const passengerIndex = updatedPassengers.findIndex((p) =>
      p.Seat.some(
        (seat) =>
          seat.planeIndex === planeIndex &&
          seat.SeatNumber === seatId.SeatNumber
      )
    );

    if (passengerIndex !== -1) {
      // Seat is already assigned to a passenger, remove it
      const seatToRemove = updatedPassengers[passengerIndex].Seat.find(
        (seat) =>
          seat.planeIndex === planeIndex &&
          seat.SeatNumber === seatId.SeatNumber
      );

      if (seatToRemove) {
        newFareQuotes -= seatToRemove.Amount; // Subtract seat price from fareQuotes

        updatedPassengers[passengerIndex].Seat = updatedPassengers[
          passengerIndex
        ].Seat.filter(
          (seat) =>
            !(
              seat.planeIndex === planeIndex &&
              seat.SeatNumber === seatId.SeatNumber
            )
        );
      }
    } else {
      // Find the first passenger who hasn't selected a seat yet for the current plane
      const passengerWithoutSeatIndex = updatedPassengers.findIndex(
        (p) => !p.Seat.some((seat) => seat.planeIndex === planeIndex)
      );

      if (passengerWithoutSeatIndex === -1) {
        console.log(
          "All passengers have selected a seat for this plane. Further selections are disabled."
        );
        Notification(
          "error",
          "All passengers have selected a seat for this plane. Further selections are disabled."
        );
        return; // Abort seat selection
      }

      updatedPassengers[passengerWithoutSeatIndex].Seat.push({
        ...seatId,
        planeIndex, // Add planeIndex to seat data using spread syntax
      });
      newFareQuotes += seatId.Amount; // Add seat price to fareQuotes
    }

    setFarePrice(newFareQuotes);
    setPassengerData(updatedPassengers);
  };

  console.log(passengerData, flightData, "passengerData");

  let token = localStorage.getItem("token");
  const key_id = "rzp_test_RKcrTpOrjFkybA";
  const checkouthandler = async (amount) => {
    const { data } = API.post(
      "/payment/razorpay-order-generate/",
      {
        action: "order_generate",
        currency_name: "INR",
        amount,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {
      const { data } = res;

      const options = {
        key: key_id,
        amount: data?.amount,
        currency: "INR",
        name: "Make U Trip",
        description: "Razorpay tutorial",
        order_id: data.id,
        callback_url:
          "https://srv453747.hstgr.cloud/api/payment/razorpay-verify-payment/",
        prefill: {
          name: "Make U Trip ",
          email: "makeutrip@gmail.com",
          contact: "1234567890",
        },
        notes: {
          address: "razorapy official",
        },
        theme: {
          color: "yellow",
        },
        handler: function (response) {
          let bodyDataToSend;
          if (response.razorpay_payment_id) {
            bodyDataToSend = {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            };
            (async () => {
              try {
                const token = localStorage.getItem("token");

                const responseData = await axios.post(
                  "https://srv453747.hstgr.cloud/api/payment/razorpay-verify-payment/",
                  bodyDataToSend,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`, // Include the token in the authorization header
                      "Content-Type": "application/json", // Assuming the content type is JSON
                    },
                  }
                );

                if (
                  responseData.data.detail ===
                  "Signature verification successfull."
                ) {
                  // return true;
                  const flightDatatopass = {
                    // flightDetails
                    AirlineCode: flightDetails.Airline.AirlineCode,
                    FlightNumber: flightDetails.Airline.FlightNumber,
                    Origin: flightDetails.Origin.CityCode,
                    Destination: flightDetails.Destination.CityCode,
                  };

                  const data = {
                    SrdvType: datatosend.SrdvType,
                    SrdvIndex: datatosend.Results.SrdvIndex,
                    TraceId: datatosend.TraceId,
                    ResultIndex: datatosend.Results.ResultIndex,
                    razorpay_payment_id: response.razorpay_payment_id,
                    Passengers: passengerData.map((passenger, index) => ({
                      ...passenger,
                      // Seat: [...passenger.Seat, ...flightDatatopass],
                      Seat: passenger.Seat
                        ? [{ ...passenger.Seat, ...flightDatatopass }]
                        : [flightDatatopass], // Ensuring Seat is an array of objects
                      fare: Fare,
                      IsLeadPax: index === 0 ? 1 : 0,
                    })),
                  };
                  if (
                    flightData.Destination.CountryCode !==
                    flightData.Origin.CountryCode
                  ) {
                    dispatch(flightbookgds(holdseatgds))
                      .then((response) => {
                        if (response.payload && response.payload.detail) {
                          console.error("API Error:", response.payload.detail);
                          navigate("/payment-unsuccess-page");
                          window.scrollTo(0, 0);
                        } else {
                          // Handle other success cases
                          // navigate("/hotel-checkout-page");
                          navigate("/payment-success-page");
                          window.scrollTo(0, 0);
                        }
                      })
                      .catch((error) => {
                        console.log("Network Error:", error);
                      });
                  } else {
                    dispatch(flightbookllc(data))
                      .then((response) => {
                        if (response.payload && response.payload.detail) {
                          console.error("API Error:", response.payload.detail);
                          navigate("/payment-unsuccess-page");
                          window.scrollTo(0, 0);
                        } else {
                          // Handle other success cases
                          // navigate("/hotel-checkout-page");
                          navigate("/payment-success-page");
                          window.scrollTo(0, 0);
                        }
                      })
                      .catch((error) => {
                        console.log("Network Error:", error);
                      });
                  }
                  // return true;
                }
              } catch (error) {
                console.log(error);
              }
            })();
          } else {
            console.error("Payment failed or was canceled");
          }
        },
      };
      const razor = new window.Razorpay(options);
      razor.open();
    });
  };

  const handlePay = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      // Store the current URL
      localStorage.setItem("redirectPath", window.location.pathname);
      // Redirect to login page
      // window.location.href = "/login"; // Replace '/login' with your actual login route
      navigate("/login");
      return;
    }
    // Check if every passenger has a seat selected
    const allPassengersHaveSeat = passengerData.every(
      (passenger) =>
        passenger.Seat !== null && typeof passenger.Seat === "object"
    );

    if (allPassengersHaveSeat) {
      if (
        flightData.Destination.CountryCode !== flightData.Origin.CountryCode
      ) {
        const data = {
          SrdvType: datatosend.SrdvType,
          SrdvIndex: datatosend.Results.SrdvIndex,
          TraceId: datatosend.TraceId,
          ResultIndex: datatosend.Results.ResultIndex,
          Passengers: passengerData.map((passenger, index) => ({
            ...passenger,
            fare: Fare,
            IsLeadPax: index === 0 ? 1 : 0,
            GSTCompanyAddress: "",
            GSTCompanyContactNumber: "",
            GSTCompanyName: "",
            GSTNumber: "",
            GSTCompanyEmail: "",
          })),
        };
        holdseatgds(data).then((res) =>
          checkouthandler(
            farePrice + totalBaggageCharges + totalMealDynamicCharges
          )
        );
      } else {
        checkouthandler(
          farePrice + totalBaggageCharges + totalMealDynamicCharges
        );
      }
    } else {
      // Handle the case where not all passengers have a seat selected
      console.log("Not all passengers have selected a seat.");
      Notification("error", "Please Select Seat First");
      // You can display a message or handle this scenario according to your application's requirements
    }
  };

  const totalBaggageCharges = passengerData.reduce((total, passenger) => {
    // Calculate total baggage charges for a single passenger
    const passengerTotal =
      passenger.Baggage?.reduce(
        (acc, baggage) => acc + (baggage.Price || 0),
        0
      ) || 0;
    return total + passengerTotal;
  }, 0);

  const totalMealDynamicCharges = passengerData.reduce((total, passenger) => {
    // Calculate total baggage charges for a single passenger
    const passengerTotal =
      passenger.MealDynamic?.reduce(
        (acc, baggage) => acc + (baggage.Price || 0),
        0
      ) || 0;
    return total + passengerTotal;
  }, 0);

  console.log(
    "Total Baggage Charges:",
    totalBaggageCharges,
    totalMealDynamicCharges
  ); // Output the total charges

  return (
    <Box>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Box sx={{ backgroundColor: "#D5E9F4", padding: 2 }}>
              <Box sx={{ py: 1, borderBottom: "1px solid #000000" }}>
                <Typography sx={{ fontWeight: 700 }}>Fare Summary </Typography>
                <Typography sx={{ fontWeight: 700, ml: 1 }} variant="caption">
                  1 ADULT
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                  mt: 1,
                  pb: 1,
                  borderBottom: "1px solid #B9D7F166",
                }}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CircleIcon sx={{ color: "#B9D7F166" }} />
                  <Box>
                    <Typography>Base Fare </Typography>
                    <Typography variant="caption">Adult (1x 4,700)</Typography>
                  </Box>
                </Box>
                <Typography>{fareData?.Results?.Fare?.BaseFare}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                  mt: 1,
                  pb: 1,
                  borderBottom: "1px solid #B9D7F166",
                }}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CircleIcon sx={{ color: "#B9D7F166" }} />

                  <Typography>Taxes and Surcharges</Typography>
                </Box>
                <Typography>{fareData?.Results?.Fare?.Tax}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                  <CircleIcon sx={{ color: "#B9D7F166" }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography> Other Services</Typography>
                    <Typography>
                      {fareData?.Results?.Fare?.OtherCharges}
                    </Typography>
                    {/* </Box> */}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: "1px solid #000",
                  mt: 1,
                  pt: 1,
                }}
              >
                <Typography sx={{ fontWeight: "700" }}>
                  Total Amount{" "}
                </Typography>

                <Typography sx={{ fontWeight: "700" }}>
                  {farePrice + totalBaggageCharges + totalMealDynamicCharges}
                </Typography>
              </Box>
              <Box sx={{ textAlign: "end" }}>
                <Grid>
                  <Button
                    sx={{ border: "1px solid black" }}
                    onClick={handlePay}
                  >
                    Pay
                  </Button>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ backgroundColor: "#D5E9F4", padding: 2, mt: 2 }}>
              <Typography>Baggage</Typography>
              <Box
                sx={{
                  maxHeight: "170px",
                  overflowY: "scroll",
                  scrollBehavior: "smooth",
                }}
              >
                {Baggage?.map((item) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mt: 2,
                      }}
                    >
                      <Box>
                        <Typography
                          variant="caption"
                          sx={{
                            ml: 1,
                            background: "#B9D7F166",
                            padding: "2px 2px",
                          }}
                        >
                          {item.Weight} kg
                        </Typography>
                      </Box>
                      {/* <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="caption">
                          {item.Origin} - {item.Destination}
                        </Typography>
                      </Box> */}
                      <Box
                        sx={{
                          padding: "5px 10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="caption" sx={{ ml: 1 }}>
                          {item.AirlineCode} - {item.FlightNumber}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          ml: "auto",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body2">₹{item.Price}</Typography>
                        {/* <Button
                          variant={
                            selectedExtras.baggage.some(
                              (selectedItem) => selectedItem.Code === item.Code
                            )
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          onClick={() => handleToggleBaggage(item)}
                        >
                          {selectedExtras.baggage.some(
                            (selectedItem) => selectedItem.Code === item.Code
                          )
                            ? "Remove"
                            : "Add"}
                        </Button> */}
                      </Box>
                      <Button onClick={() => handleToggleBaggage(item, "+")}>
                        +
                      </Button>
                      {
                        passengerData[0]?.Baggage?.filter(
                          (data) => data.Code === item.Code
                        ).length
                      }
                      <Button onClick={() => handleToggleBaggage(item, "-")}>
                        -
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box sx={{ backgroundColor: "#D5E9F4", padding: 2, mt: 2 }}>
              <Typography
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <img src={FoodIcon} alt="" width={20} /> Meals{" "}
              </Typography>
              <Box
                sx={{
                  maxHeight: "170px",
                  overflowY: "scroll",
                  scrollBehavior: "smooth",
                }}
              >
                {MealDynamic?.map((item) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mt: 2,
                      }}
                    >
                      <Box>
                        <Typography variant="body2">
                          {item.Description ? item.Description : "No Data"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          ml: "auto",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body2">₹{item.Price}</Typography>
                        {/* <Button
                          variant={
                            selectedExtras.meals.some(
                              (selectedItem) => selectedItem.Code === item.Code
                            )
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          onClick={() => handleToggleMeal(item)}
                        >
                          {selectedExtras.meals.some(
                            (selectedItem) => selectedItem.Code === item.Code
                          )
                            ? "Remove"
                            : "Add"}
                        </Button> */}
                        <Button onClick={() => handleToggleMeal(item, "+")}>
                          +
                        </Button>
                        {
                          passengerData[0]?.MealDynamic?.filter(
                            (data) => data.Code === item.Code
                          ).length
                        }
                        <Button onClick={() => handleToggleMeal(item, "-")}>
                          -
                        </Button>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <Box sx={{ backgroundColor: "#D5E9F4", padding: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "700" }}>
                  TICKET DETAILS
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    color: "#005C9D",
                    cursor: "pointer",
                  }}
                ></Typography>
              </Box>
              {flightDetailsData.flat().map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 2,
                    px: 2,
                  }}
                >
                  <Typography>
                    {moment(item.DepTime).format("dddd, DD MMM YYYY")}
                  </Typography>
                  <img src={arioPlan} alt="airplane" />
                  <Typography>
                    {item.Origin.AirportCode}{" "}
                    {moment(item.DepTime).format("h:mm A")}
                  </Typography>
                  <Typography>{item.Duration} m</Typography>
                  <Typography>
                    {item.Destination.AirportCode}{" "}
                    {moment(item.ArrTime).format("h:mm A")}
                  </Typography>
                </Box>
              ))}
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                  px: 2,
                }}
              >
                {moment(flightData?.DepTime).format("dddd, DD MMM YYYY")}
                <img src={arioPlan} alt="" />
                <Typography>
                  {flightData?.Origin.AirportCode}{" "}
                  {moment(flightData?.DepTime).format("h:mm A")}
                </Typography>
                <Typography>{flightData?.Duration} m</Typography>
                <Typography>
                  {flightData?.Destination.AirportCode}{" "}
                  {moment(flightData?.ArrTime).format("h:mm A")}
                </Typography>
              </Box> */}
            </Box>

            <Box sx={{ mt: 2, backgroundColor: "#D5E9F4" }}>
              <Typography sx={{ fontWeight: "700", px: 2, py: 2 }}>
                TRAVELLER DETAILS
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#D5E9F4",
                  padding: 2,
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  maxWidth: "100%",
                  flexWrap: "wrap",
                }}
              >
                {passengerData.map((passenger, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: "#B9D7F166",
                      px: 2,
                      py: 1,
                      minWidth: "300px",
                    }}
                  >
                    <Typography>
                      {`${passenger.Title} ${passenger.FirstName} ${passenger.LastName}`}
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Typography>{passenger.Email}, </Typography>
                      <Typography>{passenger.ContactNo}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box sx={{ backgroundColor: "#D5E9F4", padding: 2, mt: 2, mb: 3 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontWeight: "700",
                  borderBottom: "1px solid #03081B",
                  paddingBottom: 1,
                }}
              >
                <img src={seatIcon} alt="" /> Seats
              </Typography>
              {/* {seatviewData?.map((item) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    alignItems: "end",
                    borderBottom: "1px solid #0F35BD",
                    width: "fit-content",
                    paddingBottom: 1,
                    mt: 2,
                  }}
                >
                  <img src={arioPlan} alt="" />
                  <Typography>
                    {item.FromAirportCode} - {item.ToAirportCode},{" "}
                    {item.AirlineCode}-{item.AirlineNumber}
                  </Typography>
                </Box>
              ))} */}

              {seatviewData?.map((seatview, planeIndex) => (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "end",
                      borderBottom: "1px solid #0F35BD",
                      width: "fit-content",
                      paddingBottom: 1,
                      mt: 2,
                    }}
                  >
                    <img src={arioPlan} alt="" />
                    <Typography>
                      {seatview.FromAirportCode} - {seatview.ToAirportCode},{" "}
                      {seatview.AirlineCode}-{seatview.AirlineNumber}
                    </Typography>
                  </Box>
                  <Grid container spacing={2} key={planeIndex}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box
                        sx={{
                          border: "1px solid #000",
                          background: "#FFF",
                          padding: 2,
                          mt: 2,
                        }}
                      >
                        <Box
                          sx={{
                            width: "min(100% - 0px, 90%)",
                            mx: "auto",
                            my: 2,
                          }}
                        >
                          <Box
                            sx={{
                              textAlign: "center",
                              borderBottom: "1px solid #000",
                            }}
                          >
                            <img src={aeroplanHead} alt="" width={"100%"} />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mx: "40px",
                              mt: 1,
                            }}
                          >
                            <img src={ManWomanIcon} alt="" />
                            <img src={teaIcon} alt="" />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mx: "40px",
                              my: 2,
                            }}
                          >
                            <Box
                              sx={{
                                borderLeft: "1px solid #000",
                                display: "flex",
                                gap: 1,
                              }}
                            >
                              <ArrowLeftIcon />
                              <Typography color="error">Exit</Typography>
                            </Box>
                            <Box
                              sx={{
                                borderRight: "1px solid #000",
                                display: "flex",
                                gap: 1,
                              }}
                            >
                              <Typography color="error">Exit</Typography>
                              <ArrowRightIcon />
                            </Box>
                          </Box>
                          <Box>
                            <table
                              id="seatsBlock"
                              border="0"
                              width={"100%"}
                              style={{ borderSpacing: "10px" }}
                            >
                              <tr>
                                <td></td>
                                {Object.keys(seatview.Seats).length > 0 &&
                                  Object.keys(
                                    seatview.Seats[
                                      Object.keys(seatview.Seats)[0]
                                    ]
                                  ).map((columnKey, index) => (
                                    <React.Fragment key={index}>
                                      {index === 3 && <td>&nbsp;</td>}
                                      <td>{String.fromCharCode(65 + index)}</td>
                                    </React.Fragment>
                                  ))}
                              </tr>
                              {Object.keys(seatview.Seats).map(
                                (rowKey, rowIndex) => (
                                  <tr key={rowIndex}>
                                    <td>{rowKey.slice(3)}</td>
                                    {Object.keys(seatview.Seats[rowKey]).map(
                                      (columnKey, columnIndex) => (
                                        <React.Fragment key={columnIndex}>
                                          {columnIndex === 3 && <td>&nbsp;</td>}
                                          <td>
                                            <CustomSeat
                                              seatId={
                                                seatview.Seats[rowKey][
                                                  columnKey
                                                ]
                                              }
                                              onSelectSeat={handleSelectSeat}
                                              rowIndex={rowIndex}
                                              passenger={passengerData}
                                              planeIndex={planeIndex}
                                            />
                                          </td>
                                        </React.Fragment>
                                      )
                                    )}
                                  </tr>
                                )
                              )}
                            </table>
                          </Box>
                          <Box sx={{ textAlign: "center" }}>
                            <img src={arioPlanBottom} alt="" />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              ))}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box sx={{ mt: 2 }}>
                  {passengerData.map((item, index) => (
                    <Typography key={index}>
                      {item.Seat?.length ? `Passenger${index + 1}` : ""}
                      {item.Seat?.map((seat) => (
                        <span key={`${seat.PlaneIndex}-${seat.SeatNumber}`}>
                          {" - " + seat.SeatNumber}{" "}
                        </span>
                      ))}
                    </Typography>
                  ))}
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default FlightSeatBooking;
