import {
  Box,
  Container,
  Divider,
  FormControl,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HotelIcon from "../../assets/img/icon/hotelicon.png";
import Footer from "../footer/Footer";
import StarIcon from "@mui/icons-material/Star";
import Scnericon from "../../assets/img/icon/scnericon.svg";
import HotelBookingScreener from "../../assets/img/hotelbookingscreener.png";
const HotelCheckOutPage = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/", { replace: true });
  }, [navigate]);
  const searchData = useSelector((state) => state.hoteldata.searchData);
  const selectedHotel = useSelector((state) => state.hoteldata.selectedHotel);

  return (
    <>
      <Box className="hotel-check-head">
        <img src={HotelIcon} alt="" width="80px" />
        <Typography className="hotel-check-head-text">Hotel Booking</Typography>
      </Box>
      <Container>
        <Box className="hotel-check-body">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="ticket-number-text">1</Typography>
            <img src={Scnericon} alt="" width="40px" />
          </Box>
          <Typography className="hotel-booking-info-text">
            Hotel Booking Information
          </Typography>
          <Typography className="hotel-name-title">Hotel Name</Typography>
          <Typography className="hotel-name">
            {selectedHotel.HotelName}
          </Typography>
          <Typography className="hotel-address-title">Hotel Adress</Typography>
          <Typography className="hotel-address-loction-text">
            {selectedHotel.HotelAddress}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 2,
            }}
          >
            <Box>
              <Typography className="hotel-name-title">Guests</Typography>
              <Typography className="sub-title">
                {searchData.RoomGuests.reduce((accumulator, current) => {
                  return accumulator + current.NoOfAdults;
                }, 0)}
                Adults ,{" "}
                {searchData.RoomGuests.reduce((accumulator, current) => {
                  return accumulator + current.NoOfChild;
                }, 0)}
                Child
              </Typography>
            </Box>
            <Box>
              <Typography className="hotel-name-title">
                Room - Room No.
              </Typography>
              <Typography className="sub-title">1 Room - 1003</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography className="hotel-name-title">Date</Typography>
              <Typography className="sub-title">
                {searchData.CheckInDate}
              </Typography>
            </Box>
            <Box>
              <Typography className="hotel-name-title">Time</Typography>
              <Typography className="sub-title">
                {new Date(searchData.ArrivalTime).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography>
            </Box>
          </Box>
          <Divider className="divider" />
          <Typography className="hotel-name-title">Guests Details</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography className="hotel-name-title">Name</Typography>
              <Typography className="sub-title">
                {searchData.HotelPassengers.FirstName}
                {searchData.HotelPassengers.LastName}
              </Typography>
            </Box>
            <Box>
              <Typography className="hotel-name-title">Gender</Typography>
              <Typography className="sub-title">Male</Typography>
            </Box>
          </Box>
          <Typography className="hotel-name-title">Phone Number</Typography>
          <Typography className="sub-title">
            +91 {searchData.HotelPassengers.Phoneno}
          </Typography>
          <Box textAlign={"center"} mt={5}>
            <img src={HotelBookingScreener} alt="" />
          </Box>
          <Typography className="thank-you-text">
            Thank You For Booking
          </Typography>
          <Box className="rating-box">
            <StarIcon sx={{ fill: "#5A7FCB" }} />
            <StarIcon sx={{ fill: "#5A7FCB" }} />
            <StarIcon sx={{ fill: "#5A7FCB" }} />
            <StarIcon sx={{ fill: "#5A7FCB" }} />
            <StarIcon sx={{ fill: "#5A7FCB" }} />
          </Box>
          <Box className="comment-section">
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder="Add Your Valuable Comments"
              />
            </FormControl>
            <button className="submit-btn">Submit</button>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default HotelCheckOutPage;
