import React from "react";

function PaymentUnsuccessfull() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        color: "red",
      }}
    >
      Something Went Wrong
    </div>
  );
}

export default PaymentUnsuccessfull;
