import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Hoteldeluxebed from "../../assets/img/hotel/deluxebed.png";
import { Link } from "react-router-dom";
import HotelReviewSection from "../../layouts/Hotel/HotelReviewSection";
import Loader from "../../layouts/Loaders/Loader";
import { useSelector } from "react-redux";
const amenitiesData = [
  {
    id: "0",
    HeadText: "Beds and Blanket",
    TextDetils1: "Cushions",
    TextDetils2: "Woollen Blanket",
    TextDetils3: "Pillows",
  },
  {
    id: "1",
    HeadText: "Safety and Security",
    TextDetils1: "Electronic Safe",
    TextDetils2: "Safety",
    TextDetils3: "Security",
    TextDetils4: "Smoke Alarm",
  },
  {
    id: "2",
    HeadText: "Media and Entertainment",
    TextDetils1: "TV",
    TextDetils2: "Media and Entertainment Device",
  },
];
const RatingData = [
  {
    id: "0",
    TitleName: "Breakfast",
    Range: "8.6",
  },
  {
    id: "1",
    TitleName: "Lunch",
    Range: "8.6",
  },
  {
    id: "2",
    TitleName: "Dinner",
    Range: "8.6",
  },
  {
    id: "3",
    TitleName: "Staff",
    Range: "8.6",
  },
  {
    id: "4",
    TitleName: "Facilities",
    Range: "8.6",
  },
  {
    id: "5",
    TitleName: "Cleanliness",
    Range: "8.6",
  },
  {
    id: "6",
    TitleName: "Comfort",
    Range: "8.6",
  },
  {
    id: "7",
    TitleName: "Free WI-FI",
    Range: "8.6",
  },
  {
    id: "8",
    TitleName: "Parking",
    Range: "8.6",
  },
];
const SelectHotelDetils = () => {
  const loading = useSelector((state) => state.hoteldata.loading);
  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );
  return (
    <>
      <Container maxWidth="xl">
        <Box>
          <img src={Hoteldeluxebed} width={"100%"} alt="" />
          <Box
            sx={{
              display: "felx",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Typography variant="body2" sx={{ ml: 2 }}>
              142 Sq.ft
            </Typography>
            <Typography variant="body2" sx={{ ml: 2 }}>
              King Bed
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography>Deluxe Room With Balcony</Typography>
          <Box className="amenities-section">
            <Box className="amenities-box">
              <Typography className="text-main">Room Amenities </Typography>
              <ul className="anmenities-list-group">
                <li className="anmenities-list-item">Room Services</li>
                <li className="anmenities-list-item">Laundry Services</li>
                <li className="anmenities-list-item">Daily Housekeeping</li>
                <li className="anmenities-list-item">
                  24 Hours In-Room Dinning
                </li>
              </ul>
              <ul className="anmenities-list-group">
                <li className="anmenities-list-item">Air Conditioning</li>
                <li className="anmenities-list-item">Free Wi-fi</li>
                <li className="anmenities-list-item">Work Desk</li>
                <li className="anmenities-list-item">Charging Point</li>
              </ul>
              <ul className="anmenities-list-group">
                <li className="anmenities-list-item">Telephone</li>
                <li className="anmenities-list-item">Seating Area</li>
                <li className="anmenities-list-item">Mini Fridge</li>
                <li className="anmenities-list-item">Closet</li>
              </ul>
              <ul className="anmenities-list-group">
                <li className="anmenities-list-item">Mirror</li>
                <li className="anmenities-list-item">Hangers</li>
                <li className="anmenities-list-item">Chair</li>
                <li className="anmenities-list-item">Clothes Rack</li>
              </ul>
            </Box>
            {amenitiesData.map((CureE, item) => {
              const {
                HeadText,
                TextDetils1,
                TextDetils2,
                TextDetils3,
                TextDetils4,
              } = CureE;
              return (
                <Box className="amenities-box" key={item}>
                  <Typography className="text-main">{HeadText}</Typography>
                  <ul className="anmenities-list-group">
                    <li className="anmenities-list-item">{TextDetils1}</li>
                    <li className="anmenities-list-item">{TextDetils2}</li>
                    <li className="anmenities-list-item">{TextDetils3}</li>
                    <li className="anmenities-list-item">{TextDetils4}</li>
                  </ul>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box className="guest-review-section">
          <Typography className="main-head-text">Guest Review</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography className="review-rating-box">8.5</Typography>
            <Typography sx={{ ml: 2 }}>Very Good</Typography>
          </Box>
          <Box className="category-rating-section">
            <Typography className="head-text">Categories</Typography>
            <Grid container spacing={2}>
              {RatingData.map((CureE, item) => {
                const { TitleName, Range } = CureE;
                return (
                  <Grid item lg={4} md={4} sm={6} xs={6} key={item}>
                    <Box className="rating-card">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography>{TitleName}</Typography>
                        <Typography>{Range}</Typography>
                      </Box>
                      <Box className="range-box">
                        <Box className="range-bar"></Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                mt: 3,
              }}
            >
              <Box>
                <Link className="all-review-btn">Read All Review</Link>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ my: 3 }}>
          <HotelReviewSection />
        </Box>
      </Container>
    </>
  );
};

export default SelectHotelDetils;
