// import { Box, Grid, Typography } from "@mui/material";
// import React, { useState } from "react";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import SearchIcon from "@mui/icons-material/Search";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import SyncAltIcon from "@mui/icons-material/SyncAlt";
// import TravelerDetailsSugestBox from "../TravelerDetailsSugestBox/TravelerDetailsSugestBox";
// import LocationSuggestBox from "../LocationSuggestBox/LocationSuggestBox";
// import dayjs from "dayjs";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// function CheckIn(props) {
//   // const [isOpen, setIsOpen] = React.useState(false);

//   const {
//     SetOpenDatePicker,
//     id,
//     value,
//     InputProps: { ref } = {},
//     inputProps: { "aria-label": ariaLabel } = {},
//   } = props;

//   return (
//     <>
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           flexDirection: "column",
//           cursor: "pointer",
//           padding: "8px",
//         }}
//         onClick={() => SetOpenDatePicker?.((prev) => !prev)}
//       >
//         <Box sx={{ display: "flex", alignItems: "center" }}>
//           <Typography sx={{ fontSize: "22px", fontWeight: "400" }}>
//             Depature{props.title}
//           </Typography>
//           <KeyboardArrowDownIcon />
//         </Box>
//         <Box id={id} ref={ref} aria-label={ariaLabel}>
//           <Typography sx={{ color: "#022474", fontSize: "17px" }}>
//             {value.$d.toString().split(" ").slice(1, 4).join(" ")}
//           </Typography>
//         </Box>
//       </Box>
//     </>
//   );
// }
// function CheckInDatePicker(props) {
//   const [openDatePicker, SetOpenDatePicker] = React.useState(false);
//   const [value, setValue] = React.useState(dayjs());

//   return (
//     <DatePicker
//       onChange={(newValue) => {
//         setValue(newValue);
//         // handleCheckInClick();
//       }}
//       disablePast
//       slots={{ field: CheckIn, ...props.slots }}
//       slotProps={{
//         field: { SetOpenDatePicker },
//         value: value,
//       }}
//       {...props}
//       open={openDatePicker}
//       onClose={() => SetOpenDatePicker(false)}
//       onOpen={() => SetOpenDatePicker(true)}
//       value={value} // Set the initial value here
//     />
//   );
// }
// function Checkout(props) {
//   const {
//     SetOpenDatePicker,
//     id,
//     value,
//     InputProps: { ref } = {},
//     inputProps: { "aria-label": ariaLabel } = {},
//   } = props;

//   return (
//     <>
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           flexDirection: "column",
//           cursor: "pointer",
//           padding: "8px",
//         }}
//         onClick={() => SetOpenDatePicker?.((prev) => !prev)}
//       >
//         <Box sx={{ display: "flex", alignItems: "center" }}>
//           <Typography sx={{ fontSize: "22px", fontWeight: "400" }}>
//             Return
//           </Typography>
//           <KeyboardArrowDownIcon />
//         </Box>

//         {/* <Typography variant="body2">Tap to add a return date for bigger discounts</Typography> */}

//         <Box id={id} ref={ref} aria-label={ariaLabel}>
//           <Typography sx={{ color: "#022474", fontSize: "17px" }}>
//             {value.$d.toString().split(" ").slice(1, 4).join(" ")}
//           </Typography>
//         </Box>
//       </Box>
//     </>
//   );
// }
// function CheckOutDatePicker(props) {
//   const [openDatePicker, SetOpenDatePicker] = React.useState(false);
//   const [value, setValue] = React.useState(dayjs());

//   return (
//     <DatePicker
//       onChange={(newValue) => {
//         setValue(newValue);
//         // handleCheckInClick();
//       }}
//       disablePast
//       slots={{ field: Checkout, ...props.slots }}
//       slotProps={{
//         field: { SetOpenDatePicker },
//         value: value,
//       }}
//       {...props}
//       open={openDatePicker}
//       onClose={() => SetOpenDatePicker(false)}
//       onOpen={() => SetOpenDatePicker(true)}
//       value={value}
//     />
//   );
// }
// const RoundTrip = () => {
//   const [locationBox, setLocationBox] = useState({
//     isFrom: false,
//     isTo: false,
//   });
//   const [value, setValue] = useState(dayjs());
//   const [from, setFrom] = useState("Mumbai");
//   const [to, setTo] = useState("Pune");
//   const [travelerDetails, setTravelerDetails] = useState("");
//   const handleSyncClick = () => {
//     const temp = from;
//     setFrom(to);
//     setTo(temp);
//   };
//   const handeltravelerDetails = () => {
//     setTravelerDetails(!travelerDetails);
//   };
//   const handleRoundTripClick = (args) => {
//     if (args === 0) {
//       setLocationBox({
//         isFrom: true,
//         isTo: false,
//       });
//     } else if (args === 1) {
//       setLocationBox({
//         isFrom: false,
//         isTo: true,
//       });
//     }
//   };
//   return (
//     <Grid container spacing={0} alignItems="stretch">
//       <Grid
//         item
//         lg={4}
//         md={4}
//         sm={5}
//         xs={12}
//         sx={{
//           borderRight: {
//             lg: "1px solid #000",
//             sm: "1px solid #000",
//             xs: "none",
//           },
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-around",
//             width: "100%",
//             alignItems: "center",
//             gap: "20px",
//             padding: "10px",
//           }}
//         >
//           <Box
//             sx={{
//               minWidth: "100px",
//               position: "relative",
//               cursor: "pointer",
//             }}
//             onClick={() => handleRoundTripClick(0)}
//           >
//             <Typography className="from-to-text">From</Typography>
//             <Typography className="location-text">{from}</Typography>
//             {locationBox.isFrom ? <LocationSuggestBox /> : null}
//           </Box>
//           <Box>
//             <Box
//               sx={{
//                 border: "1px solid #000",
//                 borderRadius: "50%",
//                 padding: "5px",
//                 cursor: "pointer",
//                 display: "flex",
//                 justifyContent: "center",
//               }}
//               onClick={handleSyncClick}
//             >
//               <SyncAltIcon sx={{ fontSize: "17px" }} />
//             </Box>
//           </Box>
//           <Box
//             sx={{
//               minWidth: "100px",
//               position: "relative",
//               cursor: "pointer",
//             }}
//             onClick={() => handleRoundTripClick(1)}
//           >
//             <Typography className="from-to-text">To</Typography>
//             <Typography className="location-text">{to}</Typography>
//             {locationBox.isTo ? <LocationSuggestBox /> : null}
//           </Box>
//         </Box>
//       </Grid>
//       <Grid
//         item
//         lg={2}
//         md={2}
//         sm={3}
//         xs={6}
//         sx={{ borderRight: "1px solid #000" }}
//       >
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//           <CheckInDatePicker
//             label={value == null ? null : value.format("MM/DD/YYYY")}
//             value={value}
//             onChange={(newValue) => setValue(newValue)}
//           />
//         </LocalizationProvider>
//       </Grid>
//       <Grid
//         item
//         lg={2}
//         md={2}
//         sm={3}
//         xs={6}
//         sx={{ borderRight: { lg: "1px solid #000", xs: "none" } }}
//       >
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//           <CheckOutDatePicker
//             label={value == null ? null : value.format("MM/DD/YYYY")}
//             value={value}
//             onChange={(newValue) => setValue(newValue)}
//           />
//         </LocalizationProvider>
//       </Grid>
//       <Grid item lg={4} md={4} sm={4} xs={12}>
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-around",
//             position: "relative",
//           }}
//         >
//           <Box sx={{ cursor: "pointer" }} onClick={handeltravelerDetails}>
//             <Typography>Travellers & Class</Typography>
//             <Box sx={{ display: "flex", alignItems: "center" }}>
//               <Typography sx={{ fontSize: "22px", fontWeight: "400" }}>
//                 1 Traveller
//               </Typography>
//               <KeyboardArrowDownIcon />
//             </Box>
//             <Typography>Economy</Typography>
//           </Box>
//           <Box>
//             <Box
//               className="search-icon-box"
//               // onClick={handleSearch}
//               sx={{ cursor: "pointer" }}
//             >
//               <SearchIcon className="search-icon" />
//             </Box>
//           </Box>
//           {travelerDetails ? (
//             <TravelerDetailsSugestBox setTravelerDetails={setTravelerDetails} />
//           ) : null}
//         </Box>
//       </Grid>
//     </Grid>
//   );
// };

// export default RoundTrip;

import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import TravelerDetailsSugestBox from "../TravelerDetailsSugestBox/TravelerDetailsSugestBox";
import LocationSuggestBox from "../LocationSuggestBox/LocationSuggestBox";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setFlightSearchObject,
  setHomePageSearchData,
} from "../../../redux/flight/flightslice";
import { flightSearch } from "../../../redux/flight/flightapi";

function CheckIn(props) {
  const {
    SetOpenDatePicker,
    id,
    value,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        cursor: "pointer",
        padding: "8px",
      }}
      onClick={() => SetOpenDatePicker?.((prev) => !prev)}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontSize: "22px", fontWeight: "400" }}>
          Departure
        </Typography>
        <KeyboardArrowDownIcon />
      </Box>
      <Box id={id} ref={ref} aria-label={ariaLabel}>
        <Typography sx={{ color: "#022474", fontSize: "17px" }}>
          {value.$d.toString().split(" ").slice(1, 4).join(" ")}
        </Typography>
      </Box>
    </Box>
  );
}

function CheckInDatePicker(props) {
  const [openDatePicker, SetOpenDatePicker] = useState(false);
  const [value, setValue] = useState(dayjs());

  return (
    <DatePicker
      onChange={(newValue) => setValue(newValue)}
      disablePast
      slots={{ field: CheckIn, ...props.slots }}
      slotProps={{ field: { SetOpenDatePicker }, value }}
      open={openDatePicker}
      onClose={() => SetOpenDatePicker(false)}
      onOpen={() => SetOpenDatePicker(true)}
      value={value}
      {...props}
    />
  );
}

function Checkout(props) {
  const {
    SetOpenDatePicker,
    id,
    value,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        cursor: "pointer",
        padding: "8px",
      }}
      onClick={() => SetOpenDatePicker?.((prev) => !prev)}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontSize: "22px", fontWeight: "400" }}>
          Return
        </Typography>
        <KeyboardArrowDownIcon />
      </Box>
      <Box id={id} ref={ref} aria-label={ariaLabel}>
        <Typography sx={{ color: "#022474", fontSize: "17px" }}>
          {value.$d.toString().split(" ").slice(1, 4).join(" ")}
        </Typography>
      </Box>
    </Box>
  );
}

function CheckOutDatePicker(props) {
  const [openDatePicker, SetOpenDatePicker] = useState(false);
  const [value, setValue] = useState(dayjs());

  return (
    <DatePicker
      onChange={(newValue) => setValue(newValue)}
      disablePast
      slots={{ field: Checkout, ...props.slots }}
      slotProps={{ field: { SetOpenDatePicker }, value }}
      open={openDatePicker}
      onClose={() => SetOpenDatePicker(false)}
      onOpen={() => SetOpenDatePicker(true)}
      value={value}
      {...props}
    />
  );
}

const RoundTrip = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [locationBox, setLocationBox] = useState({
    isFrom: false,
    isTo: false,
  });
  const [value, setValue] = useState(dayjs());
  const [value2, setValue2] = useState(dayjs());
  const [from, setFrom] = useState({
    airport_city_name: "Mumbai",
  });
  const [to, setTo] = useState({
    airport_city_name: "Pune",
  });
  const [travelerDetails, setTravelerDetails] = useState("");
  const [travelerInfo, setTravelerInfo] = useState({
    adults: 1,
    children: 0,
    infants: 0,
  });

  const handeltravelerDetails = () => {
    setTravelerDetails(!travelerDetails);
  };

  const handleSyncClick = () => {
    const temp = from;
    setFrom(to);
    setTo(temp);
  };

  const handleRoundTripClick = (args) => {
    if (args === 0) {
      setLocationBox({
        isFrom: true,
        isTo: false,
      });
    } else if (args === 1) {
      setLocationBox({
        isFrom: false,
        isTo: true,
      });
    }
  };

  const handleSearch = () => {
    const allowedTimes = {
      anyTime: "00:00:00",
      morning: "08:00:00",
      afternoon: "14:00:00",
      evening: "19:00:00",
      night: "01:00:00",
    };

    // Default to anyTime
    const chosenTime = allowedTimes.anyTime;

    const formattedDepartureDate = moment({
      year: value.$y,
      month: value.$M,
      day: value.$D,
      hour: parseInt(chosenTime.split(":")[0], 10),
      minute: parseInt(chosenTime.split(":")[1], 10),
      second: parseInt(chosenTime.split(":")[2], 10),
    }).format("YYYY-MM-DDTHH:mm:ss");

    const formattedReturnDate = moment({
      year: value2.$y,
      month: value2.$M,
      day: value2.$D,
      hour: parseInt(chosenTime.split(":")[0], 10),
      minute: parseInt(chosenTime.split(":")[1], 10),
      second: parseInt(chosenTime.split(":")[2], 10),
    }).format("YYYY-MM-DDTHH:mm:ss");

    const data = {
      EndUserIp: "1.1.1.1",
      AdultCount: travelerInfo?.adults,
      ChildCount: travelerInfo?.children,
      InfantCount: travelerInfo?.infants,
      JourneyType: "2",
      DirectFlight: 0,
      SoftwareType: "B2C",
      Sources: "",
      minprice: null,
      maxprice: null,
      refundable: true,
      Segments: [
        {
          Origin: from.airport_city_code,
          Destination: to.airport_city_code,
          FlightCabinClass: "1",
          PreferredDepartureTime: formattedDepartureDate,
          PreferredArrivalTime: formattedDepartureDate,
        },
        {
          Origin: to.airport_city_code,
          Destination: from.airport_city_code,
          FlightCabinClass: "1",
          PreferredDepartureTime: formattedReturnDate,
          PreferredArrivalTime: formattedReturnDate,
        },
      ],
    };

    // const data = {
    //   EndUserIp: "1.1.1.1",
    //   AdultCount: travelerInfo.adults,
    //   ChildCount: travelerInfo.children,
    //   InfantCount: travelerInfo.infants,
    //   JourneyType: "2",
    //   DirectFlight: 0,
    //   SoftwareType: "B2C",
    //   Sources: "",
    //   Segments: [
    //     {
    //       Origin: from.airport_city_code,
    //       Destination: to.airport_city_code,
    //       FlightCabinClass: "1",
    //       PreferredDepartureTime: formattedDepartureDate,
    //       PreferredArrivalTime: formattedDepartureDate,
    //     },
    //     {
    //       Origin: to.airport_city_code,
    //       Destination: from.airport_city_code,
    //       FlightCabinClass: "1",
    //       PreferredDepartureTime: formattedReturnDate,
    //       PreferredArrivalTime: formattedReturnDate,
    //     },
    //   ],
    // };
    const searchData = {
      to: to,
      from: from,
      Departure: formattedDepartureDate,
      Return: formattedReturnDate,
      travelerInfo: travelerInfo,
    };
    dispatch(setHomePageSearchData(searchData));
    dispatch(setFlightSearchObject(data));
    dispatch(flightSearch(data))
      .then((res) => navigate("/flight-round-trip"))
      .catch((err) => console.error(err));
  };

  const locationFromRef = useRef(null);
  const locationToRef = useRef(null);

  const [isLocationshowFrom, setLocationshowFrom] = useState(false);
  const [isLocationshowTo, setLocationshowTo] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        locationFromRef.current &&
        !locationFromRef.current.contains(event.target)
      ) {
        setLocationshowFrom(false);
      }
      if (
        locationToRef.current &&
        !locationToRef.current.contains(event.target)
      ) {
        setLocationshowTo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Grid container spacing={0} alignItems="stretch">
      <Grid
        item
        lg={4}
        md={4}
        sm={5}
        xs={12}
        sx={{
          borderRight: {
            lg: "1px solid #000",
            sm: "1px solid #000",
            xs: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            alignItems: "center",
            gap: "20px",
            padding: "10px",
          }}
        >
          <Box
            sx={{
              minWidth: "100px",
              position: "relative",
              cursor: "pointer",
            }}
            ref={locationFromRef}
            onClick={() => setLocationshowFrom((oldVal) => !oldVal)}
          >
            <Typography className="from-to-text">From</Typography>
            <Typography className="location-text">
              {from.airport_city_name}
            </Typography>
            {isLocationshowFrom ? (
              <LocationSuggestBox
                type={"flightfrom"}
                setFrom={setFrom}
                locationBox={locationBox}
                setLocationBox={setLocationBox}
              />
            ) : null}
          </Box>
          <Box>
            <Box
              sx={{
                border: "1px solid #000",
                borderRadius: "50%",
                padding: "5px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={handleSyncClick}
            >
              <SyncAltIcon sx={{ fontSize: "17px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              minWidth: "100px",
              position: "relative",
              cursor: "pointer",
            }}
            ref={locationToRef}
            onClick={() => setLocationshowTo((oldVal) => !oldVal)}
          >
            <Typography className="from-to-text">To</Typography>
            <Typography className="location-text">
              {to.airport_city_name}
            </Typography>
            {isLocationshowTo ? (
              <LocationSuggestBox
                type={"flightto"}
                setTo={setTo}
                locationBox={locationBox}
                setLocationBox={setLocationBox}
              />
            ) : null}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        sm={3}
        xs={6}
        sx={{ borderRight: "1px solid #000" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CheckInDatePicker
            label={value == null ? null : value.format("MM/DD/YYYY")}
            value={value}
            onChange={(newValue) => setValue(newValue)}
          />
        </LocalizationProvider>
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        sm={3}
        xs={6}
        sx={{ borderRight: { lg: "1px solid #000", xs: "none" } }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CheckOutDatePicker
            label={value2 == null ? null : value.format("MM/DD/YYYY")}
            value={value2}
            onChange={(newValue) => setValue2(newValue)}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            position: "relative",
          }}
        >
          <Box sx={{ cursor: "pointer" }} onClick={handeltravelerDetails}>
            <Typography>Travellers & Class</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "22px", fontWeight: "400" }}>
                {travelerInfo
                  ? travelerInfo?.adults +
                    travelerInfo?.children +
                    travelerInfo?.infants
                  : 1}{" "}
                Traveller
              </Typography>
              <KeyboardArrowDownIcon />
            </Box>
            <Typography>Economy</Typography>
          </Box>
          {travelerDetails ? (
            <TravelerDetailsSugestBox
              travelerDetails={travelerDetails}
              setTravelerDetails={setTravelerDetails}
              setTravelerInfo={setTravelerInfo}
              travelerInfo={travelerInfo}
            />
          ) : null}
          <Box
            sx={{
              background: "#152238",
              borderRadius: "8px",
              color: "#fff",
              padding: "10px",
              display: { lg: "block", xs: "none" },
            }}
          >
            <SearchIcon onClick={handleSearch} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RoundTrip;
