import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import offerSpecial1 from "../assets/img/offerSpecial1.png";
import offerSpecial2 from "../assets/img/offerSpecial2.png";
import offerSpecial3 from "../assets/img/offerSpecial3.png";
import offerSpecial4 from "../assets/img/offerSpecial4.png";
const SpecialOffersSection = () => {
  return (
    <section className="special-offers-section">
    <Container maxWidth="xl">
      <Box sx={{ mt: 5 }}>
        <Typography className="head-title">
          Our Special Offers For customized
        </Typography>
        <Box sx={{ mt: 5 }}>
          <Grid container spacing={1}>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Box className="special-offer-card">
                <Box className="img-box">
                  <img src={offerSpecial1} alt="" />
                </Box>
                <Box className="card-contain-box">
                  <Typography className="main-text">
                    100 Free Giveaways,
                    <br /> Daily Jackpot Deals
                  </Typography>
                  <Link className="link-text">View Details</Link>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Box className="special-offer-card">
                <Box className="img-box">
                  <img src={offerSpecial2} alt="" />
                </Box>
                <Box className="card-contain-box">
                  <Typography className="main-text">
                    100 Free Giveaways,
                    <br /> Daily Jackpot Deals
                  </Typography>
                  <Link className="link-text">View Details</Link>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Box className="special-offer-card">
                <Box className="img-box">
                  <img src={offerSpecial3} alt="" />
                </Box>
                <Box className="card-contain-box">
                  <Typography className="main-text">
                    100 Free Giveaways,
                    <br /> Daily Jackpot Deals
                  </Typography>
                  <Link className="link-text">View Details</Link>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Box className="special-offer-card">
                <Box className="img-box">
                  <img src={offerSpecial4} alt="" />
                </Box>
                <Box className="card-contain-box">
                  <Typography className="main-text">
                    100 Free Giveaways,
                    <br /> Daily Jackpot Deals
                  </Typography>
                  <Link className="link-text">View Details</Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  </section>
  )
}

export default SpecialOffersSection
