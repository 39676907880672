import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../helpers/API";
import Notification from "../../layouts/Notification/Notification";

export const getHotelCountryDetails = createAsyncThunk(
  "getHotelCountryDetails",
  async (citystr, thunkAPI) => {
    try {
      const res = await API.get(`/hotel/hotelcitydetails/?city=${citystr}`);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const hotelSearch = createAsyncThunk(
  "hotelSearch",
  async ({ data, page }, thunkAPI) => {
    try {
      const res = await API.post(`hotel/hotelsearch/?page=${page}`, data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const hotelInfo = createAsyncThunk(
  "hotelInfo",
  async (argdata, thunkAPI) => {
    try {
      // const data = {
      //   ResultIndex: 9,
      //   SrdvIndex: "SrdvTB",
      //   SrdvType: "SingleTB",
      //   HotelCode: "92G|DEL",
      //   TraceId: "1",
      // };
      const res = await API.post("hotel/hotelinfo/", argdata);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const hotelRoomInfo = createAsyncThunk(
  "hotelRoomInfo",
  async (BodyData, thunkAPI) => {
    try {
      // const data = {
      //   ResultIndex: 9,
      //   SrdvIndex: "SrdvTB",
      //   SrdvType: "SingleTB",
      //   HotelCode: "92G|DEL",
      //   TraceId: "1",
      // };
      const res = await API.post("hotel/hotelroominfo/", BodyData);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const blockRoomInfo = createAsyncThunk(
  "blockRoomInfo",
  async (bodyData, thunkAPI) => {
    try {
      // const data = {
      //   ResultIndex: 9,
      //   HotelCode: "92G|DEL",
      //   HotelName: "Avatar",
      //   GuestNationality: "IN",
      //   NoOfRooms: "1",
      //   ClientReferenceNo: 0,
      //   IsVoucherBooking: true,
      //   HotelRoomsDetails: [
      //     {
      //       ChildCount: 0,
      //       RequireAllPaxDetails: false,
      //       RoomId: 0,
      //       RoomStatus: 0,
      //       RoomIndex: 4,
      //       RoomTypeCode: "211504640|4|1",
      //       RoomTypeName: "Deluxe Room",
      //       RatePlanCode: "230104963",
      //       RatePlan: 13,
      //       InfoSource: "FixedCombination",
      //       SequenceNo: "EA~~341089~4",
      //       DayRates: [
      //         {
      //           Amount: 12325,
      //           Date: "2019-09-28T00:00:00",
      //         },
      //       ],
      //       SupplierPrice: null,
      //       Price: {
      //         CurrencyCode: "INR",
      //         RoomPrice: 12325,
      //         Tax: 3113.3,
      //         ExtraGuestCharge: 0,
      //         ChildCharge: 0,
      //         OtherCharges: 26,
      //         Discount: 2175,
      //         PublishedPrice: 15464.3,
      //         PublishedPriceRoundedOff: 15464,
      //         OfferedPrice: 15464.3,
      //         OfferedPriceRoundedOff: 15464,
      //         AgentCommission: 0,
      //         AgentMarkUp: 0,
      //         ServiceTax: 4.68,
      //         TDS: 0,
      //         ServiceCharge: 0,
      //         TotalGSTAmount: 4.68,
      //         GST: {
      //           CGSTAmount: 0,
      //           CGSTRate: 0,
      //           CessAmount: 0,
      //           CessRate: 0,
      //           IGSTAmount: 4.68,
      //           IGSTRate: 18,
      //           SGSTAmount: 0,
      //           SGSTRate: 0,
      //           TaxableAmount: 26,
      //         },
      //       },
      //       RoomPromotion: "Member’s exclusive price",
      //       Amenities: ["Breakfast Buffet"],
      //       Amenity: [
      //         "Air conditioning",
      //         "Bathrobes",
      //         "Blackout drapes/curtains",
      //         "Coffee/tea maker",
      //         "DVD player",
      //         "Daily housekeeping",
      //         "Designer toiletries",
      //         "Desk",
      //         "Digital TV service",
      //         "Down comforter",
      //         "Free WiFi",
      //         "Free bottled water",
      //         "Free newspaper",
      //         "Free toiletries",
      //         "HDTV",
      //         "Hair dryer",
      //         "Hypo-allergenic bedding available",
      //         "In-room childcare (surcharge)",
      //         "In-room climate control (air conditioning)",
      //         "In-room safe (laptop compatible)",
      //         "Individually decorated",
      //         "Individually furnished",
      //         "Iron/ironing board (on request)",
      //         "LCD TV",
      //         "MP3 docking station",
      //         "Memory foam mattress",
      //         "Minibar (stocked, some free items)",
      //         "Non-Smoking",
      //         "Phone",
      //         "Premium TV channels",
      //         "Premium bedding",
      //         "Private bathroom",
      //         "Room service (24 hours)",
      //         "Separate dining area",
      //         "Separate sitting area",
      //         "Shower only",
      //         "Slippers",
      //         "Soundproofed rooms",
      //         "Turndown service",
      //         "Yard",
      //       ],
      //       SmokingPreference: "0",
      //       BedTypes: [
      //         {
      //           BedTypeCode: "13",
      //           BedTypeDescription: "1 double bed",
      //         },
      //       ],
      //       HotelSupplements: [],
      //       LastCancellationDate: "2019-09-17T00:00:00",
      //       CancellationPolicies: [
      //         {
      //           Charge: 100,
      //           ChargeType: 2,
      //           Currency: "INR",
      //           FromDate: "2019-09-18T00:00:00",
      //           ToDate: "2019-09-26T23:59:59",
      //         },
      //         {
      //           Charge: 100,
      //           ChargeType: 2,
      //           Currency: "INR",
      //           FromDate: "2019-09-27T00:00:00",
      //           ToDate: "2019-09-29T23:59:59",
      //         },
      //         {
      //           Charge: 100,
      //           ChargeType: 2,
      //           Currency: "INR",
      //           FromDate: "2019-09-28T00:00:00",
      //           ToDate: "2019-09-29T00:00:00",
      //         },
      //       ],
      //       CancellationPolicy:
      //         "Deluxe Room#^#100.00% of total amount will be charged, If cancelled between 18-Sep-2019 00:00:00 and 26-Sep-2019 23:59:59.|100.00% of total amount will be charged, If cancelled between 27-Sep-2019 00:00:00 and 29-Sep-2019 23:59:59.|100.00% of total amount will be charged, If cancelled between 28-Sep-2019 00:00:00 and 29-Sep-2019 00:00:00.|#!#",
      //       Inclusion: ["Breakfast Buffet"],
      //       BedTypeCode: "13",
      //       Supplements: "",
      //     },
      //   ],
      //   SrdvType: "SingleTB",
      //   SrdvIndex: "SrdvTB",
      //   TraceId: "1",
      // };
      const { data, status } = await API.post("hotel/blockroominfo/", bodyData);

      if (status === 200) {
        return data;
      } else {
        Notification("error", data.detail);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const hotelBookRoom = createAsyncThunk(
  "hotelBookRoom",
  async (bodyData, thunkAPI) => {
    try {
      const res = await API.post("hotel/hotelbookroom/", bodyData);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const hotelCancelRoom = createAsyncThunk(
  "hotelCancelRoom",
  async (argdata, thunkAPI) => {
    try {
      const data = {
        BookingId: 1554760,
        RequestType: "4",
        BookingMode: 5,
        Remarks: "No further requirement",
        SrdvType: "SingleTB",
        SrdvIndex: "SrdvTB",
      };

      const res = await API.post("hotel/hotelcancelroom/", data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userHotelBookings = createAsyncThunk(
  "userHotelBookings",
  async (argdata, thunkAPI) => {
    try {
      const res = await API.get("hotel/userhotelbookings/");
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
