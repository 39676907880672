import React, { useState } from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import availableSeatIcon from "../../assets/img/icon/available-seat.png";
import bookedSeatIcon from "../../assets/img/icon/booked-seat.png";
import ladiessleepingseat from "../../assets/img/bus/ladiessleepingseat.png";
import sittingactiveseat from "../../assets/img/bus/activesittingseat.png";
import bookedsittingseat from "../../assets/img/bus/bookedsittingseat.png";
import ladiessittingseat from "../../assets/img/bus/ladiessittingseat.png";
import startingIcon from "../../assets/img/icon/bus-starting.png";
import CircleIcon from "@mui/icons-material/Circle";
import Footer from "../footer/Footer";
import Notification from "../../layouts/Notification/Notification";
import {
  PointoBoard,
  PointtoDrop,
  setSelectedSeat,
} from "../../redux/bus/busslice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../layouts/Loaders/Loader";

const BusSeatBookingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const layoutData = useSelector(
    (state) =>
      state?.busdata?.SeatDetails?.Result?.SeatLayout?.SeatLayoutDetails || []
  );
  const boardingPoints = useSelector(
    (state) => state?.busdata?.boardingPoints || []
  );

  // const {
  //   GetBusRouteDetailResult: { BoardingPointsDetails, DroppingPointsDetails },
  // } = boardingPoints;

  // Safe destructuring with default values to prevent errors
  const {
    GetBusRouteDetailResult: {
      BoardingPointsDetails = [],
      DroppingPointsDetails = [],
    } = {},
  } = boardingPoints || {};

  const [selectedSeats, setSelectedSeats] = useState([]);

  const [selectedBoardingPoint, setSelectedBoardingPoint] = useState(null);
  const [selectedDroppingPoint, setSelectedDroppingPoint] = useState(null);

  const handleSelectBoardingPoint = (index) => {
    setSelectedBoardingPoint(index);
  };

  const handleSelectDroppingPoint = (index) => {
    setSelectedDroppingPoint(index);
  };

  const handleProceed = () => {
    if (selectedSeats.length === 0) {
      Notification("error", "Please Select Seat First");
    } else if (!selectedBoardingPoint) {
      Notification("error", "Please select Boading Point");
    } else if (!selectedDroppingPoint) {
      Notification("error", "Please select Dropping Point");
    } else {
      dispatch(setSelectedSeat(selectedSeats));
      dispatch(PointoBoard(selectedBoardingPoint));
      dispatch(PointtoDrop(selectedDroppingPoint));
      navigate("/bus-confirmation");
    }
  };

  const handleSeatClick = (seat) => {
    const isSelected = selectedSeats.some(
      (selected) => selected.SeatIndex === seat.SeatIndex
    );
    setSelectedSeats(
      isSelected
        ? selectedSeats.filter(
            (selected) => selected.SeatIndex !== seat.SeatIndex
          )
        : [...selectedSeats, seat]
    );
  };

  const getSeatIcon = (seat) => {
    const isSelected = selectedSeats.some(
      (selected) => selected.SeatIndex === seat.SeatIndex
    );
    if (seat.SeatType === 1) {
      if (isSelected) return bookedsittingseat;
      if (seat.IsLadiesSeat) return ladiessittingseat;
      return seat.SeatStatus ? sittingactiveseat : bookedsittingseat;
    } else {
      if (isSelected) return bookedSeatIcon;
      if (seat.IsLadiesSeat) return ladiessleepingseat;
      return seat.SeatStatus ? availableSeatIcon : bookedSeatIcon;
    }
  };

  const renderSeatMatrix = (seats, isUpper) => {
    let maxRow = 0;
    let maxColumn = 0;
    seats.forEach((seat) => {
      maxRow = Math.max(maxRow, parseInt(seat.ColumnNo, 10));
      maxColumn = Math.max(maxColumn, parseInt(seat.RowNo, 10));
    });

    let seatMatrix = Array.from({ length: maxRow + 1 }, () =>
      Array.from({ length: maxColumn + 1 }, () => null)
    );

    seats.forEach((seat) => {
      seatMatrix[parseInt(seat.ColumnNo, 10)][parseInt(seat.RowNo, 10)] = seat;
    });

    seatMatrix.forEach((row) => row.reverse());

    return seatMatrix.map((row, rowIndex) => (
      <Grid container key={`${isUpper ? "upper" : "lower"}-column-${rowIndex}`}>
        {row.map((seat, columnIndex) => {
          // For the upper deck and ColumnNo 000, skip rendering the placeholder
          if (isUpper && columnIndex === row.length - 1) {
            return null; // This skips rendering for upper deck ColumnNo 000
          }

          return seat
            ? renderSeat(seat)
            : renderSeat({ RowNo: columnIndex, ColumnNo: rowIndex }, true); // Render placeholder for other cases
        })}
      </Grid>
    ));
  };

  const renderSeat = (seat, isPlaceholder = false) => {
    if (isPlaceholder) {
      return (
        <Grid item xs key={`placeholder-${seat.RowNo}-${seat.ColumnNo}`}>
          <Box
            sx={{
              width: "64px",
              height: "70px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // border: "1px dashed #ccc",
              margin: "5px",
            }}
          >
            <Typography variant="caption"></Typography>
          </Box>
        </Grid>
      );
    }

    return (
      <Grid item xs key={seat.SeatIndex}>
        <Button
          onClick={() => handleSeatClick(seat)}
          disabled={!seat.SeatStatus}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "40px",
            height: "70px",
            margin: "5px",
            padding: "5px",
            // backgroundColor: seat.SeatStatus ? "#fff" : "#ccc",
            // "&:hover": {
            //   backgroundColor: seat.SeatStatus ? "#f0f0f0" : "#ccc",
            // },
          }}
        >
          <img
            src={getSeatIcon(seat)}
            alt={`Seat ${seat.SeatName}`}
            style={{ width: "30px", height: "60px" }}
          />
          <Typography variant="caption">{seat.SeatName}</Typography>
        </Button>
      </Grid>
    );
  };

  const renderSections = () => {
    const upperSeats = layoutData.Layout?.seatDetails
      .flatMap((row) => row)
      .filter((seat) => seat.IsUpper);
    const lowerSeats = layoutData.Layout?.seatDetails
      .flatMap((row) => row)
      .filter((seat) => !seat.IsUpper);

    return (
      <Box
        sx={{
          display: "flex",
          // justifyContent: "center",
          gap: 2,
          flexWrap: "wrap",
          justifyContent: "flex-start", // Align items to the start (left)
          // flexWrap: "wrap",
        }}
      >
        {lowerSeats?.length > 0 && (
          <Grid
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ marginRight: "10px" }}
          >
            <Box sx={{ border: "1px solid #000" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  background: "#D5E9F4",
                  justifyContent: "center",
                  padding: "10px",
                  marginBottom: "20px",
                }}
              >
                <Typography sx={{ flex: 1, textAlign: "center" }}>
                  LOWER
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <img src={startingIcon} alt="starting" />
                </Box>
              </Box>
              {renderSeatMatrix(lowerSeats, false)}
            </Box>
          </Grid>
        )}
        {upperSeats?.length > 0 && (
          <Grid
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ marginRight: "10px" }}
          >
            <Box sx={{ border: "1px solid #000" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  background: "#D5E9F4",
                  justifyContent: "center",
                  padding: "10px",
                  marginBottom: "20px",
                }}
              >
                <Typography sx={{ flex: 1, textAlign: "center" }}>
                  UPPER
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <img src={startingIcon} alt="starting" />
                </Box>
              </Box>
              {renderSeatMatrix(upperSeats, true)}
            </Box>
          </Grid>
        )}
        {/* <Grid item lg={3} md={3} sm={6} xs={12}>
          <Box sx={{ border: "1px solid #000", padding: "10px" }}>
            {BoardingPointsDetails.map((item, index) => (
              <Box className="travel-deatils-box" key={index}>
                <Box
                  className={`circle-icon ${
                    index === BoardingPointsDetails.length - 1
                      ? "last-circle"
                      : ""
                  }`}
                >
                  <CircleIcon sx={{ color: "#D5E9F4", fontSize: "30px" }} />
                </Box>
                <Typography className="travel-text">
                  {item.CityPointLocation}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Box sx={{ border: "1px solid #000", padding: "10px" }}>
            {DroppingPointsDetails.map((item, index) => (
              <Box className="travel-deatils-box" key={index}>
                <Box
                  className={`circle-icon ${
                    index === DroppingPointsDetails.length - 1
                      ? "last-circle"
                      : ""
                  }`}
                >
                  <CircleIcon sx={{ color: "#D5E9F4", fontSize: "30px" }} />
                </Box>
                <Typography className="travel-text">
                  {item.CityPointLocation}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid> */}
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Box sx={{ border: "1px solid #000", padding: "10px" }}>
            {BoardingPointsDetails?.map((item, index) => (
              <Box
                className="travel-deatils-box"
                key={index}
                onClick={() => handleSelectBoardingPoint(item)}
              >
                <Box
                  className={`circle-icon ${
                    index === BoardingPointsDetails?.length - 1
                      ? "last-circle"
                      : ""
                  }`}
                >
                  <CircleIcon
                    sx={{
                      color:
                        selectedBoardingPoint?.CityPointIndex === index + 1
                          ? "#0b5394"
                          : "#D5E9F4", // Change color if selected
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
                <Typography className="travel-text">
                  {item.CityPointLocation}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Box sx={{ border: "1px solid #000", padding: "10px" }}>
            {DroppingPointsDetails?.map((item, index) => (
              <Box
                className="travel-deatils-box"
                key={index}
                onClick={() => handleSelectDroppingPoint(item)}
              >
                <Box
                  className={`circle-icon ${
                    index === DroppingPointsDetails?.length - 1
                      ? "last-circle"
                      : ""
                  }`}
                >
                  <CircleIcon
                    sx={{
                      color:
                        selectedDroppingPoint?.CityPointIndex === index + 1
                          ? "#0b5394"
                          : "#D5E9F4", // Change color if selected
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
                <Typography className="travel-text">
                  {item.CityPointLocation}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Box>
    );
  };

  const loading = useSelector((state) => state?.busdata?.loading);
  if (loading) return <Loader />;
  return (
    <>
      <Container maxWidth="xl" sx={{ my: 5 }}>
        {renderSections()}
        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
          <Button variant="contained" color="primary" onClick={handleProceed}>
            Proceed
          </Button>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default BusSeatBookingPage;
