import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import OneWay from "./BlueTimeDateSelect/OneWay";
import RoundTrip from "./BlueTimeDateSelect/RoundTrip";
import MultiCityTrip from "./BlueTimeDateSelect/Multi-city/MultiCityTrip";
import AddMoreTrip from "./BlueTimeDateSelect/Multi-city/AddMoreTrip";
const FlightDataselectBox = ({ searchData }) => {
  const [tripValue, setTripValue] = useState("one-way");

  const handleChange = (event) => {
    setTripValue(event.target.value);
  };

  return (
    <Box className="flight-data-select-box">
      <Grid container spacing={2}>
        <Grid item lg={2} sm={2} xs={12}>
          <Box className="select-trip-box">
            <Typography sx={{ color: "#FFF" }}>Trip Type</Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tripValue}
                //   label="Age"
                onChange={handleChange}
                variant="outlined"
                IconComponent={KeyboardArrowDownIcon}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiSelect-icon": {
                    color: "#FFF",
                  },
                }}
              >
                <MenuItem value="one-way">One way</MenuItem>
                <MenuItem value="round-trip">Round Trip</MenuItem>
                <MenuItem value="multi-city">Multi City</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {tripValue === "one-way" && (
          <Grid item lg={10}>
            <OneWay setTripValue={setTripValue} searchData={searchData} />
          </Grid>
        )}
        {tripValue === "round-trip" && <RoundTrip />}

        {tripValue === "multi-city" && (
          <>
            <Grid item lg={10} sm={10} xs={12}>
              <MultiCityTrip />
            </Grid>
            <AddMoreTrip />
            <AddMoreTrip />
          </>
        )}
      </Grid>
    </Box>
  );
};

export default FlightDataselectBox;
