import { Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import React, { useState, useEffect, useRef } from "react"
import SyncAltIcon from "@mui/icons-material/SyncAlt"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import LocationSuggestBox from "../Flight/LocationSuggestBox/LocationSuggestBox"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setSearchData } from "../../redux/bus/busslice"
import moment from "moment"
import { getBusDetails } from "../../redux/bus/busapi"

function CheckIn(props) {
  const {
    SetOpenDatePicker,
    id,
    value,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
          padding: "8px",
          backgroundColor: "#FFF",
        }}
        onClick={() => SetOpenDatePicker?.((prev) => !prev)}
      >
        <Typography sx={{ fontSize: "22px", fontWeight: "400" }}>
          Journey Date{" "}
        </Typography>
        <Box id={id} ref={ref} aria-label={ariaLabel}>
          <Typography sx={{ color: "#022474", fontSize: "17px" }}>
            {value.$d.toString().split(" ").slice(1, 4).join(" ")}
          </Typography>
          <></>
        </Box>
      </Box>
    </>
  )
}
function CheckInDatePicker(props) {
  const [openDatePicker, SetOpenDatePicker] = React.useState(false)
  const [value, setValue] = React.useState(dayjs())
  const searchData = useSelector((state) => state?.busdata?.searchData)
  useEffect(() => {
    setValue(dayjs(searchData.DateData))
  }, [searchData])
  return (
    <DatePicker
      onChange={(newValue) => {
        setValue(newValue)
        // handleCheckInClick();
      }}
      disablePast
      slots={{ field: CheckIn, ...props.slots }}
      slotProps={{
        field: { SetOpenDatePicker },
        value: value,
      }}
      {...props}
      open={openDatePicker}
      onClose={() => SetOpenDatePicker(false)}
      onOpen={() => SetOpenDatePicker(true)}
      value={value} // Set the initial value here
    />
  )
}
const BusDateSelectBox = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [BusCityDetails, setBusCityDetails] = useState({})
  const [from, setFrom] = useState("Mumbai")
  const [to, setTo] = useState("Pune")
  const [value, setValue] = useState(dayjs())
  const [isLocationshowFrom, setLocationshowFrom] = useState(false)
  const [isLocationshowTo, setLocationshowTo] = useState(false)
  const searchData = useSelector((state) => state?.busdata?.searchData)

  const locationFromRef = useRef(null)
  const locationToRef = useRef(null)

  const handleSyncClick = () => {
    const temp = from
    setFrom(to)
    setTo(temp)
  }

  useEffect(() => {
    setBusCityDetails(searchData)
  }, [searchData])

  useEffect(() => {
    setValue(dayjs(searchData.DateData))
  }, [searchData])

  useEffect(() => {
    if (BusCityDetails) {
      setFrom(BusCityDetails.source_city)
      setTo(BusCityDetails.destination_city)
    }
  }, [BusCityDetails])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        locationFromRef.current &&
        !locationFromRef.current.contains(event.target)
      ) {
        setLocationshowFrom(false)
      }
      if (
        locationToRef.current &&
        !locationToRef.current.contains(event.target)
      ) {
        setLocationshowTo(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const handleSearch = () => {
    dispatch(getBusDetails(BusCityDetails)).then(() => {
      dispatch(setSearchData(BusCityDetails))
    })
  }
  const handleDateChange = (newValue) => {
    const formattedDate = newValue.format("YYYY-MM-DD")

    setBusCityDetails((prevDetails) => ({
      ...prevDetails,
      DateData: newValue,
      depart_date: formattedDate, // Format the date as a string in 'YYYY-MM-DD' format
    }))
    setValue(newValue)
  }

  return (
    <Box
      className="bus-booking-select-box"
      sx={{ backgroundColor: `${props.backgroundColor}` }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item lg={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              alignItems: "center",
              gap: "20px",
              padding: "10px",
              backgroundColor: "#fff",
            }}
          >
            <Box
              ref={locationFromRef}
              sx={{
                minWidth: "100px",
                position: "relative",
                cursor: "pointer",
              }}
              // onClick={() => handleOneWayClick(0)}
              onClick={() => setLocationshowFrom(!isLocationshowFrom)}
            >
              <Typography className="from-to-text">From</Typography>
              <Typography className="location-text">
                xssssssfwstfstcstcvyavsy{from}
              </Typography>
              {/* {isLocationshowFrom ? (
                <LocationSuggestBox
                  type={"from"}
                  setBusCityDetails={setBusCityDetails}
                />
              ) : null} */}
            </Box>
            <Box>
              <Box
                sx={{
                  border: "1px solid #000",
                  borderRadius: "50%",
                  padding: "5px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={handleSyncClick}
              >
                <SyncAltIcon sx={{ fontSize: "17px" }} />
              </Box>
            </Box>
            <Box
              ref={locationToRef}
              sx={{
                minWidth: "100px",
                position: "relative",
                cursor: "pointer",
              }}
              // onClick={() => handleOneWayClick(1)}
              onClick={() => setLocationshowTo(!isLocationshowTo)}
            >
              <Typography className="from-to-text">To</Typography>
              <Typography className="location-text">{to}</Typography>
              {isLocationshowTo ? (
                <LocationSuggestBox
                  type={"to"}
                  setBusCityDetails={setBusCityDetails}
                />
              ) : null}
            </Box>
          </Box>
        </Grid>
        <Grid item lg={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CheckInDatePicker
              label={value == null ? null : value.format("MM/DD/YYYY")}
              value={value}
              onChange={(newValue) => handleDateChange(newValue)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item lg={3}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <button className="bus-search-btn" onClick={(e) => handleSearch()}>
              Search Buses
            </button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BusDateSelectBox
