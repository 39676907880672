/* eslint-disable jsx-a11y/iframe-has-title */
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Stack,
  Typography,
  Modal,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import { Link, NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AboutHotel from "../../assets/img/abouthotel.png";
import CloseIcon from "../../assets/img/icon/closered.png";
import CheckIconTick from "../../assets/img/icon/checkicontick.png";
import HotelBedImg from "../../assets/img/hotelbed.png";
import TimerIcon from "../../assets/img/icon/timerIcon.png";
import Footer from "../footer/Footer";
import Hotelsahara from "../../assets/img/hotel/hotelsahara.png";
import HotelReviewSection from "../../layouts/Hotel/HotelReviewSection";
import { useDispatch, useSelector } from "react-redux";
import { blockRoomInfo } from "../../redux/hotel/hotelapi";
import Loader from "../../layouts/Loaders/Loader";
import { setSelectedRoom } from "../../redux/hotel/hotelslice";
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
const amenitiesData = [
  {
    id: "0",
    HeadText: "Highlighted Amenities",
    TextDetils1: "Swimming Pool",
    TextDetils2: "Restaurant",
    TextDetils3: "24-hour Room Service",
    TextDetils4: "Kids' Meals",
  },
  {
    id: "1",
    HeadText: "Basic Facillities",
    TextDetils1: "Swimming Pool",
    TextDetils2: "Dry Cleaning Service",
    TextDetils3: "24-hour Room Service",
    TextDetils4: "WI-FI",
  },
  {
    id: "2",
    HeadText: "Transfers",
    TextDetils1: "Paid Airport Transfers",
    TextDetils2: "Paid Pickup/Drop",
    TextDetils3: "Paid Bus Station Transfers",
    TextDetils4: "Kids' Meals",
  },
  {
    id: "3",
    HeadText: "Food and Drinks",
    TextDetils1: "Restaurant",
    TextDetils2: "Kids' Meals",
    TextDetils3: "Dining Area",
    TextDetils4: "Paid Railway Station Transfers",
  },
  {
    id: "0",
    HeadText: "Safety and Security",
    TextDetils1: "Safety and Security",
    TextDetils2: "CCTV",
    TextDetils3: "Fire Extinguishers",
    TextDetils4: "Emergency Exit Map",
  },
];

const Hotelsimilarcarddata = [
  {
    id: "0",
    HotelImg: Hotelsahara,
    HotelName: "Hotel Sahara Star",
    Rating: "4.5",
    Distancen: "2.1 km Away",
    HotelPrice: "12,405",
  },
  {
    id: "1",
    HotelImg: Hotelsahara,
    HotelName: "Hotel Sahara Star",
    Rating: "4.5",
    Distancen: "2.1 km Away",
    HotelPrice: "12,405",
  },
  {
    id: "2",
    HotelImg: Hotelsahara,
    HotelName: "Hotel Sahara Star",
    Rating: "4.5",
    Distancen: "2.1 km Away",
    HotelPrice: "12,405",
  },
  {
    id: "3",
    HotelImg: Hotelsahara,
    HotelName: "Hotel Sahara Star",
    Rating: "4.5",
    Distancen: "2.1 km Away",
    HotelPrice: "12,405",
  },
  {
    id: "4",
    HotelImg: Hotelsahara,
    HotelName: "Hotel Sahara Star",
    Rating: "4.5",
    Distancen: "2.1 km Away",
    HotelPrice: "12,405",
  },
  {
    id: "5",
    HotelImg: Hotelsahara,
    HotelName: "Hotel Sahara Star",
    Rating: "4.5",
    Distancen: "2.1 km Away",
    HotelPrice: "12,405",
  },
];
const HotelBookingPageTwo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state?.hoteldata.hotelRoomInfo);

  const hotelDetails = useSelector((state) => state?.hoteldata.hotelInfo);
  const homepagesearchdata = useSelector(
    (state) => state.hoteldata.searchData.dataToSendInSearch
  );

  const loading = useSelector((state) => state.hoteldata.loading);
  const [activeLink, setActiveLink] = useState("overview-section");
  useEffect(() => {
    setActiveLink("overview-section");
  }, []);

  const scrollToSection = (sectionId) => {
    const id = document?.getElementById(sectionId)?.getBoundingClientRect();
    const yOffset = -200;

    if (id) {
      window.scrollTo({
        top: id.top + window.scrollY + yOffset,
        behavior: "smooth",
      });
      setActiveLink(sectionId);
    }
  };
  const handleBlockRoom = (item) => {
    if (localStorage.getItem("token")) {
      dispatch(setSelectedRoom(item));
      window.scrollTo(0, 0);
      navigate("/hotel-payment-page");
    } else {
      const currentPath = window.location.pathname;
      localStorage.setItem("redirectPath", currentPath);
      navigate("/login");
    }
    // dispatch(blockRoomInfo());
  };
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="#1305B6"
      href="/"
      onClick={handleClick}
    >
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      Hotel in {homepagesearchdata?.SearchCity.destination}
    </Link>,
    <Typography key="3" color="text.primary">
      {hotelDetails.HotelInfoResult.HotelDetails.HotelName}
    </Typography>,
  ];

  const [open, setOpen] = useState(false);

  // Function to handle modal open
  const handleOpen = () => setOpen(true);
  // Function to handle modal close
  const handleClose = () => setOpen(false);

  // Modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <>
      <Container maxWidth="xl">
        <Box>
          <Stack spacing={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Box>
        <Box sx={{ position: "relative" }}>
          <Box className="hotel-booking-nav-section">
            <ul className="hotel-booking-ul">
              <li
                className={`hotel-booking-li ${
                  activeLink === "overview-section" ? "active-li" : ""
                }`}
              >
                <NavLink
                  className="hotel-booking-link"
                  to="#overview-section"
                  onClick={() => scrollToSection("overview-section")}
                >
                  Overview
                </NavLink>
              </li>
              <li
                className={`hotel-booking-li ${
                  activeLink === "room-section" ? "active-li" : ""
                }`}
              >
                <NavLink
                  className="hotel-booking-link"
                  to="#room-section"
                  onClick={() => scrollToSection("room-section")}
                >
                  Rooms
                </NavLink>
              </li>
              <li
                className={`hotel-booking-li ${
                  activeLink === "location-map" ? "active-li" : ""
                }`}
              >
                <NavLink
                  className="hotel-booking-link"
                  to="#location-map"
                  onClick={() => scrollToSection("location-map")}
                >
                  Location
                </NavLink>
              </li>
              <li
                className={`hotel-booking-li ${
                  activeLink === "amenities-section" ? "active-li" : ""
                }`}
              >
                <NavLink
                  className="hotel-booking-link"
                  onClick={() => scrollToSection("amenities-section")}
                >
                  Amenities
                </NavLink>
              </li>
              <li
                className={`hotel-booking-li ${
                  activeLink === "Property-Rules-section" ? "active-li" : ""
                }`}
              >
                <NavLink
                  className="hotel-booking-link"
                  onClick={() => scrollToSection("Property-Rules-section")}
                >
                  Properity Rules
                </NavLink>
              </li>
              <li
                className={`hotel-booking-li ${
                  activeLink === "user-review-section" ? "active-li" : ""
                }`}
              >
                <NavLink
                  className="hotel-booking-link"
                  onClick={() => scrollToSection("user-review-section")}
                >
                  User Review
                </NavLink>
              </li>
            </ul>
          </Box>
          <Box id="overview-section">
            <Typography className="about-hotel-main-text" id="overview-head">
              About The {hotelDetails?.HotelInfoResult?.HotelDetails.HotelName}
              {/* Hyatt Hotel */}
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <img
                    src={hotelDetails?.HotelInfoResult?.HotelDetails.Images[0]}
                    alt=""
                    width="100%"
                  />
                </Grid>
                <Grid item lg={9} md={9} sm={9} xs={12}>
                  {/* <Typography>
                    
                    {hotelDetails.HotelInfoResult.HotelDetails.Description}
                  </Typography> */}
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html:
                        hotelDetails?.HotelInfoResult?.HotelDetails.Description,
                    }}
                  >
                    {/* Intentionally left blank */}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box
            id="room-section"
            className={` ${
              activeLink === "room-section" ? "active-li--" : ""
            } `}
          >
            <Typography id="room-section-h" className="hotel-room-main-text">
              Select Rooms
            </Typography>
            <Box></Box>
            {data?.GetHotelRoomResult.HotelRoomsDetails.map((item, i) => {
              return (
                <>
                  <Box sx={{ mb: 5 }}>
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="room-detais-box">
                          <Box className="room-detils-head-section">
                            <Grid container spacing={2}>
                              <Grid item lg={3} md={3} sm={3} xs={6}>
                                <Typography className="room-detils-main-tittle">
                                  {/* Deluxe Room <br /> With Balcony */}
                                  {item.RoomTypeName}
                                </Typography>
                              </Grid>
                              {/* Modal */}
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="cancellation-policy-title"
                                aria-describedby="cancellation-policy-description"
                              >
                                <Box sx={style}>
                                  <Typography
                                    id="cancellation-policy-title"
                                    variant="h6"
                                    component="h2"
                                  >
                                    Cancellation Policy
                                  </Typography>
                                  {/* <Typography
                                    id="cancellation-policy-description"
                                    sx={{ mt: 2 }}
                                  >
                                    {item.CancellationPolicy}{" "}
                                  </Typography> */}
                                  <Typography
                                    id="cancellation-policy-description"
                                    sx={{ mt: 2 }}
                                    dangerouslySetInnerHTML={{
                                      __html: item.CancellationPolicy,
                                    }}
                                  >
                                    {/* Intentionally left blank */}
                                  </Typography>
                                </Box>
                              </Modal>
                              <Grid item lg={3} md={3} sm={2} xs={6}>
                                <Typography variant="body2">
                                  142 Sq.ft
                                </Typography>
                                <Typography variant="body2">
                                  King Bed
                                </Typography>
                              </Grid>
                              <Grid item lg={5} md={5} sm={6} xs={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box>
                                    <ul className="room-detils-ul">
                                      <li>Room Services</li>
                                      <li>Air Conditioning</li>
                                    </ul>
                                  </Box>
                                  <Box>
                                    <ul className="room-detils-ul">
                                      <li>Laundry Services</li>
                                      <li>Free Wi-fi</li>
                                    </ul>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box className="room-detils-section">
                            <Box>
                              <Typography className="room-detils-main-tittle">
                                {item.Amenities}
                              </Typography>
                              <Typography variant="body2">
                                <span style={{ marginRight: "10px" }}>
                                  <img src={CloseIcon} alt="" />
                                </span>{" "}
                                Non - Refundable
                              </Typography>
                              <Typography variant="body2">
                                <span style={{ marginRight: "5px" }}>
                                  <img src={CheckIconTick} alt="" />
                                </span>{" "}
                                No Meal included
                              </Typography>
                              <Typography
                                component="span"
                                variant="caption"
                                style={{
                                  cursor: "pointer",
                                  // textDecoration: "underline",
                                  color: "#007bff",
                                }}
                                onClick={handleOpen}
                              >
                                Cancellation Policy
                              </Typography>
                            </Box>

                            <Box>
                              <Typography>
                                <del style={{ textDecorationColor: "red" }}>
                                  {/* Rs. 6,000 */}
                                  Rs. {item.Price.PublishedPriceRoundedOff}
                                </del>
                              </Typography>
                              <Typography className="main-price">
                                Rs. {item.Price.OfferedPriceRoundedOff}{" "}
                                {/* 3,999{" "} */}
                                <span className="per-night-day-text">
                                  Per Night
                                </span>{" "}
                              </Typography>
                              <Typography className="text-add-text">
                                + Rs. {item.Price.OtherCharges} Taxes & Fees
                              </Typography>
                              <Link
                                // to="/hotel-payment-page"
                                className="select-room-link"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleBlockRoom(item);
                                }}
                              >
                                {" "}
                                Select Room
                              </Link>
                            </Box>
                          </Box>
                          {/* <Box className="room-detils-section">
                            <Box>
                              <Typography className="room-detils-main-tittle">
                                Room With Breakfast{" "}
                              </Typography>
                              <Typography variant="body2">
                                <span style={{ marginRight: "10px" }}>
                                  <img src={CloseIcon} alt="" />
                                </span>{" "}
                                Non - Refundable
                              </Typography>
                              <Typography variant="body2">
                                <span style={{ marginRight: "5px" }}>
                                  <img src={CheckIconTick} alt="" />
                                </span>{" "}
                                No Meal included
                              </Typography>
                            </Box>
                            <Box>
                              <Typography>
                                <del style={{ textDecorationColor: "red" }}>
                                  Rs. 6,000
                                </del>
                              </Typography>
                              <Typography className="main-price">
                                Rs. 3,999{" "}
                                <span className="per-night-day-text">
                                  Per Night
                                </span>{" "}
                              </Typography>
                              <Typography className="text-add-text">
                                + Rs. 800 Taxes & Fees
                              </Typography>
                              <Link
                                to="/hotel-payment-page"
                                className="select-room-link"
                              >
                                {" "}
                                Select Room
                              </Link>
                            </Box>
                          </Box>
                          <Box className="room-detils-section">
                            <Box>
                              <Typography className="room-detils-main-tittle">
                                Room With Breakfast , Lunch & Dinner{" "}
                              </Typography>
                              <Typography variant="body2">
                                <span style={{ marginRight: "10px" }}>
                                  <img src={CloseIcon} alt="" />
                                </span>{" "}
                                Non - Refundable
                              </Typography>
                              <Typography variant="body2">
                                <span style={{ marginRight: "5px" }}>
                                  <img src={CheckIconTick} alt="" />
                                </span>{" "}
                                No Meal included
                              </Typography>
                            </Box>
                            <Box>
                              <Typography>
                                <del style={{ textDecorationColor: "red" }}>
                                  Rs. 6,000
                                </del>
                              </Typography>
                              <Typography className="main-price">
                                Rs. 3,999{" "}
                                <span className="per-night-day-text">
                                  Per Night
                                </span>{" "}
                              </Typography>
                              <Typography className="text-add-text">
                                + Rs. 800 Taxes & Fees
                              </Typography>
                              <Link
                                to="/hotel-payment-page"
                                className="select-room-link"
                              >
                                {" "}
                                Select Room
                              </Link>
                            </Box>
                          </Box> */}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              );
            })}
            {/* <Box sx={{ mb: 5 }}>
              <Grid container spacing={2}>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <Box className="room-img-box">
                    <img src={HotelBedImg} alt="" width={"100%"} />
                  </Box>
                </Grid>
                <Grid item lg={9} md={9} sm={12} xs={12}>
                  <Box className="room-detais-box">
                    <Box className="room-detils-head-section">
                      <Grid container spacing={2}>
                        <Grid item lg={3} md={3} sm={3} xs={6}>
                          <Typography className="room-detils-main-tittle">
                            Deluxe Room <br /> With Balcony
                          </Typography>
                        </Grid>
                        <Grid item lg={3} md={3} sm={2} xs={6}>
                          <Typography variant="body2">142 Sq.ft</Typography>
                          <Typography variant="body2">King Bed</Typography>
                        </Grid>
                        <Grid item lg={5} md={5} sm={6} xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>
                              <ul className="room-detils-ul">
                                <li>Room Services</li>
                                <li>Air Conditioning</li>
                              </ul>
                            </Box>
                            <Box>
                              <ul className="room-detils-ul">
                                <li>Laundry Services</li>
                                <li>Free Wi-fi</li>
                              </ul>
                            </Box>
                          </Box>
                          <Box textAlign={"right"}>
                            <Link
                              to="/select-hotel-detils"
                              className="view-more-link"
                            >
                              View More
                            </Link>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="room-detils-section">
                      <Box>
                        <Typography className="room-detils-main-tittle">
                          Room Only
                        </Typography>
                        <Typography variant="body2">
                          <span style={{ marginRight: "10px" }}>
                            <img src={CloseIcon} alt="" />
                          </span>{" "}
                          Non - Refundable
                        </Typography>
                        <Typography variant="body2">
                          <span style={{ marginRight: "5px" }}>
                            <img src={CheckIconTick} alt="" />
                          </span>{" "}
                          No Meal included
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          <del style={{ textDecorationColor: "red" }}>
                            Rs. 6,000
                          </del>
                        </Typography>
                        <Typography className="main-price">
                          Rs. 3,999{" "}
                          <span className="per-night-day-text">Per Night</span>{" "}
                        </Typography>
                        <Typography className="text-add-text">
                          + Rs. 800 Taxes & Fees
                        </Typography>
                        <Link
                          to="/hotel-payment-page"
                          className="select-room-link"
                        >
                          {" "}
                          Select Room
                        </Link>
                      </Box>
                    </Box>
                    <Box className="room-detils-section">
                      <Box>
                        <Typography className="room-detils-main-tittle">
                          Room With Breakfast{" "}
                        </Typography>
                        <Typography variant="body2">
                          <span style={{ marginRight: "10px" }}>
                            <img src={CloseIcon} alt="" />
                          </span>{" "}
                          Non - Refundable
                        </Typography>
                        <Typography variant="body2">
                          <span style={{ marginRight: "5px" }}>
                            <img src={CheckIconTick} alt="" />
                          </span>{" "}
                          No Meal included
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          <del style={{ textDecorationColor: "red" }}>
                            Rs. 6,000
                          </del>
                        </Typography>
                        <Typography className="main-price">
                          Rs. 3,999{" "}
                          <span className="per-night-day-text">Per Night</span>{" "}
                        </Typography>
                        <Typography className="text-add-text">
                          + Rs. 800 Taxes & Fees
                        </Typography>
                        <Link
                          to="/hotel-payment-page"
                          className="select-room-link"
                        >
                          {" "}
                          Select Room
                        </Link>
                      </Box>
                    </Box>
                    <Box className="room-detils-section">
                      <Box>
                        <Typography className="room-detils-main-tittle">
                          Room With Breakfast , Lunch & Dinner{" "}
                        </Typography>
                        <Typography variant="body2">
                          <span style={{ marginRight: "10px" }}>
                            <img src={CloseIcon} alt="" />
                          </span>{" "}
                          Non - Refundable
                        </Typography>
                        <Typography variant="body2">
                          <span style={{ marginRight: "5px" }}>
                            <img src={CheckIconTick} alt="" />
                          </span>{" "}
                          No Meal included
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          <del style={{ textDecorationColor: "red" }}>
                            Rs. 6,000
                          </del>
                        </Typography>
                        <Typography className="main-price">
                          Rs. 3,999{" "}
                          <span className="per-night-day-text">Per Night</span>{" "}
                        </Typography>
                        <Typography className="text-add-text">
                          + Rs. 800 Taxes & Fees
                        </Typography>
                        <Link
                          to="/hotel-payment-page"
                          className="select-room-link"
                        >
                          {" "}
                          Select Room
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box> */}
            {/* 2 */}
            {/* <Box sx={{ mb: 5 }}>
              <Grid container spacing={2}>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <Box className="room-img-box">
                    <img src={HotelBedImg} alt="" width={"100%"} />
                  </Box>
                </Grid>
                <Grid item lg={9} md={9} sm={12} xs={12}>
                  <Box className="room-detais-box">
                    <Box className="room-detils-head-section">
                      <Grid container spacing={2}>
                        <Grid item lg={3} md={3} sm={3} xs={6}>
                          <Typography className="room-detils-main-tittle">
                            Deluxe Room <br /> With Balcony
                          </Typography>
                        </Grid>
                        <Grid item lg={3} md={3} sm={2} xs={6}>
                          <Typography variant="body2">142 Sq.ft</Typography>
                          <Typography variant="body2">King Bed</Typography>
                        </Grid>
                        <Grid item lg={5} md={5} sm={6} xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>
                              <ul className="room-detils-ul">
                                <li>Room Services</li>
                                <li>Air Conditioning</li>
                              </ul>
                            </Box>
                            <Box>
                              <ul className="room-detils-ul">
                                <li>Laundry Services</li>
                                <li>Free Wi-fi</li>
                              </ul>
                            </Box>
                          </Box>
                          <Box textAlign={"right"}>
                            <Link className="view-more-link">View More</Link>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="room-detils-section">
                      <Box>
                        <Typography className="room-detils-main-tittle">
                          Room Only
                        </Typography>
                        <Typography variant="body2">
                          <span style={{ marginRight: "10px" }}>
                            <img src={CloseIcon} alt="" />
                          </span>{" "}
                          Non - Refundable
                        </Typography>
                        <Typography variant="body2">
                          <span style={{ marginRight: "5px" }}>
                            <img src={CheckIconTick} alt="" />
                          </span>{" "}
                          No Meal included
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          <del style={{ textDecorationColor: "red" }}>
                            Rs. 6,000
                          </del>
                        </Typography>
                        <Typography className="main-price">
                          Rs. 3,999{" "}
                          <span className="per-night-day-text">Per Night</span>{" "}
                        </Typography>
                        <Typography className="text-add-text">
                          + Rs. 800 Taxes & Fees
                        </Typography>
                        <Link
                          to="/hotel-payment-page"
                          className="select-room-link"
                        >
                          {" "}
                          Select Room
                        </Link>
                      </Box>
                    </Box>
                    <Box className="room-detils-section">
                      <Box>
                        <Typography className="room-detils-main-tittle">
                          Room With Breakfast{" "}
                        </Typography>
                        <Typography variant="body2">
                          <span style={{ marginRight: "10px" }}>
                            <img src={CloseIcon} alt="" />
                          </span>{" "}
                          Non - Refundable
                        </Typography>
                        <Typography variant="body2">
                          <span style={{ marginRight: "5px" }}>
                            <img src={CheckIconTick} alt="" />
                          </span>{" "}
                          No Meal included
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          <del style={{ textDecorationColor: "red" }}>
                            Rs. 6,000
                          </del>
                        </Typography>
                        <Typography className="main-price">
                          Rs. 3,999{" "}
                          <span className="per-night-day-text">Per Night</span>{" "}
                        </Typography>
                        <Typography className="text-add-text">
                          + Rs. 800 Taxes & Fees
                        </Typography>
                        <Link
                          to="/hotel-payment-page"
                          className="select-room-link"
                        >
                          {" "}
                          Select Room
                        </Link>
                      </Box>
                    </Box>
                    <Box className="room-detils-section">
                      <Box>
                        <Typography className="room-detils-main-tittle">
                          Room With Breakfast , Lunch & Dinner{" "}
                        </Typography>
                        <Typography variant="body2">
                          <span style={{ marginRight: "10px" }}>
                            <img src={CloseIcon} alt="" />
                          </span>{" "}
                          Non - Refundable
                        </Typography>
                        <Typography variant="body2">
                          <span style={{ marginRight: "5px" }}>
                            <img src={CheckIconTick} alt="" />
                          </span>{" "}
                          No Meal included
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          <del style={{ textDecorationColor: "red" }}>
                            Rs. 6,000
                          </del>
                        </Typography>
                        <Typography className="main-price">
                          Rs. 3,999{" "}
                          <span className="per-night-day-text">Per Night</span>{" "}
                        </Typography>
                        <Typography className="text-add-text">
                          + Rs. 800 Taxes & Fees
                        </Typography>
                        <Link
                          to="/hotel-payment-page"
                          className="select-room-link"
                        >
                          {" "}
                          Select Room
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box> */}
          </Box>

          <Box id="location-map">
            <Typography
              id="location-head"
              sx={{
                color: "#0063A7",
                mb: 2,
                fontWeight: "700",
                fontSize: "22px",
              }}
              variant="subtitle1"
            >
              Location
            </Typography>
            <Box sx={{ height: "450px" }}>
              <iframe
                alt="map"
                // src={`https://www.google.com/maps?q=${hotelDetails.HotelInfoResult.HotelDetails.Latitude},${hotelDetails.HotelInfoResult.HotelDetails.Longitude}&hl=es;z=14&amp;output=embed`}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29374.58873699608!2d72.52939078169652!3d23.030248524499797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8461a2095f23%3A0xca272b244270caea!2sHyatt%20Regency%20Ahmedabad!5e0!3m2!1sen!2sin!4v1705996354386!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Box>
          </Box>

          <Box id="amenities-section" className="amenities-section">
            <Typography className="amenities-head-tittle" id="amenities-head">
              Amenities At {hotelDetails.HotelInfoResult.HotelDetails.HotelName}{" "}
              In {homepagesearchdata?.SearchCity.destination}
            </Typography>
            {amenitiesData.map((CureE, item) => {
              const {
                HeadText,
                TextDetils1,
                TextDetils2,
                TextDetils3,
                TextDetils4,
              } = CureE;
              return (
                <Box className="amenities-box" key={item}>
                  <Typography className="text-main">{HeadText}</Typography>
                  <ul className="anmenities-list-group">
                    <li className="anmenities-list-item">{TextDetils1}</li>
                    <li className="anmenities-list-item">{TextDetils2}</li>
                    <li className="anmenities-list-item">{TextDetils3}</li>
                    <li className="anmenities-list-item">{TextDetils4}</li>
                  </ul>
                </Box>
              );
            })}
          </Box>
          <Box id="Property-Rules-section">
            <Typography className="main-head-text">Property Rules</Typography>
            <ul className="Property-Rules-group">
              <li>
                Guests below 18 years of age are not allowed at the property.
              </li>
              <li>Unmarried couples allowed</li>
              <li>
                Passport, Aadhar, Driving License and Govt. ID are accepted as
                ID proof(s)
              </li>
              <li>Pets are not allowed</li>
              <li>Outside food is not allowed</li>
              <li>Smoking within the premises is not allowed</li>
              <li>Allows private parties or events</li>
            </ul>
          </Box>
          <Box id="user-review-section">
            <Typography className="main-head-text">User Reviews</Typography>
            <Typography variant="body2">
              Important information that you need to know before you book!
            </Typography>
            <Box className="review-head-section">
              <Box sx={{ display: "flex", alignItems: "center", mr: 5 }}>
                <Typography className="review-rating-box">4.5</Typography>
                <Typography>Very Good</Typography>
              </Box>
              <Box>
                <Typography>
                  <span>
                    <img
                      src={TimerIcon}
                      alt=""
                      width={"20px"}
                      style={{ marginRight: "5px" }}
                    />
                  </span>{" "}
                  We Aim for 100% Real Review
                </Typography>
              </Box>
            </Box>
            <HotelReviewSection />
          </Box>
        </Box>

        <Box className="similar-hotel-section">
          <Typography className="main-head-text">Similar Properties</Typography>
          <Box>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              // centeredSlides={true}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: false,
              // }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="mySwiper hotel-simlar-swiper"
            >
              {Hotelsimilarcarddata.map((curEle, i) => {
                const { HotelImg, HotelName, Rating, Distancen, HotelPrice } =
                  curEle;
                return (
                  <SwiperSlide>
                    <Box className="similar-hotel-card">
                      <img src={HotelImg} maxWidth={"100%"} alt={HotelName} />
                      <Typography className="hotel-name">
                        {HotelName}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          my: 3,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0px 10px",
                          }}
                        >
                          <Typography className="rating-box">
                            {Rating}
                          </Typography>
                          <Typography ml={1}>{Distancen}</Typography>
                        </Box>
                        <Typography className="hotel-price">
                          Rs.{HotelPrice}
                        </Typography>
                      </Box>
                      <Link className="link-text">View Details</Link>
                    </Box>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default HotelBookingPageTwo;
