import UserIcon from "../../assets/img/icon/user.png";
import EmailPhoneIcon from "../../assets/img/icon/email-or-phone.png";
import PasswordIcon from "../../assets/img/icon/password.png";
import GoogleIcon from "../../assets/img/icon/google-icon.png";
import FacebookIcon from "../../assets/img/icon/facebook-icon.png";

import {
  Box,
  Container,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/Authentication/authenticationapi";
import Notification from "../../layouts/Notification/Notification";
const Loging = () => {
  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInput((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateInputs = () => {
    let tempErrors = { email: "", password: "" };
    let isValid = true;
    if (!userInput.email) {
      tempErrors.email = "Email or Mobile number is required";
      isValid = false;
    }
    if (!userInput.password) {
      tempErrors.password = "Password is required";
      isValid = false;
    } else if (userInput.password.length < 6) {
      tempErrors.password = "Password must be at least 6 characters";
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInputs()) {
      dispatch(loginUser(userInput))
        .then((res) => {
          if (res.payload.detail === "Invalid credentials") {
            Notification("error", res.payload.detail);
          } else {
            localStorage.setItem("token", res.payload.data.access);
            // localStorage.setItem("userDetails", res.payload.data);
            localStorage.setItem(
              "userDetails",
              JSON.stringify(res.payload.data)
            );
            Notification("success", "Successfully logged in");
            // navigate("/hotel-home-page");
            const redirectPath = localStorage.getItem("redirectPath");
            if (redirectPath) {
              localStorage.removeItem("redirectPath"); // Clean up
              navigate(redirectPath);
            } else {
              navigate("/hotel-home-page");
            }
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  const handleRegister = () => {
    navigate("/");
  };

  return (
    <Box className="auth-bg-section">
      <Container maxWidth="xl">
        <Box>
          <Box className="auth-card">
            <Box className="user-box">
              <img src={UserIcon} alt="" />
            </Box>
            <form onSubmit={handleSubmit}>
              <Box className="form-section">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <Box className="icon-box">
                    <img src={EmailPhoneIcon} alt="" />
                  </Box>
                  <Box className="form-box">
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className="formcontroal-box"
                    >
                      <TextField
                        type="email"
                        name="email"
                        placeholder="Enter Email ID Or Mobile Number"
                        sx={{ "& fieldset": { border: "none" } }}
                        value={userInput.email}
                        onChange={handleInputChange}
                        error={!!errors.email}
                        // helperText={errors.email}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {errors.email && (
                  <Typography color="error">{errors.email}</Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <Box className="icon-box">
                    <img src={PasswordIcon} alt="" />
                  </Box>
                  <Box className="form-box">
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className="formcontroal-box"
                    >
                      <TextField
                        type="password"
                        name="password"
                        placeholder="Password"
                        sx={{ "& fieldset": { border: "none" } }}
                        value={userInput.password}
                        onChange={handleInputChange}
                        error={!!errors.password}
                        // helperText={errors.password}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {errors.password && (
                  <Typography color="error">{errors.password}</Typography>
                )}
                <Box className="forgot-box">
                  <Box sx={{ width: "20%" }}></Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "80%",
                    }}
                  >
                    <Typography className="suggest-text">
                      Minimum 6 Characters
                    </Typography>
                    <Typography>
                      <Link className="reset-text" to="/reset-password">
                        Reset Password
                      </Link>
                    </Typography>
                  </Box>
                </Box>
                <Box className="create-btn-box">
                  <button
                    className="btn-create"
                    type="submit"
                    // onClick={(e) => handleSubmit(e)}
                  >
                    Log in
                  </button>
                </Box>
              </Box>
            </form>
            <Typography className="or-text">
              <span
                style={{
                  width: "50px",
                  background: "#D5E9F4",
                  marginInline: "20px",
                  display: "inline-block",
                }}
              >
                or
              </span>
            </Typography>
            <Box className="social-section">
              <Box className="google-btn-box">
                <img src={GoogleIcon} alt="" />
                <Typography className="google-text">
                  Continue With Google
                </Typography>
              </Box>
              <Box className="google-btn-box">
                <img src={FacebookIcon} alt="" />
                <Typography className="google-text">
                  Continue With Facebook
                </Typography>
              </Box>
            </Box>
            <Typography className="login-text">
              Already have an account?
            </Typography>
            <Box className="login-btn-box">
              <button onClick={handleRegister} className="btn-login">
                Register
              </button>
            </Box>
            <Typography className="terms-text">
              By Registering, you agree to MakeUTrip's Privacy Policy, User
              Agreement and T&Cs
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Loging;
