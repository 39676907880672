import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import TravelerDetailsSugestBox from "../TravelerDetailsSugestBox/TravelerDetailsSugestBox";
import LocationSuggestBox from "../LocationSuggestBox/LocationSuggestBox";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  fareDetails,
  farequotes,
  flightSearch,
  ssrdetails,
} from "../../../redux/flight/flightapi";
import moment from "moment";
import {
  setFlightSearchObject,
  setHomePageSearchData,
} from "../../../redux/flight/flightslice";
import Loader from "../../Loaders/Loader";

function CheckIn(props) {
  // const [isOpen, setIsOpen] = React.useState(false);

  const {
    SetOpenDatePicker,
    id,
    value,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
          padding: "8px",
        }}
        onClick={() => SetOpenDatePicker?.((prev) => !prev)}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontSize: "22px", fontWeight: "400" }}>
            Depature{props.title}
          </Typography>
          <KeyboardArrowDownIcon />
        </Box>
        <Box id={id} ref={ref} aria-label={ariaLabel}>
          <Typography sx={{ color: "#022474", fontSize: "17px" }}>
            {value.$d.toString().split(" ").slice(1, 4).join(" ")}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
function CheckInDatePicker(props) {
  const [openDatePicker, SetOpenDatePicker] = React.useState(false);
  const [value, setValue] = React.useState(dayjs());

  return (
    <DatePicker
      onChange={(newValue) => {
        setValue(newValue);
        // handleCheckInClick();
      }}
      disablePast
      slots={{ field: CheckIn, ...props.slots }}
      slotProps={{
        field: { SetOpenDatePicker },
        value: value,
      }}
      {...props}
      open={openDatePicker}
      onClose={() => SetOpenDatePicker(false)}
      onOpen={() => SetOpenDatePicker(true)}
      value={value} // Set the initial value here
    />
  );
}
const OneWay = ({ setTripValue }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log(loading);
  const [locationBox, setLocationBox] = useState({
    isFrom: false,
    isTo: false,
  });
  const [value, setValue] = useState(dayjs());

  const [from, setFrom] = useState({
    airport_city: true,
    airport_city_code: "AMD",
    airport_city_name: "Ahmedabad",
    airport_code: "AMD",
    airport_country_code: "IN",
    airport_country_name: "INDIA",
    airport_id: 22,
    airport_lat: "23.077242",
    airport_lon: "72.63465",
    airport_name: "Sardar Vallabh Bhai Patel Intl Arpt",
    airport_num_airports: 1,
    airport_timezone: "5",
    created_at: "2024-05-01T18:05:46.585671",
    updated_at: "2024-05-01T18:05:46.585688",
    url: "http://0.0.0.0:8000/api/flight/airportdetails/22/",
  });
  const [to, setTo] = useState({
    airport_city: true,

    airport_city_code: "BOM",

    airport_city_name: "Mumbai",

    airport_code: "BOM",

    airport_country_code: "IN",

    airport_country_name: "INDIA",

    airport_id: 418,

    airport_lat: "19.088686",

    airport_lon: "72.867919",

    airport_name: "Chhatrapati Shivaji",

    airport_num_airports: 1,

    airport_timezone: "5",

    created_at: "2024-05-01T18:05:47.709576",

    updated_at: "2024-05-01T18:05:47.709597",
    url: "http://0.0.0.0:8000/api/flight/airportdetails/418/",
  });
  const [travelerDetails, setTravelerDetails] = useState("");
  const [travelerInfo, setTravelerInfo] = useState({
    adults: 1,
    children: 0,
    infants: 0,
    travelClass: 1,
  });

  const handeltravelerDetails = () => {
    setTravelerDetails(!travelerDetails);
  };
  const handleSyncClick = () => {
    const temp = from;
    setFrom(to);
    setTo(temp);
  };
  const handleOneWayClick = (args) => {
    if (args === 0) {
      setLocationBox({
        isFrom: true,
        isTo: false,
      });
    } else if (args === 1) {
      setLocationBox({
        isFrom: false,
        isTo: true,
      });
    } else if (args === 2) {
      setLocationBox({ isFrom: false, isTo: false });
    }
  };

  const handleCloseLocationBox = () => {
    setLocationBox({ isFrom: false, isTo: false });
  };

  const handleSearch = () => {
    const allowedTimes = {
      anyTime: "00:00:00",
      morning: "08:00:00",
      afternoon: "14:00:00",
      evening: "19:00:00",
      night: "01:00:00",
    };

    // Default to anyTime
    const chosenTime = allowedTimes.anyTime;

    const formattedDate = moment({
      year: value.$y,
      month: value.$M,
      day: value.$D,
      hour: parseInt(chosenTime.split(":")[0], 10),
      minute: parseInt(chosenTime.split(":")[1], 10),
      second: parseInt(chosenTime.split(":")[2], 10),
    }).format("YYYY-MM-DDTHH:mm:ss");

    const data = {
      EndUserIp: "1.1.1.1",
      AdultCount: travelerInfo?.adults,
      ChildCount: travelerInfo?.children,
      InfantCount: travelerInfo?.infants,
      JourneyType: "1",
      DirectFlight: null,
      SoftwareType: "B2C",
      Sources: "SG",
      minprice: null,
      maxprice: null,
      refundable: true,
      Segments: [
        {
          Origin: from.airport_city_code,
          Destination: to.airport_city_code,
          FlightCabinClass: travelerInfo.travelClass,
          PreferredDepartureTime: formattedDate,
          PreferredArrivalTime: formattedDate,
        },
      ],
    };
    const searchData = {
      to: to,
      from: from,
      date: formattedDate,
      travelerInfo: travelerInfo,
    };
    dispatch(setHomePageSearchData(searchData));
    dispatch(setFlightSearchObject(data));
    dispatch(flightSearch(data))
      .then((res) => navigate("/flight-ticket-one-way"))
      .catch((err) => console.error(err));
  };

  // if (loading) {
  //   return <Loader />;
  // }

  const locationFromRef = useRef(null);
  const locationToRef = useRef(null);

  const [isLocationshowFrom, setLocationshowFrom] = useState(false);
  const [isLocationshowTo, setLocationshowTo] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        locationFromRef.current &&
        !locationFromRef.current.contains(event.target)
      ) {
        setLocationshowFrom(false);
      }
      if (
        locationToRef.current &&
        !locationToRef.current.contains(event.target)
      ) {
        setLocationshowTo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <Grid container spacing={0} alignItems="stretch">
      <Grid
        item
        lg={4}
        md={4}
        sm={5}
        xs={12}
        sx={{
          borderRight: {
            lg: "1px solid #000",
            sm: "1px solid #000",
            xs: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            alignItems: "center",
            gap: "20px",
            padding: "10px",
          }}
        >
          <Box
            sx={{
              minWidth: "100px",
              position: "relative",
              cursor: "pointer",
            }}
            // onClick={() => handleOneWayClick(0)}
            ref={locationFromRef}
            onClick={() => setLocationshowFrom((oldVal) => !oldVal)}
          >
            <Typography className="from-to-text">From</Typography>
            <Typography className="location-text">
              {from.airport_city_name}
            </Typography>
            {isLocationshowFrom ? (
              <LocationSuggestBox
                type={"flightfrom"}
                setFrom={setFrom}
                locationBox={locationBox}
                setLocationBox={setLocationBox}
                handleCloseLocationBox={handleCloseLocationBox}
                handleOneWayClick={handleOneWayClick}
              />
            ) : null}
          </Box>
          <Box>
            <Box
              sx={{
                border: "1px solid #000",
                borderRadius: "50%",
                padding: "5px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={handleSyncClick}
            >
              <SyncAltIcon sx={{ fontSize: "17px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              minWidth: "100px",
              position: "relative",
              cursor: "pointer",
            }}
            // onClick={() => handleOneWayClick(1)}
            ref={locationToRef}
            onClick={() => setLocationshowTo((oldVal) => !oldVal)}
          >
            <Typography className="from-to-text">To</Typography>
            <Typography className="location-text">
              {to.airport_city_name}
            </Typography>
            {isLocationshowTo ? (
              <LocationSuggestBox
                type={"flightto"}
                setTo={setTo}
                to={to}
                setLocationBox={setLocationBox}
                handleCloseLocationBox={handleCloseLocationBox}
              />
            ) : null}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        sm={3}
        xs={6}
        sx={{ borderRight: "1px solid #000" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CheckInDatePicker
            label={value == null ? null : value.format("MM/DD/YYYY")}
            value={value}
            onChange={(newValue) => setValue(newValue)}
          />
        </LocalizationProvider>
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        sm={3}
        xs={6}
        sx={{ borderRight: { lg: "1px solid #000", xs: "none" } }}
      >
        <Box
          sx={{ padding: "0 15px", cursor: "pointer" }}
          onClick={() => setTripValue("Round-Trip")}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontSize: "22px",
                textAlign: "center",
                flexGrow: 1,
              }}
            >
              Return
            </Typography>
            <CancelIcon sx={{ fontSize: "17px" }} />
          </Box>
          <Typography sx={{ fontSize: "12px", textAlign: "center" }}>
            Tap to add a return date for bigger discounts
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",

            position: "relative",
          }}
        >
          <Box sx={{ cursor: "pointer" }} onClick={handeltravelerDetails}>
            <Typography>Travellers & Class</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "22px", fontWeight: "400" }}>
                {/* 1 */}
                {travelerInfo
                  ? travelerInfo.adults +
                    travelerInfo.children +
                    travelerInfo.infants
                  : 1}{" "}
                Traveller
              </Typography>
              <KeyboardArrowDownIcon />
            </Box>
            <Typography>
              {travelerInfo
                ? travelerInfo.travelClass === 1
                  ? "Economy"
                  : travelerInfo.travelClass === 2
                  ? "Premium Economy"
                  : travelerInfo.travelClass === 3
                  ? "Business Class"
                  : ""
                : "Economy"}
            </Typography>
          </Box>
          <Box>
            <Box className="search-icon-box" sx={{ cursor: "pointer" }}>
              <SearchIcon
                className="search-icon"
                onClick={() => handleSearch()}
              />
            </Box>
          </Box>
          {travelerDetails ? (
            <TravelerDetailsSugestBox
              setTravelerDetails={setTravelerDetails}
              setTravelerInfo={setTravelerInfo}
              travelerInfo={travelerInfo}
            />
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
};

export default OneWay;
