import { createSlice } from "@reduxjs/toolkit";
import { loginUser, registerUser } from "./authenticationapi";

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    Authentication: null,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.hotelSearchData = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.hotelInfo = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    //   .addCase(hotelRoomInfo.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(hotelRoomInfo.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.hotelRoomInfo = action.payload;
    //   })
    //   .addCase(hotelRoomInfo.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   })
    //   .addCase(blockRoomInfo.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(blockRoomInfo.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.blockRoomInfosuccess = true;
    //   })
    //   .addCase(blockRoomInfo.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   })
    //   .addCase(hotelBookRoom.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(hotelBookRoom.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.BookedRoom = action.payload;
    //   })
    //   .addCase(hotelBookRoom.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   });
  },
});

export const { setSelectedRoom, setSelectedHotel, setHomePageSearchData } =
  authenticationSlice.actions;

export default authenticationSlice.reducer;
