
import {
  Box,
  Container,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import closeIcon from "../../assets/img/icon/closered.png"
const ConfirmPassword = () => {
  return (
    <Box className="auth-bg-section">
      <Container maxWidth="xl">
        <Box>
          <Box className="auth-card" sx={{ pb: "50px", pt: "50px" }}>
            <Box className="form-section">
              <Box>
                <Typography className="reset-main-text">Enter Password</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                <Box className=" from-box-reset" sx={{ width: "100%" }}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontroal-box"
                  >
                    <TextField
                      type="password"
                      placeholder="Enter Password"
                      sx={{ "& fieldset": { border: "none" } }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Typography sx={{color:"red"}}><span><img src={closeIcon} alt="close-icon" /></span> Incorrect  Password, Please Try Again</Typography>
              <Box>
                <Typography className="reset-main-text">Confirm  Password</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                <Box className=" from-box-reset" sx={{ width: "100%" }}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontroal-box"
                  >
                    <TextField
                      type="password"
                      placeholder="Enter Confirm Password"
                      sx={{ "& fieldset": { border: "none" } }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box className="reset-otp-box">
                <button className="reset-otp-btn">Verify</button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default ConfirmPassword
