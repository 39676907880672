import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { ThemeProvider } from 'styled-components';
import { Suspense } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./app/store";
import FlightHomePage from "./views/FlightPages/FlightHomePage";
// import { createTheme } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FlightTicketOneWay from "./views/FlightPages/FlightTicketOneWay";
import HotelHomePage from "./views/hotelsPages/HotelHomePage";
import HotelBooking from "./views/hotelsPages/HotelBooking";
import HotelDetailsPage from "./views/hotelsPages/HotelDetailsPage";
import HotelPaymentPage from "./views/hotelsPages/HotelPaymentPage";
import HotelCheckOutPage from "./views/hotelsPages/HotelCheckOutPage";
import HotelBookingPageTwo from "./views/hotelsPages/HotelBookingPageTwo";
import SelectHotelDetils from "./views/hotelsPages/SelectHotelDetils";
import Navbar from "./views/Navbar/Allnavbar/Navbar";
import PaymentSuccess from "./views/hotelsPages/PaymentSuccess";
import Loader from "./layouts/Loaders/Loader";
import Registration from "./views/auth/Registration";
import Loging from "./views/auth/Loging";
import ResetPassword from "./views/auth/ResetPassword";
import OTP from "./views/auth/OTP";
import ConfirmPassword from "./views/auth/ConfirmPassword";
import PaymentUnsuccessfull from "./views/hotelsPages/PaymentUnsuccessfull";
import MyTripsPage from "./views/MyTripsPage/MyTripsPage";
import ConfirmTripPage from "./views/MyTripsPage/ConfirmTripPage";
import BusHomePage from "./views/BusPages/BusHomePage";
import BusBookigPage from "./views/BusPages/BusBookigPage";
import BusSeatBookigPage from "./views/BusPages/BusSeatBookigPage";
import BusBookingConfirmation from "./views/BusPages/BusBookingConfirmation";
import BusCheackOutPage from "./views/BusPages/BusCheackOutPage";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import FlightMultiCity from "./views/FlightPages/FlightMultiCity";
import FlightBookig from "./views/FlightPages/FlightBookig";
import FlightSeatBooking from "./views/FlightPages/FlightSeatBooking";
import FlightCheckOutPapge from "./views/FlightPages/FlightCheckOutPapge";
import FlightTicketCheckOut from "./views/FlightPages/FlightTicketCheckOut";
import FlightRoundTrip from "./views/FlightPages/FlightRoundTrip";
import FlightSeatBookingRoundTrip from "./views/FlightPages/FlightSeatBookingRoundTrip";

const theme = createTheme({
  typography: {
    fontFamily: ["Josefin Sans"].join(","),
  },
});
function App() {
  return (
    <>
      <Provider store={store}>
        {/* <PersistGate loading={<Loader />} persistor={persistor}> */}
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Navbar />
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<Registration />} />
                <Route path="/login" element={<Loging />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/otp" element={<OTP />} />
                <Route
                  path="/reset-confirm-password"
                  element={<ConfirmPassword />}
                />
                <Route path="/flight-home-page" element={<FlightHomePage />} />
                <Route
                  path="/flight-ticket-one-way"
                  element={<FlightTicketOneWay />}
                />
                <Route
                  path="/flight-round-trip"
                  element={<FlightRoundTrip />}
                />
                <Route
                  path="/flight-ticket-multi-city"
                  element={<FlightMultiCity />}
                />
                <Route path="/flight-bookig" element={<FlightBookig />} />
                <Route path="/hotel-home-page" element={<HotelHomePage />} />
                <Route path="/hotel-booking-page" element={<HotelBooking />} />
                <Route
                  path="/flight-seat-booking"
                  element={<FlightSeatBooking />}
                />
                <Route
                  path="/flight-seat-booking-roundtrip"
                  element={<FlightSeatBookingRoundTrip />}
                />
                <Route
                  path="/flight-checkout-page"
                  element={<FlightCheckOutPapge />}
                />
                <Route
                  path="/flight-ticket-checkout"
                  element={<FlightTicketCheckOut />}
                />
                <Route
                  path="/hotel-details-page"
                  element={<HotelDetailsPage />}
                />
                <Route
                  path="/hotel-payment-page"
                  element={<HotelPaymentPage />}
                />
                <Route
                  path="/hotel-booking-pagetwo"
                  element={<HotelBookingPageTwo />}
                />
                <Route
                  path="/select-hotel-detils"
                  element={<SelectHotelDetils />}
                />
                <Route
                  path="/hotel-checkout-page"
                  element={<HotelCheckOutPage />}
                />
                <Route
                  path="/payment-success-page"
                  element={<PaymentSuccess />}
                />
                <Route
                  path="/payment-unsuccess-page"
                  element={<PaymentUnsuccessfull />}
                />

                <Route path="/my-trips-page" element={<MyTripsPage />} />
                <Route
                  path="/confirm-trip-page"
                  element={<ConfirmTripPage />}
                />
                <Route path="/bus-home-page" element={<BusHomePage />} />
                <Route path="/bus-booking-page" element={<BusBookigPage />} />
                <Route
                  path="/bus-seat-booking-page"
                  element={<BusSeatBookigPage />}
                />
                <Route
                  path="/bus-confirmation"
                  element={<BusBookingConfirmation />}
                />
                <Route
                  path="/bus-cheackout-page"
                  element={<BusCheackOutPage />}
                />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </ThemeProvider>
        {/* </PersistGate> */}
      </Provider>
    </>
  );
}

export default App;
