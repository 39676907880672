
import {
  Box,
  Container,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const OTP = () => {
  return (
    <Box className="auth-bg-section">
      <Container maxWidth="xl">
        <Box>
          <Box className="auth-card" sx={{ pb: "50px", pt: "50px" }}>
            <Box className="form-section">
              <Box>
                <Typography className="reset-main-text">Enter OTP</Typography>
                <Typography className="reset-sub-text">
                  OTP has been send to Email or Mobile Number
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                <Box className=" from-box-reset" sx={{ width: "100%" }}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontroal-box"
                  >
                    <TextField
                      type="text"
                      placeholder="Enter OTP"
                      sx={{ "& fieldset": { border: "none" } }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Typography sx={{color:"#0063A7" ,cursor:"pointer"}}>Resend OTP</Typography>
              <Box className="reset-otp-box">
                <button className="reset-otp-btn">Verify</button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default OTP;
