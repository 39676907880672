import {
  Box,
  Breadcrumbs,
  Container,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Pagination,
  Slider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import img1 from "../../assets/img/offerimgs/offerhotel.png";
import img2 from "../../assets/img/offerimgs/offerallhotel.png";
import img3 from "../../assets/img/offerimgs/flashsale.png";
import img4 from "../../assets/img/offerimgs/saveuptooffer.png";
import StarIcon from "@mui/icons-material/Star";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Footer from "../footer/Footer";
import HotelDateselectBox from "../../layouts/Hotel/HotelDateselectBox";
import { useDispatch, useSelector } from "react-redux";
import { hotelInfo, hotelSearch } from "../../redux/hotel/hotelapi";
import Loader from "../../layouts/Loaders/Loader";
import { setSelectedHotel } from "../../redux/hotel/hotelslice";

function valuetext(value) {
  return `${value}°C`;
}

const HotelBooking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state?.hoteldata?.hotelSearchData);
  const searchData = useSelector(
    (state) => state?.hoteldata?.searchData?.dataToSendInAPI
  );

  const searchingData = useSelector(
    (state) => state?.hoteldata?.searchData?.dataToSendInSearch
  );

  const [reqBody, setReqBody] = useState(searchData);
  const [selectedRating, setSelectedRating] = useState("");
  const [page, setPage] = useState(1);
  const [selectedPriceRange, setSelectedPriceRange] = useState("");

  const handlePriceRangeChange = (event) => {
    const value = event.target.value;

    setSelectedPriceRange(value);
    // Update your request body here as per your API requirements
    // For example, if your API expects a min and max price:
    let minPrice, maxPrice;
    switch (value) {
      case "0-1500":
        minPrice = 0;
        maxPrice = 1500;
        break;
      case "1500-2500":
        minPrice = 1500;
        maxPrice = 2500;
        break;
      case "2500-4500":
        minPrice = 2500;
        maxPrice = 4500;
        break;
      case "4500-6500":
        minPrice = 4500;
        maxPrice = 6500;
        break;
      default:
        minPrice = 0;
        maxPrice = 6500; // Adjust this as per your maximum limit
    }
    setReqBody({
      ...reqBody,
      minprice: minPrice,
      maxprice: maxPrice,
    });
    setPage(1);
  };

  const debounce = (func, delay) => {
    let inDebounce;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleChange = (e) => {
    setReqBody({
      ...reqBody,
      PreferredHotel: e.target.value,
    });
  };
  const debouncedChangeHandler = debounce(handleChange, 500);

  const handleRatingChange = (event) => {
    setSelectedRating(event.target.value);
    setReqBody({
      ...reqBody,
      MaxRating: event.target.value,
    });
    setReqBody((prevReqBody) => ({
      ...prevReqBody,
      MinRating: event.target.value,
    }));
    setPage(1);
  };

  useEffect(() => {
    if (page) {
      dispatch(hotelSearch({ data: reqBody, page: page }));
    }
  }, [page]);

  // const handleSearchFilter = () => {
  //   dispatch(hotelSearch({ data: reqBody, page: page }));
  // };
  const handleSearchFilter = (updatedReqBody, updatedPage) => {
    dispatch(hotelSearch({ data: updatedReqBody, page: updatedPage }));
  };

  const loading = useSelector((state) => state.hoteldata.loading);

  const handleRoomAvailabilityCheck = (Cure) => {
    const argdata = {
      ResultIndex: Cure.ResultIndex,
      SrdvIndex: Cure.SrdvIndex,
      SrdvType: data.SrdvType,
      HotelCode: Cure.HotelCode,
      TraceId: data.TraceId,
    };
    dispatch(hotelInfo(argdata));
    dispatch(setSelectedHotel(Cure));
    navigate("/hotel-details-page");
    window.scrollTo(0, 0);
  };

  const StarRating = ({ rating }) => (
    <Box className="rating-box">
      {Array.from({ length: 5 }, (_, index) => (
        <StarIcon
          key={index}
          sx={{ fill: index < rating ? "#5A7FCB" : "#C4C4C4" }}
        />
      ))}
    </Box>
  );

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="#1305B6"
      href="/"
      onClick={(event) => event.preventDefault()}
    >
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={(event) => event.preventDefault()}
    >
      Hotel in {searchingData?.SearchCity.destination}
    </Link>,
  ];

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  const [value, setValue] = React.useState([1000, 100000]);
  // const handleClearFilter = () => {
  //   setSelectedRating("");
  //   setValue([1000, 100000]);
  //   // setSelectedPriceRange("");
  //   setPage(1);
  //   setReqBody((prevReqBody) => ({
  //     ...prevReqBody,
  //     MaxRating: 5,
  //     MinRating: 0,
  //     minprice: null,
  //     maxprice: null,
  //   }));
  //   handleSearchFilter();
  // };
  const handleClearFilter = () => {
    // Define the updated values directly
    const updatedRating = "";
    const updatedValue = [1000, 100000];
    const updatedPage = 1;
    const updatedReqBody = {
      MaxRating: 5,
      MinRating: 0,
      minprice: null,
      maxprice: null,
    };

    const updateReqBodytopass = {
      ...reqBody,
      ...updatedReqBody,
    };

    // Update state with these values
    setSelectedRating(updatedRating);
    setValue(updatedValue);
    setPage(updatedPage);
    setReqBody((prevReqBody) => ({
      ...prevReqBody,
      ...updatedReqBody,
    }));

    // Call `handleSearchFilter` with the updated values directly
    handleSearchFilter(updateReqBodytopass, updatedPage);
  };

  const handlesliderChange = (event, newValue) => {
    setValue(newValue);
    setReqBody({
      ...reqBody,
      minprice: newValue[0],
      maxprice: newValue[1],
    });
    setPage(1);
  };

  if (loading) return <Loader />;

  return (
    <>
      <Container maxWidth="xl">
        <Box>
          <Stack spacing={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Box>
        <HotelDateselectBox />
      </Container>
      <section>
        <Box>
          <Container maxWidth="xl">
            <Box>
              <Box className="offer-item-section">
                <ul className="offer-item-box">
                  <li className=" sort-by-text">SORT BY:</li>
                  <li className="item-text">Popular</li>
                  <li className="item-text">Highest Price</li>
                  <li className="item-text">lowest Price</li>
                </ul>
                <Box className="search-box">
                  <SearchIcon className="search-icon" />
                  <FormControl className="search-form-control">
                    <TextField
                      type="search"
                      placeholder="Hotel Search"
                      onChange={(e) => debouncedChangeHandler(e)}
                      sx={{ "& fieldset": { border: "none" } }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box className="coupon-img-section">
                <Box className="img-box">
                  <img src={img1} alt="" />
                </Box>
                <Box className="img-box">
                  <img src={img2} alt="" />
                </Box>
                <Box className="img-box">
                  <img src={img3} alt="" />
                </Box>
                <Box className="img-box">
                  <img src={img4} alt="" />
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </section>
      <section>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Box className="aside-suggested-section">
                <Box sx={{ mt: 5 }}>
                  <Typography className="main-head-text">
                    Suggested For You
                  </Typography>
                  <form>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="star-rating"
                        name="star-rating"
                        value={selectedRating}
                        onChange={handleRatingChange}
                        column
                      >
                        {[1, 2, 3, 4, 5].map((rating) => (
                          <FormControlLabel
                            key={rating}
                            value={`${rating}`}
                            control={<Radio />}
                            label={`${rating} Star${rating > 1 ? "s" : ""}`}
                            className="input-check-box"
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </form>
                </Box>
                <Box sx={{ mt: 5 }}>
                  <Typography className="main-head-text">
                    Price Per Night
                  </Typography>
                  {/* <RadioGroup
                    aria-label="price-range"
                    name="price-range"
                    value={selectedPriceRange}
                    onChange={handlePriceRangeChange}
                  >
                    <FormControlLabel
                      value="0-1500"
                      control={<Radio />}
                      label="₹0-₹1500"
                    />
                    <FormControlLabel
                      value="1500-2500"
                      control={<Radio />}
                      label="₹1500-₹2500"
                    />
                    <FormControlLabel
                      value="2500-4500"
                      control={<Radio />}
                      label="₹2500-₹4500"
                    />
                    <FormControlLabel
                      value="4500-6500"
                      control={<Radio />}
                      label="₹4500-₹6500"
                    />
                  </RadioGroup> */}
                  <Box sx={{ width: 200 }}>
                    <Slider
                      getAriaLabel={() => "Price range"}
                      value={value}
                      onChange={handlesliderChange}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      min={1000} // Minimum value of the slider
                      max={100000} // Maximum value of the slider
                    />
                  </Box>
                </Box>
                <Box className="text-center">
                  <Button
                    onClick={() => handleSearchFilter(reqBody, page)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Apply Filter
                  </Button>
                </Box>
                <Box className="text-center">
                  <Button
                    onClick={() => handleClearFilter()}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Clear Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item lg={9} md={9} sm={12} xs={12}>
              {data?.data?.map((Cure, i) => (
                <Box className="hotel-booking-card" key={i}>
                  <Grid container spacing={1}>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                      <Box className="img-box">
                        <img src={Cure.HotelPicture} alt="" />
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="card-details-box">
                        <Typography>
                          <Link className="main-text">{Cure.HotelName}</Link>
                        </Typography>
                        <Typography className="location-text">
                          {Cure.HotelLocation}
                        </Typography>
                        <Typography className="walk-dis-text">
                          {Cure.HotelAddress}
                        </Typography>
                        <StarRating rating={Cure.StarRating} />
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                      <Box className="price-section">
                        <Typography
                          sx={{ fontSize: "22px", fontWeight: "600" }}
                        >
                          <span>₹</span> {Cure.Price.PublishedPrice}
                        </Typography>
                        <Typography sx={{ display: "flex" }}>
                          <span style={{ marginRight: "10px" }}>₹</span>
                          <span
                            style={{
                              marginRight: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            + {Cure.Price.OtherCharges}
                          </span>
                          <span className="taxes-text">
                            {" "}
                            Taxes & Fees Per Night{" "}
                          </span>
                        </Typography>
                        <button
                          className="cheack-btn"
                          onClick={() => handleRoomAvailabilityCheck(Cure)}
                        >
                          Check Room Availability
                        </button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Grid> */}
            <Grid item lg={9} md={9} sm={12} xs={12}>
              {data?.data?.length > 0 ? (
                data.data.map((Cure, i) => (
                  <Box className="hotel-booking-card" key={i}>
                    <Grid container spacing={1}>
                      <Grid item lg={3} md={3} sm={3} xs={12}>
                        <Box className="img-box">
                          <img src={Cure.HotelPicture} alt={Cure.HotelName} />
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box className="card-details-box">
                          <Typography>
                            <Link className="main-text">{Cure.HotelName}</Link>
                          </Typography>
                          <Typography className="location-text">
                            {Cure.HotelLocation}
                          </Typography>
                          <Typography className="walk-dis-text">
                            {Cure.HotelAddress}
                          </Typography>
                          <StarRating rating={Cure.StarRating} />
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={3} xs={12}>
                        <Box className="price-section">
                          <Typography
                            sx={{ fontSize: "22px", fontWeight: "600" }}
                          >
                            <span>₹</span> {Cure.Price.PublishedPrice}
                          </Typography>
                          <Typography sx={{ display: "flex" }}>
                            <span style={{ marginRight: "10px" }}>₹</span>
                            <span
                              style={{
                                marginRight: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              + {Cure.Price.OtherCharges}
                            </span>
                            <span className="taxes-text">
                              {" "}
                              Taxes & Fees Per Night{" "}
                            </span>
                          </Typography>
                          <button
                            style={{
                              cursor: "pointer",
                            }}
                            className="cheack-btn"
                            onClick={() => handleRoomAvailabilityCheck(Cure)}
                          >
                            Check Room Availability
                          </button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))
              ) : (
                <Typography variant="subtitle1" style={{ margin: "10px 0" }}>
                  <h3>No data found....</h3>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="xl" sx={{ marginTop: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data ? (
              <Pagination
                // count={data?.page?.total}
                count={data?.page?.total ?? 0}
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
                color="primary"
                showFirstButton
                showLastButton
              />
            ) : (
              ""
            )}
          </Box>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default HotelBooking;
