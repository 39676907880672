import { Box, Grid, Typography } from '@mui/material';
import React from 'react'
import IndianFlag from "../../assets/img/icon/flagindian.png";
import BedIcon from "../../assets/img/icon/bedicon.png";
import CalenderIcon from "../../assets/img/icon/calender-icon.png";
import PersonIcon from "../../assets/img/icon/person-icon.png";
import HelpfulIcon from "../../assets/img/icon/helpfulicon.png";
import NotHelpfulIcon from "../../assets/img/icon/nothelpfulicon.png";
import { Link } from 'react-router-dom';
const reviewData = [
    {
     id:"0",
     FirstLatter:"E",
     UserName:"Elika",
     Nation:"India",
     RoomText:"Deluxe Room",
     RoomDay:"1 Night , May 20,2023",
     ReviewDate:"May 22,2023",
     TravelPerson:"Solo Traveller",
     Rating:"4.5",
     UserReview:"All are good very beautiful place, i want to recommend everyone for visit food are good thank you miss kamini to helping us for smooth check in or smooth check out. Front office team staff are very coprative. Thank you so much for such a amazing stay.",
     HotelReview:"Thank you for rating us highly! Service excellence with the urge to anticipate and comply with guest needs is what we believe in. Looking forward to hosting you soon again! Team FabHotels India’s most preferred chain of budget hotels. 1000+ Hotels | 60+ Cities",
    }, 
    {
     id:"1",
     FirstLatter:"E",
     UserName:"Elika",
     Nation:"India",
     RoomText:"Deluxe Room",
     RoomDay:"1 Night , May 20,2023",
     ReviewDate:"May 22,2023",
     TravelPerson:"Solo Traveller",
     Rating:"4.5",
     UserReview:"All are good very beautiful place, i want to recommend everyone for visit food are good thank you miss kamini to helping us for smooth check in or smooth check out. Front office team staff are very coprative. Thank you so much for such a amazing stay.",
     HotelReview:"Thank you for rating us highly! Service excellence with the urge to anticipate and comply with guest needs is what we believe in. Looking forward to hosting you soon again! Team FabHotels India’s most preferred chain of budget hotels. 1000+ Hotels | 60+ Cities",
    }, 
   ]
const HotelReviewSection = () => {
  return (
    <Box>
    <Grid container spacing={2}>
      <Grid item lg={3} md={3} sm={3} xs={12}>
        <Box className="filter-box-section">
          <Typography className="filter-box-head">Filters</Typography>
          <Box className="input-card">
            <input
                  type="checkbox"
                  id="Location"
                  name="Location"
                  value="Location"
                  className="input-checak-box"
                />
                <label for="Location" className='lable-text'>Location</label>
          </Box>
          <Box className="input-card">
            <input
                  type="checkbox"
                  id="Food"
                  name="Food"
                  value="Food"
                  className="input-checak-box"
                />
                <label for="Food" className='lable-text'>Food</label>
          </Box>
          <Box className="input-card">
            <input
                  type="checkbox"
                  id="Amenities"
                  name="Amenities"
                  value="Amenities"
                  className="input-checak-box"
                />
                <label for="Amenities" className='lable-text'>Amenities</label>
          </Box>
          <Box className="input-card">
            <input
                  type="checkbox"
                  id="Cleanliness"
                  name="Cleanliness"
                  value="Cleanliness"
                  className="input-checak-box"
                />
                <label for="Cleanliness" className='lable-text'>Cleanliness</label>
          </Box>
          <Box className="input-card">
            <input
                  type="checkbox"
                  id="Value-for-Money"
                  name="Value-for-Money"
                  value="Value-for-Money"
                  className="input-checak-box"
                />
                <label for="Value-for-Money" className='lable-text'>Value for Money</label>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={9} md={9} sm={9} xs={12}>
        {reviewData.map((CureE, item) => {
          const {
            FirstLatter,
            UserName,
            Nation,
            RoomText,
            RoomDay,
            ReviewDate,
            TravelPerson,
            Rating,
            UserReview,
            HotelReview,
          } = CureE;
          return (
            <Box className="review-rating-box-section">
              <Box className="review-rating-box-color">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap:"wrap",
                  }}
                >
                  <Box sx={{ display: "flex" ,flexWrap:"wrap"}}>
                    <Box>
                      <Typography className="review-first-latter-text">
                        {FirstLatter}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography>{UserName}</Typography>
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <img src={IndianFlag} width={"20px"} alt="" />
                        <Typography ml={1}> {Nation}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography sx={{ visibility: "hidden" }}>
                      .
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img src={BedIcon} alt="icon" width={"20px"} />
                      <Typography ml={1}> {RoomText} </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography sx={{ visibility: "hidden" }}>
                      .
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={CalenderIcon}
                        width={"20px"}
                        alt="icon"
                      />
                      <Typography ml={1}>{RoomDay}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography>Review : {ReviewDate}</Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img src={PersonIcon} alt="" width={"20px"} />
                      <Typography ml={1}>{TravelPerson}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography className="rating-box">
                      {Rating}
                    </Typography>
                  </Box>
                </Box>
                <Typography sx={{ mt: 2 }}>{UserReview}</Typography>
                <Box sx={{ mt: 4 }}>
                  <Box className="hotel-response-box">
                    <Typography className="hotel-response-main-text">
                      Hotel Response :
                    </Typography>
                    <Typography>{HotelReview}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "end", mt: 3 }}
              >
                <Box>
                  <Link  className="link-text">
                    <img src={HelpfulIcon} width={"25px"} alt="" />{" "}
                    Helpful{" "}
                  </Link>
                </Box>
                <Box>
                  <Link className="link-text">
                    <img src={NotHelpfulIcon} width={"25px"} alt="" />{" "}
                    Not Helpful{" "}
                  </Link>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  </Box>
  )
}

export default HotelReviewSection
