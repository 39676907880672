import {
  Box,
  Breadcrumbs,
  Container,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import React, { useState, useEffect } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import img1 from "../../assets/img/hotel/hotellook3.png";
import TwouserIcon from "../../assets/img/icon/Twousers.png";
import Footer from "../footer/Footer";
import Loader from "../../layouts/Loaders/Loader";
import {
  blockRoomInfo,
  hotelBookRoom,
  hotelRoomInfo,
} from "../../redux/hotel/hotelapi";

// import { useDispatch } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { setHomePageSearchData } from "../../redux/hotel/hotelslice";
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
const HotelPaymentPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_data = JSON.parse(localStorage.getItem("userDetails"));
  const [hotelPassengers, setHotelPassengers] = useState({
    Email: user_data ? user_data.email : "",
    FirstName: user_data ? user_data.first_name : "",
    LastName: user_data ? user_data.last_name : "",
    LeadPassenger: false,
    MiddleName: null,
    PAN: "",
    PassportExpDate: null,
    PassportIssueDate: null,
    PassportNo: null,
    PaxType: "1",
    Phoneno: user_data ? user_data.mobile_number : "",
    Title: "Mr",
  });

  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setHotelPassengers({
      ...hotelPassengers,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const [isAgreed, setIsAgreed] = React.useState(false);

  const validate = () => {
    let tempErrors = {};
    if (!hotelPassengers.Email) tempErrors.Email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(hotelPassengers.Email))
      tempErrors.Email = "Email is not valid";
    if (!hotelPassengers.Phoneno)
      tempErrors.Phoneno = "Phone number is required";
    else if (!/^\d+$/.test(hotelPassengers.Phoneno))
      tempErrors.Phonenos = "Phone number is not valid";
    else if (!/^\d{10}$/.test(hotelPassengers.Phoneno))
      // This line is updated
      tempErrors.Phoneno = "Phone number must be exactly 10 digits.";
    if (!hotelPassengers.Title) tempErrors.Title = "Title is required";
    if (!hotelPassengers.FirstName)
      tempErrors.FirstName = "First name is required";
    if (!hotelPassengers.LastName)
      tempErrors.LastName = "Last name is required";
    // Add more validations as per your requirement
    // Checkbox validation
    if (!arrivalTime) tempErrors.ArrivalTime = "Arrival time is required";

    if (!isAgreed)
      tempErrors.isAgreed =
        "You must agree to the terms and conditions to proceed.";

    setErrors(tempErrors);
    // validateTime();
    return Object.keys(tempErrors).length === 0;
  };

  const data2 = useSelector((state) => state?.hoteldata.hotelRoomInfo);
  const selectedhotel = useSelector((state) => state?.hoteldata.selectedHotel);

  const searchquerydata = useSelector(
    (state) => state?.hoteldata.searchData.dataToSendInSearch
  );

  const selectedRoom = useSelector((state) => state?.hoteldata.selectedRoom);

  const homepagesearchdata = useSelector(
    (state) => state.hoteldata.searchData.dataToSendInSearch
  );
  // let token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzA4MzYyODU4LCJpYXQiOjE3MDgyNzY0NTgsImp0aSI6IjkxMTg4NTY5MzJhZDQzNjRiODg2YmQ4ODc3M2ZiZDk3IiwidXNlcl9pZCI6Mn0.s3TFd02NezNom_M0dwWsb9foED9a8cBbtmR6DL7hmIU";

  let token = localStorage.getItem("token");

  const key_id = "rzp_test_J7AMwDnnlsHZ9b";
  // const key_id = process.env.key_id;

  const checkouthandler = async (amount) => {
    const { data } = await axios.post(
      "https://srv453747.hstgr.cloud/payment/razorpay-order-generate/",
      {
        action: "order_generate",
        currency_name: "INR",
        amount,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const options = {
      key: key_id,
      amount: data.amount,
      currency: "INR",
      name: "Make U Trip",
      description: "Razorpay tutorial",
      order_id: data.id,
      callback_url:
        "https://srv453747.hstgr.cloud/payment/razorpay-verify-payment",
      prefill: {
        name: "Make U Trip ",
        email: "makeutrip@gmail.com",
        contact: "1234567890",
      },
      notes: {
        address: "razorapy official",
      },
      theme: {
        color: "yellow",
      },
      handler: function (response) {
        let bodyDataToSend;
        if (response.razorpay_payment_id) {
          bodyDataToSend = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };
          (async () => {
            try {
              const responseData = await axios.post(
                "https://srv453747.hstgr.cloud/payment/razorpay-verify-payment/",
                bodyDataToSend
              );

              // Assuming responseData is the response object from axios

              if (
                responseData.data.detail ===
                "Signature verification successfull."
              ) {
                const selectedRoomWithHotelPassenger = {
                  ...selectedRoom, // Copy existing properties from selectedRoom
                  HotelPassenger: [hotelPassengers], // Add HotelPassenger property
                };

                const searchdataquery = {
                  ...searchquerydata,
                  ArrivalTime: arrivalTime,
                  HotelPassengers: hotelPassengers,
                };

                dispatch(setHomePageSearchData(searchdataquery));

                // for Room Checking
                const roomCombinations =
                  data2.GetHotelRoomResult.RoomCombinations.RoomCombination;
                const selectedCombinationIndex = 0;
                const selectedRoomIndices =
                  roomCombinations[selectedCombinationIndex];
                const data = searchdataquery.RoomGuests.reduce(
                  (acc, room, roomIndex) => {
                    let passengers = [];
                    for (let i = 0; i < room.NoOfAdults; i++) {
                      let passenger = { ...hotelPassengers };
                      if (i === 0) {
                        passenger.LeadPassenger = true; // Mark the first adult in the first room as lead passenger
                      }
                      passengers.push(passenger);
                    }
                    for (let i = 0; i < room.NoOfChild; i++) {
                      let passenger = {
                        ...hotelPassengers,
                        PaxType: "2",
                        Title: "Mstr", // Remove this if we dont get the required json
                      }; // Child passenger
                      passengers.push(passenger);
                    }

                    // Assuming you have some way to populate hotelDetails for each room
                    let hotelRoomDetails = {
                      ...selectedRoom,
                      RoomIndex: selectedRoomIndices[roomIndex],
                      ChildCount: room.NoOfChild,
                      HotelPassenger: passengers,
                      SmokingPreference: "0", // Remove this if we dont get the required json
                    };
                    acc.push(hotelRoomDetails);

                    return acc;
                  },
                  []
                );

                const bodyData = {
                  razorpay_payment_id: bodyDataToSend.razorpay_payment_id,
                  ResultIndex: selectedhotel.ResultIndex,
                  checkin_date: searchquerydata.CheckInDate,
                  checkout_date: searchquerydata.CheckOutDate,
                  HotelCode: selectedhotel.HotelCode,
                  HotelName: selectedhotel.HotelName,
                  GuestNationality: "IN",
                  NoOfRooms: searchquerydata.NoOfRooms,
                  ClientReferenceNo: 0,
                  IsVoucherBooking: true,
                  HotelRoomsDetails: data,
                  ArrivalTime: arrivalTime,
                  IsPackageFare: true,
                  SrdvType: data2.GetHotelRoomResult.SrdvType,
                  SrdvIndex: data2.GetHotelRoomResult.SrdvIndex,
                  TraceId: data2.GetHotelRoomResult.TraceId,
                };

                // return true;
                dispatch(hotelBookRoom(bodyData))
                  .then((response) => {
                    if (response.payload && response.payload.detail) {
                      console.error("API Error:", response.payload.detail);
                      navigate("/payment-unsuccess-page");
                      window.scrollTo(0, 0);
                    } else {
                      // Handle other success cases
                      navigate("/hotel-checkout-page");
                      window.scrollTo(0, 0);
                    }
                  })
                  .catch((error) => {
                    console.log("Network Error:", error);
                  });
              }
            } catch (error) {
              console.log(error);
            }
          })();
        } else {
          console.error("Payment failed or was canceled");
        }
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  const handleBlockRoom = (e) => {
    e.preventDefault();
    if (validate()) {
      const roomCombinations =
        data2.GetHotelRoomResult.RoomCombinations.RoomCombination;
      const selectedCombinationIndex = 0; // This could be dynamically set based on some criteria
      const selectedRoomIndices = roomCombinations[selectedCombinationIndex];
      const hotelRoomsDetails = searchquerydata.RoomGuests.reduce(
        (acc, room, index) => {
          let passengers = [];
          let hotelRoomDetails = {
            ...selectedRoom, // Assuming this is a template object you have for room details
            RoomIndex: selectedRoomIndices[index], // Assign RoomIndex based on the selected combination
          };
          acc.push(hotelRoomDetails);

          return acc;
        },
        []
      );
      const bodyData = {
        ResultIndex: selectedhotel.ResultIndex,
        HotelCode: selectedhotel.HotelCode,
        HotelName: selectedhotel.HotelName,
        GuestNationality: "IN",
        NoOfRooms: searchquerydata.NoOfRooms,
        ClientReferenceNo: 0,
        IsVoucherBooking: true,
        // HotelRoomsDetails: [selectedRoom],
        // HotelRoomsDetails: Array.from(
        //   { length: searchquerydata.NoOfRooms },
        //   () => selectedRoom
        // ),
        HotelRoomsDetails: hotelRoomsDetails,
        SrdvType: data2.GetHotelRoomResult.SrdvType,
        SrdvIndex: data2.GetHotelRoomResult.SrdvIndex,
        TraceId: data2.GetHotelRoomResult.TraceId,
      };
      dispatch(blockRoomInfo(bodyData)).then(
        checkouthandler(
          data2.GetHotelRoomResult.HotelRoomsDetails[0].Price
            .PublishedPriceRoundedOff * homepagesearchdata?.NoOfRooms
        )
      );
    } else {
      console.log("Validation failed", errors);
    }
  };

  const loading = useSelector((state) => state.hoteldata.loading);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="#1305B6"
      href="/"
      onClick={handleClick}
    >
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      Hotel in {homepagesearchdata?.SearchCity.destination}
    </Link>,
    <Typography key="3" color="text.primary">
      {selectedhotel.HotelName}
    </Typography>,
  ];

  const [arrivalTime, setArrivalTime] = React.useState(null);
  const [error, setError] = React.useState("");

  // Simple validation example
  const validateTime = (time) => {
    if (time && time < new Date()) {
      setError("Arrival time cannot be in the past.");
      return false;
    } else if (!time) {
      setError("Please Select Arrival Time");
    }
    setError("");
    return true;
  };

  // const handleTimeChange = (time) => {

  //   setArrivalTime(time);
  // };
  // const handleTimeChange = (time) => {

  //   // Format the time to "YYYY-MM-DDTHH:MM:SS"
  //   // const formattedTime = dayjs(time).format("YYYY-MM-DDTHH:MM:SS");
  //   const timeString = dayjs(time).format("HH:mm:ss");
  //   const dateString = dayjs(searchquerydata.value).format("YYYY-MM-DD");
  //   // Combine check-in date with time
  //   const combinedDateTime = dayjs(`${dateString}T${timeString}`);

  //   setArrivalTime(combinedDateTime);
  // };
  const handleTimeChange = (time) => {
    // Extract and format the time part
    const timeString = dayjs(time).format("HH:mm:ss");
    // Extract and format the date part
    const dateString = dayjs(searchquerydata?.CheckInDate, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    ); // Make sure to parse the date correctly
    // Combine check-in date with time
    const combinedDateTime = dayjs(`${dateString}T${timeString}`);
    // Format the combined date and time into a string
    const formattedDateTime = combinedDateTime.format("YYYY-MM-DDTHH:mm:ss");

    setArrivalTime(formattedDateTime); // Set the formatted string
    // setArrivalTime(time);
  };

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );
  return (
    <>
      <Container maxWidth="xl">
        {/* <Box calssName=""><span>Home</span> <ChevronRightIcon/> <span> Hotel in Mumbai</span> <ChevronRightIcon/> <span>Hyatt</span>  </Box> */}
        <Box>
          <Stack spacing={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={6} md={7} sm={8} xs={12}>
              <Box className="hotel-reviwe-intinery-section">
                <Typography className="itinerary-title">
                  Review your itinerary
                </Typography>
                <Box className="check-out-hotel-card">
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Box>
                        <img
                          src={selectedhotel.HotelPicture}
                          alt=""
                          width="100%"
                          className="hotel-img"
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={7} md={7} sm={7} xs={12} ml="auto">
                      <Box className="card-details-box">
                        <Typography className="main-text">
                          {/* Hyatt Regency */}
                          {selectedhotel.HotelName}
                        </Typography>
                        {/* <Box className="rating-box">
                          <StarIcon sx={{ fill: "#5A7FCB" }} />
                          <StarIcon sx={{ fill: "#5A7FCB" }} />
                          <StarIcon sx={{ fill: "#5A7FCB" }} />
                          <StarIcon sx={{ fill: "#5A7FCB" }} />
                          <StarIcon sx={{ fill: "#C4C4C4" }} />
                        </Box> */}
                        <Box className="rating-box">
                          {Array(5)
                            .fill(0)
                            .map((_, index) => (
                              <StarIcon
                                key={index}
                                sx={{
                                  fill:
                                    index < selectedhotel.StarRating
                                      ? "#5A7FCB"
                                      : "#C4C4C4",
                                }}
                              />
                            ))}
                        </Box>
                        <Typography className="sub-text">
                          {/* Mumbai Airport Road , Mumbai */}
                          {selectedhotel.HotelAddress}
                        </Typography>
                        <Link className="booking-link">Booking Policy</Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box className="presidet-section">
                <Typography className="main-title">
                  {selectedRoom?.RoomTypeName}
                </Typography>
                <Box className="president-box">
                  <Box className="">
                    <Typography className="main-text">Check- in</Typography>
                    <Typography className="sub-text">
                      {searchquerydata?.CheckInDate}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="days-text">
                      {(() => {
                        // Ensure both dates are defined and in the expected format
                        if (
                          !searchquerydata?.CheckInDate ||
                          !searchquerydata?.CheckOutDate
                        ) {
                          return ""; // Return empty string or appropriate fallback
                        }

                        // Parse the date strings in "DD/MM/YYYY" format
                        const checkInDateParts =
                          searchquerydata.CheckInDate.split("/");
                        const checkOutDateParts =
                          searchquerydata.CheckOutDate.split("/");

                        // Guard against incomplete data
                        if (
                          checkInDateParts.length < 3 ||
                          checkOutDateParts.length < 3
                        ) {
                          return ""; // Return empty string or appropriate fallback
                        }

                        const checkInDate = new Date(
                          parseInt(checkInDateParts[2], 10), // Year
                          parseInt(checkInDateParts[1], 10) - 1, // Month (subtract 1 for 0-based index)
                          parseInt(checkInDateParts[0], 10) // Day
                        );
                        const checkOutDate = new Date(
                          parseInt(checkOutDateParts[2], 10), // Year
                          parseInt(checkOutDateParts[1], 10) - 1, // Month (subtract 1 for 0-based index)
                          parseInt(checkOutDateParts[0], 10) // Day
                        );

                        // Calculate the number of nights
                        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
                        const nights = Math.round(
                          (checkOutDate - checkInDate) / oneDayInMilliseconds
                        );

                        return nights > 0
                          ? `${nights} Night${nights > 1 ? "s" : ""}`
                          : "";
                      })()}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="main-text">Check- out</Typography>
                    <Typography className="sub-text">
                      {searchquerydata?.CheckOutDate}
                    </Typography>
                  </Box>
                  <Box sx={{ pl: 5 }}>
                    <Typography className="main-text">
                      Guests & Rooms{" "}
                    </Typography>
                    <Typography className="sub-text">
                      <span>
                        {searchquerydata?.RoomGuests.reduce(
                          (accumulator, currentGroup) => {
                            const noOfAdults = parseInt(
                              currentGroup.NoOfAdults,
                              10
                            );

                            return accumulator + noOfAdults;
                          },
                          0
                        )}{" "}
                        Adults |{" "}
                      </span>
                      <span>
                        {homepagesearchdata?.RoomGuests.reduce(
                          (accumulator, currentGroup) => {
                            const noOfAdults = parseInt(
                              currentGroup.NoOfChild,
                              10
                            );
                            return accumulator + noOfAdults;
                          },
                          0
                        )}{" "}
                        Child |
                      </span>
                      <span>{searchquerydata?.NoOfRooms} Room </span>{" "}
                    </Typography>
                  </Box>
                  <Box sx={{ pt: 5 }}>
                    <Typography className="main-text">Arrival Time</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <TimePicker
                          label="Select Time of Arrival"
                          onChange={(e) => handleTimeChange(e)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {!!errors.ArrivalTime && (
                      <Typography color="error" variant="caption">
                        {errors.ArrivalTime}
                      </Typography>
                    )}
                    {/* Your existing code for displaying adults, children, and rooms */}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={4} xs={12} ml="auto">
              <Box className="check-out-right-section">
                <Typography className="head-title">Your Reservation</Typography>
                <Box className="price-box">
                  <Typography className="amount-text">
                    {selectedRoom?.RoomTypeName}
                  </Typography>
                  <Typography className="price-text">
                    {selectedRoom?.Price.RoomPrice} ₹
                  </Typography>
                </Box>
                <Box className="price-box">
                  <Typography className="amount-text">
                    Service Charges
                  </Typography>
                  <Typography className="price-text">
                    {selectedRoom?.Price.AgentCommission} ₹
                  </Typography>
                </Box>
                <Box className="price-box">
                  <Typography className="amount-text">Taxes</Typography>
                  <Typography className="price-text">
                    {selectedRoom?.Price.Tax} ₹
                  </Typography>
                </Box>
                <Box className="price-box">
                  <Typography className="amount-text">No of Rooms</Typography>
                  <Typography className="price-text">
                    {homepagesearchdata?.NoOfRooms}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderTop: "1px solid #000000CC",
                    borderBottom: "1px solid #000000CC",
                    paddingY: "10px",
                  }}
                >
                  <Box className="price-box">
                    <Typography className=" total-red-text">Total</Typography>
                    <Typography className=" total-red-text">
                      {/* 12000  */}
                      {
                        data2.GetHotelRoomResult.HotelRoomsDetails[0].Price
                          .PublishedPriceRoundedOff
                      }
                      <span style={{ color: "#000000" }}>₹</span>
                    </Typography>
                  </Box>
                  {/* <Box className="price-box">
                    <Typography className="coupon-code-text">
                      Make U Code Applied
                    </Typography>
                    <Typography className="coupon-code-text">3000 ₹</Typography>
                  </Box> */}
                </Box>
                <Box className="price-box">
                  <Typography className="total-red-text">
                    Grand Total
                  </Typography>
                  <Typography className="total-red-text">
                    {/* 9000 */}
                    {data2?.GetHotelRoomResult.HotelRoomsDetails[0].Price
                      .PublishedPriceRoundedOff * homepagesearchdata?.NoOfRooms}
                    <span style={{ color: "#000000" }}>₹</span>
                  </Typography>
                </Box>
                <Box>
                  {/* <Box className="gst-box">
                    <Typography> Add Your GST Number</Typography>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className="formcontroal-box"
                    >
                      <TextField
                        type="text"
                        sx={{ "& fieldset": { border: "none" } }}
                      />
                    </FormControl>
                  </Box> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mb: 5 }}>
          {/* <Box>
            <Box className="Primary-icon-section">
              <img src={TwouserIcon} alt="" />
              <Typography className="title">Primary Guest Details</Typography>
            </Box>
            <Box className="hotel-contact-details-section">
              <Grid container spacing={2} lg={7} md={8} sm={12} xs={12}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className="contact-details-title">
                    Contact Details
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Box className="input-box">
                    <FormControl fullWidth>
                      <TextField
                        type="email"
                        onChange={handleChange}
                        name="Email"
                        placeholder="Enter Your Email Address"
                        sx={{ "& fieldset": { border: "none" } }}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Box className="select-phone-number-section">
                    <Box className="select-box">
                      <select>
                        <option>91 +</option>
                      </select>
                    </Box>
                    <Box>
                      <FormControl fullWidth>
                        <TextField
                          type="tel"
                          onChange={handleChange}
                          name="Phoneno"
                          placeholder="Enter Mobile Number"
                          sx={{ "& fieldset": { border: "none" } }}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1">
                    Your booking details will be sent to this email address and
                    mobile number.
                  </Typography>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Box className="personal-details-section">
                    <Grid container spacing={2}>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={3}
                        justifyContent="center"
                        display="flex"
                      >
                        <Box>
                          <Typography className="title-text">Title</Typography>
                          <select
                            className="select-box"
                            onChange={handleChange}
                            name="Title"
                          >
                            <option value={"Mr"}>Mr.</option>
                            <option value={"Mis."}>Mis.</option>
                          </select>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={6}
                        xs={9}
                        justifyContent="center"
                        display="flex"
                      >
                        <Box>
                          <Typography className="title-text">
                            First Name
                          </Typography>
                          <FormControl>
                            <TextField
                              type="text"
                              className="input-box"
                              onChange={handleChange}
                              name="FirstName"
                              sx={{ "& fieldset": { border: "none" } }}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        justifyContent={{ xs: "flex-start", lg: "center" }}
                        display="flex"
                      >
                        <Box>
                          <Typography className="title-text">
                            Last Name
                          </Typography>

                          <FormControl>
                            <TextField
                              type="text"
                              className="input-box"
                              onChange={handleChange}
                              name="LastName"
                              sx={{ "& fieldset": { border: "none" } }}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item lg={12} xs={12}>
                  <Box
                    sx={{ display: "flex", alignItems: "baseline" }}
                    className="privacy-section"
                  >
                    <input type="checkbox" />
                    <Typography className="privacy-text">
                      I Understand and agree to the rules of this fare , and the{" "}
                      <Link className="privacy-link"> Terms & condition </Link>
                      of Make U Trip
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: "center" }}>
                    <button
                      className="continue-btn"
                      onClick={() => handleBlockRoom()}
                    >
                      Continue to Payment
                    </button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box> */}
          <Box
            className="hotel-contact-details-section"
            component="form"
            onSubmit={handleBlockRoom}
          >
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className="contact-details-title">
                  Contact Details
                </Typography>
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box>
                  <FormControl fullWidth className="input-box">
                    <TextField
                      type="email"
                      onChange={handleChange}
                      name="Email"
                      placeholder="Enter Your Email Address"
                      sx={{ "& fieldset": { border: "none" } }}
                      value={hotelPassengers.Email}
                      error={!!errors.Email}
                      // helperText={errors.Email}
                    />
                  </FormControl>
                  {/* {!!errors.Email && (
                    <Typography color="error" variant="caption">
                      {errors.Email}
                    </Typography>
                  )} */}
                </Box>
                {errors.Email && (
                  <Typography color="error">{errors.Email}</Typography>
                )}
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box className="select-phone-number-section">
                  <Box className="select-box">
                    <select>
                      <option>91 +</option>
                    </select>
                  </Box>
                  <Box>
                    <FormControl fullWidth>
                      <TextField
                        type="tel"
                        onChange={handleChange}
                        name="Phoneno"
                        placeholder="Enter Mobile Number"
                        inputProps={{
                          maxLength: 10,
                          pattern: "[0-9]*", // This pattern will allow only digits to be entered
                        }}
                        sx={{ "& fieldset": { border: "none" } }}
                        value={hotelPassengers.Phoneno}
                        error={!!errors.Phoneno}
                        // helperText={errors.Phoneno}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {errors.Phoneno && (
                  <Typography color="error">{errors.Phoneno}</Typography>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1">
                  Your booking details will be sent to this email address and
                  mobile number.
                </Typography>
              </Grid>
              <Grid item lg={8} md={12} sm={12} xs={12}>
                <Box className="personal-details-section">
                  <Grid container spacing={2}>
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={4}
                      xs={3}
                      justifyContent="center"
                      display="flex"
                    >
                      <Box>
                        <Typography className="title-text">Title</Typography>
                        <select
                          className="select-box"
                          onChange={handleChange}
                          name="Title"
                          value={hotelPassengers.Title}
                        >
                          <option value={"Mr"}>Mr.</option>
                          <option value={"Mis."}>Mis.</option>
                        </select>
                        {!!errors.Title && (
                          <Typography color="error" variant="caption">
                            {errors.Title}
                          </Typography>
                        )}
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={6}
                      xs={9}
                      justifyContent="center"
                      display="flex"
                    >
                      <Box>
                        <Typography className="title-text">
                          First Name
                        </Typography>
                        <FormControl>
                          <TextField
                            type="text"
                            className="input-box"
                            placeholder="Enter First Name"
                            onChange={handleChange}
                            name="FirstName"
                            sx={{ "& fieldset": { border: "none" } }}
                            value={hotelPassengers.FirstName}
                          />
                          {!!errors.FirstName && (
                            <Typography color="error" variant="caption">
                              {errors.FirstName}
                            </Typography>
                          )}
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={6}
                      xs={12}
                      justifyContent={{ xs: "flex-start", lg: "center" }}
                      display="flex"
                    >
                      <Box>
                        <Typography className="title-text">
                          Last Name
                        </Typography>
                        <FormControl>
                          <TextField
                            type="text"
                            className="input-box"
                            onChange={handleChange}
                            name="LastName"
                            placeholder="Enter Last Name"
                            sx={{ "& fieldset": { border: "none" } }}
                            value={hotelPassengers.LastName}
                          />
                          {!!errors.FirstName && (
                            <Typography color="error" variant="caption">
                              {errors.LastName}
                            </Typography>
                          )}
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Box
                  sx={{ display: "flex", alignItems: "baseline" }}
                  className="privacy-section"
                >
                  <input
                    type="checkbox"
                    checked={isAgreed}
                    onChange={(e) => setIsAgreed(e.target.checked)}
                  />
                  <Typography className="privacy-text">
                    I Understand and agree to the rules of this fare, and the{" "}
                    <Link className="privacy-link">Terms & condition</Link>
                    of Make U Trip
                  </Typography>
                </Box>
                {errors.isAgreed && (
                  <Typography color="error" variant="caption">
                    {errors.isAgreed}
                  </Typography>
                )}
                <Box sx={{ textAlign: "center" }}>
                  <Button
                    style={{
                      cursor: "pointer",
                    }}
                    type="submit"
                    className="continue-btn"
                    onClick={(e) => handleBlockRoom(e)}
                  >
                    Continue to Payment
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default HotelPaymentPage;
