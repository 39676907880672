import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
const sliderDays = [
    {
      id: "0",
      name: "Fri, April 23",
      price: "₹5,200",
    },
    {
      id: "1",
      name: "Fri, April 23",
      price: "₹5,200",
    },
    {
      id: "2",
      name: "Fri, April 23",
      price: "₹5,200",
    },
    {
      id: "4",
      name: "Fri, April 23",
      price: "₹5,200",
    },
    {
      id: "5",
      name: "Fri, April 23",
      price: "₹5,200",
    },
    {
      id: "6",
      name: "Fri, April 23",
      price: "₹5,200",
    },
    {
      id: "7",
      name: "Fri, April 23",
      price: "₹5,200",
    },
    {
      id: "8",
      name: "Fri, April 23",
      price: "₹5,200",
    },
  ];
const SliderFoteDateSelect = () => {
    const [slidesPerView, setSlidesPerView] = useState(7);
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setSlidesPerView(7);
      } else if (width >= 768) {
        setSlidesPerView(6);
      } else {
        setSlidesPerView(3);
      }
    };
  
    useEffect(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  return (
    <Box>
    <Swiper
      slidesPerView={slidesPerView}
      navigation={true}
      spaceBetween={30}
      breakpoints={{
        640: {
          slidesPerView: 5,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 5,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 7,
          spaceBetween: 20,
        },
      }}
      modules={[Navigation]}
      className="flight-swiper"
    >
      {sliderDays.map((item) => {
        return (
          <SwiperSlide>
            <Box
              // onClick={() => setSelectprice(item.price)}
              sx={{
                display: "flex",
                backgroundColor: "transparent",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                borderRight: "1px solid #000",
              }}
            >
              <Typography>{item.name}</Typography>
              <Typography>{item.price}</Typography>
            </Box>
          </SwiperSlide>
        );
      })}
    </Swiper>
  </Box>
  )
}

export default SliderFoteDateSelect
