// import {
//   Box,
//   Breadcrumbs,
//   Container,
//   Grid,
//   Stack,
//   Typography,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import FlightDataselectBox from "../../layouts/Flight/FlightDataselectBox";
// import bannerImg from "../../assets/img/flight/bannerimg.png";
// import AboutAppSection from "../../layouts/AboutAppSection";
// import Footer from "../../views/footer/Footer";
// import FlightAside from "../../layouts/Flight/FlightAside";
// import FlightCard from "../../layouts/Flight/FlightCard";
// import { flightData } from "./FlightTicketOneWay";
// import SliderFoteDateSelect from "../../layouts/Flight/SliderForDateSelect/SliderFoteDateSelect";

// const breadcrumbs = [
//   <Link
//     underline="hover"
//     key="1"
//     color="#1305B6"
//     href="/"
//     //   onClick={handleClick}
//   >
//     Home
//   </Link>,
//   <Link
//     underline="hover"
//     key="2"
//     color="inherit"

//     //   onClick={handleClick}
//   >
//     Hotel in
//   </Link>,
// ];
// const FlightMultiCity = () => {
//   const [activeslide, setActiveslide] = useState("first");
//   const handleClickActive = (ID) => {
//     setActiveslide(ID);
//   };

//   return (
//     <>
//       <Container maxWidth="xl">
//         <Box sx={{ backgroundColor: "#3c74dc" }}>
//           <Stack spacing={2}>
//             <Breadcrumbs
//               separator={<NavigateNextIcon fontSize="large" />}
//               aria-label="breadcrumb"
//             >
//               {breadcrumbs}
//             </Breadcrumbs>
//           </Stack>
//           <FlightDataselectBox />
//         </Box>
//       </Container>

//       <Box sx={{ mt: 2 }}>
//         <Container>
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               flexDirection: "row",
//               flexWrap: "wrap",
//               gap: "2",
//             }}
//           >
//             <Typography sx={{ mr: 1 }}>Fare Type</Typography>
//             <Box className="radio-box">
//               <input
//                 type="radio"
//                 id="Regular"
//                 name="fav_language"
//                 value="Regular"
//               />
//               <label for="Regular">Regular</label>
//               <br />
//             </Box>
//             <Box className="radio-box">
//               <input
//                 type="radio"
//                 id="Armed-Force"
//                 name="fav_language"
//                 value="Armed-Force"
//               />
//               <label for="Armed-Force">Armed Force</label>
//               <br />
//             </Box>
//             <Box className="radio-box">
//               <input
//                 type="radio"
//                 id="Student"
//                 name="fav_language"
//                 value="Senior-Citizen"
//               />
//               <label for="Student">Student</label>
//               <br />
//             </Box>
//             <Box className="radio-box">
//               <input
//                 type="radio"
//                 id="Senior-Citizen"
//                 name="fav_language"
//                 value="Senior-Citizen"
//               />
//               <label for="Senior-Citizen">Senior Citizen</label>
//               <br />
//             </Box>
//             <Box className="radio-box">
//               <input
//                 type="radio"
//                 id="Doctor-&-Nurse"
//                 name="fav_language"
//                 value="Doctor-&-Nurse"
//               />
//               <label for="Doctor-&-Nurse">Doctor & Nurse</label>
//               <br />
//             </Box>
//             <Box className="radio-box">
//               <input
//                 type="radio"
//                 id="Double-Seat"
//                 name="fav_language"
//                 value="Double-Seat"
//               />
//               <label for="Double-Seat">Double Seat</label>
//               <br />
//             </Box>
//           </Box>
//         </Container>
//       </Box>
//       <Container maxWidth="xl">
//         <Grid container spacing={2}>
//           <Grid item lg={3} md={3} sm={12} xs={12} sx={{ my: 2 }}>
//             {/* <FlightAside /> */}
//           </Grid>
//           <Grid item lg={9} md={9} sm={12} xs={12} sx={{ my: 2 }}>
//             <Box
//               className="flight-multi-city-head-box"
//               sx={{ display: "flex", justifyContent: "space-around" }}
//             >
//               <Box
//                 onClick={() => handleClickActive("first")}
//                 id="first"
//                 className={`flight-main-text ${
//                   activeslide === "first" ? "active" : ""
//                 }`}
//                 sx={{
//                   width: "50%",
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                 }}
//               >
//                 <Typography
//                   sx={{
//                     my: 1,
//                     textAlign: "center",
//                     fontSize: "20px",
//                     fontWeight: "700",
//                   }}
//                 >
//                   Ahmedabad to Delhi
//                 </Typography>
//                 <Typography sx={{ mb: 1 }}>Friday, April 28,2023</Typography>
//               </Box>
//               <Box
//                 onClick={() => handleClickActive("second")}
//                 id="second"
//                 className={`flight-main-text ${
//                   activeslide === "second" ? "active" : ""
//                 }`}
//                 sx={{
//                   width: "50%",
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                 }}
//               >
//                 <Typography
//                   sx={{
//                     my: 1,
//                     textAlign: "center",
//                     fontSize: "20px",
//                     fontWeight: "700",
//                   }}
//                 >
//                   Mumbai to Delhi
//                 </Typography>
//                 <Typography>Friday, April 30,2023</Typography>
//               </Box>
//             </Box>
//             <SliderFoteDateSelect />
//             <Box sx={{ my: 2 }}>
//               {flightData
//                 .filter((item) => parseInt(item.id) < 3)
//                 .map((item, i) => {
//                   return <FlightCard key={item.id} flightData={item} />;
//                 })}
//             </Box>
//             <Box>
//               <img src={bannerImg} alt="" width={"100%"} />
//             </Box>
//             <Box sx={{ my: 2 }}>
//               {flightData
//                 .filter((item) => parseInt(item.id) < 1)
//                 .map((item, i) => {
//                   return <FlightCard key={item.id} flightData={item} />;
//                 })}
//             </Box>
//           </Grid>
//         </Grid>
//       </Container>
//       <AboutAppSection />
//       <Footer />
//     </>
//   );
// };

// export default FlightMultiCity;

import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FlightDataselectBox from "../../layouts/Flight/FlightDataselectBox";
import bannerImg from "../../assets/img/flight/bannerimg.png";
import AboutAppSection from "../../layouts/AboutAppSection";
import Footer from "../../views/footer/Footer";
import FlightAside from "../../layouts/Flight/FlightAside";
import FlightCard from "../../layouts/Flight/FlightCard";
import { flightData } from "./FlightTicketOneWay";
import SliderFoteDateSelect from "../../layouts/Flight/SliderForDateSelect/SliderFoteDateSelect";
import { useSelector } from "react-redux";
import Loader from "../../layouts/Loaders/Loader";

const breadcrumbs = [
  <Link underline="hover" key="1" color="#1305B6" to="/">
    Home
  </Link>,
  <Link underline="hover" key="2" color="inherit" to="#">
    Hotel in
  </Link>,
];

const FlightMultiCity = () => {
  const flightDetails = useSelector(
    (state) => state.flightdata.flightSearchData.Results
  );

  const searchData = useSelector((state) => state.flightdata.searchData);
  const loading = useSelector((state) => state.flightdata.loading);
  const [activeslide, setActiveslide] = useState("first");

  const handleClickActive = (ID) => {
    setActiveslide(ID);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ backgroundColor: "#3c74dc" }}>
          <Stack spacing={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
          <FlightDataselectBox searchData={searchData} />
        </Box>
      </Container>

      {/* <Box sx={{ mt: 2 }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "2",
            }}
          >
            <Typography sx={{ mr: 1 }}>Fare Type</Typography>
            <Box className="radio-box">
              <input
                type="radio"
                id="Regular"
                name="fav_language"
                value="Regular"
              />
              <label htmlFor="Regular">Regular</label>
              <br />
            </Box>
            <Box className="radio-box">
              <input
                type="radio"
                id="Armed-Force"
                name="fav_language"
                value="Armed-Force"
              />
              <label htmlFor="Armed-Force">Armed Force</label>
              <br />
            </Box>
            <Box className="radio-box">
              <input
                type="radio"
                id="Student"
                name="fav_language"
                value="Senior-Citizen"
              />
              <label htmlFor="Student">Student</label>
              <br />
            </Box>
            <Box className="radio-box">
              <input
                type="radio"
                id="Senior-Citizen"
                name="fav_language"
                value="Senior-Citizen"
              />
              <label htmlFor="Senior-Citizen">Senior Citizen</label>
              <br />
            </Box>
            <Box className="radio-box">
              <input
                type="radio"
                id="Doctor-&-Nurse"
                name="fav_language"
                value="Doctor-&-Nurse"
              />
              <label htmlFor="Doctor-&-Nurse">Doctor & Nurse</label>
              <br />
            </Box>
            <Box className="radio-box">
              <input
                type="radio"
                id="Double-Seat"
                name="fav_language"
                value="Double-Seat"
              />
              <label htmlFor="Double-Seat">Double Seat</label>
              <br />
            </Box>
          </Box>
        </Container>
      </Box> */}
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12} sx={{ my: 2 }}>
            {/* <FlightAside /> */}
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12} sx={{ my: 2 }}>
            {/* <Box
              className="flight-multi-city-head-box"
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              <Box
                onClick={() => handleClickActive("first")}
                id="first"
                className={`flight-main-text ${
                  activeslide === "first" ? "active" : ""
                }`}
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    my: 1,
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "700",
                  }}
                >
                  Ahmedabad to Delhi
                </Typography>
                <Typography sx={{ mb: 1 }}>Friday, April 28,2023</Typography>
              </Box>
              <Box
                onClick={() => handleClickActive("second")}
                id="second"
                className={`flight-main-text ${
                  activeslide === "second" ? "active" : ""
                }`}
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    my: 1,
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "700",
                  }}
                >
                  Mumbai to Delhi
                </Typography>
                <Typography>Friday, April 30,2023</Typography>
              </Box>
            </Box>
            <SliderFoteDateSelect /> */}
            <Box sx={{ display: "flex", my: 2 }}>
              <Box sx={{ flex: 1, mx: 1 }}>
                {flightDetails[0].map((item, i) => {
                  return (
                    <FlightCard
                      key={item.id}
                      flightData={item}
                      data={"multicity"}
                    />
                  );
                })}
              </Box>
              {/* <Box sx={{ flex: 1, mx: 1 }}>
                {flightDetails[0]
                  // .filter((item) => item.Segments[0][1])
                  .map((item, i) => {
                    return (
                      <FlightCard
                        key={item.id}
                        flightData={item}
                        type={"roundTrip"}
                      />
                    );
                  })}
              </Box> */}
            </Box>
            <Box>
              <img src={bannerImg} alt="" width={"100%"} />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <AboutAppSection />
      <Footer />
    </>
  );
};

export default FlightMultiCity;
