import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Footer from "../footer/Footer";
import BusDateSelectBox from "../../layouts/Bus/BusDateSelectBox";
import SliderForDateSelect from "../../layouts/Bus/SliderForDateSelect/SliderForDateSelect";
import BusCard from "../../layouts/Bus/businfoCard/BusCard";
import BusAsideSection from "../../layouts/Bus/BusAsideBox/BusAsideSection";
import { useDispatch, useSelector } from "react-redux";
import { boardingPoints, seatDetails } from "../../redux/bus/busapi";
import { setSelectedBus, setSelectedSeat } from "../../redux/bus/busslice";
import Loader from "../../layouts/Loaders/Loader";

const BusBookigPage = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const availablebus = useSelector(
    (state) => state?.busdata?.AvailableBuses?.Result
  );

  const [formData, setFormData] = useState({
    TraceId: availablebus?.TraceId,
    ResultIndex: "",
  });

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="#1305B6"
      href="/"
      onClick={(event) => event.preventDefault()}
    >
      Make U Trip
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={(event) => event.preventDefault()}
    >
      Bus Booking
    </Link>,
  ];

  const handelClick = (item) => {
    const data = { ...formData };
    data.ResultIndex = item.ResultIndex;
    dispatch(seatDetails(data)).then((res) => {
      if (res) {
        dispatch(boardingPoints(data));
      }
    });

    dispatch(setSelectedBus(item));
    Navigate("/bus-seat-booking-page");
  };
  const loading = useSelector((state) => state?.busdata?.loading);
  // if (loading) return <Loader />;

  return (
    <Box>
      <Container maxWidth="xl">
        <BusDateSelectBox />
        <Box>
          <Stack spacing={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Box>
      </Container>
      <section>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={12} xs={12} sx={{ mb: 2 }}>
              <BusAsideSection />
            </Grid>
            {loading ? (
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <Loader />
              </Grid>
            ) : (
              <Grid item lg={9} md={9} sm={12} xs={12} sx={{ mb: 2 }}>
                {/* <SliderForDateSelect /> */}
                <Box sx={{ my: 5 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ width: "80px" }}>
                      <Typography sx={{ textWrap: "nowrap" }}>
                        Sort By:
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "space-around",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography sx={{ cursor: "pointer" }}>
                        Departure Time
                      </Typography>
                      <Typography sx={{ cursor: "pointer" }}>
                        Arrival Time
                      </Typography>
                      <Typography sx={{ cursor: "pointer" }}>Price</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ my: 3 }}>
                    <BusCard
                      handelClick={handelClick}
                      availablebus={availablebus}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </section>
      <Footer />
    </Box>
  );
};

export default BusBookigPage;
