import {
  Box,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import GroupPackagesImg from "../assets/img/Group-Packages.png";
import FamilyPackagesImg from "../assets/img/Family-Packages.png";
import HoneymoonPackagesImg from "../assets/img/honeymoon-package-img.png";

const ExploreMoreToureSection = () => {
  return (
    <section>
      <Box className="explore-more-toure-section">
        <Container maxWidth="xl">
          <Typography className="main-head-title">
            Explore More Tours
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={8} md={10} sm={12} sx={12} ml="auto">
              <Grid container spacing={2}>
                <Grid item lg={12} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box className="img-box">
                      <img src={HoneymoonPackagesImg} className="img-" alt="" />
                      <Typography className="text">
                        Honeymoon Packages
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                    <Box className="img-box">
                      <img src={FamilyPackagesImg} alt="" className="img-" />
                      <Typography className="text">Family Packages</Typography>
                    </Box>
                    <Box className="img-box">
                      <img src={GroupPackagesImg} alt="" className="img-" />
                      <Typography className="text">Group Packages</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ mt: 5 }}>
            <Grid container spacing={2}>
              <Grid item lg={9} md={10} sm={12} xs={12} ml="auto">
                <Grid container spacing={2}>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <Typography className="travel-info-text">
                      Your Travel Journey Starts Here Sign up and we'll send the
                      best deals to you
                    </Typography>
                  </Grid>
                  <Grid item lg={9} md={9} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box className="email-box">
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className="formcontroal-box"
                        >
                          <TextField
                            type="email"
                            placeholder="Your Email"
                            sx={{ "& fieldset": { border: "none" } }}
                          />
                        </FormControl>
                      </Box>
                      <Box className="join-btn-box">
                        <button
                          className="btn"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          Join Now
                        </button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </section>
  );
};

export default ExploreMoreToureSection;
