import { Box, Typography, Grid, Radio } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import timerIcon from "../../assets/img/icon/timericonblack.png";
import {
  fareDetails,
  farequotes,
  seatview,
  ssrdetails,
} from "../../redux/flight/flightapi";
import FlightDetailsModal from "./FlightDetailsModal";

const FlightCard = ({
  flightData,
  type,
  selectedFlight,
  handleSelect,
  data,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const flightDetails2 = useSelector(
    (state) => state.flightdata.flightSearchData
  );

  const [modalOpen, setModalOpen] = useState(false);
  const { SrdvType, TraceId } = flightDetails2;
  const SrdvIndex = flightData?.FareDataMultiple?.[0]?.SrdvIndex;
  const ResultIndex = flightData?.FareDataMultiple?.[0]?.ResultIndex;

  const airlineName =
    type === "roundTrip"
      ? flightData?.Segments?.[1]?.[0]?.Airline?.AirlineName
      : flightData?.Segments?.[0]?.[0]?.Airline?.AirlineName;
  const airline =
    type === "roundTrip"
      ? flightData?.Segments?.[1]?.[0]?.Airline
      : flightData?.Segments?.[0]?.[0]?.Airline;
  const origin =
    type === "roundTrip"
      ? flightData?.Segments?.[1]?.[0]?.Origin
      : flightData?.Segments?.[0]?.[0]?.Origin;
  const destination =
    type === "roundTrip"
      ? flightData?.Segments?.[1]?.[0]?.Destination
      : flightData?.Segments?.[0]?.[flightData.Segments[0].length - 1]
          ?.Destination;
  // const destination =
  //   type === "roundTrip"
  //     ? flightData?.Segments?.[1]?.[0]?.Destination
  //     : flightData?.Segments?.[0]?.[1]?.Destination ||
  //       flightData?.Segments?.[0]?.[0]?.Destination;

  const duration =
    type === "roundTrip"
      ? flightData?.Segments?.[1]?.[0]?.Duration
      : flightData?.Segments?.[0]?.[0]?.Duration;
  const DepartureTime =
    type === "roundTrip"
      ? moment(flightData?.Segments?.[1]?.[0]?.DepTime).format("hh:mm A")
      : moment(flightData?.Segments?.[0]?.[0]?.DepTime).format("hh:mm A");
  const ArrivalTime =
    type === "roundTrip"
      ? moment(flightData?.Segments?.[1]?.[0]?.ArrTime).format("hh:mm A")
      : moment(flightData?.Segments?.[0]?.[0]?.ArrTime).format("hh:mm A");

  const handleSubmit = () => {
    const data = {
      SrdvType: SrdvType,
      SrdvIndex: SrdvIndex,
      TraceId: TraceId,
      ResultIndex: ResultIndex,
    };
    // dispatch(fareDetails(data)).then((res) => navigate("/flight-bookig"));
    dispatch(fareDetails(data)).then((res) =>
      dispatch(farequotes(data)).then((res) =>
        dispatch(ssrdetails(data))
          .then((res) =>
            dispatch(seatview(data))
              .then((res) => navigate("/flight-bookig"))
              .catch((err) => console.error(err))
          )
          .catch((err) => console.error(err))
      )
    );
  };
  const segments =
    type === "roundTrip" ? flightData.Segments[1] : flightData.Segments[0]; // Assuming we are only dealing with the first array of segments

  const totalStops = segments.length - 1;
  const totalDuration = segments.reduce(
    (acc, segment) => acc + segment.Duration + (segment.GroundTime || 0),
    0
  );

  const formatDuration = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const getStopsText = (stops) => {
    if (stops === 0) return "non-stop";
    if (stops === 1) return "1 stop";
    return `${stops} stops`;
  };

  return (
    <>
      {data === "multicity" ? (
        <>
          <Box sx={{ backgroundColor: "#9BC9F166", p: 2, mb: 2 }}>
            {flightData.Segments.flat().map((segment, index) => (
              <Grid key={index} container spacing={2}>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Typography
                    sx={{
                      backgroundColor: "#95BDFF",
                      padding: "0px 10px",
                      display: "inline-block",
                      mb: 1,
                      fontSize: "12px",
                    }}
                  >
                    lock this Price
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      columnGap: "10px",
                    }}
                  >
                    <Box>
                      <img
                        src={`https://common-cdn.com/images/airlines/48_48/${segment.Airline.AirlineCode}.png`}
                        alt=""
                      />
                    </Box>
                    <Box>
                      <Typography sx={{ fontWeight: "700", fontSize: "17px" }}>
                        {segment.Airline.AirlineName}
                      </Typography>
                      <Typography>
                        {segment.Airline.AirlineCode}{" "}
                        {segment.Airline.FlightNumber}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                        {moment(segment.DepTime).format("hh:mm A")}
                      </Typography>
                      <Typography>{segment.Origin.CityName}</Typography>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography>{segment.Duration} min</Typography>
                      <Typography>Non-stop</Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                        {moment(segment.ArrTime).format("hh:mm A")}
                      </Typography>
                      <Typography>{segment.Destination.CityName}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    {index === 0 && (
                      <Typography
                        sx={{ fontSize: "20px", fontWeight: "700", mb: 2 }}
                      >
                        ₹ {flightData.OfferedFare}
                      </Typography>
                    )}

                    {type === "radio" ? (
                      <>
                        <Radio
                          checked={
                            selectedFlight?.FareDataMultiple[0]?.ResultIndex ===
                            flightData?.FareDataMultiple[0]?.ResultIndex
                          }
                          onChange={() => handleSelect(flightData)}
                          name="selectedFlight"
                          inputProps={{ "aria-label": "Select Flight" }}
                        />
                        <Typography
                          onClick={() => setModalOpen(true)}
                          sx={{
                            cursor: "pointer",
                            borderRadius: "20px",
                            padding: "4px 15px",
                            display: "inline-block",
                            mb: 1,
                            fontSize: "12px",
                            fontWeight: "700",
                          }}
                        >
                          View Details
                        </Typography>
                      </>
                    ) : (
                      index === 0 && (
                        <>
                          <Typography
                            onClick={handleSubmit}
                            sx={{
                              cursor: "pointer",
                              backgroundColor: "#005C9D",
                              color: "#FFF",
                              borderRadius: "20px",
                              padding: "4px 15px",
                              display: "inline-block",
                              mb: 1,
                              fontSize: "12px",
                              fontWeight: "700",
                            }}
                          >
                            View Price
                          </Typography>
                          <Typography
                            onClick={() => setModalOpen(true)}
                            sx={{
                              cursor: "pointer",
                              borderRadius: "20px",
                              padding: "4px 15px",
                              display: "inline-block",
                              mb: 1,
                              fontSize: "12px",
                              fontWeight: "700",
                            }}
                          >
                            View Details
                          </Typography>
                        </>
                      )
                    )}
                  </Box>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Box sx={{ textAlign: "end" }}>
                    {/* <Link sx={{ color: "#1017B2" }}>View Flight details</Link> */}
                  </Box>
                </Grid>
              </Grid>
            ))}
            <FlightDetailsModal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              flightData={flightData}
              type={type}
              data={data}
            />
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ backgroundColor: "#9BC9F166", p: 2, mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <Typography
                  sx={{
                    backgroundColor: "#95BDFF",
                    padding: "0px 10px",
                    display: "inline-block",
                    mb: 1,
                    fontSize: "12px",
                  }}
                >
                  lock this Price
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    columnGap: "10px",
                  }}
                >
                  <Box>
                    <img
                      src={`https://common-cdn.com/images/airlines/48_48/${airline?.AirlineCode}.png`}
                      alt=""
                    />
                  </Box>
                  <Box>
                    <Typography sx={{ fontWeight: "700", fontSize: "17px" }}>
                      {airlineName || "Airline name is not available"}
                    </Typography>
                    <Typography>
                      {airline?.AirlineCode || ""}{" "}
                      {airline?.FlightNumber || "Airline name is not available"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                      {DepartureTime || "Airline name is not available"}
                    </Typography>
                    <Typography>
                      {origin?.CityName || "Airline name is not available"}
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography sx={{ borderBottom: "1px solid #000" }}>
                      {/* {flightData.arivelTime} */}
                      {/* {DepartureTime - ArrivalTime} */}
                      {formatDuration(totalDuration) ||
                        "Airline name is not available"}{" "}
                    </Typography>
                    <Typography>{getStopsText(totalStops)}</Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                      {ArrivalTime || "Airline name is not available"}
                    </Typography>
                    <Typography>
                      {destination?.CityName || "Airline name is not available"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
              }}
            >
              <img src={timerIcon} alt="" />
              <Typography> 100% on Time.</Typography>
            </Box> */}
                  <Typography
                    sx={{ fontSize: "20px", fontWeight: "700", mb: 2 }}
                  >
                    ₹ {flightData.OfferedFare}
                  </Typography>
                  {/* <Typography
              onClick={() => handleSubmit()}
              sx={{
                cursor: "pointer",
                backgroundColor: "#005C9D",
                color: "#FFF",
                borderRadius: "20px",
                padding: "4px 15px",
                display: "inline-block",
                mb: 1,
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              View Price
            </Typography> */}
                  {/* {type === "radio" ? (
              <>
                <Radio
                  checked={
                    selectedFlight?.FareDataMultiple[0]?.ResultIndex ===
                    flightData?.FareDataMultiple[0]?.ResultIndex
                  }
                  onChange={() => handleSelect(flightData)}
                  // value={flightData}
                  name="selectedFlight"
                  inputProps={{ "aria-label": "Select Flight" }}
                />
                <Typography
                  onClick={() => setModalOpen(true)}
                  sx={{
                    cursor: "pointer",
                    // backgroundColor: "#005C9D",
                    // color: "#FFF",
                    borderRadius: "20px",
                    padding: "4px 15px",
                    display: "inline-block",
                    mb: 1,
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  View Details
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  onClick={handleSubmit}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "#005C9D",
                    color: "#FFF",
                    borderRadius: "20px",
                    padding: "4px 15px",
                    display: "inline-block",
                    mb: 1,
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  View Price
                </Typography>
                <Typography
                  onClick={() => setModalOpen(true)}
                  sx={{
                    cursor: "pointer",
                    // backgroundColor: "#005C9D",
                    // color: "#FFF",
                    borderRadius: "20px",
                    padding: "4px 15px",
                    display: "inline-block",
                    mb: 1,
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  View Details
                </Typography>
              </>
            )} */}
                  {type === "radio" ? (
                    <>
                      <Radio
                        checked={
                          selectedFlight?.FareDataMultiple[0]?.ResultIndex ===
                          flightData?.FareDataMultiple[0]?.ResultIndex
                        }
                        onChange={() => handleSelect(flightData)}
                        name="selectedFlight"
                        inputProps={{ "aria-label": "Select Flight" }}
                      />
                      <Typography
                        onClick={() => setModalOpen(true)}
                        sx={{
                          cursor: "pointer",
                          borderRadius: "20px",
                          padding: "4px 15px",
                          display: "inline-block",
                          mb: 1,
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                      >
                        View Details
                      </Typography>
                    </>
                  ) : (
                    data !== "multicity" && (
                      <>
                        <Typography
                          onClick={handleSubmit}
                          sx={{
                            cursor: "pointer",
                            backgroundColor: "#005C9D",
                            color: "#FFF",
                            borderRadius: "20px",
                            padding: "4px 15px",
                            display: "inline-block",
                            mb: 1,
                            fontSize: "12px",
                            fontWeight: "700",
                          }}
                        >
                          View Price
                        </Typography>
                        <Typography
                          onClick={() => setModalOpen(true)}
                          sx={{
                            cursor: "pointer",
                            borderRadius: "20px",
                            padding: "4px 15px",
                            display: "inline-block",
                            mb: 1,
                            fontSize: "12px",
                            fontWeight: "700",
                          }}
                        >
                          View Details
                        </Typography>
                      </>
                    )
                  )}
                </Box>
              </Grid>
              {/* <Grid item lg={12} xs={12}>
          <Typography sx={{ backgroundColor: "#FFF", textAlign: "center" }}>
            Get 250 Off using MMTOFFer{" "}
          </Typography>
        </Grid> */}
              <Grid item lg={12} xs={12}>
                <Box sx={{ textAlign: "end" }}>
                  {/* <Link sx={{ color: "#1017B2" }}>View Flight details</Link> */}
                </Box>
              </Grid>
            </Grid>
            <FlightDetailsModal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              flightData={flightData}
              type={type}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default FlightCard;
