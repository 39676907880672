import {
  Box,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { getBusCity } from "../../../redux/bus/busapi";
import { getFlightCity } from "../../../redux/flight/flightapi";

const LocationSuggestBox = ({
  setBusCityDetails,
  searchData,
  type,
  locationBox,
  setLocationBox,
  handleOneWayClick,
  setFrom,
  handleCloseLocationBox,
  setTo,
}) => {
  const dispatch = useDispatch();
  // const searchData = useSelector((state) => state?.busdata?.BusCityDetails);
  const data = useSelector((state) =>
    type === "flightfrom" || type === "flightto"
      ? state.flightdata
      : state?.busdata
  );

  const [citystr, setCityStr] = useState("");
  const [citystrReady, setCitystrReady] = useState(false);
  const [dropdownfill, setDropdownfill] = useState([]);
  const [city, setCity] = useState(
    type === "flightfrom"
      ? searchData?.from
      : type === "flightto"
      ? searchData?.to
      : ""
  );

  useEffect(() => {
    let timeoutId;
    if (citystrReady) {
      timeoutId = setTimeout(() => {
        if (type === "flightfrom" || type === "flightto") {
          dispatch(getFlightCity(citystr)).then((res) =>
            setCitystrReady(false)
          );
        }
        dispatch(getBusCity(citystr));
        setCitystrReady(false); // Reset to false after API call
      }, 1000); // Adjust the delay time as needed
    }
    return () => clearTimeout(timeoutId); // Cleanup on component unmount or re-render
  }, [dispatch, citystr, citystrReady]);

  const handleSelectCity = (destination) => {
    if (type === "from") {
      setBusCityDetails((prevLocation) => {
        return {
          ...prevLocation,
          source_city: destination.CityName,
          source_code: destination.CityId,
        };
      });
    } else if (type === "to") {
      setBusCityDetails((prevLocation) => {
        return {
          ...prevLocation,
          destination_city: destination.CityName,
          destination_code: destination.CityId,
        };
      });
    }
    setCity(destination);
    // handleOneWayClick(2);
    setCityStr("");
    setDropdownfill("");

    // Close the modal
    if (type === "flightfrom") {
      setFrom(destination);
      // handleCloseLocationBox();
      setDropdownfill("");
      setLocationBox((prevState) => ({
        ...prevState,
        isFrom: false,
      }));
    } else if (type === "flightto") {
      setTo(destination);
      // handleCloseLocationBox();
      setDropdownfill("");
      setLocationBox((prevState) => ({
        ...prevState,
        isTo: false,
      }));
    }
  };

  useEffect(() => {
    if (data && !city) {
      // Only update dropdownfill from data if a city hasn't just been selected
      if (type === "flightfrom" || type === "flightto") {
        setDropdownfill(data.flightDetails);
      } else {
        setDropdownfill(data.BusCityDetails);
      }
    }
  }, [data, city]);

  const handleChange = (e) => {
    const newCitystr = e.target.value;
    setCityStr(e.target.value);
    if (newCitystr.length > 0) {
      setCitystrReady(true);
    }
  };

  const renderPrimaryText = (city) => {
    const cityName = city.CityName ? city.CityName : city.airport_city_name;

    // Check if type is 'isfrom' or 'isto' and append the airport details if true
    if (type === "flightfrom" || type === "flightto") {
      return `${cityName} (${city.airport_name})`;
    }

    return cityName;
  };
  return (
    <Box className="location-suggest-box" sx={{ minWidth: "300px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: 1,
        }}
      >
        <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
        <FormControl
          variant="outlined"
          fullWidth
          className="formcontroal-box"
          // onClick={(e) => handleSelectCity(e)}
        >
          <TextField
            type="text"
            placeholder="Where do you want to stay?"
            sx={{ "& fieldset": { border: "none" } }}
            onChange={(e) => handleChange(e)}
            autoFocus
          />
        </FormControl>
        <Typography
          variant="caption"
          sx={{
            cursor: "pointer",
            color: "rgba(0, 0, 0, 0.54)",
          }}
        >
          CLEAR
        </Typography>
      </Box>
      <Box className="location-suggest-list-box">
        <Box>
          {/* <Typography variant="body2">POPULAR</Typography> */}
          <Box>
            <List>
              {dropdownfill && dropdownfill.length > 0 ? (
                dropdownfill.map((city) => (
                  <ListItem disablePadding key={city.id}>
                    <ListItemButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleSelectCity(city)}
                    >
                      <ListItemIcon>
                        <FmdGoodOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={renderPrimaryText(city)} />
                    </ListItemButton>
                  </ListItem>
                ))
              ) : (
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText
                      primary={
                        citystr.length > 0
                          ? "Enter valid city"
                          : "Please search for a city"
                      }
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LocationSuggestBox;
