
import TicketCheckOut from "../../layouts/TicketCheckOut";
const BusCheackOutPage = () => {
  return (
    <TicketCheckOut
      moduleName="Bus"
      fromDestinosName="Boriwali"
      fromDate="28,04,2023"
      fromDeparture="07:05 AM"
      toDestinosName="Wakad"
      toDate="28,04,2023"
      toArival="11:05 AM"
      class="AC Sleeper"
      seatNo="8A"
      TravelName="Bharat"
    />
  );
};

export default BusCheackOutPage;
