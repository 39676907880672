import "swiper/css/navigation";
import "swiper/css";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FlightDataselectBox from "../../layouts/Flight/FlightDataselectBox";
import image from "../../assets/img/flight/flight-company-logo.png";
import bannerImg from "../../assets/img/flight/bannerimg.png";
import AboutAppSection from "../../layouts/AboutAppSection";
import Footer from "../../views/footer/Footer";
import FlightAside from "../../layouts/Flight/FlightAside";
import FlightCard from "../../layouts/Flight/FlightCard";
import SliderFoteDateSelect from "../../layouts/Flight/SliderForDateSelect/SliderFoteDateSelect";
import { useSelector } from "react-redux";
import Loader from "../../layouts/Loaders/Loader";
export const flightData = [
  {
    id: "0",
    aeroplanImge: image,
    aeroplanName: "Air India",
    aeroplanNumber: "AI 123",
    fromTime: "10:00 ",
    fromLoction: "Ahmedabad",
    arivelTime: "01 hr 45 min",
    toTime: "11:20",
    toLocation: "Delhi",
    price: "₹5,800",
  },
  {
    id: "1",
    aeroplanImge: image,
    aeroplanName: "Air India",
    aeroplanNumber: "AI 123",
    fromTime: "10:00 ",
    fromLoction: "Ahmedabad",
    arivelTime: "01 hr 45 min",
    toTime: "11:20",
    toLocation: "Delhi",
    price: "₹5,800",
  },
  {
    id: "2",
    aeroplanImge: image,
    aeroplanName: "Air India",
    aeroplanNumber: "AI 123",
    fromTime: "10:00 ",
    fromLoction: "Ahmedabad",
    arivelTime: "01 hr 45 min",
    toTime: "11:20",
    toLocation: "Delhi",
    price: "₹5,800",
  },
  {
    id: "3",
    aeroplanImge: image,
    aeroplanName: "Air India",
    aeroplanNumber: "AI 123",
    fromTime: "10:00 ",
    fromLoction: "Ahmedabad",
    arivelTime: "01 hr 45 min",
    toTime: "11:20",
    toLocation: "Delhi",
    price: "₹5,800",
  },
  {
    id: "4",
    aeroplanImge: image,
    aeroplanName: "Air India",
    aeroplanNumber: "AI 123",
    fromTime: "10:00 ",
    fromLoction: "Ahmedabad",
    arivelTime: "01 hr 45 min",
    toTime: "11:20",
    toLocation: "Delhi",
    price: "₹5,800",
  },
];
const breadcrumbs = [
  <Link
    underline="hover"
    key="1"
    color="#1305B6"
    href="/"
    //   onClick={handleClick}
  >
    Home
  </Link>,
  <Link
    underline="hover"
    key="2"
    color="inherit"

    //   onClick={handleClick}
  >
    Hotel in
  </Link>,
];
const FlightTicketOneWay = () => {
  const [filterValue, setFilterValue] = useState({});
  const flightDetails = useSelector(
    (state) => state.flightdata.flightSearchData.Results
  );
  const searchData = useSelector((state) => state.flightdata.searchData);

  const loading = useSelector((state) => state.flightdata.loading);

  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ backgroundColor: "#3c74dc" }}>
          <Stack spacing={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
          <FlightDataselectBox searchData={searchData} />
        </Box>
      </Container>

      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12} sx={{ my: 2 }}>
            <FlightAside setFilterValue={setFilterValue} />
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12} sx={{ my: 2 }}>
            {loading ? (
              <Loader />
            ) : (
              <Box sx={{ my: 2 }}>
                {flightDetails[0].map((item) => (
                  <FlightCard key={item.id} flightData={item} />
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
      <AboutAppSection />
      <Footer />
    </>
  );
};

export default FlightTicketOneWay;
