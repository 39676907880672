import TicketCheckOut from "../../layouts/TicketCheckOut";
const FlightTicketCheckOut = () => {
  return (
    <TicketCheckOut
      moduleName="Flight"
      fromDestinosName="Ahmedabad"
      fromDate="27,04,2023"
      fromDeparture="21:30 PM"
      toDestinosName="Bengaluru"
      toDate="27,04,2023"
      toArival="23:40 PM"
      class="Economy"
      seatNo="8A"
      TravelName="GoindiaGo"
    />
  );
};

export default FlightTicketCheckOut;
