import { Box, Breadcrumbs, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { SearchBoxContainer } from "./MyTripsPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import HotelIcon from "../../assets/img/icon/hotelFill-icon.svg";
import EmailIcon from "@mui/icons-material/Email";
import UserIcon from "../../assets/img/icon/user.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DownloadInvoiceIcon from "../../assets/img/icon/download-invoice-icon.svg";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
const breadcrumbs = [
  <Link
    underline="hover"
    key="1"
    style={{ color: "#fff" }}
    // href="/"
    to="/my-trips-page"
    onClick={handleClick}
  >
    My Trips
  </Link>,
  <Link
    underline="hover"
    key="2"
    style={{ color: "#fff" }}
    to=""
    // href="/material-ui/getting-started/installation/"
    onClick={handleClick}
  >
    Completed
  </Link>,
  <Typography key="3" color="#fff">
    The Hotel Dera formerly JW Manu
  </Typography>,
];
const ConfirmTripPage = () => {
  return (
    <div>
      <Box className="confirm-trip-head-section">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            pt: 2,
          }}
        >
          <Box>
            <Stack spacing={2}>
              <Breadcrumbs
                separator={
                  <NavigateNextIcon fontSize="large" sx={{ color: "#fff" }} />
                }
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          </Box>
          <SearchBoxContainer />
        </Box>
        <Box sx={{ mt: 5 }}>
          <Box sx={{ px: 5, pb: 2 }}>
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Your Booking has been completed.
            </Typography>
            <Typography sx={{ color: "#fff", fontSize: "22px" }}>
              Booking ID : NU 123123123123123{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mb: 5 }}>
        <Container maxWidth="xl">
          <Box sx={{ mt: 5 }}>
            <Box className="completed-trip-box" sx={{ px: 0 }}>
              <Box sx={{ px: 4 }}>
                <Box className="trip-icon-box">
                  <img src={HotelIcon} alt="" width="70px" />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box className="user-icon-box">
                    <img src={UserIcon} alt="" width="15px" />
                  </Box>
                  <Typography className="user-main-text">
                    Elika Maheshawari
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", columnGap: "100px", flexWrap: "wrap" }}
                >
                  <Box sx={{ mt: 5 }}>
                    <Typography className="trip-main-text">
                      Hard Rock Hotel
                    </Typography>
                    <Typography>Hotel in Goa</Typography>
                  </Box>
                  <Box sx={{ mt: 5 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <CheckCircleIcon sx={{ color: "#0063A7" }} />
                      <Typography className="completed-main-text">
                        Completed{" "}
                      </Typography>
                    </Box>
                    <Typography>Booking ID : NH 123123123123123 </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 5,
                  }}
                >
                  <button className="view-btn">View Booking</button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{ width: { md: "40%", sm: "40%", xs: "100%" }, mt: 5 }}
                  >
                    <Typography>Check-In</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="date-time-text">
                        Friday, 03 Feb 2023{" "}
                      </Typography>
                      <Typography className="date-time-text">
                        11:50 AM
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{ width: { md: "40%", sm: "40%", xs: "100%" }, mt: 5 }}
                  >
                    <Typography>Check-Out</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="date-time-text">
                        Monday, 06 Feb 2023{" "}
                      </Typography>
                      <Typography className="date-time-text">
                        02:00 PM
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography className="hotel-room-text">
                  1 Room, 3 Night
                </Typography>
              </Box>
              <Box
                sx={{ borderBottom: "3px solid #0063A7", width: "100%", my: 8 }}
              ></Box>
              <Box sx={{ px: 4, mt: 5 }}>
                <Typography className="traveler-details-head-text">
                  Traveller Details
                </Typography>
                <Typography
                  sx={{ mt: 1 }}
                  className="traveler-details-sub-text"
                >
                  Bus Operator Or Our Service Experts might Connect with you on
                  below Contact Details
                </Typography>
                <Typography sx={{ my: 2 }} className="traveler-details-number">
                  Traveller (1)
                </Typography>
                <Box sx={{ display: "flex", alignItems: "start" }}>
                  <Box className="user-icon-box">
                    <img src={UserIcon} alt="" width="15px" />
                  </Box>
                  <Box>
                    <Typography className="user-main-text">
                      Elika Maheshawari
                    </Typography>
                    <Typography>28 Year - Female</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <EmailIcon sx={{ color: "#0063A7", mr: 2 }} />
                  <Typography sx={{ fontSize: "20px" }}>
                    elika03@gmail.com
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <LocalPhoneIcon sx={{ color: "#0063A7", mr: 2 }} />
                  <Typography sx={{ fontSize: "20px" }}>******2121</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 5,
                  }}
                >
                  <button className="view-btn">
                    <img
                      src={DownloadInvoiceIcon}
                      alt="DownloadInvoiceIcon"
                      width={25}
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Download Invoice
                  </button>
                </Box>
              </Box>
            </Box>
            <Box className="trip-price-section">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "40%" }}>
                  <Typography className="head-title-text">
                    Pricing Details
                  </Typography>
                  <Box className="trip-price-confirm-box">
                    <Box
                      className="trip-price-box"
                      sx={{ borderBottom: "1px solid #000000" }}
                    >
                      <Typography className="trip-price-text">
                        Hotel Charges
                      </Typography>
                      <Typography className="trip-price-text">
                        Rs. 4200
                      </Typography>
                    </Box>
                    <Box className="trip-price-box">
                      <Typography className="trip-price-text">
                        Taxes & Fees
                      </Typography>
                      <Typography className="trip-price-text">
                        Rs. 30
                      </Typography>
                    </Box>
                    <Box className="trip-price-box">
                      <Typography className="price-total-text">
                        Total Cost
                      </Typography>
                      <Typography className="price-total-text">
                        Rs. 4500
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderLeft: "1px solid #000000",
                  }}
                >
                  <Typography className="head-title-text">
                    Payment Paid From
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 5,
                      width: "70%",
                    }}
                  >
                    <Typography className="pyment-bank-text">
                      BOB Credit Card (xx1111)
                    </Typography>
                    <Typography className="pyment-price-text">
                      Rs. 4500
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 5,
              }}
            >
              <button className="view-btn">
                <img
                  src={DownloadInvoiceIcon}
                  alt="DownloadInvoiceIcon"
                  width={25}
                  style={{ marginRight: "10px" }}
                />{" "}
                Download Invoice
              </button>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default ConfirmTripPage;
