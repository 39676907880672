import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import bag1 from "../../assets/img/icon/bag1.png";
import bag2 from "../../assets/img/icon/bag2.png";
import bag3 from "../../assets/img/icon/bag3.png";
import puhaFood from "../../assets/img/icon/puhafood.png";
import MusliFood from "../../assets/img/icon/muslifood.png";
import BeryaniFood from "../../assets/img/icon/beryani.png";
import FoodIcon from "../../assets/img/icon/foodIcon.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import arioPlan from "../../assets/img/icon/airoplan-bg-blue.png";
import seatIcon from "../../assets/img/icon/seatsicon.png";
import aeroplanHead from "../../assets/img/icon/airoplan-head.png";
import ManWomanIcon from "../../assets/img/icon/man-woman-icon.png";
import teaIcon from "../../assets/img/icon/tea-icon.png";
import arioPlanBottom from "../../assets/img/icon/airoplan-bottom.png";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Footer from "../footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from "../../helpers/API";
import axios from "axios";
import { bookSeats } from "../../redux/bus/busapi";
import {
  flightbookgds,
  flightbookgdsTwo,
  flightbookllc,
} from "../../redux/flight/flightapi";
import Notification from "../../layouts/Notification/Notification";
import moment from "moment";

const FlightSeatBookingRoundTrip = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fareQuotes = useSelector(
    (state) => state.flightdata.farequotes.Results.Fare.PublishedFare
  );

  const holdseatgds = useSelector((state) => state?.flightdata?.holdseatgds);
  const holdseatgdsTwo = useSelector(
    (state) => state?.flightdata?.holdseatgdsTwo
  );

  const BaggageOne = useSelector(
    (state) => state.flightdata.ssrdetails.Baggage[0]
  );
  const MealDynamicOne = useSelector(
    (state) => state.flightdata.ssrdetails.MealDynamic[0]
  );

  const BaggageTwo = useSelector(
    (state) => state.flightdata.ssrdetailsTwo.Baggage[0]
  );
  const MealDynamicTwo = useSelector(
    (state) => state.flightdata.ssrdetailsTwo.MealDynamic[0]
  );

  console.log(BaggageOne, BaggageTwo, MealDynamicOne, MealDynamicTwo);

  const fareQuotesTwo = useSelector(
    (state) => state.flightdata.farequotesTwo.Results.Fare.PublishedFare
  );
  const [farePrice, setFarePrice] = useState(fareQuotes ? fareQuotes : 0);
  const [farePriceTwo, setFarePriceTwo] = useState(
    fareQuotesTwo ? fareQuotesTwo : 0
  );

  const flightDetailsData = useSelector(
    (state) => state?.flightdata.farequotes.Results.Segments[0]
  );

  const flightDetailsDataTwo = useSelector(
    (state) => state?.flightdata.farequotesTwo.Results.Segments[0]
  );

  const seatview = useSelector(
    (state) => state.flightdata.seatviewdata.Results[0].Seats
  );
  const seatviewData = useSelector(
    (state) => state.flightdata.seatviewdata.Results
  );
  const seatviewTwo = useSelector(
    (state) => state.flightdata.seatviewdataTwo.Results[0].Seats
  );
  const seatviewDataTwo = useSelector(
    (state) => state.flightdata.seatviewdataTwo.Results
  );

  const flightDetails = useSelector(
    (state) => state.flightdata.farequotes.Results.Segments[0][0]
  );

  const flightDetailsTwo = useSelector(
    (state) => state.flightdata.farequotesTwo.Results.Segments[0][0]
  );

  const passengers = useSelector((state) => state?.flightdata?.passengers);
  const flightData = useSelector(
    (state) => state?.flightdata.farequotes.Results.Segments[0][0]
  );
  const flightDataTwo = useSelector(
    (state) => state?.flightdata.farequotesTwo.Results.Segments[0][0]
  );

  const Fare = useSelector((state) => state.flightdata.farequotes.Results.Fare);
  const FareTwo = useSelector(
    (state) => state.flightdata.farequotesTwo.Results.Fare
  );
  const datatosend = useSelector((state) => state.flightdata.farequotes);
  const datatosendTwo = useSelector((state) => state.flightdata.farequotesTwo);
  const fareData = useSelector((state) => state.flightdata.farequotes);
  const fareDataTwo = useSelector((state) => state.flightdata.farequotesTwo);

  const [passengerData, setPassengerData] = useState(
    passengers ? passengers : []
  );
  const [passengerDataTwo, setPassengerDataTwo] = useState(
    passengers ? passengers : []
  );

  // const CustomSeat = ({ seatId, onSelectSeat, rowIndex, passenger }) => {
  //   const isBooked = seatId.IsBooked;
  //   const [isSelected, setIsSelected] = useState(
  //     passenger.some((passengers) => passengers.Seat === seatId)
  //   );

  //   const handleSeatClick = () => {
  //     const updatedPassengers = [...passenger]; // Create a shallow copy of passengers

  //     // Check if the seat is already assigned to a passenger
  //     const passengerIndex = updatedPassengers.findIndex(
  //       (passenger) => passenger.Seat === seatId
  //     );

  //     if (passengerIndex !== -1) {
  //       // Seat is already assigned to a passenger, create a new object without the Seat key
  //       updatedPassengers[passengerIndex] = {
  //         ...updatedPassengers[passengerIndex],
  //         Seat: null, // Remove the Seat key
  //       };
  //     } else {
  //       // Find the first passenger who hasn't selected a seat yet
  //       const passengerWithoutSeatIndex = updatedPassengers.findIndex(
  //         (passenger) => !passenger.Seat
  //       );

  //       if (passengerWithoutSeatIndex === -1) {
  //         console.log(
  //           "All passengers have selected a seat. Further selections are disabled."
  //         );
  //         Notification(
  //           "error",
  //           "All passengers have selected a seat. Further selections are disabled."
  //         );
  //         return; // Abort seat selection
  //       }

  //       // Update the selected seat for the passenger without a seat
  //       updatedPassengers[passengerWithoutSeatIndex] = {
  //         ...updatedPassengers[passengerWithoutSeatIndex],
  //         Seat: seatId,
  //       };
  //     }

  //     setIsSelected(!isSelected);
  //     onSelectSeat(seatId, rowIndex);
  //   };

  //   return (
  //     <Box
  //       sx={{
  //         cursor: isBooked ? "not-allowed" : "pointer",
  //         width: "20px",
  //         height: "20px",
  //         background: isBooked
  //           ? "#FF0000" // Red color for booked seats
  //           : isSelected
  //           ? "#0063A7" // Blue color for selected seats
  //           : "#D9D9D9", // Default color for available seats
  //         "&:hover": {
  //           background: isBooked
  //             ? "#FF0000" // Keep red color on hover if booked
  //             : "#0063A7", // Change to blue color on hover if available
  //         },
  //       }}
  //       onClick={!isBooked ? handleSeatClick : undefined} // Disable click if booked
  //     ></Box>
  //   );
  // };
  const CustomSeat = ({
    seatId,
    onSelectSeat,
    rowIndex,
    passenger,
    planeIndex,
  }) => {
    const isBooked = seatId.IsBooked;

    // Determine if this seat is selected for the current planeIndex
    const isSelected = passenger.some((p) =>
      p.Seat?.some(
        (seat, index) =>
          seat.planeIndex === planeIndex &&
          seat.SeatNumber === seatId.SeatNumber
      )
    );

    const handleSeatClick = () => {
      if (isBooked) return;

      onSelectSeat(seatId, rowIndex, planeIndex);
    };

    return (
      <Box
        sx={{
          cursor: isBooked ? "not-allowed" : "pointer",
          width: "20px",
          height: "20px",
          background: isBooked
            ? "#FF0000" // Red color for booked seats
            : isSelected
            ? "#0063A7" // Blue color for selected seats
            : "#D9D9D9", // Default color for available seats
          "&:hover": {
            background: isBooked ? "#FF0000" : "#0063A7", // Keep or change color on hover based on availability
          },
        }}
        onClick={!isBooked ? handleSeatClick : undefined} // Disable click if booked
      ></Box>
    );
  };
  const numRows = 32;

  // Create an array with numbers 1 to numRows
  const rowNumbers = Array.from({ length: numRows }, (_, index) => index + 1);

  const handleSelectSeat = (seatId, rowIndex, planeIndex) => {
    const updatedPassengers = passengerData.map((passenger) => ({
      ...passenger,
      Seat: passenger.Seat ? [...passenger.Seat] : [],
    }));

    let newFareQuotes = farePrice;

    // Check if the seat is already assigned to a passenger for the current plane
    const passengerIndex = updatedPassengers.findIndex((p) =>
      p.Seat.some(
        (seat) =>
          seat.planeIndex === planeIndex &&
          seat.SeatNumber === seatId.SeatNumber
      )
    );

    if (passengerIndex !== -1) {
      // Seat is already assigned to a passenger, remove it
      const seatToRemove = updatedPassengers[passengerIndex].Seat.find(
        (seat) =>
          seat.planeIndex === planeIndex &&
          seat.SeatNumber === seatId.SeatNumber
      );

      if (seatToRemove) {
        newFareQuotes -= seatToRemove.Amount; // Subtract seat price from fareQuotes

        updatedPassengers[passengerIndex].Seat = updatedPassengers[
          passengerIndex
        ].Seat.filter(
          (seat) =>
            !(
              seat.planeIndex === planeIndex &&
              seat.SeatNumber === seatId.SeatNumber
            )
        );
      }
    } else {
      // Find the first passenger who hasn't selected a seat yet for the current plane
      const passengerWithoutSeatIndex = updatedPassengers.findIndex(
        (p) => !p.Seat.some((seat) => seat.planeIndex === planeIndex)
      );

      if (passengerWithoutSeatIndex === -1) {
        console.log(
          "All passengers have selected a seat for this plane. Further selections are disabled."
        );
        Notification(
          "error",
          "All passengers have selected a seat for this plane. Further selections are disabled."
        );
        return; // Abort seat selection
      }

      updatedPassengers[passengerWithoutSeatIndex].Seat.push({
        ...seatId,
        planeIndex, // Add planeIndex to seat data using spread syntax
      });
      newFareQuotes += seatId.Amount; // Add seat price to fareQuotes
    }

    setFarePrice(newFareQuotes);
    setPassengerData(updatedPassengers);
  };

  const handleSelectSeatTwo = (seatId, rowIndex, planeIndex) => {
    const updatedPassengers = passengerDataTwo.map((passenger) => ({
      ...passenger,
      Seat: passenger.Seat ? [...passenger.Seat] : [],
    }));

    let newFareQuotes = farePrice;

    // Check if the seat is already assigned to a passenger for the current plane
    const passengerIndex = updatedPassengers.findIndex((p) =>
      p.Seat.some(
        (seat) =>
          seat.planeIndex === planeIndex &&
          seat.SeatNumber === seatId.SeatNumber
      )
    );

    if (passengerIndex !== -1) {
      // Seat is already assigned to a passenger, remove it
      const seatToRemove = updatedPassengers[passengerIndex].Seat.find(
        (seat) =>
          seat.planeIndex === planeIndex &&
          seat.SeatNumber === seatId.SeatNumber
      );

      if (seatToRemove) {
        newFareQuotes -= seatToRemove.Amount; // Subtract seat price from fareQuotes

        updatedPassengers[passengerIndex].Seat = updatedPassengers[
          passengerIndex
        ].Seat.filter(
          (seat) =>
            !(
              seat.planeIndex === planeIndex &&
              seat.SeatNumber === seatId.SeatNumber
            )
        );
      }
    } else {
      // Find the first passenger who hasn't selected a seat yet for the current plane
      const passengerWithoutSeatIndex = updatedPassengers.findIndex(
        (p) => !p.Seat.some((seat) => seat.planeIndex === planeIndex)
      );

      if (passengerWithoutSeatIndex === -1) {
        console.log(
          "All passengers have selected a seat for this plane. Further selections are disabled."
        );
        Notification(
          "error",
          "All passengers have selected a seat for this plane. Further selections are disabled."
        );
        return; // Abort seat selection
      }

      updatedPassengers[passengerWithoutSeatIndex].Seat.push({
        ...seatId,
        planeIndex, // Add planeIndex to seat data using spread syntax
      });
      newFareQuotes += seatId.Amount; // Add seat price to fareQuotes
    }

    setFarePriceTwo(newFareQuotes);
    setPassengerDataTwo(updatedPassengers);
  };

  let token = localStorage.getItem("token");
  const key_id = "rzp_test_RKcrTpOrjFkybA";

  const checkouthandler = async (amount) => {
    try {
      const response = await API.post(
        "/payment/razorpay-order-generate/",
        {
          action: "order_generate",
          currency_name: "INR",
          amount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response;

      const options = {
        key: key_id,
        amount: data.amount,
        currency: "INR",
        name: "Make U Trip",
        description: "Razorpay tutorial",
        order_id: data.id,
        callback_url:
          "https://srv453747.hstgr.cloud/api/payment/razorpay-verify-payment/",
        prefill: {
          name: "Make U Trip ",
          email: "makeutrip@gmail.com",
          contact: "1234567890",
        },
        notes: {
          address: "razorapy official",
        },
        theme: {
          color: "yellow",
        },
        handler: async function (response) {
          if (response.razorpay_payment_id) {
            const bodyDataToSend = {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            };

            try {
              const verifyResponse = await axios.post(
                "https://srv453747.hstgr.cloud/api/payment/razorpay-verify-payment/",
                bodyDataToSend,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                }
              );

              if (
                verifyResponse.data.detail ===
                "Signature verification successful."
              ) {
                if (
                  flightData.Destination.CountryCode !==
                  flightData.Origin.CountryCode
                ) {
                  dispatch(flightbookgds(holdseatgds))
                    .then((response) => {
                      if (response.payload && response.payload.detail) {
                        console.error("API Error:", response.payload.detail);
                        navigate("/payment-unsuccess-page");
                        window.scrollTo(0, 0);
                      } else {
                        // Second API call for seat booking
                        dispatch(flightbookgdsTwo(holdseatgdsTwo))
                          .then((response) => {
                            if (response.payload && response.payload.detail) {
                              console.error(
                                "API Error:",
                                response.payload.detail
                              );
                              navigate("/payment-unsuccess-page");
                              window.scrollTo(0, 0);
                            } else {
                              navigate("/payment-success-page");
                              window.scrollTo(0, 0);
                            }
                          })
                          .catch((error) => {
                            console.log("Network Error:", error);
                          });
                      }
                    })
                    .catch((error) => {
                      console.log("Network Error:", error);
                    });
                } else {
                  // First API call for seat booking
                  const flightData1 = {
                    AirlineCode: flightDetails.Airline.AirlineCode,
                    FlightNumber: flightDetails.Airline.FlightNumber,
                    Origin: flightDetails.Origin.CityCode,
                    Destination: flightDetails.Destination.CityCode,
                  };

                  const data1 = {
                    SrdvType: datatosend.SrdvType,
                    SrdvIndex: datatosend.Results.SrdvIndex,
                    TraceId: datatosend.TraceId,
                    ResultIndex: datatosend.Results.ResultIndex,
                    razorpay_payment_id: response.razorpay_payment_id,
                    Passengers: passengerData.map((passenger, index) => ({
                      ...passenger,
                      Seat: passenger.Seat
                        ? [{ ...passenger.Seat, ...flightData1 }]
                        : [flightData1],
                      fare: Fare,
                      IsLeadPax: index === 0 ? 1 : 0,
                    })),
                  };

                  dispatch(flightbookllc(data1))
                    .then((response) => {
                      if (response.payload && response.payload.detail) {
                        console.error("API Error:", response.payload.detail);
                        navigate("/payment-unsuccess-page");
                        window.scrollTo(0, 0);
                      } else {
                        // Second API call for seat booking
                        const flightData2 = {
                          AirlineCode: flightDetailsTwo.Airline.AirlineCode,
                          FlightNumber: flightDetailsTwo.Airline.FlightNumber,
                          Origin: flightDetailsTwo.Origin.CityCode,
                          Destination: flightDetailsTwo.Destination.CityCode,
                        };

                        const data2 = {
                          SrdvType: datatosendTwo.SrdvType,
                          SrdvIndex: datatosendTwo.Results.SrdvIndex,
                          TraceId: datatosendTwo.TraceId,
                          ResultIndex: datatosendTwo.Results.ResultIndex,
                          razorpay_payment_id: response.razorpay_payment_id,
                          Passengers: passengerDataTwo.map(
                            (passenger, index) => ({
                              ...passenger,
                              Seat: passenger.Seat
                                ? [{ ...passenger.Seat, ...flightData2 }]
                                : [flightData2],
                              fare: FareTwo,
                              IsLeadPax: index === 0 ? 1 : 0,
                            })
                          ),
                        };

                        dispatch(flightbookllc(data2))
                          .then((response) => {
                            if (response.payload && response.payload.detail) {
                              console.error(
                                "API Error:",
                                response.payload.detail
                              );
                              navigate("/payment-unsuccess-page");
                              window.scrollTo(0, 0);
                            } else {
                              navigate("/payment-success-page");
                              window.scrollTo(0, 0);
                            }
                          })
                          .catch((error) => {
                            console.log("Network Error:", error);
                          });
                      }
                    })
                    .catch((error) => {
                      console.log("Network Error:", error);
                    });
                }
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            console.error("Payment failed or was canceled");
          }
        },
      };

      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error("Error generating Razorpay order:", error);
    }
  };

  const handlePay = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      localStorage.setItem("redirectPath", window.location.pathname);
      navigate("/login");
      return;
    }

    const allPassengersHaveSeat = passengerData.every(
      (passenger) =>
        passenger.Seat !== null && typeof passenger.Seat === "object"
    );

    if (allPassengersHaveSeat) {
      if (
        flightData.Destination.CountryCode !== flightData.Origin.CountryCode
      ) {
        const data1 = {
          SrdvType: datatosend.SrdvType,
          SrdvIndex: datatosend.Results.SrdvIndex,
          TraceId: datatosend.TraceId,
          ResultIndex: datatosend.Results.ResultIndex,
          Passengers: passengerData.map((passenger, index) => ({
            ...passenger,
            fare: Fare,
            IsLeadPax: index === 0 ? 1 : 0,
          })),
        };
        const data2 = {
          SrdvType: datatosendTwo.SrdvType,
          SrdvIndex: datatosendTwo.Results.SrdvIndex,
          TraceId: datatosendTwo.TraceId,
          ResultIndex: datatosendTwo.Results.ResultIndex,
          Passengers: passengerDataTwo.map((passenger, index) => ({
            ...passenger,
            fare: FareTwo,
            IsLeadPax: index === 0 ? 1 : 0,
          })),
        };

        dispatch(holdseatgds(data1)).then((res) =>
          dispatch(
            holdseatgdsTwo(data2).then((res) =>
              checkouthandler(
                farePrice +
                  farePriceTwo +
                  totalBaggageCharges +
                  totalMealDynamicCharges +
                  totalBaggageChargesTwo +
                  totalMealDynamicChargesTwo
              )
            )
          )
        );
      } else {
        checkouthandler(
          farePrice +
            farePriceTwo +
            totalBaggageCharges +
            totalMealDynamicCharges +
            totalBaggageChargesTwo +
            totalMealDynamicChargesTwo
        );
      }
    } else {
      console.log("Not all passengers have selected a seat.");
      Notification("error", "Please Select Seat First");
    }
  };

  const [selectedExtrasOne, setSelectedExtrasOne] = useState({
    baggage: [],
    meals: [],
  });
  const [selectedExtrasTwo, setSelectedExtrasTwo] = useState({
    baggage: [],
    meals: [],
  });

  const handleToggleBaggageOne = (baggageOption, action) => {
    if (action === "+") {
      setSelectedExtrasOne((prevState) => ({
        ...prevState,
        baggage: [...prevState.baggage, baggageOption],
      }));
    } else if (action === "-") {
      const indexToRemove = selectedExtrasOne.baggage.findIndex(
        (item) => item.Code === baggageOption.Code
      );
      if (indexToRemove !== -1) {
        setSelectedExtrasOne((prevState) => ({
          ...prevState,
          baggage: [
            ...prevState.baggage.slice(0, indexToRemove),
            ...prevState.baggage.slice(indexToRemove + 1),
          ],
        }));
      }
    }
  };

  const handleToggleBaggageTwo = (baggageOption, action) => {
    if (action === "+") {
      setSelectedExtrasTwo((prevState) => ({
        ...prevState,
        baggage: [...prevState.baggage, baggageOption],
      }));
    } else if (action === "-") {
      const indexToRemove = selectedExtrasTwo.baggage.findIndex(
        (item) => item.Code === baggageOption.Code
      );
      if (indexToRemove !== -1) {
        setSelectedExtrasTwo((prevState) => ({
          ...prevState,
          baggage: [
            ...prevState.baggage.slice(0, indexToRemove),
            ...prevState.baggage.slice(indexToRemove + 1),
          ],
        }));
      }
    }
  };

  // Toggle selection for meals
  const handleToggleMealOne = (mealOption, action) => {
    if (action === "+") {
      setSelectedExtrasOne((prevState) => ({
        ...prevState,
        meals: [...prevState.meals, mealOption],
      }));
    } else if (action === "-") {
      const indexToRemove = selectedExtrasOne.meals.findIndex(
        (item) => item.Code === mealOption.Code
      );
      if (indexToRemove !== -1) {
        setSelectedExtrasOne((prevState) => ({
          ...prevState,
          meals: [
            ...prevState.meals.slice(0, indexToRemove),
            ...prevState.meals.slice(indexToRemove + 1),
          ],
        }));
      }
    }
  };

  const handleToggleMealTwo = (mealOption, action) => {
    if (action === "+") {
      setSelectedExtrasTwo((prevState) => ({
        ...prevState,
        meals: [...prevState.meals, mealOption],
      }));
    } else if (action === "-") {
      const indexToRemove = selectedExtrasTwo.meals.findIndex(
        (item) => item.Code === mealOption.Code
      );
      if (indexToRemove !== -1) {
        setSelectedExtrasTwo((prevState) => ({
          ...prevState,
          meals: [
            ...prevState.meals.slice(0, indexToRemove),
            ...prevState.meals.slice(indexToRemove + 1),
          ],
        }));
      }
    }
  };

  const updatePassengerExtrasOne = () => {
    const updatedPassengers = passengerData.map((passenger) => ({
      ...passenger,
      Baggage: selectedExtrasOne.baggage,
      MealDynamic: selectedExtrasOne.meals,
    }));
    setPassengerData(updatedPassengers);
  };

  const updatePassengerExtrasTwo = () => {
    const updatedPassengers = passengerData.map((passenger) => ({
      ...passenger,
      Baggage: selectedExtrasTwo.baggage,
      MealDynamic: selectedExtrasTwo.meals,
    }));
    setPassengerDataTwo(updatedPassengers);
  };

  useEffect(() => {
    updatePassengerExtrasOne();
  }, [selectedExtrasOne]);

  useEffect(() => {
    updatePassengerExtrasTwo();
  }, [selectedExtrasTwo]);

  const totalBaggageCharges = passengerData.reduce((total, passenger) => {
    // Calculate total baggage charges for a single passenger
    const passengerTotal =
      passenger.Baggage?.reduce(
        (acc, baggage) => acc + (baggage.Price || 0),
        0
      ) || 0;
    return total + passengerTotal;
  }, 0);
  const totalBaggageChargesTwo = passengerDataTwo.reduce((total, passenger) => {
    // Calculate total baggage charges for a single passenger
    const passengerTotal =
      passenger.Baggage?.reduce(
        (acc, baggage) => acc + (baggage.Price || 0),
        0
      ) || 0;
    return total + passengerTotal;
  }, 0);

  const totalMealDynamicCharges = passengerData.reduce((total, passenger) => {
    // Calculate total baggage charges for a single passenger
    const passengerTotal =
      passenger.MealDynamic?.reduce(
        (acc, baggage) => acc + (baggage.Price || 0),
        0
      ) || 0;
    return total + passengerTotal;
  }, 0);

  const totalMealDynamicChargesTwo = passengerDataTwo.reduce(
    (total, passenger) => {
      // Calculate total baggage charges for a single passenger
      const passengerTotal =
        passenger.MealDynamic?.reduce(
          (acc, baggage) => acc + (baggage.Price || 0),
          0
        ) || 0;
      return total + passengerTotal;
    },
    0
  );

  console.log(
    "Total Baggage Charges:",
    totalBaggageCharges,
    totalMealDynamicCharges,
    totalBaggageChargesTwo,
    totalMealDynamicChargesTwo
  ); // Output the total charges

  return (
    <Box>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Box sx={{ backgroundColor: "#D5E9F4", padding: 2 }}>
              <Box sx={{ py: 1, borderBottom: "1px solid #000000" }}>
                <Typography sx={{ fontWeight: 700 }}>Fare Summary </Typography>
                <Typography sx={{ fontWeight: 700, ml: 1 }} variant="caption">
                  1 ADULT
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                  mt: 1,
                  pb: 1,
                  borderBottom: "1px solid #B9D7F166",
                }}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CircleIcon sx={{ color: "#B9D7F166" }} />
                  <Box>
                    <Typography>Base Fare </Typography>
                    {/* <Typography variant="caption">Adult (1x 4,700)</Typography> */}
                  </Box>
                </Box>
                <Typography>
                  {fareData?.Results?.Fare?.BaseFare +
                    fareDataTwo?.Results?.Fare?.BaseFare}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                  mt: 1,
                  pb: 1,
                  borderBottom: "1px solid #B9D7F166",
                }}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CircleIcon sx={{ color: "#B9D7F166" }} />

                  <Typography>Taxes and Surcharges</Typography>
                </Box>
                <Typography>
                  {fareData?.Results?.Fare?.Tax +
                    fareDataTwo?.Results?.Fare?.Tax}
                </Typography>
              </Box>
              {passengerData[0].Seat && passengerDataTwo[0].Seat && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                    mt: 1,
                    pb: 1,
                    borderBottom: "1px solid #B9D7F166",
                  }}
                >
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <CircleIcon sx={{ color: "#B9D7F166" }} />
                    <Typography>Seat Selection Charge</Typography>
                  </Box>
                  <Typography>
                    {passengerData[0].Seat.Amount +
                      passengerDataTwo[0].Seat.Amount}
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                  <CircleIcon sx={{ color: "#B9D7F166" }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography> Other Services</Typography>
                    <Typography>
                      {fareData?.Results?.Fare?.OtherCharges +
                        fareDataTwo?.Results?.Fare?.OtherCharges}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: "1px solid #000",
                  mt: 1,
                  pt: 1,
                }}
              >
                <Typography sx={{ fontWeight: "700" }}>
                  Total Amount{" "}
                </Typography>

                <Typography sx={{ fontWeight: "700" }}>
                  {farePrice +
                    farePriceTwo +
                    totalBaggageCharges +
                    totalMealDynamicCharges +
                    totalBaggageChargesTwo +
                    totalMealDynamicChargesTwo}
                </Typography>
              </Box>
              <Box sx={{ textAlign: "end" }}>
                <Grid>
                  <Button
                    sx={{ border: "1px solid black" }}
                    onClick={handlePay}
                  >
                    Pay
                  </Button>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ backgroundColor: "#D5E9F4", padding: 2, mt: 2 }}>
              <Typography>Baggage</Typography>
              <Box
                sx={{
                  maxHeight: "170px",
                  overflowY: "scroll",
                  scrollBehavior: "smooth",
                }}
              >
                {BaggageOne?.map((item) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mt: 2,
                      }}
                    >
                      <Box>
                        <Typography
                          variant="caption"
                          sx={{
                            ml: 1,
                            background: "#B9D7F166",
                            padding: "2px 2px",
                          }}
                        >
                          {item.Weight} kg
                        </Typography>
                      </Box>
                      {/* <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="caption">
                          {item.Origin} - {item.Destination}
                        </Typography>
                      </Box> */}
                      <Box
                        sx={{
                          padding: "5px 10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="caption" sx={{ ml: 1 }}>
                          {item.AirlineCode} - {item.FlightNumber}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          ml: "auto",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body2">₹{item.Price}</Typography>
                        {/* <Button
                          variant={
                            selectedExtras.baggage.some(
                              (selectedItem) => selectedItem.Code === item.Code
                            )
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          onClick={() => handleToggleBaggage(item)}
                        >
                          {selectedExtras.baggage.some(
                            (selectedItem) => selectedItem.Code === item.Code
                          )
                            ? "Remove"
                            : "Add"}
                        </Button> */}
                      </Box>
                      <Button onClick={() => handleToggleBaggageOne(item, "+")}>
                        +
                      </Button>
                      {
                        passengerData[0]?.Baggage?.filter(
                          (data) => data.Code === item.Code
                        ).length
                      }
                      <Button onClick={() => handleToggleBaggageOne(item, "-")}>
                        -
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box sx={{ backgroundColor: "#D5E9F4", padding: 2, mt: 2 }}>
              <Typography
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <img src={FoodIcon} alt="" width={20} /> Meals{" "}
              </Typography>
              <Box
                sx={{
                  maxHeight: "170px",
                  overflowY: "scroll",
                  scrollBehavior: "smooth",
                }}
              >
                {MealDynamicOne?.map((item) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mt: 2,
                      }}
                    >
                      <Box>
                        <Typography variant="body2">
                          {item.Description ? item.Description : "No Data"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          ml: "auto",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body2">₹{item.Price}</Typography>
                        {/* <Button
                          variant={
                            selectedExtras.meals.some(
                              (selectedItem) => selectedItem.Code === item.Code
                            )
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          onClick={() => handleToggleMeal(item)}
                        >
                          {selectedExtras.meals.some(
                            (selectedItem) => selectedItem.Code === item.Code
                          )
                            ? "Remove"
                            : "Add"}
                        </Button> */}
                        <Button onClick={() => handleToggleMealOne(item, "+")}>
                          +
                        </Button>
                        {
                          passengerData[0]?.MealDynamic?.filter(
                            (data) => data.Code === item.Code
                          ).length
                        }
                        <Button onClick={() => handleToggleMealOne(item, "-")}>
                          -
                        </Button>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box sx={{ backgroundColor: "#D5E9F4", padding: 2, mt: 2 }}>
              <Typography>Baggage</Typography>
              <Box
                sx={{
                  maxHeight: "170px",
                  overflowY: "scroll",
                  scrollBehavior: "smooth",
                }}
              >
                {BaggageTwo &&
                  BaggageTwo?.map((item) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          mt: 2,
                        }}
                      >
                        <Box>
                          <Typography
                            variant="caption"
                            sx={{
                              ml: 1,
                              background: "#B9D7F166",
                              padding: "2px 2px",
                            }}
                          >
                            {item.Weight} kg
                          </Typography>
                        </Box>
                        {/* <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="caption">
                          {item.Origin} - {item.Destination}
                        </Typography>
                      </Box> */}
                        <Box
                          sx={{
                            padding: "5px 10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="caption" sx={{ ml: 1 }}>
                            {item.AirlineCode} - {item.FlightNumber}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            ml: "auto",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body2">₹{item.Price}</Typography>
                          {/* <Button
                          variant={
                            selectedExtras.baggage.some(
                              (selectedItem) => selectedItem.Code === item.Code
                            )
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          onClick={() => handleToggleBaggage(item)}
                        >
                          {selectedExtras.baggage.some(
                            (selectedItem) => selectedItem.Code === item.Code
                          )
                            ? "Remove"
                            : "Add"}
                        </Button> */}
                        </Box>
                        <Button
                          onClick={() => handleToggleBaggageTwo(item, "+")}
                        >
                          +
                        </Button>
                        {
                          passengerDataTwo[0]?.Baggage?.filter(
                            (data) => data.Code === item.Code
                          ).length
                        }
                        <Button
                          onClick={() => handleToggleBaggageTwo(item, "-")}
                        >
                          -
                        </Button>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
            <Box sx={{ backgroundColor: "#D5E9F4", padding: 2, mt: 2 }}>
              <Typography
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <img src={FoodIcon} alt="" width={20} /> Meals{" "}
              </Typography>
              <Box
                sx={{
                  maxHeight: "170px",
                  overflowY: "scroll",
                  scrollBehavior: "smooth",
                }}
              >
                {MealDynamicTwo?.map((item) => {
                  {
                    console.log(item, "item");
                  }
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mt: 2,
                      }}
                    >
                      <Box>
                        <Typography variant="body2">
                          {item.Description ? item.Description : "No Data"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          ml: "auto",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body2">₹{item.Price}</Typography>
                        {/* <Button
                          variant={
                            selectedExtras.meals.some(
                              (selectedItem) => selectedItem.Code === item.Code
                            )
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          onClick={() => handleToggleMeal(item)}
                        >
                          {selectedExtras.meals.some(
                            (selectedItem) => selectedItem.Code === item.Code
                          )
                            ? "Remove"
                            : "Add"}
                        </Button> */}
                        <Button onClick={() => handleToggleMealTwo(item, "+")}>
                          +
                        </Button>
                        {
                          passengerDataTwo[0]?.MealDynamic?.filter(
                            (data) => data.Code === item.Code
                          ).length
                        }
                        <Button onClick={() => handleToggleMealTwo(item, "-")}>
                          -
                        </Button>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <Box sx={{ backgroundColor: "#D5E9F4", padding: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "700" }}>
                  TICKET DETAILS
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    color: "#005C9D",
                    cursor: "pointer",
                  }}
                >
                  {/* View Details{" "} */}
                  {/* <KeyboardArrowDownIcon sx={{ color: "#222222" }} />{" "} */}
                </Typography>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                  px: 2,
                }}
              >
                {moment(flightData?.DepTime).format("dddd, DD MMM YYYY")}
                <img src={arioPlan} alt="" />
                <Typography>
                  {flightData?.Origin.AirportCode}{" "}
                  {moment(flightData?.DepTime).format("h:mm A")}
                </Typography>
                <Typography>{flightData?.Duration} m</Typography>
                <Typography>
                  {flightData?.Destination.AirportCode}{" "}
                  {moment(flightData?.ArrTime).format("h:mm A")}
                </Typography>
              </Box> */}
              {flightDetailsData.map((item, index) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 2,
                    px: 2,
                  }}
                >
                  {moment(item?.DepTime).format("dddd, DD MMM YYYY")}
                  <img src={arioPlan} alt="" />
                  <Typography>
                    {item?.Origin.AirportCode}{" "}
                    {moment(item?.DepTime).format("h:mm A")}
                  </Typography>
                  <Typography>{item?.Duration} m</Typography>
                  <Typography>
                    {item?.Destination.AirportCode}{" "}
                    {moment(item?.ArrTime).format("h:mm A")}
                  </Typography>
                </Box>
              ))}
              {flightDetailsDataTwo.map((item, index) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 2,
                    px: 2,
                  }}
                >
                  {moment(item?.DepTime).format("dddd, DD MMM YYYY")}
                  <img src={arioPlan} alt="" />
                  <Typography>
                    {item?.Origin.AirportCode}{" "}
                    {moment(item?.DepTime).format("h:mm A")}
                  </Typography>
                  <Typography>{item?.Duration} m</Typography>
                  <Typography>
                    {item?.Destination.AirportCode}{" "}
                    {moment(item?.ArrTime).format("h:mm A")}
                  </Typography>
                </Box>
              ))}
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                  px: 2,
                }}
              >
                {moment(flightDataTwo?.DepTime).format("dddd, DD MMM YYYY")}
                <img src={arioPlan} alt="" />
                <Typography>
                  {flightDataTwo?.Origin.AirportCode}{" "}
                  {moment(flightDataTwo?.DepTime).format("h:mm A")}
                </Typography>
                <Typography>{flightDataTwo?.Duration} m</Typography>
                <Typography>
                  {flightDataTwo?.Destination.AirportCode}{" "}
                  {moment(flightDataTwo?.ArrTime).format("h:mm A")}
                </Typography>
              </Box> */}
            </Box>

            <Box sx={{ mt: 2, backgroundColor: "#D5E9F4" }}>
              <Typography sx={{ fontWeight: "700", px: 2, py: 2 }}>
                TRAVELLER DETAILS
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#D5E9F4",
                  padding: 2,
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  maxWidth: "100%",
                  flexWrap: "wrap",
                }}
              >
                {passengerData.map((passenger, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: "#B9D7F166",
                      px: 2,
                      py: 1,
                      minWidth: "300px",
                    }}
                  >
                    <Typography>
                      {`${passenger.Title} ${passenger.FirstName} ${passenger.LastName}`}
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Typography>{passenger.Email}, </Typography>
                      <Typography>{passenger.ContactNo}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box sx={{ backgroundColor: "#D5E9F4", padding: 2, mt: 2 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontWeight: "700",
                  borderBottom: "1px solid #03081B",
                  paddingBottom: 1,
                }}
              >
                <img src={seatIcon} alt="" /> Seats
              </Typography>
              {/* <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "end",
                  borderBottom: "1px solid #0F35BD",
                  width: "fit-content",
                  paddingBottom: 1,
                  mt: 2,
                }}
              >
                <img src={arioPlan} alt="" />
                <Typography>
                  {flightData?.Origin.AirportCode} -{" "}
                  {flightData?.Destination.AirportCode},{" "}
                  {flightData?.Airline.AirlineCode}-
                  {flightData?.Airline.FlightNumber}
                </Typography>
                <Typography>
                  {flightDataTwo?.Origin.AirportCode} -{" "}
                  {flightDataTwo?.Destination.AirportCode},{" "}
                  {flightDataTwo?.Airline.AirlineCode}-
                  {flightDataTwo?.Airline.FlightNumber}
                </Typography>
              </Box> */}
              {/* <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  {seatviewData?.map((item) => (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "end",
                        borderBottom: "1px solid #0F35BD",
                        width: "fit-content",
                        paddingBottom: 1,
                        mt: 2,
                      }}
                    >
                      <img src={arioPlan} alt="" />
                      <Typography>
                        {item.FromAirportCode} - {item.ToAirportCode},{" "}
                        {item.AirlineCode}-{item.AirlineNumber}
                      </Typography>
                    </Box>
                  ))}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  {seatviewDataTwo?.map((item) => (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "end",
                        borderBottom: "1px solid #0F35BD",
                        width: "fit-content",
                        paddingBottom: 1,
                        mt: 2,
                      }}
                    >
                      <img src={arioPlan} alt="" />
                      <Typography>
                        {item.FromAirportCode} - {item.ToAirportCode},{" "}
                        {item.AirlineCode}-{item.AirlineNumber}
                      </Typography>
                    </Box>
                  ))}
                </Grid>
              </Grid> */}

              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  {seatviewData?.map((seatview, planeIndex) => (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          alignItems: "end",
                          borderBottom: "1px solid #0F35BD",
                          width: "fit-content",
                          paddingBottom: 1,
                          mt: 2,
                        }}
                      >
                        <img src={arioPlan} alt="" />
                        <Typography>
                          {seatview.FromAirportCode} - {seatview.ToAirportCode},{" "}
                          {seatview.AirlineCode}-{seatview.AirlineNumber}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          border: "1px solid #000",
                          background: "#FFF",
                          padding: 2,
                          mt: 2,
                        }}
                      >
                        <Box
                          sx={{
                            width: "min(100% - 0px, 90%)",
                            mx: "auto",
                            my: 2,
                          }}
                        >
                          <Box
                            sx={{
                              textAlign: "center",
                              borderBottom: "1px solid #000",
                            }}
                          >
                            <img src={aeroplanHead} alt="" width={"100%"} />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mx: "40px",
                              mt: 1,
                            }}
                          >
                            <img src={ManWomanIcon} alt="" />
                            <img src={teaIcon} alt="" />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mx: "40px",
                              my: 2,
                            }}
                          >
                            <Box
                              sx={{
                                borderLeft: "1px solid #000",
                                display: "flex",
                                gap: 1,
                              }}
                            >
                              <ArrowLeftIcon />
                              <Typography color="error">Exit</Typography>
                            </Box>
                            <Box
                              sx={{
                                borderRight: "1px solid #000",
                                display: "flex",
                                gap: 1,
                              }}
                            >
                              <Typography color="error">Exit</Typography>
                              <ArrowRightIcon />
                            </Box>
                          </Box>
                          <Box>
                            <table
                              id="seatsBlock"
                              border="0"
                              width={"100%"}
                              style={{ borderSpacing: "10px" }}
                            >
                              <tr>
                                <td></td>
                                {Object.keys(seatview.Seats).length > 0 &&
                                  Object.keys(
                                    seatview.Seats[
                                      Object.keys(seatview.Seats)[0]
                                    ]
                                  ).map((columnKey, index) => (
                                    <React.Fragment key={index}>
                                      {index === 3 && <td>&nbsp;</td>}
                                      <td>{String.fromCharCode(65 + index)}</td>
                                    </React.Fragment>
                                  ))}
                              </tr>
                              {Object.keys(seatview.Seats).map(
                                (rowKey, rowIndex) => (
                                  <tr key={rowIndex}>
                                    <td>{rowKey.slice(3)}</td>
                                    {Object.keys(seatview.Seats[rowKey]).map(
                                      (columnKey, columnIndex) => (
                                        <React.Fragment key={columnIndex}>
                                          {columnIndex === 3 && <td>&nbsp;</td>}
                                          <td>
                                            <CustomSeat
                                              seatId={
                                                seatview.Seats[rowKey][
                                                  columnKey
                                                ]
                                              }
                                              onSelectSeat={handleSelectSeat}
                                              rowIndex={rowIndex}
                                              passenger={passengerData}
                                              planeIndex={planeIndex}
                                            />
                                          </td>
                                        </React.Fragment>
                                      )
                                    )}
                                  </tr>
                                )
                              )}
                            </table>
                          </Box>
                          <Box sx={{ textAlign: "center" }}>
                            <img src={arioPlanBottom} alt="" />
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ))}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  {seatviewDataTwo?.map((seatview, planeIndex) => (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          alignItems: "end",
                          borderBottom: "1px solid #0F35BD",
                          width: "fit-content",
                          paddingBottom: 1,
                          mt: 2,
                        }}
                      >
                        <img src={arioPlan} alt="" />
                        <Typography>
                          {seatview.FromAirportCode} - {seatview.ToAirportCode},{" "}
                          {seatview.AirlineCode}-{seatview.AirlineNumber}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          border: "1px solid #000",
                          background: "#FFF",
                          padding: 2,
                          mt: 2,
                        }}
                      >
                        <Box
                          sx={{
                            width: "min(100% - 0px, 90%)",
                            mx: "auto",
                            my: 2,
                          }}
                        >
                          <Box
                            sx={{
                              textAlign: "center",
                              borderBottom: "1px solid #000",
                            }}
                          >
                            <img src={aeroplanHead} alt="" width={"100%"} />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mx: "40px",
                              mt: 1,
                            }}
                          >
                            <img src={ManWomanIcon} alt="" />
                            <img src={teaIcon} alt="" />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mx: "40px",
                              my: 2,
                            }}
                          >
                            <Box
                              sx={{
                                borderLeft: "1px solid #000",
                                display: "flex",
                                gap: 1,
                              }}
                            >
                              <ArrowLeftIcon />
                              <Typography color="error">Exit</Typography>
                            </Box>
                            <Box
                              sx={{
                                borderRight: "1px solid #000",
                                display: "flex",
                                gap: 1,
                              }}
                            >
                              <Typography color="error">Exit</Typography>
                              <ArrowRightIcon />
                            </Box>
                          </Box>
                          <Box>
                            <table
                              id="seatsBlock"
                              border="0"
                              width={"100%"}
                              style={{ borderSpacing: "10px" }}
                            >
                              <tr>
                                <td></td>
                                {Object.keys(seatview.Seats).length > 0 &&
                                  Object.keys(
                                    seatview.Seats[
                                      Object.keys(seatview.Seats)[0]
                                    ]
                                  ).map((columnKey, index) => (
                                    <React.Fragment key={index}>
                                      {index === 3 && <td>&nbsp;</td>}
                                      <td>{String.fromCharCode(65 + index)}</td>
                                    </React.Fragment>
                                  ))}
                              </tr>
                              {Object.keys(seatview.Seats).map(
                                (rowKey, rowIndex) => (
                                  <tr key={rowIndex}>
                                    <td>{rowKey.slice(3)}</td>
                                    {Object.keys(seatview.Seats[rowKey]).map(
                                      (columnKey, columnIndex) => (
                                        <React.Fragment key={columnIndex}>
                                          {columnIndex === 3 && <td>&nbsp;</td>}
                                          <td>
                                            <CustomSeat
                                              seatId={
                                                seatview.Seats[rowKey][
                                                  columnKey
                                                ]
                                              }
                                              onSelectSeat={handleSelectSeatTwo}
                                              rowIndex={rowIndex}
                                              passenger={passengerDataTwo}
                                              planeIndex={planeIndex}
                                            />
                                          </td>
                                        </React.Fragment>
                                      )
                                    )}
                                  </tr>
                                )
                              )}
                            </table>
                          </Box>
                          <Box sx={{ textAlign: "center" }}>
                            <img src={arioPlanBottom} alt="" />
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ))}
                </Grid>
                <Grid>
                  {/* <Grid item lg={6} md={6} sm={12} xs={12}> */}
                  <Box sx={{ mt: 2 }}>
                    {passengerData.map((item, index) => (
                      <Typography key={index}>
                        {item.Seat?.length ? `Passenger${index + 1}` : ""}
                        {item.Seat?.map((seat) => (
                          <span key={`${seat.PlaneIndex}-${seat.SeatNumber}`}>
                            {" - " + seat.SeatNumber}{" "}
                          </span>
                        ))}
                      </Typography>
                    ))}
                    {passengerDataTwo.map((item, index) => (
                      <Typography key={index}>
                        {item.Seat?.length ? `Passenger${index + 1}` : ""}
                        {item.Seat?.map((seat) => (
                          <span key={`${seat.PlaneIndex}-${seat.SeatNumber}`}>
                            {" - " + seat.SeatNumber}{" "}
                          </span>
                        ))}
                      </Typography>
                    ))}
                    {/* <Typography>
                      ADULT 1 <span> - 18B </span>
                    </Typography> */}
                    {/* <Typography sx={{ mt: 1 }}>
                      TAP TO FILTER SEATS BY PRICE{" "}
                    </Typography> */}
                    {/* <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                      <Typography
                        sx={{
                          backgroundColor: "#B9D7F166",
                          padding: "1px 5px",
                          borderRadius: "5px",
                        }}
                      >
                        ₹ 150
                      </Typography>
                      <Typography>Unavailable</Typography>
                    </Box> */}
                    {/* <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                      <Typography
                        sx={{
                          backgroundColor: "#B9D7F166",
                          padding: "1px 5px",
                          borderRadius: "5px",
                        }}
                      >
                        ₹ 150
                      </Typography>
                    </Box> */}
                    {/* <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                      <Typography
                        sx={{
                          backgroundColor: "#B9D7F166",
                          padding: "1px 5px",
                          borderRadius: "5px",
                        }}
                      >
                        ₹ 150
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                      <Typography
                        sx={{
                          backgroundColor: "#B9D7F166",
                          padding: "1px 5px",
                          borderRadius: "5px",
                        }}
                      >
                        ₹ 150
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                      <Typography
                        sx={{
                          backgroundColor: "#B9D7F166",
                          padding: "1px 5px",
                          borderRadius: "5px",
                        }}
                      >
                        ____
                      </Typography>
                      <Typography>NON RECLINING</Typography>
                    </Box> */}
                  </Box>
                  {/* </Grid> */}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default FlightSeatBookingRoundTrip;
