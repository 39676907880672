import { configureStore } from "@reduxjs/toolkit";

import authReducer from "../redux/hotel/hotelslice";

import authenticationReducer from "../redux/Authentication/authenticationslice";

import busdataReducer from "../redux/bus/busslice";
import flightslice from "../redux/flight/flightslice";

export const store = configureStore({
  reducer: {
    hoteldata: authReducer,
    authenticationdata: authenticationReducer,
    busdata: busdataReducer,
    flightdata: flightslice,
  },
});

// import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
// import {
//   persistReducer,
//   persistStore,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from "redux-persist";

// import authReducer from "../redux/hotel/hotelslice";

// const persistConfig = {
//   key: "root",
//   version: 1,
//   storage,
// };

// const rootReducer = combineReducers({
//   hoteldata: authReducer,
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }),
// });

// export const persistor = persistStore(store);
