import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import TravelerDetailsSugestBox from "../../TravelerDetailsSugestBox/TravelerDetailsSugestBox";

const MultiCityTrip = () => {
  const [travelerDetails, setTravelerDetails] = useState("");
  const [travelerInfo, setTravelerInfo] = useState({
    adults: 1,
    children: 0,
    infants: 0,
  });

  const handeltravelerDetails = () => {
    setTravelerDetails(!travelerDetails);
  };

  return (
    <Grid container spacing={0} sx={{ sm: { spacing: 2 } }}>
      <Grid item lg={8} xs={12}>
        <Box>
          <Typography sx={{ color: "#FFF" }}>From</Typography>
          <Box className="multi-select-box">
            <Typography sx={{ color: "#FFF" }}>
              Ahmedabad to New Delhi
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={2} sm={3} xs={6}>
        <Box sx={{ position: "relative" }}>
          <Typography sx={{ color: "#FFF" }}>Passengers</Typography>
          <Box onClick={handeltravelerDetails} className="traveler-details-box">
            <Typography sx={{ fontSize: "17px", fontWeight: "400" }}>
              1 Traveller
            </Typography>
          </Box>
          {travelerDetails ? (
            <TravelerDetailsSugestBox
              travelerDetails={travelerDetails}
              setTravelerDetails={setTravelerDetails}
              setTravelerInfo={setTravelerInfo}
              travelerInfo={travelerInfo}
            />
          ) : null}
        </Box>
      </Grid>
      <Grid item lg={2} sm={3} xs={6}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            ml: { sm: 2 },
          }}
        >
          <button className="search-btn">Update Search</button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MultiCityTrip;
