import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import LocationbgIcon from "../../assets/img/icon/location.png";
import EmailIcon from "../../assets/img/icon/email.png";
import CallUsIcon from "../../assets/img/icon/call-us.png";
import SnapchatIcon from "../../assets/img/icon/sanpchat-icon.png";
import FaceBookIcon from "../../assets/img/icon/facebook-icon-black.png";
import InstagramIcon from "../../assets/img/icon/instagram-icon.png";
import TwitterIcon from "../../assets/img/icon/twitter-icon.png";

import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="footer-section">
      <Container maxWidth="xl">
        <Box className="footer-info-section">
          <Grid container>
            <Grid item lg={7} md={10} sm={12} xs={12} ml="auto">
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="footer-info-box">
                    <Box>
                      {/* <LocationbgIcon/> */}
                      <img src={LocationbgIcon} alt="" width="50px" />
                    </Box>
                    <Box>
                      <Typography className="head-title">Address</Typography>
                      <Typography className="info-text">
                        D-704, TITANIUM SQUARE , THALTEJ, AHMEDABAD, GUJARAT -
                        380054
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="footer-info-box">
                    <Box>
                      {/* <EmailIcon /> */}
                      <img src={EmailIcon} alt="" width="50px" />
                    </Box>
                    <Box>
                      <Typography className="head-title">Mail us </Typography>
                      <Typography className="info-text" sx={{ mt: 5 }}>
                        makeutrip@gmail.com
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="footer-info-box">
                    <Box>
                      {/* <CallUsIcon /> */}
                      <img src={CallUsIcon} alt="" width="50px" />
                    </Box>
                    <Box>
                      <Typography className="head-title">Call us</Typography>
                      <Typography className="info-text" sx={{ mt: 5 }}>
                        9100000000
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mr: "auto", display: "flex", justifyContent: "end" }}>
          <Link style={{ marginRight: "5px", display: "inline-block" }}>
            <img src={SnapchatIcon} alt="" width={20} />
          </Link>
          <Link style={{ marginRight: "5px", display: "inline-block" }}>
            <img src={TwitterIcon} alt="" width={20} />
          </Link>
          <Link style={{ marginRight: "5px", display: "inline-block" }}>
            <img src={FaceBookIcon} alt="" width={20} />
          </Link>
          <Link style={{ marginRight: "5px", display: "inline-block" }}>
            <img src={InstagramIcon} alt="" width={20} />
          </Link>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
