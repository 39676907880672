import {
  Box,
  Breadcrumbs,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Card,
  CardActions,
  CardContent,
  Popover,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import dayjs from "dayjs";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

import { useDispatch, useSelector } from "react-redux";
import {
  getHotelCountryDetails,
  hotelSearch,
} from "../../redux/hotel/hotelapi";
import { setHomePageSearchData } from "../../redux/hotel/hotelslice";
function handleClick(event) {
  event.preventDefault();
  // console.info("You clicked a breadcrumb.");
}
const HotelDateselectBox = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchData = useSelector(
    (state) => state.hoteldata.searchData.dataToSendInSearch
  );

  const [showRoomDeatilsBox, SetshowRoomDeatilsBox] = useState("");
  const [citystr, setCityStr] = useState("");
  const [value, setValue] = useState(dayjs(searchData?.value));
  const [value2, setValue2] = useState(dayjs(searchData?.value2));
  const data = useSelector((state) => state.hoteldata);
  const [city, setCity] = useState(searchData?.SearchCity);
  const [dropdownfill, setDropdownfill] = useState([]);
  const [isLocationshow, setLocationshow] = useState(false);

  const breadcrumbs2 = [
    <Link
      underline="hover"
      key="1"
      color="#FFFFFF"
      href="/"
      // onClick={handleClick}
    >
      <span style={{ color: "#FFF" }}>Home</span>
    </Link>,
    <Link
      underline="hover"
      key="2"
      href="/material-ui/getting-started/installation/"
      // onClick={handleClick}
    >
      <span style={{ color: "#FFF" }}>
        Hotel in {searchData?.SearchCity.destination}
      </span>
    </Link>,
  ];
  const handleRoomBox = () => {
    SetshowRoomDeatilsBox(!showRoomDeatilsBox);
  };

  const [citystrReady, setCitystrReady] = useState(false);
  useEffect(() => {
    let timeoutId;
    if (citystrReady) {
      timeoutId = setTimeout(() => {
        dispatch(getHotelCountryDetails(citystr));
        setCitystrReady(false); // Reset to false after API call
      }, 1000); // Adjust the delay time as needed
    }
    return () => clearTimeout(timeoutId); // Cleanup on component unmount or re-render
  }, [dispatch, citystr, citystrReady]);

  const handleChange = (e) => {
    const newCitystr = e.target.value;
    setCityStr(e.target.value);
    if (newCitystr.length > 0) {
      setCitystrReady(true);
    }
  };

  useEffect(() => {
    if (data && data.hotelDetails) {
      setDropdownfill(data.hotelDetails.data);
    }
  }, [data]);
  const handleSelectCity = (destination) => {
    setCity(destination);
    setLocationshow(false);
  };

  // for room selection

  const [NoOfRooms, setNoOfRooms] = useState(
    searchData?.NoOfRooms ? searchData.NoOfRooms : 1
  );
  // const [roomGuests, setRoomGuests] = useState([
  //   { NoOfAdults: 1, NoOfChild: 0, ChildAge: [] },
  // ]);

  const [roomGuests, setRoomGuests] = useState(
    searchData?.RoomGuests ?? [{ NoOfAdults: 1, NoOfChild: 0, ChildAge: [] }]
  );

  const handleChangeRoom = (event) => {
    const numberOfRooms = parseInt(event.target.value, 10);
    setNoOfRooms(numberOfRooms);

    const updatedRoomGuests = [];
    for (let i = 0; i < numberOfRooms; i++) {
      if (roomGuests[i]) {
        updatedRoomGuests.push(roomGuests[i]);
      } else {
        updatedRoomGuests.push({ NoOfAdults: 1, NoOfChild: 0, ChildAge: [] });
      }
    }
    setRoomGuests(updatedRoomGuests);
  };

  const handleAdultChange = (event, roomIndex) => {
    const newRoomGuests = roomGuests.map((guest, index) =>
      index === roomIndex
        ? { ...guest, NoOfAdults: parseInt(event.target.value, 10) }
        : guest
    );
    setRoomGuests(newRoomGuests);
  };

  const handleChildChange = (event, roomIndex) => {
    const newRoomGuests = roomGuests.map((guest, index) =>
      index === roomIndex
        ? {
            ...guest,
            NoOfChild: parseInt(event.target.value, 10),
            ChildAge: Array(parseInt(event.target.value, 10)).fill(""),
          }
        : guest
    );
    setRoomGuests(newRoomGuests);
  };
  const [errors, setErrors] = useState({});
  const validate = () => {
    let tempErrors = {};
    // Child age validation
    roomGuests.forEach((room, index) => {
      if (room.NoOfChild > 0) {
        room.ChildAge.forEach((age, childIndex) => {
          if (age === "" || age < 1 || age > 17) {
            const ageErrorKey = `ChildAge_${index}_${childIndex}`;
            tempErrors[ageErrorKey] = `Child ${childIndex + 1} in Room ${
              index + 1
            } must have an age between 1 and 17.`;
          }
        });
      }
    });

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChildAgeChange = (event, roomIndex, childIndex) => {
    const newRoomGuests = roomGuests.map((guest, index) =>
      index === roomIndex
        ? {
            ...guest,
            ChildAge: guest.ChildAge.map((age, idx) =>
              idx === childIndex ? parseInt(event.target.value, 10) : age
            ),
          }
        : guest
    );
    setRoomGuests(newRoomGuests);
  };

  const [finalData, setFinalData] = useState({
    NoOfRooms: 1,
    RoomGuests: [{ NoOfAdults: 1, NoOfChild: 0, ChildAge: [] }],
  });

  const handleSubmit = () => {
    if (validate()) {
      const finalData2 = {
        NoOfRooms: NoOfRooms,
        RoomGuests: roomGuests.map((room) => ({
          NoOfAdults: room.NoOfAdults,
          NoOfChild: room.NoOfChild,
          ChildAge: room.ChildAge,
        })),
      };
      setFinalData(finalData2);
      handleRoomBox();
    }
    // Here you would typically make an API call to submit the finalData
  };

  const handleSearch = () => {
    const day = value.$D;
    const month = value.$M + 1; // Subtract 1 to make it valid (assuming it's zero-based)
    const year = value.$y;
    // Format the date as "DD/MM/YYYY"
    const formattedDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year}`;

    const day2 = value2.$D;
    const month2 = value2.$M + 1; // Subtract 1 to make it valid (assuming it's zero-based)
    const year2 = value2.$y;
    // Format the date as "DD/MM/YYYY"
    const formattedDate2 = `${day2.toString().padStart(2, "0")}/${month2
      .toString()
      .padStart(2, "0")}/${year2}`;

    // Parse date strings into Date objects
    const startDate = new Date(
      parseInt(formattedDate.split("/")[2]), // Year
      parseInt(formattedDate.split("/")[1]) - 1, // Month (subtract 1 for 0-based index)
      parseInt(formattedDate.split("/")[0]) // Day
    );

    const endDate = new Date(
      parseInt(formattedDate2.split("/")[2]), // Year
      parseInt(formattedDate2.split("/")[1]) - 1, // Month (subtract 1 for 0-based index)
      parseInt(formattedDate2.split("/")[0]) // Day
    );

    // Calculate the number of nights
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const nights = Math.floor((endDate - startDate) / oneDayInMilliseconds);

    // return true;
    const data = {
      BookingMode: "5",
      CheckInDate: formattedDate,
      // CheckInDate: "30/04/2024",
      NoOfNights: nights,
      CountryCode: city.country_code,
      CityId: city.cityid,
      ResultCount: null,
      PreferredCurrency: "INR",
      GuestNationality: "IN",
      // NoOfRooms: Room,
      // NoOfRooms: 1,
      // RoomGuests: [
      //   {
      //     NoOfAdults: Adults,
      //     // NoOfChild: Children,
      //     NoOfChild: 1,
      //     ChildAge: [8],
      //   },
      // ],
      PreferredHotel: "",
      MaxRating: 5,
      MinRating: 0,
      ReviewScore: null,
      IsNearBySearchAllowed: false,
    };

    let dataToSendInAPI = { ...data, ...finalData };

    const SearchData = {
      CheckInDate: formattedDate,
      CheckOutDate: formattedDate2,
      SearchCity: city,
      // Room: Room,
      value: value,
      value2: value2,
      // Adults: Adults,
      // Children: Children,
    };

    let dataToSendInSearch = { ...SearchData, ...finalData };

    const DataToSendInSearchAction = {
      dataToSendInSearch,
      dataToSendInAPI,
    };

    dispatch(setHomePageSearchData(DataToSendInSearchAction));
    dispatch(hotelSearch({ data: dataToSendInAPI, page: 1 }));
    navigate("/hotel-booking-page");
    window.scrollTo(0, 0);
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // for room selection

  return (
    <Box className="hotel-booking-sectel-box">
      <Box sx={{ paddingY: "20px" }}>
        <Stack spacing={2}>
          <Breadcrumbs
            separator={
              <NavigateNextIcon fontSize="large" sx={{ color: "#FFF" }} />
            }
            aria-label="breadcrumb"
          >
            {breadcrumbs2}
          </Breadcrumbs>
        </Stack>
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={2} sm={6} xs={12}>
          <Box className="list-box" sx={{ position: "relative" }}>
            <Typography className="list-title">City, Area Property</Typography>
            <Box
              className="location-box"
              onClick={() => setLocationshow(!isLocationshow)}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#FFF" }}>
                  {" "}
                  {city?.destination}{" "}
                </Typography>
                <KeyboardArrowDownIcon sx={{ color: "#FFF" }} />
              </Box>
            </Box>
            <Box>
              {isLocationshow && (
                <Box className="location-suggest-box">
                  <Box sx={{ display: "flex", alignItems: "center", px: 1 }}>
                    <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className="formcontroal-box"
                      // onClick={(e) => handleSelectCity(e)}
                    >
                      <TextField
                        type="text"
                        placeholder="Where do you want to stay?"
                        sx={{ "& fieldset": { border: "none" } }}
                        onChange={(e) => handleChange(e)}
                        autoFocus
                      />
                    </FormControl>
                    <Typography
                      variant="caption"
                      sx={{
                        cursor: "pointer",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                    >
                      CLEAR
                    </Typography>
                  </Box>
                  <Box className="location-suggest-list-box">
                    <Box>
                      {/* <Typography variant="body2">POPULAR</Typography> */}
                      <Box>
                        <List>
                          {dropdownfill?.map((city) => (
                            <ListItem disablePadding key={city.id}>
                              <ListItemButton
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => handleSelectCity(city)}
                              >
                                <ListItemIcon>
                                  <FmdGoodOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={city.destination} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}

              <FormControl
                fullWidth
                variant="standard"
                className="select-box-form-control"
                sx={{ "& fieldset": { border: "none" } }}
              ></FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={2} sm={6} xs={12}>
          <Box className="list-box">
            <Typography
              className="list-title"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Check in <KeyboardArrowDownIcon sx={{ color: "#FFF" }} />{" "}
            </Typography>
            <Box className="date-box">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    disablePast
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                    renderInput={(props) => (
                      <TextField {...props} variant="standard" />
                    )}
                    format=" DD MMM YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={2} sm={6} xs={12}>
          <Box className="list-box">
            <Typography
              className="list-title"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Check Out <KeyboardArrowDownIcon sx={{ color: "#FFF" }} />{" "}
            </Typography>
            <Box className="date-box">
              <LocalizationProvider dateAdapter={AdapterDayjs} sx={{}}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    disablePast
                    fullWidth
                    value={value2}
                    onChange={(newValue) => setValue2(newValue)}
                    renderInput={(props) => (
                      <TextField {...props} variant="standard" />
                    )}
                    format=" DD MMM YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={2} sm={6} xs={12}>
          <Box className="list-box">
            <Typography
              className="list-title"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Rooms & Guests
            </Typography>
            <Box sx={{ position: "relative" }}>
              <Box className="room-and-guest-box">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    justifyContent: "space-between",
                  }}
                  onClick={handleRoomBox}
                >
                  <Typography className="room-date-text" sx={{ color: "#FFF" }}>
                    {NoOfRooms} <span className="text-room">Room</span>
                  </Typography>
                  <Typography
                    className="room-date-text"
                    ml={2}
                    sx={{ color: "#FFF" }}
                  >
                    {/* {roomGuests.reduce(
                      (acc, group) => acc + group.NoOfAdults,
                      0
                    )} */}
                    {roomGuests.reduce((accumulator, currentGroup) => {
                      // Convert NoOfAdults from string to integer
                      const noOfAdults = parseInt(currentGroup.NoOfAdults, 10);
                      // Accumulate the total
                      return accumulator + noOfAdults;
                    }, 0)}
                    <span className="text-room">Adults</span>
                  </Typography>
                  <Typography>
                    <KeyboardArrowDownIcon
                      sx={{ color: "#FFF" }}
                      // aria-describedby={id}
                      // variant="contained"
                      // onClick={handleClick}
                    />
                  </Typography>
                </Box>
              </Box>

              {showRoomDeatilsBox && (
                <Box className="room-guest-sugestion-section">
                  <Card variant="outlined" sx={{ border: "none" }}>
                    <CardContent>
                      <FormControl
                        fullWidth
                        sx={{ mb: 4, minWidth: 120, backgroundColor: "#FFF" }}
                      >
                        <Select
                          value={NoOfRooms}
                          onChange={handleChangeRoom}
                          displayEmpty
                        >
                          {[1, 2, 3, 4].map((roomNumber) => (
                            <MenuItem key={roomNumber} value={roomNumber}>
                              {roomNumber} {roomNumber > 1 ? "Rooms" : "Room"}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {roomGuests.map((room, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                          <Typography variant="h6">Room {index + 1}</Typography>
                          <FormControl
                            variant="outlined"
                            sx={{ mr: 2, minWidth: 100 }}
                          >
                            <Select
                              value={room.NoOfAdults}
                              onChange={(e) => handleAdultChange(e, index)}
                            >
                              {[1, 2, 3, 4].map((adultNumber) => (
                                <MenuItem key={adultNumber} value={adultNumber}>
                                  {adultNumber}{" "}
                                  {adultNumber > 1 ? "Adults" : "Adult"}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            variant="outlined"
                            sx={{ minWidth: 100, mb: 2 }}
                          >
                            <Select
                              value={room.NoOfChild}
                              onChange={(e) => handleChildChange(e, index)}
                            >
                              {[0, 1, 2, 3].map((childNumber) => (
                                <MenuItem key={childNumber} value={childNumber}>
                                  {childNumber}{" "}
                                  {childNumber !== 1 ? "Children" : "Child"}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {room.ChildAge.map((age, childIndex) => (
                            <TextField
                              key={childIndex}
                              label={`Child ${childIndex + 1} Age`}
                              type="number"
                              value={age}
                              onChange={(e) =>
                                handleChildAgeChange(e, index, childIndex)
                              }
                              sx={{ ml: 2, mb: 1, width: 100 }}
                              inputProps={{ min: "1", max: "17" }}
                              error={
                                !!errors[`ChildAge_${index}_${childIndex}`]
                              }
                              helperText={
                                errors[`ChildAge_${index}_${childIndex}`] || ""
                              }
                            />
                          ))}
                        </Box>
                      ))}
                    </CardContent>
                    <CardActions>
                      <Button
                        style={{
                          cursor: "pointer",
                        }}
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ mt: 2 }}
                      >
                        Submit
                      </Button>
                    </CardActions>
                  </Card>
                  {Object.keys(errors).length > 0 && (
                    <Typography color="error" variant="caption">
                      Error
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Box className="btn-box">
            <button
              className="update-btn"
              onClick={handleSearch}
              style={{
                cursor: "pointer",
              }}
            >
              Update Search
            </button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HotelDateselectBox;
