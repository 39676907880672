import { Box, Card, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as Navigationarrow } from "../assets/img/icon/navigationarrow.svg";
import DestinationCardImg1 from "../assets/img/destinationcard1.pn.png";
import DestinationCardImg2 from "../assets/img/destinationcard1.png";
import DestinationCardImg3 from "../assets/img/destinationcard3.png";
import DestinationCardImg4 from "../assets/img/destinationcard4.png";
const TopDestinationSection = () => {
  return (
    <section>
    <Box className="top-destinations-section">
      <Container>
        <Box sx={{ mt: 2 }}>
          <Typography className="head-title-text">
            Top Destinations
          </Typography>
        </Box>
        <Box className="destination-box-section">
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Card
                className="card-box"
                sx={{ background: `url(${DestinationCardImg2})` }}
              >
                <Box className="card-content">
                  <Box className="card-head-box">
                    <Typography className="text">Rome, Italty</Typography>
                    <Typography className="text">$5,42k</Typography>
                  </Box>
                  <Box className="card-footer">
                    <Box sx={{ mr: 2 }}>
                      <Navigationarrow />
                    </Box>
                    <Typography className="text">10 Days Trip</Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Card
                className="card-box"
                sx={{ background: `url(${DestinationCardImg1})` }}
              >
                <Box className="card-content">
                  <Box className="card-head-box">
                    <Typography className="text">London, UK</Typography>
                    <Typography className="text">$4.2k</Typography>
                  </Box>
                  <Box className="card-footer">
                    <Box sx={{ mr: 2 }}>
                      <Navigationarrow />
                    </Box>
                    <Typography className="text">12 Days Trip</Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Card
                className="card-box"
                sx={{ background: `url(${DestinationCardImg3})` }}
              >
                <Box className="card-content">
                  <Box className="card-head-box">
                    <Typography className="text">Full Europe</Typography>
                    <Typography className="text">$15k</Typography>
                  </Box>
                  <Box className="card-footer">
                    <Box sx={{ mr: 2 }}>
                      <Navigationarrow />
                    </Box>
                    <Typography className="text">28 Days Trip</Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Card
                className="card-box"
                sx={{ background: `url(${DestinationCardImg4})` }}
              >
                <Box className="card-content">
                  <Box className="card-head-box">
                    <Typography className="text">London, UK</Typography>
                    <Typography className="text">$4.2k</Typography>
                  </Box>
                  <Box className="card-footer">
                    <Box sx={{ mr: 2 }}>
                      <Navigationarrow />
                    </Box>
                    <Typography className="text">12 Days Trip</Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  </section>
  )
}

export default TopDestinationSection
