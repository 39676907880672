import UserIcon from "../../assets/img/icon/user.png";
import EmailPhoneIcon from "../../assets/img/icon/email-or-phone.png";
import PasswordIcon from "../../assets/img/icon/password.png";
import GoogleIcon from "../../assets/img/icon/google-icon.png";
import FacebookIcon from "../../assets/img/icon/facebook-icon.png";

import {
  Box,
  Container,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { registerUser } from "../../redux/Authentication/authenticationapi";
import { useDispatch } from "react-redux";
const Registration = () => {
  // const [formData, setFormData] = useState({});
  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    password: "",
    confirm_password: "",
    gender: "male",
  });
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validate = () => {
    let errors = {};
    let isValid = true;

    // Username validation
    if (!formData.username.trim()) {
      errors.username = "Username is required";
      isValid = false;
    }

    // First name validation
    if (!formData.first_name.trim()) {
      errors.first_name = "First name is required";
      isValid = false;
    }

    // Last name validation
    if (!formData.last_name.trim()) {
      errors.last_name = "Last name is required";
      isValid = false;
    }

    // Email validation
    if (!formData.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    // Mobile number validation
    if (!formData.mobile_number) {
      errors.mobile_number = "Mobile number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.mobile_number)) {
      errors.mobile_number = "Mobile number is invalid";
      isValid = false;
    }

    // Password validation
    if (!formData.password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
      isValid = false;
    }

    // Confirm password validation
    if (formData.confirm_password !== formData.password) {
      errors.confirm_password = "Passwords do not match";
      isValid = false;
    } else if (!formData.confirm_password) {
      errors.confirm_password = "Confirm Password is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Navigate or perform other actions upon successful validation
      dispatch(registerUser(formData))
        .then((res) => {
          // localStorage.setItem("token", res.payload.data.access);
          // localStorage.setItem("userDetails", res.payload.data);
          navigate("/login");
        })
        .catch((err) => {
          console.log(err, "err");
        });
    } else {
      console.log("Validation failed");
    }
  };

  return (
    <Box className="auth-bg-section">
      <Container maxWidth="xl">
        <Box>
          <Box className="auth-card">
            <Box className="user-box">
              <img src={UserIcon} alt="" />
            </Box>
            <Typography className="register-text">Register</Typography>
            <Typography className="create-text">Create Your Account</Typography>
            <form onSubmit={handleSubmit}>
              <Box className="form-section">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <Box className="icon-box">
                    <img src={UserIcon} alt="" />
                  </Box>
                  <Box className="form-box">
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className="formcontroal-box"
                    >
                      <TextField
                        type="text"
                        placeholder="User Name"
                        name="username"
                        onChange={(e) => handleInputChange(e)}
                        sx={{ "& fieldset": { border: "none" } }}
                        error={!!errors.username}
                        // helperText={errors.username}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {errors.username && (
                  <Typography  color="error">{errors.username}</Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <Box className="icon-box">
                    <img src={UserIcon} alt="" />
                  </Box>
                  <Box className="form-box">
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className="formcontroal-box"
                    >
                      <TextField
                        type="text"
                        placeholder="First Name"
                        name="first_name"
                        onChange={(e) => handleInputChange(e)}
                        error={!!errors.first_name}
                        // helperText={errors.first_name}
                        sx={{ "& fieldset": { border: "none" } }}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {errors.first_name && (
                  <Typography  color="error">{errors.first_name}</Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <Box className="icon-box">
                    <img src={UserIcon} alt="" />
                  </Box>
                  <Box className="form-box">
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className="formcontroal-box"
                    >
                      <TextField
                        type="text"
                        placeholder="Last Name"
                        name="last_name"
                        error={!!errors.last_name}
                        // helperText={errors.last_name}
                        onChange={(e) => handleInputChange(e)}
                        sx={{ "& fieldset": { border: "none" } }}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {errors.last_name && (
                  <Typography  color="error">{errors.last_name}</Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <Box className="icon-box">
                    <img src={EmailPhoneIcon} alt="" />
                  </Box>
                  <Box className="form-box">
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className="formcontroal-box"
                    >
                      <TextField
                        type="email"
                        name="email"
                        placeholder="Enter Email ID"
                        error={!!errors.email}
                        // helperText={errors.email}
                        onChange={(e) => handleInputChange(e)}
                        sx={{ "& fieldset": { border: "none" } }}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {errors.email && (
                  <Typography  color="error">{errors.email}</Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <Box className="icon-box">
                    <img src={EmailPhoneIcon} alt="" />
                  </Box>
                  <Box className="form-box">
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className="formcontroal-box"
                    >
                      <TextField
                        type="number"
                        name="mobile_number"
                        placeholder="Enter Mobile Number"
                        error={!!errors.mobile_number}
                        // helperText={errors.mobile_number}
                        onChange={(e) => handleInputChange(e)}
                        sx={{ "& fieldset": { border: "none" } }}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {errors.mobile_number && (
                  <Typography  color="error">{errors.mobile_number}</Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <Box className="icon-box">
                    <img src={PasswordIcon} alt="" />
                  </Box>
                  <Box className="form-box">
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className="formcontroal-box"
                    >
                      <TextField
                        type="password"
                        placeholder="Password"
                        name="password"
                        error={!!errors.password}
                        // helperText={errors.password}
                        onChange={(e) => handleInputChange(e)}
                        sx={{ "& fieldset": { border: "none" } }}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {errors.password && (
                  <Typography  color="error">{errors.password}</Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <Box className="icon-box">
                    <img src={PasswordIcon} alt="" />
                  </Box>
                  <Box className="form-box">
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className="formcontroal-box"
                    >
                      <TextField
                        type="password"
                        name="confirm_password"
                        error={!!errors.confirm_password}
                        // helperText={errors.confirm_password}
                        onChange={(e) => handleInputChange(e)}
                        placeholder="Confirm Password"
                        sx={{ "& fieldset": { border: "none" } }}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {errors.confirm_password && (
                  <Typography  color="error">{errors.confirm_password}</Typography>
                )}
                <Box className="create-btn-box">
                  <button
                    className="btn-create"
                    type="submit"
                    // onClick={(e) => handleSubmit(e)}
                  >
                    Create Account
                  </button>
                </Box>
              </Box>
            </form>
            <Typography className="or-text">
              <span
                style={{
                  width: "50px",
                  background: "#D5E9F4",
                  marginInline: "20px",
                  display: "inline-block",
                }}
              >
                or
              </span>
            </Typography>
            <Box className="social-section">
              <Box className="google-btn-box">
                <img src={GoogleIcon} alt="" />
                <Typography className="google-text">
                  Continue With Google
                </Typography>
              </Box>
              <Box className="google-btn-box">
                <img src={FacebookIcon} alt="" />
                <Typography className="google-text">
                  Continue With Facebook
                </Typography>
              </Box>
              <Box></Box>
            </Box>
            <Typography className="login-text">
              Already have an account?
            </Typography>
            <Box className="login-btn-box">
              <button onClick={handleLogin} className="btn-login">
                Log in
              </button>
            </Box>
            <Typography className="terms-text">
              By Registering, you agree to MakeUTrip's Privacy Policy, User
              Agreement and T&Cs
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Registration;
