import { Box, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import LocationSuggestBox from "../../LocationSuggestBox/LocationSuggestBox";
const AddMoreTrip = () => {
  const dispatch = useDispatch();
  const searchData = useSelector(
    (state) => state?.hoteldata?.searchData?.dataToSendInSearch
  );
  const [value, setValue] = useState(dayjs(searchData?.value));
  const [value2, setValue2] = useState(dayjs(searchData?.value2));
  const [travelerDetails, setTravelerDetails] = useState("");
  const handeltravelerDetails = () => {
    setTravelerDetails(!travelerDetails);
  };
  const [locationBox, setLocationBox] = useState({
    isFrom: false,
    isTo: false,
  });
  const [city, setCity] = useState(
    searchData
      ? searchData?.SearchCity
      : {
          cityid: "101318",
          country: "India",
          country_code: "IN",
          created_at: "2024-01-24T05:53:18.616155",
          destination: "Bhayandar",
          state_province: "",
          state_province_code: "",
          updated_at: "2024-01-24T05:53:18.616180",
          url: "http://srv453747.hstgr.cloud:8000/hotel/hotelcitydetails/331/",
        }
  );
  const handleMultiCityTripClick = (args) => {
    if (args === 0) {
      setLocationBox({
        isFrom: true,
        isTo: false,
      });
    } else if (args === 1) {
      setLocationBox({
        isFrom: false,
        isTo: true,
      });
    }
  };
  return (
    <Grid item lg={12} md={12} xs={12} sm={12}>
      <Grid container spacing={0}>
        <Grid item lg={2} xs={0}>
          <Typography
            sx={{
              height: "100%",
              paddingTop: "25px",
              paddingRight: "25px",
              color: "#FFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            Trip 1
          </Typography>
        </Grid>
        <Grid item lg={10} xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item lg={2} sm={3} xs={6}>
              <Box>
                <Typography sx={{ color: "#FFF" }}>From</Typography>
                <Box className="location-box" sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onClick={() => handleMultiCityTripClick(0)}
                  >
                    <Typography sx={{ color: "#FFF" }}>Ahmedabad</Typography>
                    <KeyboardArrowDownIcon sx={{ color: "#FFF" }} />
                  </Box>
                  {locationBox.isFrom ? <LocationSuggestBox /> : null}
                </Box>
              </Box>
            </Grid>
            <Grid item lg={2} sm={3} xs={6}>
              <Box>
                <Typography sx={{ color: "#FFF" }}>To</Typography>
                <Box className="location-box" sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onClick={() => handleMultiCityTripClick(1)}
                  >
                    <Typography sx={{ color: "#FFF" }}>Ahmedabad</Typography>
                    <KeyboardArrowDownIcon sx={{ color: "#FFF" }} />
                  </Box>
                  {locationBox.isTo ? <LocationSuggestBox /> : null}
                </Box>
              </Box>
            </Grid>
            <Grid item lg={2} sm={3} xs={6}>
              <Box className="list-box">
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: "#FFF",
                  }}
                >
                  Depart
                </Typography>
                <Box className="date-box" sx={{ widht: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        slots={{
                          openPickerIcon: KeyboardArrowDownIcon,
                        }}
                        sx={{
                          "& .MuiIconButton-root": {
                            color: "#FFF",
                          },
                        }}
                        disablePast
                        value={value}
                        onChange={(newValue) => setValue(newValue)}
                        renderInput={(props) => (
                          <TextField {...props} variant="standard" />
                        )}
                        format=" DD MMM YYYY"
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={2} sm={3} xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "100%",
                  paddingTop: "23px",
                }}
              >
                <CancelIcon
                  sx={{
                    color: "white",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                />
                {/* <Button variant="contained"> Add City</Button> */}
                <button
                  style={{
                    cursor: "pointer",
                    color: "white",
                    fontSize: "17px",
                    backgroundColor: "#005c9d",
                    padding: "13px 25px",
                    borderRadius: "3px",
                    border: "1px solid #005c9d",
                  }}
                >
                  Add City
                </button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddMoreTrip;
