import Footer from "../footer/Footer";
import { Box, Container, Divider, Typography } from "@mui/material";
import Scnericon from "../../assets/img/icon/scnericon.svg";

const FlightCheckOutPapge = () => {
  return (
    <>
      <Box className="hotel-check-head">
        {/* <img src={HotelIcon} alt="" width="80px" /> */}
        <Typography className="hotel-check-head-text">Boarding Pass</Typography>
      </Box>
      <Container>
        <Box className="hotel-check-body">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="ticket-number-text">1</Typography>
            <img src={Scnericon} alt="" width="40px" />
          </Box>
          <Typography
            sx={{ color: "#8890D4 " }}
            className="hotel-booking-info-text"
          >
            Flight Information
          </Typography>
          <Typography className="hotel-name-title">Hotel Name</Typography>
          <Typography className="hotel-name">
            {/* {selectedHotel.HotelName} */}
            1234566
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 2,
            }}
          >
            <Box>
              <Typography className="hotel-name-title">From</Typography>
              <Typography
                sx={{ color: "#8890D4 !important" }}
                className="sub-title"
              >
                Ahmedabad
              </Typography>
            </Box>
            <Box>
              <Typography className="hotel-name-title">To</Typography>
              <Typography
                className="sub-title"
                sx={{ color: "#8890D4 !important" }}
              >
                Bengaluru
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 2,
            }}
          >
            <Box>
              <Typography className="hotel-name-title">Class</Typography>
              <Typography className="sub-title">B</Typography>
            </Box>
            <Box>
              <Typography className="hotel-name-title">Seat No.</Typography>
              <Typography className="sub-title">8A</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography className="hotel-name-title">Date</Typography>
              <Typography className="sub-title">
                Thursday,April 27,2023
              </Typography>
            </Box>
            <Box>
              <Typography className="hotel-name-title">Time</Typography>
              <Typography className="sub-title">21:30 PM</Typography>
            </Box>
          </Box>
          <Divider className="divider" />
          <Typography className="hotel-name-title">
            Passenger Details
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography className="hotel-name-title">Name</Typography>
              <Typography className="sub-title">Adam</Typography>
            </Box>
            <Box>
              <Typography className="hotel-name-title">Gender</Typography>
              <Typography className="sub-title">Male</Typography>
            </Box>
          </Box>
          <Typography className="hotel-name-title" sx={{ mt: 1 }}>
            Phone Number
          </Typography>
          <Typography className="sub-title">123456789</Typography>
          {/* <Box textAlign={"center"} mt={5}>
            <img src={HotelBookingScreener} alt="" />
          </Box>
          <Typography className="thank-you-text">
            Thank You For Booking
          </Typography>
          <Box className="rating-box">
            <StarIcon sx={{ fill: "#5A7FCB" }} />
            <StarIcon sx={{ fill: "#5A7FCB" }} />
            <StarIcon sx={{ fill: "#5A7FCB" }} />
            <StarIcon sx={{ fill: "#5A7FCB" }} />
            <StarIcon sx={{ fill: "#5A7FCB" }} />
          </Box>
          <Box className="comment-section">
            <FormControl fullWidth>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder="Add Your Valuable Comments"
              />
            </FormControl>
            <button className="submit-btn">Submit</button>
          </Box> */}
        </Box>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 5,
          }}
        >
          <Typography
            sx={{
              backgroundColor: "#0C316D",
              color: "white",
              borderRadius: "20px",
              padding: "10px 30px",
              cursor: "pointer",
              width: "400px",
            }}
          >
            Download{" "}
          </Typography>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default FlightCheckOutPapge;
