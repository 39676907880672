import React, { useState, useEffect } from "react";
import Footer from "../footer/Footer";
import { Box, Container } from "@mui/system";
import BusDateSelectBox from "../../layouts/Bus/BusDateSelectBox";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Button,
  TextField,
  TextareaAutosize,
  Typography,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import ProfileIcon from "../../assets/img/icon/profile-outline.png";
import { useDispatch, useSelector } from "react-redux";
import { blockSeats, bookSeats } from "../../redux/bus/busapi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Notification from "../../layouts/Notification/Notification";
import moment from "moment";
import API from "../../helpers/API";
import Loader from "../../layouts/Loaders/Loader";

const StarRating = ({ rating }) => (
  <Box className="rating-box">
    {Array.from({ length: 5 }, (_, index) => (
      <StarIcon
        key={index}
        sx={{ fill: index < rating ? "#5A7FCB" : "#C4C4C4" }}
      />
    ))}
  </Box>
);
const BusBookingConfirmation = () => {
  const [passengerErrors, setPassengerErrors] = useState([]);
  const selectedSeat = useSelector((state) => state?.busdata.selectedSeat);
  const searchData = useSelector((state) => state?.busdata.searchData);
  const seatDetails = useSelector((state) => state?.busdata.SeatDetails);
  const selectedBus = useSelector((state) => state?.busdata.selectedBus);
  const AvailableBuses = useSelector((state) => state?.busdata.AvailableBuses);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const boardingPoint = useSelector((state) => state?.busdata?.board);
  const droppingPoint = useSelector((state) => state?.busdata?.drop);

  let dataToSubmit;
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validatePassengers(passengers);
    if (validationErrors.length > 0) {
      // Here you would typically update your component state to show the validation errors next to the corresponding fields
      console.error("Validation Errors", validationErrors);
      // Optionally, alert the user that there are validation errors
      setPassengerErrors(validationErrors);
      Notification("error", "Please correct the errors before submitting.");
      return; // Stop the submission process
    }
    // Prepare data for submission
    dataToSubmit = {
      ResultIndex: selectedBus.ResultIndex, // Assuming static, adjust as needed
      TraceId: AvailableBuses.Result.TraceId, // Assuming static, adjust as needed
      BoardingPointId: boardingPoint.CityPointIndex, // Assuming static, adjust as needed
      DroppingPointId: droppingPoint.CityPointIndex, // Assuming static, adjust as needed
      RefID: "1", // Assuming static, adjust as needed
      Passenger: passengers,
    };

    // dispatch(blockSeats(dataToSubmit)).then((Res) => {
    //   checkouthandler(
    //     selectedSeat.reduce(
    //       (accumulator, seat) => accumulator + seat.SeatFare,
    //       0
    //     )
    //   );
    // });
    dispatch(blockSeats(dataToSubmit))
      .then((Res) => {
        // Assuming Res might be an HTTP response object
        if (Res.payload.detail === null) {
          console.error("Received non-200 status:", Res);
        } else {
          const totalFare = selectedSeat.reduce(
            (accumulator, seat) => accumulator + seat.SeatFare,
            0
          );
          checkouthandler(totalFare);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // Specifically handle status 400
          console.error("Error 400:", error.message);
        } else {
          // Handle other errors
          console.error("An error occurred:", error.message);
        }
      });
  };

  const validatePassengers = (passengers) => {
    let errors = [];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation regex

    passengers.forEach((passenger, index) => {
      let passengerErrors = {};

      // Check for required fields
      if (!passenger.FirstName.trim())
        passengerErrors.FirstName = "First Name is required";
      if (!passenger.LastName.trim())
        passengerErrors.LastName = "Last Name is required";
      if (!passenger.Email.trim() || !emailRegex.test(passenger.Email))
        passengerErrors.Email = "Valid Email is required";
      if (!passenger.Phoneno.trim())
        passengerErrors.Phoneno = "Phone Number is required";
      if (!passenger.Age.trim()) passengerErrors.Age = "Age is required";
      if (!passenger.Title.trim()) passengerErrors.Title = "Title is required";
      if (!passenger.Gender.trim())
        passengerErrors.Gender = "Gender is required";
      if (!passenger.Address.trim())
        passengerErrors.Address = "Address is required";

      if (Object.keys(passengerErrors).length > 0)
        // errors.push({ ...passengerErrors });
        errors[index] = passengerErrors;
    });

    return errors;
  };

  const [passengers, setPassengers] = useState([]);
  useEffect(() => {
    const initialPassengers = selectedSeat?.map((seat, index) => ({
      LeadPassenger: index === 0 ? true : false,
      PassengerId: 0,
      Title: "",
      FirstName: "",
      LastName: "",
      IdType: null,
      IdNumber: null,
      Email: "",
      Phoneno: "",
      Gender: "", // Assuming '1' as default gender, adjust as needed
      Age: "",
      Address: "",
      Seat: seat,
    }));
    setPassengers(initialPassengers);
  }, [selectedSeat]);

  const handleInputChange = (index, field, value) => {
    const updatedPassengers = [...passengers];
    updatedPassengers[index][field] = value;
    setPassengers(updatedPassengers);
  };

  let token = localStorage.getItem("token");
  const key_id = "rzp_test_J7AMwDnnlsHZ9b";
  const checkouthandler = async (amount) => {
    const { data } = API.post(
      "/payment/razorpay-order-generate/",
      {
        action: "order_generate",
        currency_name: "INR",
        amount,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {
      const { data } = res;

      const options = {
        key: key_id,
        amount: data?.amount,
        currency: "INR",
        name: "Make U Trip",
        description: "Razorpay tutorial",
        order_id: data.id,
        callback_url:
          "https://srv453747.hstgr.cloud/payment/razorpay-verify-payment/",
        prefill: {
          name: "Make U Trip ",
          email: "makeutrip@gmail.com",
          contact: "1234567890",
        },
        notes: {
          address: "razorapy official",
        },
        theme: {
          color: "yellow",
        },
        handler: function (response) {
          let bodyDataToSend;
          if (response.razorpay_payment_id) {
            bodyDataToSend = {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            };
            (async () => {
              try {
                const responseData = await axios.post(
                  "https://srv453747.hstgr.cloud/payment/razorpay-verify-payment/",
                  bodyDataToSend
                );

                if (
                  responseData.data.detail ===
                  "Signature verification successfull."
                ) {
                  // return true;
                  dispatch(bookSeats(dataToSubmit))
                    .then((response) => {
                      if (response.payload && response.payload.detail) {
                        console.error("API Error:", response.payload.detail);
                        navigate("/payment-unsuccess-page");
                        window.scrollTo(0, 0);
                      } else {
                        // Handle other success cases
                        // navigate("/hotel-checkout-page");
                        navigate("/payment-success-page");
                        window.scrollTo(0, 0);
                      }
                    })
                    .catch((error) => {
                      console.log("Network Error:", error);
                    });
                }
              } catch (error) {
                console.log(error);
              }
            })();
          } else {
            console.error("Payment failed or was canceled");
          }
        },
      };
      const razor = new window.Razorpay(options);
      razor.open();
    });
  };
  const departureTime = moment(selectedBus.DepartureTime);
  const arrivalTime = moment(selectedBus.ArrivalTime);
  const duration = moment.duration(departureTime.diff(arrivalTime));

  // Format the duration as "X hours Y minutes"
  const formattedDuration = `${duration.hours()} hrs`;
  const loading = useSelector((state) => state?.busdata?.loading);
  if (loading) return <Loader />;
  return (
    <>
      <Container maxWidth="xl">
        {/* <BusDateSelectBox backgroundColor="#0063A7" /> */}
        <Box>
          <Box className="bus-confirmation-head-section">
            <Grid container spacing={2}>
              <Grid item lg={12} xs={12}>
                <Typography className="review-head-text">
                  Review Itinerary
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} xs={12}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography className="bus-name-text">
                    {/* Bharat Bus (2x1) */}
                    {selectedBus?.TravelName}
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <Typography>Ratings</Typography>
                    <Box>
                      <StarRating rating={4} />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={9} md={9} xs={12}>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ width: "20%", textAlign: "center" }}>
                    <Typography className="arivel-text">
                      {boardingPoint.CityPointName}
                    </Typography>
                    <Typography className="time-text">
                      {moment(boardingPoint.CityPointTime).format(
                        "MMMM D, YYYY"
                      )}
                    </Typography>
                  </Box>
                  <Box className="hours-box">
                    <Typography className="hours-line">
                      {/* {formattedDuration} */}
                      {/* {moment
                        .utc(
                          moment(droppingPoint.CityPointTime).diff(
                            moment(boardingPoint.CityPointTime)
                          )
                        )
                        .format("H [hrs and] m [mins]")} */}
                      {moment
                        .utc(
                          moment(droppingPoint.CityPointTime).diff(
                            moment(boardingPoint.CityPointTime)
                          )
                        )
                        .format("H [hrs]")}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "20%", textAlign: "center" }}>
                    <Typography className="arivel-text">
                      {droppingPoint.CityPointName}
                    </Typography>
                    <Typography className="time-text">
                      {moment(droppingPoint.CityPointTime).format(
                        "MMMM D, YYYY"
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item lg={9} md={9} sm={8} xs={12}>
                <Box className="traveller-info-section">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      backgroundColor: "#0063A7",
                      padding: "20px 10px",
                    }}
                  >
                    <img src={ProfileIcon} alt="" width={25} />
                    <Typography className="traveller-head-text">
                      Traveller Information
                    </Typography>
                  </Box>
                  <Box sx={{ padding: "20px 25px" }}>
                    <Typography className="" sx={{ color: "#234085", my: 1 }}>
                      Please make sure you enter the Name as per your Govt.
                      photo id
                    </Typography>

                    {/* <Grid container spacing={2}>
                      <Grid item lg={9} sm={8} xs={12}>
                        <Box>
                          <Typography className="title-text">
                            Enter Your Name
                          </Typography>
                          <FormControl fullWidth>
                            <TextField
                              type="text"
                              className="input-box"
                              placeholder="Enter your Name"
                              // onChange={handleChange}
                              name="FirstName"
                              sx={{ "& fieldset": { border: "none" } }}
                              // value={hotelPassengers.FirstName}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item lg={3} sm={4} xs={6}>
                        <Box>
                          <Typography className="title-text">
                            Age (in Years)
                          </Typography>
                          <FormControl fullWidth>
                            <TextField
                              type="text"
                              className="input-box"
                              placeholder="Enter your Name"
                              // onChange={handleChange}
                              name="FirstName"
                              sx={{ "& fieldset": { border: "none" } }}
                              // value={hotelPassengers.FirstName}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item lg={12}>
                        <Box className="gender-section">
                          <Typography className="title-text">Gender</Typography>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Box className="radio-gender-box active">Male</Box>
                            <Box className="radio-gender-box">Female</Box>
                            <Box className="radio-gender-box">Other</Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item lg={12}>
                        <Typography
                          className=""
                          sx={{ color: "#022474", my: 1 }}
                        >
                          Your E-ticket and updates will be sent to the
                          following details
                        </Typography>
                      </Grid>
                      <Grid item lg={6} sm={6} xs={12}>
                        <Box>
                          <Typography className="title-text">
                            Email Id
                          </Typography>
                          <FormControl fullWidth>
                            <TextField
                              type="text"
                              className="input-box"
                              placeholder="Enter your Eamil"
                              // onChange={handleChange}
                              name="FirstName"
                              sx={{ "& fieldset": { border: "none" } }}
                              // value={hotelPassengers.FirstName}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item lg={6} sm={6} xs={12}>
                        <Box>
                          <Typography className="title-text">
                            Mobile Number
                          </Typography>
                          <FormControl fullWidth>
                            <TextField
                              type="text"
                              className="input-box"
                              placeholder="Enter your Mobile Number"
                              // onChange={handleChange}
                              name="FirstName"
                              sx={{ "& fieldset": { border: "none" } }}
                              // value={hotelPassengers.FirstName}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item lg={12} xs={12}>
                        <Box>
                          <Typography className="title-text">
                            Address
                          </Typography>
                          <FormControl fullWidth>
                            <TextareaAutosize
                              className="textarea-filed"
                              aria-label="minimum height"
                              minRows={5}
                              placeholder="Minimum 3 rows"
                              fullWidth
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item lg={3} sm={6} xs={12}>
                        <Box>
                          <Typography className="title-text">State</Typography>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={state}
                              placeholder="select state"
                              onChange={handleChange}
                              className="select-box"
                              sx={{ "& fieldset": { border: "none" } }}
                            >
                              <MenuItem value={10}>Ahmedabad</MenuItem>
                              <MenuItem value={20}>Andhra Pradesh</MenuItem>
                              <MenuItem value={30}>Chhattisgarh</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item lg={3} sm={6} xs={12}>
                        <Box>
                          <Typography className="title-text">
                            Pincode
                          </Typography>
                          <FormControl fullWidth>
                            <TextField
                              type="text"
                              className="input-box"
                              placeholder="Enter your Pincode"
                              // onChange={handleChange}
                              name="FirstName"
                              sx={{ "& fieldset": { border: "none" } }}
                              // value={hotelPassengers.FirstName}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid> */}
                    {/* <Container maxWidth="xl">
                      <form onSubmit={handleSubmit}>
                        {passengers.map((passenger, index) => (
                          <Grid container spacing={2} key={index} mb={2}>
                            <Grid item xs={12}>
                              <Typography variant="h6">
                                Passenger {index + 1}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="First Name"
                                value={passenger.FirstName}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "FirstName",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Last Name"
                                value={passenger.LastName}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "LastName",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Email"
                                value={passenger.Email}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Email",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Phone Number"
                                value={passenger.Phoneno}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Phoneno",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Age"
                                value={passenger.Age}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Age",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Address"
                                multiline
                                minRows={3}
                                placeholder="Enter your address here"
                                value={passenger.Address}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Address",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                        ))}
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ marginTop: "20px" }}
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      </form>
                    </Container> */}
                    <Container maxWidth="xl">
                      <form onSubmit={handleSubmit}>
                        {passengers.map((passenger, index) => (
                          <Grid container spacing={2} key={index} mb={2}>
                            <Grid item xs={12}>
                              <Typography variant="h6">
                                Passenger {index + 1}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="First Name"
                                error={!!passengerErrors[index]?.FirstName}
                                helperText={
                                  passengerErrors[index]?.FirstName || ""
                                }
                                value={passenger.FirstName}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "FirstName",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Last Name"
                                value={passenger.LastName}
                                error={!!passengerErrors[index]?.LastName}
                                helperText={
                                  passengerErrors[index]?.LastName || ""
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "LastName",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Email"
                                error={!!passengerErrors[index]?.Email}
                                helperText={passengerErrors[index]?.Email || ""}
                                value={passenger.Email}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Email",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              {/* <TextField
                                fullWidth
                                label="Phone Number"
                                value={passenger.Phoneno}
                                error={!!passengerErrors[index]?.Phoneno}
                                helperText={
                                  passengerErrors[index]?.Phoneno || ""
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Phoneno",
                                    e.target.value
                                  )
                                }
                              /> */}
                              <TextField
                                fullWidth
                                label="Phone Number"
                                value={passenger.Phoneno}
                                error={!!passengerErrors[index]?.Phoneno}
                                helperText={
                                  passengerErrors[index]?.Phoneno || ""
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // Regular expression to check if the input contains only digits
                                  const isDigits = /^\d*$/.test(value);
                                  if (isDigits && value.length <= 10) {
                                    handleInputChange(index, "Phoneno", value);
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              {/* <TextField
                                fullWidth
                                label="Age"
                                value={passenger.Age}
                                error={!!passengerErrors[index]?.Age}
                                helperText={passengerErrors[index]?.Age || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Age",
                                    e.target.value
                                  )
                                }
                              /> */}
                              <TextField
                                fullWidth
                                label="Age"
                                value={passenger.Age}
                                error={!!passengerErrors[index]?.Age}
                                helperText={passengerErrors[index]?.Age || ""}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // Allow the input to be empty
                                  if (value === "") {
                                    handleInputChange(index, "Age", value);
                                    return;
                                  }
                                  // Check if the input is numeric
                                  const isNumeric = /^\d+$/.test(value);
                                  const numericValue = parseInt(value, 10);
                                  if (
                                    isNumeric &&
                                    numericValue >= 0 &&
                                    numericValue <= 100
                                  ) {
                                    handleInputChange(index, "Age", value);
                                  }
                                }}
                              />
                            </Grid>
                            {/* <Grid item xs={6}>
                              <Select
                                fullWidth
                                value={passenger.Title}
                                error={!!passengerErrors[index]?.Title}
                                helperText={passengerErrors[index]?.Title || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Title",
                                    e.target.value
                                  )
                                }
                                displayEmpty
                                inputProps={{ "aria-label": "Title" }}
                              >
                                <MenuItem value="">
                                  <em>Select Title</em>
                                </MenuItem>
                                <MenuItem value="Mr">Mr</MenuItem>
                                <MenuItem value="Mrs">Mrs</MenuItem>
                                <MenuItem value="Miss">Miss</MenuItem>
                                <MenuItem value="Mstr">Mstr</MenuItem>
                              </Select>
                            </Grid> */}
                            <Grid item xs={6}>
                              <FormControl
                                fullWidth
                                error={!!passengerErrors[index]?.Title}
                              >
                                {/* <InputLabel id={`title-label-${index}`}>
                                  Title
                                </InputLabel> */}
                                <Select
                                  labelId={`title-label-${index}`}
                                  value={passenger.Title}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "Title",
                                      e.target.value
                                    )
                                  }
                                  displayEmpty
                                  inputProps={{ "aria-label": "Title" }}
                                >
                                  <MenuItem value="">
                                    <em>Select Title</em>
                                  </MenuItem>
                                  <MenuItem value="Mr">Mr</MenuItem>
                                  <MenuItem value="Mrs">Mrs</MenuItem>
                                  <MenuItem value="Miss">Miss</MenuItem>
                                  <MenuItem value="Mstr">Mstr</MenuItem>
                                </Select>
                                {!!passengerErrors[index]?.Title && (
                                  <FormHelperText>
                                    {passengerErrors[index]?.Title}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              {/* <Select
                                fullWidth
                                value={passenger.Gender}
                                error={!!passengerErrors[index]?.Gender}
                                helperText={
                                  passengerErrors[index]?.Gender || ""
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Gender",
                                    e.target.value
                                  )
                                }
                                displayEmpty
                                inputProps={{ "aria-label": "Gender" }}
                              >
                                <MenuItem value="">
                                  <em>Select Gender</em>
                                </MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                              </Select> */}
                              <FormControl
                                fullWidth
                                error={!!passengerErrors[index]?.Gender}
                              >
                                {/* <InputLabel>Gender</InputLabel> */}
                                <Select
                                  value={passenger.Gender}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "Gender",
                                      e.target.value
                                    )
                                  }
                                  displayEmpty
                                  inputProps={{ "aria-label": "Gender" }}
                                >
                                  <MenuItem value="">
                                    <em>Select Gender</em>
                                  </MenuItem>
                                  <MenuItem value="1">Male</MenuItem>
                                  <MenuItem value="2">Female</MenuItem>
                                </Select>
                                {!!passengerErrors[index]?.Gender && (
                                  <FormHelperText>
                                    {passengerErrors[index]?.Gender}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Address"
                                error={!!passengerErrors[index]?.Address}
                                helperText={
                                  passengerErrors[index]?.Address || ""
                                }
                                multiline
                                minRows={3}
                                placeholder="Enter your address here"
                                value={passenger.Address}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "Address",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            {/* Add other fields as needed */}
                          </Grid>
                        ))}
                        {/* <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ marginTop: "20px" }}
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button> */}
                      </form>
                    </Container>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={3} md={3} sm={4} xs={12}>
                <Box className="bus-price-section">
                  <Typography className="title-text">PRICE</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>Total Basefare</Typography>
                    <Typography>
                      {selectedSeat.reduce(
                        (accumulator, seat) => accumulator + seat.SeatFare,
                        0
                      )}{" "}
                      ₹
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>Taxes & fees</Typography>
                    <Typography>30 ₹</Typography>
                  </Box>
                  <Box
                    sx={{
                      py: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderTop: "1px solid #000",
                    }}
                  >
                    <Typography sx={{ color: "#EE3131", fontWeight: "700" }}>
                      Final Price
                    </Typography>
                    <Typography sx={{ color: "#EE3131", fontWeight: "700" }}>
                      {selectedSeat.reduce(
                        (accumulator, seat) => accumulator + seat.SeatFare,
                        30
                      )}{" "}
                      ₹
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="continue-btn"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Continue To Book
                    </button>
                  </Box>
                </Box>
                <Box className="arrival-point-section">
                  <Box className="arrival-point-box">
                    <Typography className="title-text">
                      Boarding point Details
                    </Typography>
                    <Typography className="time-review-text">
                      {moment(boardingPoint.CityPointTime).format(
                        "h:mm A, dddd, D MMMM YYYY"
                      )}
                    </Typography>
                    <Typography className="place-text">
                      {boardingPoint?.CityPointName}
                    </Typography>
                    <Typography className="address-text">
                      {boardingPoint?.CityPointLocation}
                    </Typography>
                  </Box>
                  <Box className="line-top-set"></Box>
                  <Box className="arrival-point-box ">
                    <Typography className="title-text">
                      Dropping point Details
                    </Typography>
                    <Typography className="time-review-text">
                      {moment(droppingPoint.CityPointTime).format(
                        "h:mm A, dddd, D MMMM YYYY"
                      )}
                    </Typography>
                    <Typography className="place-text">
                      {droppingPoint?.CityPointName}
                    </Typography>
                    <Typography className="address-text">
                      {droppingPoint?.CityPointLocation}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default BusBookingConfirmation;
