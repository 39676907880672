import {
  Box,
  Container,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import searchIcon from "../../assets/img/icon/search.png";
import UpcomingBagBlack from "../../assets/img/icon/upcomming-bag-black.png";
import CancelledBagBlack from "../../assets/img/icon/Cancelled-bag-icon.png";
import HotelIcon from "../../assets/img/icon/hotelFill-icon.svg"
import CloseIcon from "@mui/icons-material/Close";
import UserIcon from "../../assets/img/icon/user.png"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadInvoiceIcon from "../../assets/img/icon/download-invoice-icon.svg"
export const SearchBoxContainer = () =>{
  return (
    <Box className="search-box-container">
    <Box className="search-box">
      <FormControl
        variant="outlined"
        fullWidth
        className="formcontroal-box"
      >
        <TextField
          type="text"
          placeholder="Search For A Booking"
          sx={{ "& fieldset": { border: "none" } }}
        />
      </FormControl>
      <img src={searchIcon} alt="" width={20}  />
    </Box>
    <Box className="close-icon-box">
      <CloseIcon sx={{ color: "#fff" }} />
    </Box>
  </Box>
  )
 
}
const MyTripsPage = () => {
    const [selectedTab, setSelectedTab] = useState("upcoming");

  const handleTabClick = (tabId) => {
    setSelectedTab(tabId);
  };
  return (
    <div>
      <Box className="my-trip-head-section">
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography className="my-trip-head-title">My Trips</Typography>
          <SearchBoxContainer />
        </Box>
        <Box className="my-trip-head-bottom-section">
          <Box className={`trip-box ${selectedTab === "upcoming" && "selected-tab"}`}
          sx={{ borderRight: "1px solid #000" }}
          onClick={() => handleTabClick("upcoming")}>
            <Box>
              <img src={UpcomingBagBlack} alt=""  className="img-bag" />
            </Box>
            <Typography className="main-text">Upcoming</Typography>
          </Box>
          <Box className={`trip-box ${selectedTab === "cancelled" && "selected-tab"}`}
          sx={{ borderRight: "1px solid #000" }}
          onClick={() => handleTabClick("cancelled")}>
            <Box>
              <img src={CancelledBagBlack} alt=""  className="img-bag" />
            </Box>
            <Typography className="main-text">Cancelled</Typography>
          </Box>
          <Box className={`trip-box ${selectedTab === "completed" && "selected-tab"}`}
          onClick={() => handleTabClick("completed")}>
            <Box>
              <img src={UpcomingBagBlack} alt=""  className="img-bag" />
            </Box>
            <Typography className="main-text">Completed</Typography>
          </Box>
        </Box>
      </Box>
      <Container maxWidth="xl">
      <Box sx={{ mt: 10 }} id="upcoming" style={{ display: selectedTab === "upcoming" ? "block" : "none" }}>
        <Container maxWidth="xl">
          <Box className="my-trip-empty-box">
            <Typography className="main-text">
              Looks empty, you've no upcoming bookings.
            </Typography>
            <Typography className="sub-text">
              When you book a trip, you will see your itinerary here.
            </Typography>
            <Box className="btn-box">
              <button className="btn-text">Plane A Trip </button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ mt: 10 }} id="cancelled" style={{ display: selectedTab === "cancelled" ? "block" : "none" }}>
        <Container maxWidth="xl">
          <Box className="my-trip-empty-box">
            <Typography className="main-text">
              Looks empty, you've no upcoming bookings.{" "}
            </Typography>
            <Typography className="sub-text">
              Great! Looks like you’ve no cancelled bookings
            </Typography>
            <Box className="btn-box">
              <button className="btn-text">Plane A Trip </button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ mt: 12 }} id="completed" style={{ display: selectedTab === "completed" ? "block" : "none" }}>
        <Container maxWidth="xl">
          <Box className="completed-trip-box">
                <Box className="trip-icon-box">
                    <img src={HotelIcon} alt=""  width="70px"/>
                </Box>
                <Box sx={{display:"flex",alignItems:"center"}}>
                    <Box className="user-icon-box">
                        <img src={UserIcon} alt="" width="15px" />
                    </Box>
                    <Typography className="user-main-text">Elika Maheshawari</Typography>
                </Box>
                <Box sx={{display:"flex",columnGap:"100px" ,flexWrap:"wrap"}}>

                <Box sx={{mt:5}} >
                    <Typography className="trip-main-text">Hard Rock Hotel</Typography>
                    <Typography>Hotel in Goa</Typography>
                </Box>
                <Box sx={{mt:5}}>
                <Box sx={{display:"flex",alignItems:"center"  ,marginBottom:"10px" }}>
                        <CheckCircleIcon sx={{color:"#0063A7"}} />
                        <Typography className="completed-main-text">Completed </Typography>
                </Box>
                    <Typography>Booking ID : NH 123123123123123 </Typography>
                </Box>
                </Box>
                <Box sx={{display:"flex",alignItems:"center",justifyContent:"center" ,mt:5}}>
                    <button className="view-btn">View Booking</button>
                </Box>  
                <Box sx={{display:"flex",justifyContent:"space-between",flexWrap:"wrap"}}>
                    <Box sx={{width:{md:"40%",sm:"40%",xs:"100%"} ,mt:5}}>
                        <Typography>Check-In</Typography>
                        <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                            <Typography className="date-time-text">Friday, 03 Feb 2023 </Typography>
                            <Typography className="date-time-text">11:50 AM</Typography>
                        </Box>
                    </Box>
                    <Box sx={{width:{md:"40%",sm:"40%", xs:"100%"},mt:5}}>
                        <Typography>Check-Out</Typography>
                        <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                            <Typography className="date-time-text">Monday, 06 Feb 2023    </Typography>
                            <Typography className="date-time-text">02:00 PM</Typography>
                        </Box>
                    </Box>
                </Box>
                
                    <Typography className="hotel-room-text">1 Room, 3 Night</Typography>

             <Box sx={{display:"flex",alignItems:"center",justifyContent:"center" ,mt:5}}>
                    <button className="view-btn"><img src={DownloadInvoiceIcon} alt="DownloadInvoiceIcon" width={25} style={{marginRight:"10px"}} />  Download Invoice</button>
                </Box> 
          </Box>
        </Container>
      </Box>
      </Container>
    </div>
  );
};

export default MyTripsPage;
