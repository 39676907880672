import {
    Box,
    Grid,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import TravelerDetailsSugestBox from "../TravelerDetailsSugestBox/TravelerDetailsSugestBox";
  import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
  import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
  import LocationSuggestBox from "../LocationSuggestBox/LocationSuggestBox";
  import dayjs from "dayjs";
  import { useDispatch, useSelector } from "react-redux";
  import CancelIcon from "@mui/icons-material/Cancel";
const RoundTrip = ({setTripValue}) => {
    const dispatch = useDispatch();
    const searchData = useSelector(
      (state) => state?.hoteldata?.searchData?.dataToSendInSearch
    );
    const [value, setValue] = useState(dayjs(searchData?.value));
    const [value2, setValue2] = useState(dayjs(searchData?.value2));
    const [travelerDetails, setTravelerDetails] = useState("");
    const handeltravelerDetails = () => {
      setTravelerDetails(!travelerDetails);
    };
    const [locationBox, setLocationBox] = useState({
      isFrom: false,
      isTo: false,
    });
    const [city, setCity] = useState(
      searchData
        ? searchData?.SearchCity
        : {
            cityid: "101318",
            country: "India",
            country_code: "IN",
            created_at: "2024-01-24T05:53:18.616155",
            destination: "Bhayandar",
            state_province: "",
            state_province_code: "",
            updated_at: "2024-01-24T05:53:18.616180",
            url: "http://srv453747.hstgr.cloud:8000/hotel/hotelcitydetails/331/",
          }
    );
    const handleRoundTripClick = (args) => {
      if (args === 0) {
        setLocationBox({
          isFrom: true,
          isTo: false,
        });
      } else if (args === 1) {
        setLocationBox({
          isFrom: false,
          isTo: true,
        });
      }
    };
  return (
    <Grid item lg={10}>
    <Grid container spacing={2}>
      <Grid item lg={2}>
        <Box>
          <Typography sx={{ color: "#FFF" }}>From</Typography>
          <Box className="location-box" sx={{ position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => handleRoundTripClick(0)}
            >
              <Typography sx={{ color: "#FFF" }}>Ahmedabad</Typography>
              <KeyboardArrowDownIcon sx={{ color: "#FFF" }} />
            </Box>
            {locationBox.isFrom ? <LocationSuggestBox /> : null}
          </Box>
        </Box>
      </Grid>
      <Grid item lg={2}>
        <Box>
          <Typography sx={{ color: "#FFF" }}>To</Typography>
          <Box className="location-box" sx={{ position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => handleRoundTripClick(1)}
            >
              <Typography sx={{ color: "#FFF" }}>Ahmedabad</Typography>
              <KeyboardArrowDownIcon sx={{ color: "#FFF" }} />
            </Box>
            {locationBox.isTo ? <LocationSuggestBox /> : null}
          </Box>
        </Box>
      </Grid>
      <Grid item lg={2} sm={6} xs={12}>
        <Box className="list-box">
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "#FFF",
            }}
          >
            Depart
          </Typography>
          <Box className="date-box" sx={{ widht: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  slots={{
                    openPickerIcon: KeyboardArrowDownIcon,
                  }}
                  sx={{
                    "& .MuiIconButton-root": {
                      color: "#FFF",
                    },
                  }}
                  disablePast
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                  renderInput={(props) => (
                    <TextField {...props} variant="standard" />
                  )}
                  format=" DD MMM YYYY"
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={2} sm={6} xs={12}>
        <Box className="list-box">
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "#FFF",
            }}
          >
            Return
          </Typography>
          <Box className="date-box">
            <LocalizationProvider dateAdapter={AdapterDayjs} sx={{}}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  slots={{
                    openPickerIcon: KeyboardArrowDownIcon,
                  }}
                  sx={{
                    "& .MuiIconButton-root": {
                      color: "#FFF",
                    },
                  }}
                  disablePast
                  fullWidth
                  value={value2}
                  onChange={(newValue) => setValue2(newValue)}
                  renderInput={(props) => (
                    <TextField {...props} variant="standard" />
                  )}
                  format=" DD MMM YYYY"
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={2}>
        <Box sx={{ position: "relative" }}>
          <Typography sx={{ color: "#FFF" }}>Passengers</Typography>
          <Box
            onClick={handeltravelerDetails}
            className="traveler-details-box"
          >
            <Typography sx={{ fontSize: "17px", fontWeight: "400" }}>
              1 Traveller
            </Typography>
          </Box>
          {travelerDetails ? (
            <TravelerDetailsSugestBox setTravelerDetails={setTravelerDetails} />
          ) : null}
        </Box>
      </Grid>
      <Grid item lg={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <button className="search-btn">Update Search</button>
        </Box>
      </Grid>
    </Grid>
  </Grid>
  )
}

export default RoundTrip
