import { createSlice } from "@reduxjs/toolkit";
import {
  fareDetails,
  farequotes,
  flightSearch,
  getFlightCity,
  ssrdetails,
  seatview,
  flightbookllc,
  fareDetailsTwo,
  farequotesTwo,
  ssrdetailsTwo,
  seatviewTwo,
  flightbookgds,
  flightholdgds,
  flightbookgdsTwo,
  flightholdgdsTwo,
  //   hotelSearch,
  //   blockRoomInfo,
  //   hotelBookRoom,
  //   hotelCancelRoom,
  //   hotelRoomInfo,
  //   hotelInfo,
  //   userHotelBookings,
} from "./flightapi";

export const flightSlice = createSlice({
  name: "flight",
  initialState: {
    flightDetails: null,
    flightSearchData: null,
    flightSearchObject: null,
    fareDetails: null,
    fareDetailsTwo: null,
    farequotes: null,
    farequotesTwo: null,
    searchData: null,
    ssrdetails: null,
    ssrdetailsTwo: null,
    passengers: null,
    seatviewdata: null,
    seatviewdataTwo: null,
    flightbook: null,
    flightbookgds: null,
    flightbookgdsTwo: null,
    flightholdgds: null,
    flightholdgdsTwo: null,
    // hotelRoomInfo: null,
    // blockRoomInfosuccess: false,
    // BookedRoom: null,
    loading: false,
    error: null,
  },
  reducers: {
    // Add any reducers here if needed
    setHomePageSearchData: (state, action) => {
      state.searchData = action.payload;
    },
    setPassengers: (state, action) => {
      state.passengers = action.payload;
    },
    setFlightSearchObject: (state, action) => {
      state.flightSearchObject = action.payload;
    },
    // setSelectedRoom: (state, action) => {
    //   state.selectedRoom = action.payload;
    // },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getFlightCity.pending, (state) => {
        state.loading = false;
      })
      .addCase(getFlightCity.fulfilled, (state, action) => {
        state.loading = false;
        state.flightDetails = action.payload;
      })
      .addCase(getFlightCity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(flightSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(flightSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.flightSearchData = action.payload;
      })
      .addCase(flightSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fareDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fareDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.fareDetails = action.payload;
      })
      .addCase(fareDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fareDetailsTwo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fareDetailsTwo.fulfilled, (state, action) => {
        state.loading = false;
        state.fareDetailsTwo = action.payload;
      })
      .addCase(fareDetailsTwo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(farequotes.pending, (state) => {
        state.loading = true;
      })
      .addCase(farequotes.fulfilled, (state, action) => {
        state.loading = false;
        state.farequotes = action.payload;
      })
      .addCase(farequotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(farequotesTwo.pending, (state) => {
        state.loading = true;
      })
      .addCase(farequotesTwo.fulfilled, (state, action) => {
        state.loading = false;
        state.farequotesTwo = action.payload;
      })
      .addCase(farequotesTwo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(ssrdetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(ssrdetails.fulfilled, (state, action) => {
        state.loading = false;
        state.ssrdetails = action.payload;
      })
      .addCase(ssrdetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(ssrdetailsTwo.pending, (state) => {
        state.loading = true;
      })
      .addCase(ssrdetailsTwo.fulfilled, (state, action) => {
        state.loading = false;
        state.ssrdetailsTwo = action.payload;
      })
      .addCase(ssrdetailsTwo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(seatview.pending, (state) => {
        state.loading = true;
      })
      .addCase(seatview.fulfilled, (state, action) => {
        state.loading = false;
        state.seatviewdata = action.payload;
      })
      .addCase(seatview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(seatviewTwo.pending, (state) => {
        state.loading = true;
      })
      .addCase(seatviewTwo.fulfilled, (state, action) => {
        state.loading = false;
        state.seatviewdataTwo = action.payload;
      })
      .addCase(seatviewTwo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(flightbookllc.pending, (state) => {
        state.loading = true;
      })
      .addCase(flightbookllc.fulfilled, (state, action) => {
        state.loading = false;
        state.flightbook = action.payload;
      })
      .addCase(flightbookllc.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(flightholdgds.pending, (state) => {
        state.loading = true;
      })
      .addCase(flightholdgds.fulfilled, (state, action) => {
        state.loading = false;
        state.flightholdgds = action.payload;
      })
      .addCase(flightholdgds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(flightholdgdsTwo.pending, (state) => {
        state.loading = true;
      })
      .addCase(flightholdgdsTwo.fulfilled, (state, action) => {
        state.loading = false;
        state.flightholdgdsTwo = action.payload;
      })
      .addCase(flightholdgdsTwo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(flightbookgds.pending, (state) => {
        state.loading = true;
      })
      .addCase(flightbookgds.fulfilled, (state, action) => {
        state.loading = false;
        state.flightbookgds = action.payload;
      })
      .addCase(flightbookgds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(flightbookgdsTwo.pending, (state) => {
        state.loading = true;
      })
      .addCase(flightbookgdsTwo.fulfilled, (state, action) => {
        state.loading = false;
        state.flightbookgdsTwo = action.payload;
      })
      .addCase(flightbookgdsTwo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Add more cases here if needed
  },
});

export const { setHomePageSearchData, setPassengers, setFlightSearchObject } =
  flightSlice.actions;

export default flightSlice.reducer;
