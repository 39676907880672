import {
  Box,
  Container,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  Card,
  CardActions,
  CardContent,
  Popover,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import SpecialOffersSection from "../../layouts/SpecialOffersSection";
import TopDestinationSection from "../../layouts/TopDestinationSection";
import AboutAppSection from "../../layouts/AboutAppSection";
import ExploreMoreToureSection from "../../layouts/ExploreMoreToureSection";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HistoryIcon from "@mui/icons-material/History";
import Footer from "../footer/Footer";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getHotelCountryDetails,
  hotelSearch,
} from "../../redux/hotel/hotelapi";
import { Navigate, useNavigate } from "react-router-dom";
import Loader from "../../layouts/Loaders/Loader";
import { setHomePageSearchData } from "../../redux/hotel/hotelslice";
import { checkGridRowIdIsValid } from "@mui/x-data-grid";

function CheckIn(props) {
  // const [isOpen, setIsOpen] = React.useState(false);

  const {
    SetOpenDatePicker,
    id,
    value,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <>
      <Box
        sx={{
          py: 2,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography>Check in </Typography>
        <Box
          // onClick={handleCheckInClick}
          // onClick={() => setIsOpen(true)}
          onClick={() => SetOpenDatePicker?.((prev) => !prev)}
          id={id}
          ref={ref}
          aria-label={ariaLabel}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography className="check-date">
            {value.$d.toString().split(" ").slice(1, 4).join(" ")}
          </Typography>
          <Typography>
            <KeyboardArrowDownIcon />
          </Typography>
          <></>
        </Box>
      </Box>
    </>
  );
}
function CheckInDatePicker(props) {
  const [openDatePicker, SetOpenDatePicker] = React.useState(false);
  const searchData = useSelector(
    (state) => state?.hoteldata?.searchData?.dataToSendInSearch
  );
  const [value, setValue] = React.useState(
    searchData && searchData.value ? dayjs(searchData.value) : dayjs()
  );
  return (
    <DatePicker
      onChange={(newValue) => {
        setValue(newValue);
        // handleCheckInClick();
      }}
      disablePast
      slots={{ field: CheckIn, ...props.slots }}
      slotProps={{
        field: { SetOpenDatePicker },
        value: value,
      }}
      {...props}
      open={openDatePicker}
      onClose={() => SetOpenDatePicker(false)}
      onOpen={() => SetOpenDatePicker(true)}
      value={value} // Set the initial value here
    />
  );
}

function CheckOut(props) {
  // const [isOpen, setIsOpen] = React.useState(false);

  const {
    SetOpenDatePicker,
    id,
    value,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <>
      <Box
        sx={{
          py: 2,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography>Check out</Typography>
        <Box
          onClick={() => SetOpenDatePicker?.((prev) => !prev)}
          id={id}
          ref={ref}
          aria-label={ariaLabel}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography className="check-date">
            {value.$d.toString().split(" ").slice(1, 4).join(" ")}
          </Typography>
          <Typography>
            <KeyboardArrowDownIcon />
          </Typography>
          <></>
        </Box>
      </Box>
    </>
  );
}
function CheckOutDatePicker(props) {
  const [openDatePicker, SetOpenDatePicker] = React.useState(false);
  const searchData = useSelector(
    (state) => state?.hoteldata?.searchData?.dataToSendInSearch
  );
  const [value, setValue] = React.useState(
    searchData && searchData.value2
      ? dayjs(searchData.value2)
      : dayjs(props.value)
  );

  return (
    <DatePicker
      onChange={(newValue) => {
        setValue(newValue);
        // handleCheckInClick();
      }}
      disablePast
      slots={{ field: CheckOut, ...props.slots }}
      slotProps={{
        field: { SetOpenDatePicker },
        value: value,
      }}
      {...props}
      open={openDatePicker}
      onClose={() => SetOpenDatePicker(false)}
      onOpen={() => SetOpenDatePicker(true)}
      value={props.value} // Set the initial value here
    />
  );
}

const HotelHomePage = (props) => {
  const searchData = useSelector(
    (state) => state?.hoteldata?.searchData?.dataToSendInSearch
  );

  const [value, setValue] = useState(dayjs());
  // const [value2, setValue2] = useState(dayjs());
  const [value2, setValue2] = React.useState(dayjs(value).add(1, "day"));
  const data = useSelector((state) => state.hoteldata);
  const [dropdownfill, setDropdownfill] = useState([]);
  const [citystr, setCityStr] = useState("");
  const [showPriceDeatilsBox, SetshowPriceDeatilsBox] = useState("");
  const [showRoomDeatilsBox, SetshowRoomDeatilsBox] = useState("");
  const [isLocationshow, setLocationshow] = useState(false);

  const [city, setCity] = useState({
    cityid: "101318",
    country: "India",
    country_code: "IN",
    created_at: "2024-01-24T05:53:18.616155",
    destination: "Bhayandar",
    state_province: "",
    state_province_code: "",
    updated_at: "2024-01-24T05:53:18.616180",
    url: "http://srv453747.hstgr.cloud:8000/hotel/hotelcitydetails/331/",
  });
  const [selectedPriceRange, setSelectedPriceRange] = useState(
    searchData
      ? {
          minprice: searchData.minprice,
          maxprice: searchData.maxprice,
        }
      : {
          minprice: 0,
          maxprice: 1500,
        }
  );
  // useState("₹0-₹1500");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRoomBox = () => {
    SetshowRoomDeatilsBox(!showRoomDeatilsBox);
  };
  const handleopenPrice = () => {
    SetshowPriceDeatilsBox(!showPriceDeatilsBox);
  };

  const [citystrReady, setCitystrReady] = useState(false);
  useEffect(() => {
    let timeoutId;
    if (citystrReady) {
      timeoutId = setTimeout(() => {
        dispatch(getHotelCountryDetails(citystr));
        setCitystrReady(false); // Reset to false after API call
      }, 1000); // Adjust the delay time as needed
    }
    return () => clearTimeout(timeoutId); // Cleanup on component unmount or re-render
  }, [dispatch, citystr, citystrReady]);

  const handleChange = (e) => {
    const newCitystr = e.target.value;
    setCityStr(e.target.value);
    if (newCitystr.length > 0) {
      setCitystrReady(true);
    }
  };

  useEffect(() => {
    if (data && data.hotelDetails) {
      setDropdownfill(data.hotelDetails.data);
    }
  }, [data]);

  const loading = useSelector((state) => state.hoteldata.loading);

  const handleSelectCity = (destination) => {
    setCity(destination);
    setCityStr("");
    setDropdownfill("");
  };

  const handlePriceRangeClick = (price) => {
    let minPrice, maxPrice;
    switch (
      price // Use `price` directly, no need for a redundant `const value = price;`
    ) {
      case "0-1500":
        minPrice = 0;
        maxPrice = 1500;
        break;
      case "1500-2500":
        minPrice = 1500;
        maxPrice = 2500;
        break;
      case "2500-4500":
        minPrice = 2500;
        maxPrice = 4500;
        break;
      case "4500-6500":
        minPrice = 4500;
        maxPrice = 6500;
        break;
      default:
        minPrice = 0;
        maxPrice = 6500; // Adjust this as per your maximum limit
    }
    setSelectedPriceRange({
      // Assuming `setSelectedPriceRange` correctly handles an object
      minprice: minPrice,
      maxprice: maxPrice,
    });
    SetshowPriceDeatilsBox(false); // Corrected naming convention and typo
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // for room selection

  const [NoOfRooms, setNoOfRooms] = useState(1);
  const [roomGuests, setRoomGuests] = useState([
    { NoOfAdults: 1, NoOfChild: 0, ChildAge: [] },
  ]);

  const handleChangeRoom = (event) => {
    const numberOfRooms = parseInt(event.target.value, 10);
    setNoOfRooms(numberOfRooms);

    const updatedRoomGuests = [];
    for (let i = 0; i < numberOfRooms; i++) {
      if (roomGuests[i]) {
        updatedRoomGuests.push(roomGuests[i]);
      } else {
        updatedRoomGuests.push({ NoOfAdults: 1, NoOfChild: 0, ChildAge: [] });
      }
    }
    setRoomGuests(updatedRoomGuests);
  };

  const handleAdultChange = (event, roomIndex) => {
    const newRoomGuests = roomGuests.map((guest, index) =>
      index === roomIndex
        ? { ...guest, NoOfAdults: parseInt(event.target.value, 10) }
        : guest
    );
    setRoomGuests(newRoomGuests);
  };

  const handleChildChange = (event, roomIndex) => {
    const newRoomGuests = roomGuests.map((guest, index) =>
      index === roomIndex
        ? {
            ...guest,
            NoOfChild: parseInt(event.target.value, 10),
            ChildAge: Array(parseInt(event.target.value, 10)).fill(""),
          }
        : guest
    );
    setRoomGuests(newRoomGuests);
  };

  const handleChildAgeChange = (event, roomIndex, childIndex) => {
    const newRoomGuests = roomGuests.map((guest, index) =>
      index === roomIndex
        ? {
            ...guest,
            ChildAge: guest.ChildAge.map((age, idx) =>
              idx === childIndex ? parseInt(event.target.value, 10) : age
            ),
          }
        : guest
    );
    setRoomGuests(newRoomGuests);
  };

  const [finalData, setFinalData] = useState({
    NoOfRooms: 1,
    RoomGuests: [{ NoOfAdults: 1, NoOfChild: 0, ChildAge: [] }],
  });

  const handleSubmit = () => {
    if (validate()) {
      const finalData2 = {
        NoOfRooms: NoOfRooms.toString(),
        RoomGuests: roomGuests.map((room) => ({
          NoOfAdults: room.NoOfAdults,
          NoOfChild: room.NoOfChild,
          ChildAge: room.ChildAge,
        })),
      };
      setFinalData(finalData2);
      handleRoomBox();
    }
    // Here you would typically make an API call to submit the finalData
  };

  const [errors, setErrors] = useState({});
  const validate = () => {
    let tempErrors = {};

    // Child age validation
    roomGuests.forEach((room, index) => {
      if (room.NoOfChild > 0) {
        room.ChildAge.forEach((age, childIndex) => {
          if (age === "" || age < 1 || age > 17) {
            const ageErrorKey = `ChildAge_${index}_${childIndex}`;
            tempErrors[ageErrorKey] = `Child ${childIndex + 1} in Room ${
              index + 1
            } must have an age between 1 and 17.`;
          }
        });
      }
    });

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSearch = () => {
    const day = value.$D;
    const month = value.$M + 1; // Subtract 1 to make it valid (assuming it's zero-based)
    const year = value.$y;
    // Format the date as "DD/MM/YYYY"
    const formattedDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year}`;

    const day2 = value2.$D;
    const month2 = value2.$M + 1; // Subtract 1 to make it valid (assuming it's zero-based)
    const year2 = value2.$y;
    // Format the date as "DD/MM/YYYY"
    const formattedDate2 = `${day2.toString().padStart(2, "0")}/${month2
      .toString()
      .padStart(2, "0")}/${year2}`;

    // Parse date strings into Date objects
    const startDate = new Date(
      parseInt(formattedDate.split("/")[2]), // Year
      parseInt(formattedDate.split("/")[1]) - 1, // Month (subtract 1 for 0-based index)
      parseInt(formattedDate.split("/")[0]) // Day
    );

    const endDate = new Date(
      parseInt(formattedDate2.split("/")[2]), // Year
      parseInt(formattedDate2.split("/")[1]) - 1, // Month (subtract 1 for 0-based index)
      parseInt(formattedDate2.split("/")[0]) // Day
    );

    // Calculate the number of nights
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const nights = Math.floor((endDate - startDate) / oneDayInMilliseconds);

    // return true;
    const data = {
      BookingMode: "5",
      CheckInDate: formattedDate,
      // minprice: selectedPriceRange.minprice,
      // maxprice: selectedPriceRange.maxprice,
      NoOfNights: nights,
      CountryCode: city.country_code,
      CityId: city.cityid,
      ResultCount: null,
      PreferredCurrency: "INR",
      GuestNationality: "IN",
      PreferredHotel: "",
      MaxRating: 5,
      MinRating: 0,
      ReviewScore: null,
      IsNearBySearchAllowed: false,
    };

    let dataToSendInAPI = { ...data, ...finalData };

    const SearchData = {
      CheckInDate: formattedDate,
      CheckOutDate: formattedDate2,
      minprice: selectedPriceRange.minprice,
      maxprice: selectedPriceRange.maxprice,
      SearchCity: city,
      value: value,
      value2: value2,
    };

    let dataToSendInSearch = { ...SearchData, ...finalData };

    const DataToSendInSearchAction = {
      dataToSendInSearch,
      dataToSendInAPI,
    };

    dispatch(setHomePageSearchData(DataToSendInSearchAction));
    dispatch(hotelSearch({ data: dataToSendInAPI, page: 1 }));
    navigate("/hotel-booking-page");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // Check if searchData has the properties you are interested in
    if (searchData && searchData.NoOfRooms && searchData.RoomGuests) {
      setFinalData({
        NoOfRooms: searchData.NoOfRooms,
        RoomGuests: searchData.RoomGuests,
      });
      setRoomGuests(searchData.RoomGuests);
      setNoOfRooms(searchData.NoOfRooms);

      // Check for additional properties if necessary
      if (searchData.SearchCity) {
        setCity(searchData.SearchCity);
      }
      if (searchData.value2) {
        setValue2(dayjs(searchData.value2));
      }
      if (searchData.value) {
        setValue(dayjs(searchData.value));
      }
    }
  }, []);

  useEffect(() => {
    const nextDay = dayjs(value).add(1, "day");
    // if (value2.isBefore(nextDay)) {
    setValue2(nextDay);
    // }
  }, [value]);

  // for room selection
  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <Box>
      <Box>
        <Container maxWidth="xl">
          <Box className="hotel-cheack-out-section">
            <Grid container spacing={0}>
              <Grid
                item
                lg={3}
                sm={3}
                xs={12}
                sx={{ borderRight: "1px solid #000000" }}
                onClick={() => setLocationshow(!isLocationshow)}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    py: 2,
                    position: "relative",
                  }}
                >
                  <Typography sx={{ cursor: "pointer" }}>
                    Search City
                  </Typography>

                  {!isLocationshow && (
                    <>
                      <Box sx={{ mt: { xs: 2, sm: 3, md: 2 } }}>
                        <h3>{city.destination}</h3>
                      </Box>
                    </>
                  )}
                  {isLocationshow && (
                    <Box className="location-suggest-box">
                      <Box
                        sx={{ display: "flex", alignItems: "center", px: 1 }}
                      >
                        <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className="formcontroal-box"
                          // onClick={(e) => handleSelectCity(e)}
                        >
                          <TextField
                            type="text"
                            placeholder="Where do you want to stay?"
                            sx={{ "& fieldset": { border: "none" } }}
                            onChange={(e) => handleChange(e)}
                            autoFocus
                          />
                        </FormControl>
                        <Typography
                          variant="caption"
                          sx={{
                            cursor: "pointer",
                            color: "rgba(0, 0, 0, 0.54)",
                          }}
                        >
                          CLEAR
                        </Typography>
                      </Box>
                      <Box className="location-suggest-list-box">
                        <Box>
                          {/* <Typography variant="body2">POPULAR</Typography> */}
                          <Box>
                            <List>
                              {dropdownfill && dropdownfill.length > 0 ? (
                                dropdownfill.map((city) => (
                                  <ListItem disablePadding key={city.id}>
                                    <ListItemButton
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleSelectCity(city)}
                                    >
                                      <ListItemIcon>
                                        <FmdGoodOutlinedIcon />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={city.destination}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                ))
                              ) : (
                                <ListItem disablePadding>
                                  <ListItemButton>
                                    <ListItemText
                                      primary={
                                        citystr.length > 0
                                          ? "Enter valid city"
                                          : "Please search for a city"
                                      }
                                    />
                                  </ListItemButton>
                                </ListItem>
                              )}
                            </List>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                lg={2}
                sm={3}
                xs={6}
                sx={{ borderRight: "1px solid #000000" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CheckInDatePicker
                      label={value == null ? null : value.format("MM/DD/YYYY")}
                      value={value}
                      onChange={(newValue) => setValue(newValue)}
                    />
                  </LocalizationProvider>

                  {/* <Box>Saturday</Box> */}
                  {/* {isDatePickerVisible && (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        className="date-picker"
                          value={value}
                          // onChange={(newValue) => setValue(newValue)}
                          onChange={(newValue) => {
                            setValue(newValue);
                            handleCheckInClick();
                          }}
                          // renderInput={(props) => (
                          //   <TextField {...props} variant="standard" />
                          // )}
                          format="DD MMM YYYY"
                        />
                      </LocalizationProvider>
                    </>
                  )} */}
                </Box>
              </Grid>
              <Grid
                item
                lg={2}
                sm={3}
                xs={6}
                sx={{ borderRight: "1px solid #000000" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {/* <CheckOutDatePicker
                      label={
                        value2 == null ? null : value2.format("MM/DD/YYYY")
                      }
                      value={value2}
                      onChange={(newValue) => setValue2(newValue)}
                      minDate={dayjs(value).add(1, "day")}
                    /> */}
                    <CheckOutDatePicker
                      label={
                        value2 == null ? null : value2.format("MM/DD/YYYY")
                      }
                      value={value2}
                      onChange={(newValue) => setValue2(newValue)}
                      minDate={dayjs(value).add(1, "day")}
                      // Other props as necessary
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid
                item
                lg={2}
                sm={3}
                xs={12}
                sx={{ borderRight: "1px solid #000000" }}
              >
                <Box
                  sx={{
                    py: 2,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <Typography>Rooms & Guest</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleRoomBox}
                    // onClick={handleClick}
                  >
                    <Typography className="room-date-text">
                      {NoOfRooms} <span className="text-room">Room</span>
                    </Typography>
                    <Typography className="room-date-text" ml={2}>
                      {/* {roomGuests?.reduce(
                        (acc, group) => acc + group.NoOfAdults,
                        0
                      )}{" "} */}
                      {roomGuests?.reduce((accumulator, currentGroup) => {
                        const noOfAdults = parseInt(
                          currentGroup.NoOfAdults,
                          10
                        );
                        return accumulator + noOfAdults;
                      }, 0)}
                      <span className="text-room">Adults</span>
                    </Typography>
                    <Typography>
                      <KeyboardArrowDownIcon
                      // aria-describedby={id}
                      // variant="contained"
                      // onClick={handleClick}
                      />
                    </Typography>
                  </Box>
                  {showRoomDeatilsBox && (
                    <Box className="room-guest-sugestion-section">
                      <Card
                        sx={{ backgroundColor: "White", border: "none" }}
                        variant="outlined"
                      >
                        <CardContent>
                          <FormControl fullWidth sx={{ mb: 4, minWidth: 120 }}>
                            <Select
                              value={NoOfRooms}
                              onChange={handleChangeRoom}
                              displayEmpty
                            >
                              {[1, 2, 3, 4].map((roomNumber) => (
                                <MenuItem key={roomNumber} value={roomNumber}>
                                  {roomNumber}{" "}
                                  {roomNumber > 1 ? "Rooms" : "Room"}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {roomGuests.map((room, index) => (
                            <Box key={index} sx={{ mb: 2 }}>
                              <Typography variant="h6">
                                Room {index + 1}
                              </Typography>
                              <FormControl
                                variant="outlined"
                                sx={{ mr: 2, minWidth: 100 }}
                              >
                                <Select
                                  value={room.NoOfAdults}
                                  onChange={(e) => handleAdultChange(e, index)}
                                >
                                  {[1, 2, 3, 4].map((adultNumber) => (
                                    <MenuItem
                                      key={adultNumber}
                                      value={adultNumber}
                                    >
                                      {adultNumber}{" "}
                                      {adultNumber > 1 ? "Adults" : "Adult"}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl
                                variant="outlined"
                                sx={{ minWidth: 100, mb: 2 }}
                              >
                                <Select
                                  value={room.NoOfChild}
                                  onChange={(e) => handleChildChange(e, index)}
                                >
                                  {[0, 1, 2, 3].map((childNumber) => (
                                    <MenuItem
                                      key={childNumber}
                                      value={childNumber}
                                    >
                                      {childNumber}{" "}
                                      {childNumber !== 1 ? "Children" : "Child"}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {room.ChildAge.map((age, childIndex) => (
                                <TextField
                                  key={childIndex}
                                  label={`Child ${childIndex + 1} Age`}
                                  type="number"
                                  value={age}
                                  onChange={(e) =>
                                    handleChildAgeChange(e, index, childIndex)
                                  }
                                  sx={{ ml: 2, mb: 1, width: 100 }}
                                  inputProps={{ min: "1", max: "17" }}
                                  // inputProps={{ min: "1", max: "17" }}
                                  // Display error message if present for this specific child's age
                                  error={
                                    !!errors[`ChildAge_${index}_${childIndex}`]
                                  }
                                  helperText={
                                    errors[`ChildAge_${index}_${childIndex}`] ||
                                    ""
                                  }
                                />
                              ))}
                            </Box>
                          ))}
                          {/* <Typography color="error" variant="caption">
                            {errors[`ChildAge_${index}_${childIndex}`] || ""}
                          </Typography> */}
                        </CardContent>

                        <CardActions>
                          <Button
                            style={{
                              cursor: "pointer",
                            }}
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ mt: 2 }}
                          >
                            Submit
                          </Button>
                        </CardActions>
                      </Card>
                    </Box>
                  )}
                  {Object.keys(errors).length > 0 && (
                    <Typography color="error" variant="caption">
                      Error
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item lg={3} sm={4} xs={12}>
                <Box
                  sx={{
                    py: 2,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      px: 2,
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box onClick={handleopenPrice} sx={{ cursor: "pointer" }}>
                      <Typography>Price per Night</Typography>
                      <Typography className="price-text">
                        {/* <span className="price-symbol">₹</span> */}
                        {/* 0-2500 */}₹{selectedPriceRange.minprice}- ₹
                        {selectedPriceRange.maxprice}
                      </Typography>
                    </Box>
                    <Box
                      className="search-icon-box"
                      onClick={handleSearch}
                      sx={{ cursor: "pointer" }}
                    >
                      <SearchIcon className="search-icon" />
                    </Box>
                  </Box>
                  {showPriceDeatilsBox && (
                    <Box className="price-sugestion-box">
                      <List>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => handlePriceRangeClick("0-1500")}
                          >
                            <ListItemText primary="₹0-₹1500" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => handlePriceRangeClick("1500-2500")}
                          >
                            <ListItemText primary="₹1500-₹2500" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => handlePriceRangeClick("2500-4500")}
                          >
                            <ListItemText primary="₹2500-₹4500" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => handlePriceRangeClick("4500-6500")}
                          >
                            <ListItemText primary="₹4000-₹6500" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <SpecialOffersSection />
      <TopDestinationSection />
      <AboutAppSection />
      <ExploreMoreToureSection />
      <section>
        <Footer />
      </section>
    </Box>
  );
};

export default HotelHomePage;
