import React, { useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Container,
  FormControl,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import SeatIcon from "../assets/img/icon/seat-cheack.png";
import screener from "../assets/img/hotelbookingscreener.png";
import Footer from "../views/footer/Footer";
const StarRating = ({ initialRating = 0 }) => {
  const [rating, setRating] = useState(initialRating);

  const handleStarClick = (index) => {
    setRating(index + 1);
  };
  return (
    <Box sx={{ textAlign: "center" }}>
      {Array.from({ length: 5 }, (_, index) => (
        <StarIcon
          key={index}
          sx={{
            fill: index < rating ? "#5A7FCB" : "#C4C4C4",
            cursor: "pointer",
            fontSize: "35px",
          }}
          onClick={() => handleStarClick(index)}
        />
      ))}
    </Box>
  );
};
const TicketCheckOut = (props) => {
  return (
    <>
      <Container>
        <Box className="bus-check-section">
          <Box className="bus-check-body">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <Typography className="bus-head-text">From</Typography>
                <Typography className="location-text">
                  {props.fromDestinosName}{" "}
                </Typography>
                <Typography sx={{ mb: 1 }}>Date</Typography>
                <Typography sx={{ mb: 1 }}>{props.fromDate}</Typography>
                <Typography sx={{ mb: 1 }}>Departure</Typography>
                <Typography sx={{ mb: 1 }}>{props.fromDeparture} </Typography>
              </Box>

              <Box>
                <img src={SeatIcon} alt="" width={70} />
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Typography className="bus-head-text">To</Typography>
                <Typography className="location-text">
                  {props.toDestinosName}{" "}
                </Typography>
                <Typography sx={{ mb: 1 }}>Date</Typography>
                <Typography sx={{ mb: 1 }}>{props.toDate}</Typography>
                <Typography sx={{ mb: 1 }}>Arrival</Typography>
                <Typography sx={{ mb: 1 }}>{props.toArival} </Typography>
              </Box>
            </Box>
            <Typography sx={{ textAlign: "center", my: 3, fontSize: "22px" }}>
              {props.moduleName}: {props.TravelName}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography>Class: {props.class} </Typography>
              <Typography>Seat No.: {props.seatNo}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 3,
              }}
            >
              <img src={screener} alt="" width={150} />
            </Box>
            <Box sx={{ my: 2 }}>
              <Typography className="thank-you-text">
                Thank You For Booking
              </Typography>
              <StarRating rating={2} />
            </Box>
            <Box>
              <Box className="comment-box">
                <Typography className="main-text">
                  Add Your Valuable Comments
                </Typography>
                <FormControl fullWidth>
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    className="textarea-filed"
                    sx={{ width: "100%", "& fieldset": { border: "none" } }}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ textAlign: "center", mt: 5 }}>
              <button className="submit-btn">Submit</button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ textAlign: "center", my: 5 }}>
          <button className="check-download-btn">Download</button>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default TicketCheckOut;
