// import { Box, Grid, Typography } from "@mui/material";
// import React, { useEffect, useRef, useState } from "react";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import SyncAltIcon from "@mui/icons-material/SyncAlt";
// import CancelIcon from "@mui/icons-material/Cancel";
// import dayjs from "dayjs";
// import AddIcon from "@mui/icons-material/Add";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import LocationSuggestBox from "../../LocationSuggestBox/LocationSuggestBox";
// function CheckIn(props) {
//   // const [isOpen, setIsOpen] = React.useState(false);

//   const {
//     SetOpenDatePicker,
//     id,
//     value,
//     InputProps: { ref } = {},
//     inputProps: { "aria-label": ariaLabel } = {},
//   } = props;

//   return (
//     <>
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           flexDirection: "column",
//           cursor: "pointer",
//           padding: "8px",
//         }}
//         onClick={() => SetOpenDatePicker?.((prev) => !prev)}
//       >
//         <Box sx={{ display: "flex", alignItems: "center" }}>
//           <Typography sx={{ fontSize: "22px", fontWeight: "400" }}>
//             Depature{props.title}
//           </Typography>
//           <KeyboardArrowDownIcon />
//         </Box>
//         <Box id={id} ref={ref} aria-label={ariaLabel}>
//           <Typography sx={{ color: "#022474", fontSize: "17px" }}>
//             {value.$d.toString().split(" ").slice(1, 4).join(" ")}
//           </Typography>
//         </Box>
//       </Box>
//     </>
//   );
// }
// function CheckInDatePicker(props) {
//   const [openDatePicker, SetOpenDatePicker] = React.useState(false);
//   const [value, setValue] = React.useState(dayjs());

//   return (
//     <DatePicker
//       onChange={(newValue) => {
//         setValue(newValue);
//         // handleCheckInClick();
//       }}
//       disablePast
//       slots={{ field: CheckIn, ...props.slots }}
//       slotProps={{
//         field: { SetOpenDatePicker },
//         value: value,
//       }}
//       {...props}
//       open={openDatePicker}
//       onClose={() => SetOpenDatePicker(false)}
//       onOpen={() => SetOpenDatePicker(true)}
//       value={value} // Set the initial value here
//     />
//   );
// }

// const AddMoreTrip = ({ setLocationTwo }) => {
//   const [locationBox, setLocationBox] = useState({
//     isFrom: false,
//     isTo: false,
//   });
//   const [value, setValue] = useState(dayjs());
//   const [from, setFrom] = useState("Mumbai");
//   const [to, setTo] = useState("Pune");
//   // const [travelerInfo, setTravelerInfo] = useState({
//   //   adults: 1,
//   //   children: 0,
//   //   infants: 0,
//   // });

//   const locationFromRef = useRef(null);
//   const locationToRef = useRef(null);

//   const [isLocationshowFrom, setLocationshowFrom] = useState(false);
//   const [isLocationshowTo, setLocationshowTo] = useState(false);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         locationFromRef.current &&
//         !locationFromRef.current.contains(event.target)
//       ) {
//         setLocationshowFrom(false);
//       }
//       if (
//         locationToRef.current &&
//         !locationToRef.current.contains(event.target)
//       ) {
//         setLocationshowTo(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const handleSyncClick = () => {
//     const temp = from;
//     setFrom(to);
//     setTo(temp);
//   };
//   const handleMultiCityClick = (args) => {
//     if (args === 0) {
//       setLocationBox({
//         isFrom: true,
//         isTo: false,
//       });
//     } else if (args === 1) {
//       setLocationBox({
//         isFrom: false,
//         isTo: true,
//       });
//     }
//   };

//   useEffect(() => {
//     setLocationTwo({ to: to, from: from, value: value });
//   }, [to, from, value]);
//   return (
//     <Grid container spacing={0}>
//       <Grid
//         item
//         lg={4}
//         md={4}
//         sm={5}
//         xs={12}
//         sx={{ borderRight: "1px solid #000" }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-around",
//             width: "100%",
//             alignItems: "center",
//             gap: "20px",
//             padding: "10px",
//           }}
//         >
//           <Box
//             ref={locationFromRef}
//             onClick={() => setLocationshowFrom((oldVal) => !oldVal)}
//             sx={{
//               minWidth: "100px",
//               position: "relative",
//               cursor: "pointer",
//             }}
//           >
//             <Typography className="from-to-text">From</Typography>
//             <Typography className="location-text">
//               {from.airport_city_name}
//             </Typography>
//             {isLocationshowFrom ? (
//               <LocationSuggestBox
//                 type={"flightfrom"}
//                 setFrom={setFrom}
//                 locationBox={locationBox}
//                 setLocationBox={setLocationBox}
//               />
//             ) : null}
//           </Box>
//           <Box>
//             <Box
//               sx={{
//                 border: "1px solid #000",
//                 borderRadius: "50%",
//                 padding: "5px",
//                 cursor: "pointer",
//                 display: "flex",
//                 justifyContent: "center",
//               }}
//               onClick={handleSyncClick}
//             >
//               <SyncAltIcon sx={{ fontSize: "17px" }} />
//             </Box>
//           </Box>
//           <Box
//             sx={{
//               minWidth: "100px",
//               position: "relative",
//               cursor: "pointer",
//             }}
//             ref={locationToRef}
//             onClick={() => setLocationshowTo((oldVal) => !oldVal)}
//           >
//             <Typography className="from-to-text">To</Typography>
//             <Typography className="location-text">
//               {to.airport_city_name}
//             </Typography>
//             {isLocationshowTo ? (
//               <LocationSuggestBox
//                 type={"flightto"}
//                 setTo={setTo}
//                 to={to}
//                 locationBox={locationBox}
//                 setLocationBox={setLocationBox}
//               />
//             ) : null}
//           </Box>
//         </Box>
//       </Grid>
//       <Grid
//         item
//         lg={2}
//         md={4}
//         sm={3}
//         xs={6}
//         sx={{ borderRight: "1px solid #000" }}
//       >
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//           <CheckInDatePicker
//             label={value == null ? null : value.format("MM/DD/YYYY")}
//             value={value}
//             onChange={(newValue) => setValue(newValue)}
//           />
//         </LocalizationProvider>
//       </Grid>
//       <Grid item lg={4} md={4} sm={3} xs={6}>
//         <Box
//           sx={{
//             my: 2,
//             display: "flex",
//             justifyContent: "space-evenly",
//             alignItems: "center",
//             padding: "5px",
//           }}
//         >
//           <Typography
//             sx={{
//               border: "1px solid #000",
//               display: "flex",
//               gap: 1,
//               justifyContent: "center",
//               cursor: "pointer",
//               alignItems: "center",
//               py: 1,
//               px: 2,
//               maxWidth: "250px",
//               flex: 1,
//               fontSize: "14px",
//             }}
//           >
//             <AddIcon /> ADD ANOTHER CITY
//           </Typography>
//           <CancelIcon sx={{ cursor: "pointer" }} />
//         </Box>
//       </Grid>
//     </Grid>
//   );
// };

// export default AddMoreTrip;

// import { Box, Grid, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import SyncAltIcon from "@mui/icons-material/SyncAlt";
// import CancelIcon from "@mui/icons-material/Cancel";
// import AddIcon from "@mui/icons-material/Add";
// import dayjs from "dayjs";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import LocationSuggestBox from "../../LocationSuggestBox/LocationSuggestBox";

// function CheckIn(props) {
//   const {
//     SetOpenDatePicker,
//     id,
//     value,
//     InputProps: { ref } = {},
//     inputProps: { "aria-label": ariaLabel } = {},
//   } = props;

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         alignItems: "center",
//         flexDirection: "column",
//         cursor: "pointer",
//         padding: "8px",
//       }}
//       onClick={() => SetOpenDatePicker?.((prev) => !prev)}
//     >
//       <Box sx={{ display: "flex", alignItems: "center" }}>
//         <Typography sx={{ fontSize: "22px", fontWeight: "400" }}>
//           Departure{props.title}
//         </Typography>
//         <KeyboardArrowDownIcon />
//       </Box>
//       <Box id={id} ref={ref} aria-label={ariaLabel}>
//         <Typography sx={{ color: "#022474", fontSize: "17px" }}>
//           {value.$d.toString().split(" ").slice(1, 4).join(" ")}
//         </Typography>
//       </Box>
//     </Box>
//   );
// }

// function CheckInDatePicker(props) {
//   const [openDatePicker, SetOpenDatePicker] = React.useState(false);
//   const [value, setValue] = React.useState(dayjs());

//   return (
//     <DatePicker
//       onChange={(newValue) => {
//         setValue(newValue);
//       }}
//       disablePast
//       slots={{ field: CheckIn, ...props.slots }}
//       slotProps={{
//         field: { SetOpenDatePicker },
//         value: value,
//       }}
//       {...props}
//       open={openDatePicker}
//       onClose={() => SetOpenDatePicker(false)}
//       onOpen={() => SetOpenDatePicker(true)}
//       value={value}
//     />
//   );
// }

// const MultiCityTrip = ({ setLocationTwo, handleSearch }) => {
//   const [searchRows, setSearchRows] = useState([
//     { from: "Mumbai", to: "Pune", value: dayjs() },
//   ]);
//   const [openLocationBox, setOpenLocationBox] = useState({});

//   const handleSyncClick = (index) => {
//     setSearchRows((prev) =>
//       prev.map((row, i) => {
//         if (i === index) {
//           return { ...row, from: row.to, to: row.from };
//         }
//         return row;
//       })
//     );
//   };

//   const addSearchRow = () => {
//     setSearchRows((prev) => [
//       ...prev,
//       { from: "Mumbai", to: "Pune", value: dayjs() },
//     ]);
//   };

//   const removeSearchRow = (index) => {
//     setSearchRows((prev) => prev.filter((_, i) => i !== index));
//   };

//   useEffect(() => {
//     setLocationTwo(searchRows);
//   }, [searchRows, setLocationTwo]);

//   const handleLocationBoxToggle = (type, index) => {
//     setOpenLocationBox((prev) => ({
//       ...prev,
//       [index]: prev[index] === type ? null : type,
//     }));
//   };

//   return (
//     <Grid container spacing={0} alignItems="stretch">
//       {searchRows.map((row, index) => (
//         <React.Fragment key={index}>
//           <Grid
//             item
//             lg={4}
//             md={4}
//             sm={5}
//             xs={12}
//             sx={{ borderRight: "1px solid #000" }}
//           >
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-around",
//                 width: "100%",
//                 alignItems: "center",
//                 gap: "20px",
//                 padding: "10px",
//               }}
//             >
//               <Box
//                 onClick={() => handleLocationBoxToggle("isFrom", index)}
//                 sx={{
//                   minWidth: "100px",
//                   position: "relative",
//                   cursor: "pointer",
//                 }}
//               >
//                 <Typography className="from-to-text">From</Typography>
//                 <Typography className="location-text">{row.from}</Typography>
//                 {openLocationBox[index] === "isFrom" && (
//                   <LocationSuggestBox
//                     type={"flightfrom"}
//                     setFrom={(from) =>
//                       setSearchRows((prev) =>
//                         prev.map((r, i) => (i === index ? { ...r, from } : r))
//                       )
//                     }
//                     locationBox={openLocationBox}
//                     setLocationBox={setOpenLocationBox}
//                   />
//                 )}
//               </Box>
//               <Box>
//                 <Box
//                   sx={{
//                     border: "1px solid #000",
//                     borderRadius: "50%",
//                     padding: "5px",
//                     cursor: "pointer",
//                     display: "flex",
//                     justifyContent: "center",
//                   }}
//                   onClick={() => handleSyncClick(index)}
//                 >
//                   <SyncAltIcon sx={{ fontSize: "17px" }} />
//                 </Box>
//               </Box>
//               <Box
//                 onClick={() => handleLocationBoxToggle("isTo", index)}
//                 sx={{
//                   minWidth: "100px",
//                   position: "relative",
//                   cursor: "pointer",
//                 }}
//               >
//                 <Typography className="from-to-text">To</Typography>
//                 <Typography className="location-text">{row.to}</Typography>
//                 {openLocationBox[index] === "isTo" && (
//                   <LocationSuggestBox
//                     type={"flightto"}
//                     setTo={(to) =>
//                       setSearchRows((prev) =>
//                         prev.map((r, i) => (i === index ? { ...r, to } : r))
//                       )
//                     }
//                     locationBox={openLocationBox}
//                     setLocationBox={setOpenLocationBox}
//                   />
//                 )}
//               </Box>
//             </Box>
//           </Grid>
//           <Grid
//             item
//             lg={2}
//             md={4}
//             sm={3}
//             xs={6}
//             sx={{ borderRight: "1px solid #000" }}
//           >
//             <LocalizationProvider dateAdapter={AdapterDayjs}>
//               <CheckInDatePicker
//                 label={
//                   row.value == null ? null : row.value.format("MM/DD/YYYY")
//                 }
//                 value={row.value}
//                 onChange={(newValue) =>
//                   setSearchRows((prev) =>
//                     prev.map((r, i) =>
//                       i === index ? { ...r, value: newValue } : r
//                     )
//                   )
//                 }
//               />
//             </LocalizationProvider>
//           </Grid>
//           <Grid item lg={4} md={4} sm={3} xs={6}>
//             <Box
//               sx={{
//                 my: 2,
//                 display: "flex",
//                 justifyContent: "space-evenly",
//                 alignItems: "center",
//                 padding: "5px",
//               }}
//             >
//               <Typography
//                 sx={{
//                   border: "1px solid #000",
//                   display: "flex",
//                   gap: 1,
//                   justifyContent: "center",
//                   cursor: "pointer",
//                   alignItems: "center",
//                   py: 1,
//                   px: 2,
//                   maxWidth: "250px",
//                   flex: 1,
//                   fontSize: "14px",
//                 }}
//                 onClick={addSearchRow}
//               >
//                 <AddIcon /> ADD ANOTHER CITY
//               </Typography>
//               {index !== 0 && (
//                 <CancelIcon
//                   sx={{ cursor: "pointer" }}
//                   onClick={() => removeSearchRow(index)}
//                 />
//               )}
//             </Box>
//           </Grid>
//         </React.Fragment>
//       ))}
//     </Grid>
//   );
// };

// export default MultiCityTrip;

import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LocationSuggestBox from "../../LocationSuggestBox/LocationSuggestBox";

function CheckIn(props) {
  const {
    SetOpenDatePicker,
    id,
    value,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        cursor: "pointer",
        padding: "8px",
      }}
      onClick={() => SetOpenDatePicker?.((prev) => !prev)}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontSize: "22px", fontWeight: "400" }}>
          Departure{props.title}
        </Typography>
        <KeyboardArrowDownIcon />
      </Box>
      <Box id={id} ref={ref} aria-label={ariaLabel}>
        <Typography sx={{ color: "#022474", fontSize: "17px" }}>
          {value.$d.toString().split(" ").slice(1, 4).join(" ")}
        </Typography>
      </Box>
    </Box>
  );
}

function CheckInDatePicker(props) {
  const [openDatePicker, SetOpenDatePicker] = React.useState(false);
  const [value, setValue] = React.useState(dayjs());

  return (
    <DatePicker
      onChange={(newValue) => {
        setValue(newValue);
      }}
      disablePast
      slots={{ field: CheckIn, ...props.slots }}
      slotProps={{
        field: { SetOpenDatePicker },
        value: value,
      }}
      {...props}
      open={openDatePicker}
      onClose={() => SetOpenDatePicker(false)}
      onOpen={() => SetOpenDatePicker(true)}
      value={value}
    />
  );
}

const MultiCityTrip = ({ setLocationTwo, handleSearch }) => {
  const [searchRows, setSearchRows] = useState([
    { from: "Mumbai", to: "Pune", value: dayjs() },
  ]);
  const [openLocationBox, setOpenLocationBox] = useState({});

  const handleSyncClick = (index) => {
    setSearchRows((prev) =>
      prev.map((row, i) => {
        if (i === index) {
          return { ...row, from: row.to, to: row.from };
        }
        return row;
      })
    );
  };

  // const addSearchRow = () => {
  //   setSearchRows((prev) => [
  //     ...prev,
  //     { from: "Mumbai", to: "Pune", value: dayjs() },
  //   ]);
  // };
  const addSearchRow = () => {
    if (searchRows.length < 4) {
      setSearchRows((prev) => [
        ...prev,
        { from: "Mumbai", to: "Pune", value: dayjs() },
      ]);
    }
  };

  const removeSearchRow = (index) => {
    setSearchRows((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    setLocationTwo(searchRows);
  }, [searchRows, setLocationTwo]);

  const handleLocationBoxToggle = (type, index) => {
    setOpenLocationBox((prev) => ({
      ...prev,
      [index]: prev[index] === type ? null : type,
    }));
  };

  return (
    <Grid container spacing={0} alignItems="stretch">
      {searchRows.map((row, index) => (
        <React.Fragment key={index}>
          <Grid
            item
            lg={4}
            md={4}
            sm={5}
            xs={12}
            sx={{ borderRight: "1px solid #000" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                alignItems: "center",
                gap: "20px",
                padding: "10px",
              }}
            >
              <Box
                onClick={() => handleLocationBoxToggle("isFrom", index)}
                sx={{
                  minWidth: "100px",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <Typography className="from-to-text">From</Typography>
                <Typography className="location-text">
                  {row.from.airport_city_name || row.from}
                </Typography>
                {openLocationBox[index] === "isFrom" && (
                  <LocationSuggestBox
                    type={"flightfrom"}
                    setFrom={(from) =>
                      setSearchRows((prev) =>
                        prev.map((r, i) => (i === index ? { ...r, from } : r))
                      )
                    }
                    locationBox={openLocationBox}
                    setLocationBox={setOpenLocationBox}
                  />
                )}
              </Box>
              <Box>
                <Box
                  sx={{
                    border: "1px solid #000",
                    borderRadius: "50%",
                    padding: "5px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => handleSyncClick(index)}
                >
                  <SyncAltIcon sx={{ fontSize: "17px" }} />
                </Box>
              </Box>
              <Box
                onClick={() => handleLocationBoxToggle("isTo", index)}
                sx={{
                  minWidth: "100px",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <Typography className="from-to-text">To</Typography>
                <Typography className="location-text">
                  {row.to.airport_city_name || row.to}
                </Typography>
                {openLocationBox[index] === "isTo" && (
                  <LocationSuggestBox
                    type={"flightto"}
                    setTo={(to) =>
                      setSearchRows((prev) =>
                        prev.map((r, i) => (i === index ? { ...r, to } : r))
                      )
                    }
                    locationBox={openLocationBox}
                    setLocationBox={setOpenLocationBox}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            lg={2}
            md={4}
            sm={3}
            xs={6}
            sx={{ borderRight: "1px solid #000" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CheckInDatePicker
                label={
                  row.value == null ? null : row.value.format("MM/DD/YYYY")
                }
                value={row.value}
                onChange={(newValue) =>
                  setSearchRows((prev) =>
                    prev.map((r, i) =>
                      i === index ? { ...r, value: newValue } : r
                    )
                  )
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <Box
              sx={{
                my: 2,
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <Typography
                sx={{
                  border: "1px solid #000",
                  display: "flex",
                  gap: 1,
                  justifyContent: "center",
                  cursor: "pointer",
                  alignItems: "center",
                  py: 1,
                  px: 2,
                  maxWidth: "250px",
                  flex: 1,
                  fontSize: "14px",
                }}
                onClick={addSearchRow}
              >
                <AddIcon /> ADD ANOTHER CITY
              </Typography>

              <CancelIcon
                sx={{ cursor: "pointer" }}
                onClick={() => index !== 0 && removeSearchRow(index)}
              />
            </Box>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default MultiCityTrip;
