import React, { useEffect, useRef, useState } from "react"
import SpecialOffersSection from "../../layouts/SpecialOffersSection"
import TopDestinationSection from "../../layouts/TopDestinationSection"
import AboutAppSection from "../../layouts/AboutAppSection"
import ExploreMoreToureSection from "../../layouts/ExploreMoreToureSection"
import Footer from "../footer/Footer"
import { Box, Container, Grid, Typography } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { useDispatch, useSelector } from "react-redux"
import SearchIcon from "@mui/icons-material/Search"

import { useNavigate } from "react-router-dom"
import LocationSuggestBox from "../../layouts/Flight/LocationSuggestBox/LocationSuggestBox"
import { getBusDetails } from "../../redux/bus/busapi"
import { setSearchData } from "../../redux/bus/busslice"
import Loader from "../../layouts/Loaders/Loader"

function CheckIn(props) {
  const {
    SetOpenDatePicker,
    id,
    value,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props

  const getDayOfWeek = (value) => {
    // Get the day of the week using dayjs
    const dayOfWeek = dayjs(value).format("dddd")

    // Return the day of the week
    return dayOfWeek
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
        }}
        onClick={() => SetOpenDatePicker?.((prev) => !prev)}
        id={id}
        ref={ref}
        aria-label={ariaLabel}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>
            Travel Date
          </Typography>

          <KeyboardArrowDownIcon />
        </Box>
        <Box>
          <Typography sx={{ fontSize: "22px", fontWeight: "700", mb: 1 }}>
            {value.$d.toString().split(" ").slice(1, 4).join(" ")}
          </Typography>
          <Typography sx={{ fontSize: "17px", fontWeight: "400" }}>
            {getDayOfWeek(value)}
          </Typography>
        </Box>
      </Box>
    </>
  )
}
function CheckInDatePicker(props) {
  const [openDatePicker, SetOpenDatePicker] = React.useState(false)
  const [value, setValue] = React.useState(dayjs())
  const searchData = useSelector((state) => state?.busdata?.searchData)
  useEffect(() => {
    setValue(dayjs(searchData.DateData))
  }, [searchData])
  return (
    <DatePicker
      onChange={(newValue) => {
        setValue(newValue)
        // handleCheckInClick();
      }}
      disablePast
      slots={{ field: CheckIn, ...props.slots }}
      slotProps={{
        field: { SetOpenDatePicker },
        value: value,
      }}
      {...props}
      open={openDatePicker}
      onClose={() => SetOpenDatePicker(false)}
      onOpen={() => SetOpenDatePicker(true)}
      value={value} // Set the initial value here
    />
  )
}
const BusHomePage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const searchData = useSelector((state) => state?.busdata?.searchData)

  // const [value, setValue] = useState(dayjs(searchData?.DateData));
  const [value, setValue] = useState(dayjs())

  useEffect(() => {
    setValue(searchData?.DateData ? dayjs(searchData.DateData) : dayjs())
  }, [searchData.DateData])

  const [BusCityDetails, setBusCityDetails] = useState({})
  const [staticData, setStaticData] = useState({
    source_city: "Mumbai",
    source_code: "3534",
    destination_city: "Pune",
    destination_code: "9771",
    depart_date: "2020-06-14",
  })
  const [isLocationshowFrom, setLocationshowFrom] = useState(false)
  const [isLocationshowTo, setLocationshowTo] = useState(false)
  const locationFromRef = useRef(null)
  const locationToRef = useRef(null)
  const handleSearch = async () => {
    dispatch(getBusDetails(BusCityDetails)).then(() => {
      dispatch(setSearchData(BusCityDetails ? BusCityDetails : staticData))
      navigate("/bus-booking-page")
    })
  }
  useEffect(() => {
    setBusCityDetails(searchData)
  }, [searchData])

  const handleDateChange = (newValue) => {
    setValue(newValue)
    setBusCityDetails((prevLocation) => {
      return {
        ...prevLocation,
        ["depart_date"]: dayjs(newValue).format("YYYY-MM-DD"),
        ["DateData"]: newValue,
      }
    })
    // depart_date
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        locationFromRef.current &&
        !locationFromRef.current.contains(event.target)
      ) {
        setLocationshowFrom(false)
      }
      if (
        locationToRef.current &&
        !locationToRef.current.contains(event.target)
      ) {
        setLocationshowTo(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const loading = useSelector((state) => state?.busdata?.loading)

  if (loading) return <Loader />
  return (
    <div>
      <Box>
        <Container maxWidth="xl">
          <Box className="bus-home-head-section">
            <Grid container spacing={2} alignItems={"center"}>
              <Grid
                item
                lg={4}
                md={3}
                sm={4}
                xs={12}
                onClick={() => setLocationshowFrom(!isLocationshowFrom)}
                // onClick={() => handleOneWayClick(0)}
              >
                <Box
                  ref={locationFromRef}
                  sx={{
                    cursor: "pointer",
                    position: "relative",
                    paddingLeft: { lg: "20px", xs: "0px" },
                  }}
                >
                  <Typography className="from-to-text">From </Typography>
                  <Typography className="location-main-text">
                    {BusCityDetails && BusCityDetails?.source_city
                      ? BusCityDetails.source_city
                      : "Please Select a City"}
                  </Typography>
                  {/* <Typography className="country-text">India</Typography> */}
                  {isLocationshowFrom ? (
                    <LocationSuggestBox
                      type={"from"}
                      setBusCityDetails={setBusCityDetails}
                      // handleOneWayClick={handleOneWayClick}
                      // handleCloseLocationBox={handleCloseLocationBox}
                    />
                  ) : null}
                </Box>
              </Grid>
              <Grid
                item
                lg={4}
                md={3}
                sm={4}
                xs={12}
                // onClick={() => handleOneWayClick(1)}
                onClick={() => setLocationshowTo(!isLocationshowTo)}
              >
                <Box
                  ref={locationToRef}
                  sx={{ position: "relative", cursor: "pointer" }}
                >
                  <Typography className="from-to-text">To </Typography>
                  <Typography className="location-main-text">
                    {BusCityDetails && BusCityDetails?.destination_city
                      ? BusCityDetails.destination_city
                      : "Please Select a City"}
                  </Typography>
                  {/* <Typography className="country-text">India</Typography> */}
                  {isLocationshowTo ? (
                    <LocationSuggestBox
                      type={"to"}
                      setBusCityDetails={setBusCityDetails}
                    />
                  ) : null}
                </Box>
              </Grid>
              <Grid
                item
                lg={2}
                md={3}
                sm={3}
                xs={6}
                // onClick={() => handleOneWayClick(3)}
              >
                <Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CheckInDatePicker
                        label={
                          value == null ? null : value.format("MM/DD/YYYY")
                        }
                        value={value}
                        // onChange={(newValue) => setValue(newValue)}
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                lg={1}
                md={2}
                sm={1}
                xs={6}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <Box
                  className="search-icon-box"
                  onClick={handleSearch}
                  sx={{ cursor: "pointer" }}
                >
                  <SearchIcon className="search-icon" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <SpecialOffersSection />
      <TopDestinationSection />
      <AboutAppSection />
      <ExploreMoreToureSection />
      <section>
        <Footer />
      </section>
    </div>
  )
}

export default BusHomePage
