import { createSlice } from "@reduxjs/toolkit";
import {
  getHotelCountryDetails,
  hotelSearch,
  blockRoomInfo,
  hotelBookRoom,
  hotelCancelRoom,
  hotelRoomInfo,
  hotelInfo,
  userHotelBookings,
} from "./hotelapi";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    hotelDetails: null,
    hotelSearchData: null,
    hotelInfo: null,
    searchData: null,
    selectedHotel: null,
    selectedRoom: null,
    hotelRoomInfo: null,
    blockRoomInfosuccess: false,
    BookedRoom: null,
    loading: false,
    error: null,
  },
  reducers: {
    // Add any reducers here if needed
    setHomePageSearchData: (state, action) => {
      state.searchData = action.payload;
    },
    setSelectedHotel: (state, action) => {
      state.selectedHotel = action.payload;
    },
    setSelectedRoom: (state, action) => {
      state.selectedRoom = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getHotelCountryDetails.pending, (state) => {
        state.loading = false;
      })
      .addCase(getHotelCountryDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.hotelDetails = action.payload;
      })
      .addCase(getHotelCountryDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(hotelSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(hotelSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.hotelSearchData = action.payload;
      })
      .addCase(hotelSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(hotelInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(hotelInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.hotelInfo = action.payload;
      })
      .addCase(hotelInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(hotelRoomInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(hotelRoomInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.hotelRoomInfo = action.payload;
      })
      .addCase(hotelRoomInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(blockRoomInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(blockRoomInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.blockRoomInfosuccess = true;
      })
      .addCase(blockRoomInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(hotelBookRoom.pending, (state) => {
        state.loading = true;
      })
      .addCase(hotelBookRoom.fulfilled, (state, action) => {
        state.loading = false;
        state.BookedRoom = action.payload;
      })
      .addCase(hotelBookRoom.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Add more cases here if needed
  },
});

export const { setSelectedRoom, setSelectedHotel, setHomePageSearchData } =
  authSlice.actions;

export default authSlice.reducer;
